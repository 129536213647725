import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface ISelectedDeviceCompanySlice {
    company: any;
    refreshCompany: number;
}

const initialState: ISelectedDeviceCompanySlice = {
    company: undefined,
    refreshCompany: 0,
};

const selectedDeviceCompanySlice = createSlice({
    name: 'selectedDeviceCompanySlice',
    initialState,
    reducers: {
        selectedGroupInit(state:any) {
            state.device = {};
        },
        selectedGroupSet(state:any, action: PayloadAction<ISelectedDeviceCompanySlice>) {
            const { company: company } = action.payload;
            state.company = company;
        },
        refreshCompanyList(state) {
            state.refreshCompany++;
        },
    },
});

export const { selectedGroupInit, selectedGroupSet, refreshCompanyList } = selectedDeviceCompanySlice.actions;

export default selectedDeviceCompanySlice.reducer;
