import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import {
    ENUMS,
    getColumnDateOption,
    getColumnSearch,
    getColumnSearchOption,
    geti18nText,
    NyUtils,
} from '@nybble/nyreact';
import { getTimezone } from '../../utils/Utils';

function DeviceErrorColumns(props?: any) {
    return [
        {
            title: geti18nText('device.error.table.id'),
            dataIndex: 'id',
            width: '2%',
            align: 'center',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
        },
        {
            title: geti18nText('device.error.table.error.code'),
            dataIndex: 'errorCode',
            width: '5%',
            align: 'center',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('device.error.table.error'),
            width: '5%',
            dataIndex: 'error',
            align: 'center',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('device.error.table.count.mode'),
            dataIndex: 'countMode',
            width: '4%',
            align: 'center',
            render: (text: any, record: { countMode: any }) => {
                if (record.countMode) {
                    return <CheckOutlined style={{ color: 'green' }} />;
                } else {
                    return <CloseOutlined style={{ color: 'red' }} />;
                }
            },
        },
        {
            title: geti18nText('device.error.table.hopper.exist'),
            dataIndex: 'hopperExist',
            width: '4%',
            align: 'center',
            render: (text: any, record: { hopperExist: any }) => {
                if (record.hopperExist) {
                    return <CheckOutlined style={{ color: 'green' }} />;
                } else {
                    return <CloseOutlined style={{ color: 'red' }} />;
                }
            },
        },
        {
            title: geti18nText('device.error.table.hopper.fail.feed'),
            dataIndex: 'hopperFailFeed',
            width: '4%',
            align: 'center',
            render: (text: any, record: { hopperFailFeed: any }) => {
                if (record.hopperFailFeed) {
                    return <CheckOutlined style={{ color: 'green' }} />;
                } else {
                    return <CloseOutlined style={{ color: 'red' }} />;
                }
            },
        },
        {
            title: geti18nText('device.error.table.reject.exist'),
            dataIndex: 'rejectExists',
            width: '4%',
            align: 'center',
            render: (text: any, record: { rejectExists: any }) => {
                if (record.rejectExists) {
                    return <CheckOutlined style={{ color: 'green' }} />;
                } else {
                    return <CloseOutlined style={{ color: 'red' }} />;
                }
            },
        },
        {
            title: geti18nText('device.error.table.reject.full'),
            dataIndex: 'rejectFull',
            width: '4%',
            align: 'center',
            render: (text: any, record: { rejectFull: any }) => {
                if (record.rejectFull) {
                    return <CheckOutlined style={{ color: 'green' }} />;
                } else {
                    return <CloseOutlined style={{ color: 'red' }} />;
                }
            },
        },
        {
            title: geti18nText('device.error.table.reject.near.full'),
            dataIndex: 'rejectNearFull',
            width: '4%',
            align: 'center',
            render: (text: any, record: { rejectNearFull: any }) => {
                if (record.rejectNearFull) {
                    return <CheckOutlined style={{ color: 'green' }} />;
                } else {
                    return <CloseOutlined style={{ color: 'red' }} />;
                }
            },
        },
        {
            title: geti18nText('device.error.table.reject.waiting'),
            dataIndex: 'rejectWaiting',
            width: '4%',
            align: 'center',
            render: (text: any, record: { rejectWaiting: any }) => {
                if (record.rejectWaiting) {
                    return <CheckOutlined style={{ color: 'green' }} />;
                } else {
                    return <CloseOutlined style={{ color: 'red' }} />;
                }
            },
        },
        {
            title: geti18nText('device.error.table.error.time'),
            dataIndex: 'errorTime',
            width: '10%',
            align: 'center',
            sorter: (a: any, b: any) => {},
            render: (text: string, record: { [index: string]: any }) => {
                if (record.errorTime) {
                    return (
                        <div>
                            {new Date(record.errorTime).toLocaleString(NyUtils.getSelectedLocale(), getTimezone())}
                        </div>
                    );
                }
            },
            ...getColumnDateOption(true, undefined, undefined, true),
        },
    ];
}
export default DeviceErrorColumns;

export const DetailsErrorCSVColCustomization: any = () => {
    return [
        {
            errorTime: (value: string) => {
                return new Date(value).toLocaleString(NyUtils.getSelectedLocale(), getTimezone());
            },
        },
        {
            countMode: (value: boolean) => {
                return value ? 'TRUE' : 'FALSE';
            },
        },
        {
            hopperExist: (value: boolean) => {
                return value ? 'TRUE' : 'FALSE';
            },
        },

        {
            hopperFailFeed: (value: boolean) => {
                return value ? 'TRUE' : 'FALSE';
            },
        },
        {
            rejectExists: (value: boolean) => {
                return value ? 'TRUE' : 'FALSE';
            },
        },
        {
            rejectFull: (value: boolean) => {
                return value ? 'TRUE' : 'FALSE';
            },
        },
        {
            rejectNearFull: (value: boolean) => {
                return value ? 'TRUE' : 'FALSE';
            },
        },
        {
            rejectWaiting: (value: boolean) => {
                return value ? 'TRUE' : 'FALSE';
            },
        },
    ];
};
