import { geti18nText, NyDataEdit, NySession, NyUtils } from '@nybble/nyreact';
import { Col, Form, Input, Row } from 'antd';
import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { CONSTANTS_REQ } from '../../../utils/Constants';

const ServiceVendorEdit = (props: any) => {
    const [editHeader, setEditHeader] = useState(geti18nText('service.vendor.table.header'));
    const [loading, setLoading] = useState(false);
    const [isCreate, setIsCreate] = useState(false);
    const { id } = useParams<any>();
    const history = useHistory();
    const [form] = Form.useForm();

    const onModalClose = () => {
        setEditHeader(geti18nText('service.vendor.table.header'));
        form.resetFields();
    };
    function setValues(dataForm: any) {
        setEditHeader(dataForm.name);
        form.setFieldsValue(dataForm);
    }

    return (
        <NyDataEdit
            layout="horizontal"
            formProps={{ labelCol: { span: 7 }, wrapperCol: { span: 17 } }}
            editHeader={editHeader}
            loading={loading}
            setLoading={setLoading}
            onModalClose={onModalClose}
            url={CONSTANTS_REQ.SERVICE_VENDOR.EDIT}
            setValues={setValues}
            width={800}
            form={form}
            goBack={() => history.goBack()}
            paramsId={id}
            setIsCreate={setIsCreate}
            {...props}
            hideButtons={!NySession.hasAnyRole(['RULE_UPDATE_D_MANUFACTURE'])}
        >
            <Row gutter={24}>
                <Form.Item name="id" style={{ display: 'none' }}>
                    <Input readOnly={true} />
                </Form.Item>
                <Col span={18}>
                    <Form.Item
                        rules={[
                            {
                                required: true,
                                message: geti18nText('app.default.required'),
                            },
                        ]}
                        label={geti18nText('service.vendor.table.name')}
                        name="name"
                    >
                        <Input />
                    </Form.Item>
                </Col>
            </Row>
        </NyDataEdit>
    );
};

export default ServiceVendorEdit;
