import { geti18nText, NyDataEdit, NySession, NyMaskContent, NyRequestResolver, RESPONSE, NyUtils } from '@nybble/nyreact';
import { Col, Form, Input, Row, Tabs, Image, Descriptions, Tooltip, Button, Modal, InputNumber } from 'antd';
import L from 'leaflet';
import React, { useRef, useState } from 'react';
import { LatLng, Map, Marker, Popup, TileLayer } from 'react-leaflet';
import { useHistory, useParams } from 'react-router-dom';
import { CONSTANTS_REQ, URL_PREFIX } from '../../../utils/Constants';
import DeviceIndex from '../../device';

const AdminLocationEdit = (props: any) => {
    const [editHeader, setEditHeader] = useState(geti18nText('office.edit.new'));
    const [loading, setLoading] = useState(false);
    const { id } = useParams<any>();
    const history = useHistory();
    const [form] = Form.useForm();
    const { TabPane } = Tabs;
    const [deviceItem, setDeviceItem] = useState<any>(null);
    const [openModalCreate, setOpenModalCreate] = useState<any>(false);
    const [modalVisible, setModalVisible] = useState(false);
    const [position, setPosition] = useState<any>();
    const [officeId, setOfficeId] = useState<any>();
    const mapRef = useRef<Map>(null);

    const onModalClose = () => {
        setOpenModalCreate(false);
        form.resetFields();
    };
    function setValues(dataForm: any) {
        setEditHeader(dataForm.name);
        setDeviceItem(dataForm);
        if (dataForm.latitude && dataForm.longitude) {
            setPosition({ lat: dataForm.latitude, lng: dataForm.longitude });
        }
        setOfficeId(dataForm.id);

        form.setFieldsValue(dataForm);
    }

    function setIsCreate(val: any) {
        if (val === true) {
            setOpenModalCreate(true);
        } else {
            setOpenModalCreate(false);
        }
    }

    const getGlobalCoordinates = () => {
        form.setFieldsValue({
            longitude: position.lng,
            latitude: position.lat,
        });
        setPosition(null);
        setModalVisible(false);
    };

    function addMarker(e: any) {
        setPosition(e.latlng);
    }

    var homeIcon = L.divIcon({
        className: 'marker-pin-icon',
        html: '<div class="marker-pin marker-pin-bounce"></div><i aria-label="icon: home" class="anticon anticon-home marker-pin-bounce-icon"><svg viewBox="64 64 896 896" focusable="false" class="" data-icon="home" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M946.5 505L534.6 93.4a31.93 31.93 0 0 0-45.2 0L77.5 505c-12 12-18.8 28.3-18.8 45.3 0 35.3 28.7 64 64 64h43.4V908c0 17.7 14.3 32 32 32H448V716h112v224h265.9c17.7 0 32-14.3 32-32V614.3h43.4c17 0 33.3-6.7 45.3-18.8 24.9-25 24.9-65.5-.1-90.5z"></path></svg></i>',
        iconSize: [25, 41],
        iconAnchor: [12, 41],
        popupAnchor: [1, -34],
        shadowSize: [41, 41],
    });

    function openMap() {
        setModalVisible(true);
    }

    function onCancel() {
        setModalVisible(false);
        setPosition(null);
    }

    function onSaveAndGetData(data: any) {
        const offices: [any] = NyUtils.load('OFFICE');
        if (data.active === true) {
            let index = offices.findIndex((el) => el.id === data.id);
            if (index != -1) offices.splice(index, 1);
            offices.push({ id: data.id, name: data.name });
        } else {
            let index = offices.findIndex((el) => el.id === data.id);
            if (index != -1) offices.splice(index, 1);
        }
        NyUtils.save('OFFICE', offices ? offices : []);
        props.onSaveAndGetData(data);
    }

    return (
        <NyDataEdit
            layout="horizontal"
            formProps={{ labelCol: { span: 5 }, wrapperCol: { span: 15 } }}
            editHeader={editHeader}
            loading={loading}
            setLoading={setLoading}
            onModalClose={onModalClose}
            url={CONSTANTS_REQ.LOCATION.EDIT}
            setValues={setValues}
            width={1500}
            form={form}
            goBack={() => history.goBack()}
            paramsId={id}
            setIsCreate={setIsCreate}
            {...props}
            onSaveAndGetData={onSaveAndGetData}
            hideButtons={!NySession.hasAnyRole(['RULE_CRUD_BRANCH'])}
        >
            <Tabs defaultActiveKey="1" type="card">
                <TabPane tab={geti18nText('office.edit.tab.info')} key="1">
                    <Row gutter={24}>
                        <Form.Item name="id" style={{ display: 'none' }}>
                            <Input readOnly={true} />
                        </Form.Item>
                        <Col span={12}>
                            <Form.Item
                                rules={[
                                    {
                                        required: true,
                                        message: geti18nText('app.default.required'),
                                    },
                                ]}
                                label={geti18nText('office.table.column.name')}
                                name="name"
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label={geti18nText('office.table.column.external_id')} name="externalId">
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item label={geti18nText('office.table.column.hnb_unit_code')} name="hnbUnitCode">
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                rules={[
                                    {
                                        required: true,
                                        message: geti18nText('app.default.required'),
                                    },
                                ]}
                                label={geti18nText('office.table.column.address')}
                                name="address"
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item label={geti18nText('office.table.column.place')} name="place">
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label={geti18nText('office.table.column.house_number')} name="houseNumber">
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item label={geti18nText('device.edit.longitude')} name="longitude">
                                <InputNumber style={{ width: '100%' }} decimalSeparator="." />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label={geti18nText('device.edit.latitude')} name="latitude">
                                <InputNumber style={{ width: '100%' }} decimalSeparator="." />
                            </Form.Item>
                        </Col>
                        ¸
                    </Row>
                    <Row gutter={24}>
                        <Col span={24} offset={2}>
                            <Button type="primary" onClick={openMap}>
                                {geti18nText('device.edit.showMap')}
                            </Button>
                        </Col>
                    </Row>
                </TabPane>
                <TabPane disabled={openModalCreate} tab={geti18nText('office.edit.tab.devices')} key="2">
                    <DeviceIndex isAdmin={true} officeId={officeId}></DeviceIndex>
                </TabPane>
            </Tabs>
            <Modal
                title={geti18nText('customer.edit.ModalTitle')}
                visible={modalVisible}
                onCancel={onCancel}
                onOk={getGlobalCoordinates}
                destroyOnClose={true}
                okText={geti18nText('customer.edit.ModalOkText')}
                width={'900px'}
            >
                <Map
                    center={[45.74497120551588, 16.10733032226563]}
                    onclick={addMarker}
                    zoom={7}
                    style={{ height: '500px', width: '100%' }}
                    ref={mapRef}
                >
                    <TileLayer
                        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                    {position && <Marker icon={homeIcon} position={position}></Marker>}
                </Map>
            </Modal>
        </NyDataEdit>
    );
};

export default AdminLocationEdit;
