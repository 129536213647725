import { geti18nText, NySession } from '@nybble/nyreact';
import { Layout, Menu } from 'antd';
import React, { useEffect, useState } from 'react';
import { RootState } from '../../rootReducer';
import { useDispatch, useSelector } from 'react-redux';

import { SearchOutlined } from '@ant-design/icons';
import { setMenuCollapsed, setActive } from '../../slices/menuSlice';
import './index.scss';
import { INyLayoutMenu } from './types';
import { useHistory } from 'react-router-dom';

const { Sider } = Layout;
const { SubMenu } = Menu;

const LayoutMenu = ({
    menuHistory,
    menuItems,
    menuLocation,
    menuLogo,
    menuTheme = 'light',
    menuWidth = 250,
    siderClassName,
    menuClassName,
    noRoles = false,
}: INyLayoutMenu) => {
    const [selectedKeys, setSelectedKeys] = useState<any>([]);
    const [breakpoint, setBreakpoint] = useState<boolean>(false);
    const [openKeys, setOpenKeys] = useState<any>([]);
    const history = useHistory();
    const { collapsed, active } = useSelector((state: RootState) => state.menu);

    const dispatch = useDispatch();

    let root = document.documentElement;

    useEffect(() => {
        setSelectedKeys(findKey(menuItems, undefined, menuLocation.pathname));
        checkOverflow();
        dispatch(setMenuCollapsed(collapsed));
        if (collapsed) {
            root.style.setProperty('--sticky-width', '80px');
        } else {
            root.style.setProperty('--sticky-width', '200px');
        }
    }, []);

    useEffect(() => {
        dispatch(setActive(menuLocation.pathname));
    }, []);

    useEffect(() => {
        setTimeout(checkOverflow, 500);
    }, [collapsed]);

    useEffect(() => {
        if (NySession.isUserAuthenticated()) {
            if (active) {
                setSelectedKeys(findKey(menuItems, undefined, active));
            }
            if (
                NySession?.getUser()?.validAppCertificate != null &&
                NySession?.getUser()?.validAppCertificate === false
            ) {
                history.push('/admin/cert');
            }
        }
    }, [active]);

    const checkOverflow = () => {
        let elements = Array.from(document.getElementsByClassName('menu-element'));
        elements.forEach((element: any) => {
            if (0 > element.clientWidth - element.scrollWidth) {
                element.classList.add('marquee');
            }
        });
    };

    function generateMenu(menus: any) {
        let retValue = [];
        let menu: any;
        for (menu of menus) {
            if (NySession.hasAnyRole(menu.role) || noRoles) {
                if (menu.submenu) {
                    retValue.push(
                        <SubMenu
                            key={menu.key}
                            icon={menu.icon}
                            onTitleClick={menuSelected}
                            title={geti18nText(menu.i18n)}
                        >
                            {generateMenu(menu.submenu)}
                        </SubMenu>
                    );
                } else {
                    retValue.push(
                        <Menu.Item
                            className="menu-item"
                            key={menu.key}
                            icon={menu.icon}
                            menu-path={menu.path}
                            menu-i18n={menu.i18n}
                        >
                            {/* {geti18nText(menu.i18n)} */}
                            {collapsed ? (
                                geti18nText(menu.i18n)
                            ) : (
                                <div className="menu-element">
                                    <span>
                                        <span className="menu-text" style={{ textOverflow: 'ellipsis' }}>
                                            {geti18nText(menu.i18n)}
                                        </span>
                                    </span>
                                </div>
                            )}
                        </Menu.Item>
                    );
                }
            }
        }
        return retValue;
    }

    function menuSelected(selected: any) {
        if (selected.hasOwnProperty('keyPath')) {
            dispatch(setActive(selected.item.props['menu-path']));
        } else {
            dispatch(setActive(undefined));
            setSelectedKeys(findKey(menuItems, selected.key));
        }

        setTimeout(checkOverflow, 500);
    }

    function findKey(menus: any, key: any, path = undefined) {
        let menu;
        for (menu of menus) {
            if (menu.submenu) {
                let submenuMatch: any = findKey(menu.submenu, key, path);
                if (submenuMatch) {
                    return [menu.key, ...submenuMatch];
                } else if (key && menu.key === key) {
                    return [menu.key];
                }
            } else if (key && menu.key === key) {
                menuHistory.push(menu.path);
                return [menu.key];
            } else if (path && menu.path === path) {
                menuHistory.push(menu.path);
                return [menu.key];
            }
        }
        return undefined;
    }

    return (
        <Sider
            // breakpoint="lg"
            collapsed={collapsed}
            width={menuWidth}
            theme={menuTheme}
            className={siderClassName}
            onCollapse={(collapsed) => {
                dispatch(setMenuCollapsed(collapsed));
                if (collapsed) {
                    root.style.setProperty('--sticky-width', '80px');
                } else {
                    root.style.setProperty('--sticky-width', '200px');
                }
            }}
            onBreakpoint={(broken) => {
                setBreakpoint(broken);
            }}
            style={{
                height: '100vh',
                position: 'fixed',
                left: 0,
            }}
        >
            {!collapsed && menuLogo}
            <Menu
                theme={menuTheme}
                mode="inline"
                className={menuClassName}
                onClick={menuSelected}
                selectedKeys={selectedKeys}
                openKeys={selectedKeys}
            >
                {collapsed && (
                    <Menu.Item
                        key="menu.search"
                        onClick={() => {
                            dispatch(setMenuCollapsed(false));
                            root.style.setProperty('--sticky-width', '200px');
                        }}
                        icon={<SearchOutlined />}
                    ></Menu.Item>
                )}
                {generateMenu(menuItems)}
            </Menu>
        </Sider>
    );
};

export default LayoutMenu;
