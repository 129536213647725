import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface IDeviceSettingsDenominationSlice {
    refresh: number;
}

const initialState: IDeviceSettingsDenominationSlice = {
    refresh: 0,
};

const deviceSettingsDenominationSlice = createSlice({
    name: 'deviceSettingsDenominationSlice',
    initialState,
    reducers: {
        refreshList(state) {
            state.refresh++;
        },
    },
});

export const { refreshList } = deviceSettingsDenominationSlice.actions;

export default deviceSettingsDenominationSlice.reducer;
