import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import {
    ENUMS,
    getColumnDateOption,
    getColumnSearch,
    getColumnSearchOption,
    geti18nText,
    NyDataTable,
    NySession,
} from '@nybble/nyreact';
import { Popover, Tabs, Typography } from 'antd';
import React, { useState } from 'react';
import { CONSTANTS_REQ } from '../../utils/Constants';
import TableSettings from '../table_settings';
import UserEdit from './edit';
const UserIndex = (props: any) => {
    const { TabPane } = Tabs;
    const table = TableSettings();

    const { Text } = Typography;

    function setDefaultFilterValue() {
        if (props.companyId != null) {
            const id = parseInt(props.companyId);
            if (id != undefined) return [{ field: 'company.id', condition: 'equals', value: id }];
        }

        return [{ field: 'active', condition: 'equals_bool', value: 1 }];
    }

    const CSVUser: any = () => {
        return [
            {
                roles: (value: string) => {
                    if (value == undefined) return '';
                    return value.replaceAll('||', ', ');
                },
            },
        ];
    };

    return (
        <NyDataTable
            headerTitle={geti18nText('users.table.header')}
            url={CONSTANTS_REQ.USER.LIST}
            showRecordModal={true}
            addNewButtonText={geti18nText('users.table.add')}
            hideButtons={!NySession.hasAnyRole(['RULE_UPDATE_USER'])}
            modalComponent={UserEdit}
            exportCSV={true}
            colCSVCustomization={CSVUser()}
            setDefaultPageSize={table.setDefaultPageSize(20)}
            onDataLoaded={table.onLoadData}
            setDefaultFilterValue={() => table.setDefaultFilterValue(setDefaultFilterValue())}
            setDefaultCurrentPage={table.setDefaultCurrentPage()}
            setDefaultSortOrder={table.setDefaultSortOrder()}
            columns={[
                {
                    title: geti18nText('users.table.column.id'),
                    dataIndex: 'id',
                    width: '10%',
                    sorter: (a: any, b: any) => {},
                    ...getColumnSearch('number'),
                },
                {
                    title: geti18nText('users.table.column.username'),
                    dataIndex: 'username',
                    sorter: (a: any, b: any) => {},
                    ...getColumnSearch('string'),
                },
                {
                    title: geti18nText('users.table.column.last_name'),
                    dataIndex: 'firstName',
                    sorter: (a: any, b: any) => {},
                    ...getColumnSearch('string'),
                },
                {
                    title: geti18nText('users.table.column.first_name'),
                    dataIndex: 'lastName',
                    sorter: (a: any, b: any) => {},
                    ...getColumnDateOption(true, undefined, undefined, true),
                },
                {
                    title: geti18nText('users.table.column.roles'),
                    dataIndex: 'roles',
                    render: (text: string, record: { [index: string]: any }) => {
                        if (text == undefined) {
                            return <></>;
                        } else {
                            var array = text.split('||');

                            return (
                                <>
                                    {array != null && array.length > 1 && (
                                        <Popover
                                            placement="left"
                                            content={
                                                <div>
                                                    {array.map((item: any) => {
                                                        return (
                                                            <div>
                                                                <Text>{item}</Text>
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            }
                                            overlayStyle={{
                                                whiteSpace: 'pre-line',
                                                maxHeight: '40vh',
                                                overflowY: 'auto',
                                                border: '1px solid #E0E0E0',
                                            }}
                                        >
                                            <div>{array[0] + ' ... '}</div>
                                        </Popover>
                                    )}
                                    {array != null && array.length == 1 && <div>{array[0]}</div>}
                                </>
                            );
                        }
                    },
                },
                {
                    title: geti18nText('users.table.column.active'),
                    dataIndex: 'active',
                    width: '10%',
                    render: (text: any, record: { active: any }) => {
                        if (record.active) {
                            return <CheckOutlined style={{ color: 'green' }} />;
                        } else {
                            return <CloseOutlined style={{ color: 'red' }} />;
                        }
                    },
                    ...getColumnSearchOption(ENUMS.ACTIVE(), 'equals_bool', 1),
                },
            ]}
        />
    );
};

export default UserIndex;
