import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import {
    ENUMS,
    getColumnDateOption,
    getColumnSearch,
    getColumnSearchOption,
    geti18nText,
    NyDataTable,
    NyRequestResolver,
    NySession,
    NyUtils,
    RESPONSE,
} from '@nybble/nyreact';
import { Row, Tabs, Tag } from 'antd';
import React, { useEffect, useState } from 'react';
import { APPLICATION_KEY, CONSTANTS_REQ } from '../../../utils/Constants';
import { getTimezone } from '../../../utils/Utils';
import HnbSnapshotColumns from './columns';
import HnbSnapshotHistoryEdit from './edit';

const HnbSnapshotHistoryIndex = (props: any) => {
    const { TabPane } = Tabs;
    const [lastUpdate, setLastUpdate] = useState<any>(null);

    function setDefaultFilterValue() {
        console.log(props);
        return [{ field: 'device_id', condition: 'equals', value: props.device_id }];
    }

    function csvColumns() {
        return [
            {
                title: 'Id',
                dataIndex: ['d', 'id'],
            },
            {
                title: geti18nText('device.table.column.serial.number'),
                dataIndex: ['d', 'serialNumber'],
            },

            {
                title: geti18nText('device.table.column.inventory.number'),
                dataIndex: ['d', 'inventoryNumber'],
            },
            {
                title: geti18nText('device.information.table.model'),
                dataIndex: ['model', 'name'],
            },
            {
                title: geti18nText('device.table.column.manufacturer'),
                dataIndex: ['manufacturer', 'name'],
            },
            {
                title: geti18nText('hnb.snapshot.table.hnb_device_type'),
                dataIndex: 'hnbDeviceType',
            },
            {
                title: geti18nText('hnb.snapshot.table.hnb_device_location'),
                dataIndex: 'hnbDeviceLocation',
            },
            {
                title: geti18nText('hnb.snapshot.table.software_eur'),
                dataIndex: 'softwareEur',
            },
            {
                title: geti18nText('hnb.snapshot.table.software_hr'),
                dataIndex: 'softwareHr',
            },
            {
                title: geti18nText('hnb.snapshot.table.count_state'),
                dataIndex: 'programCountState',
            },

            {
                title: geti18nText('hnb.snapshot.table.branch_name'),
                dataIndex: 'branchName',
            },
            {
                title: geti18nText('hnb.snapshot.table.branch_hnb_code'),
                dataIndex: 'branchHnbCode',
            },
            {
                title: geti18nText('hnb.device.maintenance_date'),
                dataIndex: 'maintenanceDate',
            },
            {
                title: geti18nText('hnb.snapshot.table.created'),
                dataIndex: 'created',
            },
        ];
    }

    return (
        <>
            <NyDataTable
                url={CONSTANTS_REQ.HNB_SNAPSHOT_HISTORY.LIST}
                showRecordModal={true}
                hideButtons={true}
                setDefaultPageSize={10}
                exportCSV={true}
                setDefaultFilterValue={setDefaultFilterValue}
                modalComponent={HnbSnapshotHistoryEdit}
                readonly={!NySession.hasAnyRole(['RULE_UPDATE_MANUAL_COUNT'])}
                columns={HnbSnapshotColumns()}
                colCSVHeaderType={'TITLE'}
                CSVFileName={'HNB_podaci_inventarni_broj'}
                csvColumns={csvColumns()}
                colCSVCustomization={[
                    {
                        maintenanceDate: (value: any) => {
                            if (value != null) {
                                return new Date(value).toLocaleDateString(NyUtils.getSelectedLocale(),getTimezone());
                            }
                        },
                    },
                    {
                        created: (value: string) => {
                            return value != null ? new Date(value).toLocaleString(NyUtils.getSelectedLocale(),getTimezone()) : null;
                        },
                    },
                    {
                        hnbDeviceType: (value: any) => {
                            return geti18nText('hnb.device.type.' + value);
                        },
                    },
                    {
                        hnbDeviceLocation: (value: any) => {
                            return geti18nText('hnb.device.location.' + value);
                        },
                    },
                ]}
            />
        </>
    );
};

export default HnbSnapshotHistoryIndex;
