import { CheckOutlined, CloseOutlined, EditOutlined, PoweroffOutlined, UserOutlined } from '@ant-design/icons';
import {
    ENUMS,
    getColumnDateOption,
    getColumnSearch,
    getColumnSearchOption,
    geti18nText,
    NySession,
    NyUtils,
} from '@nybble/nyreact';
import { Popover, Typography, Comment } from 'antd';
import React from 'react';
import { GetEnum, GetEnumNameForValue } from '../../utils/Enums';
import { getTimezone } from '../../utils/Utils';

const deviceModels = () => {
    const models = NyUtils.load('DEVICE_MODELS');
    let types: any = [];
    models.forEach((element: any) => {
        let ret: any = {};
        ret.id = element.name;
        ret.text = element.name;
        types.push(ret);
    });
    return types;
};
const manufacturers = () => {
    const manufacturers = NyUtils.load('MANUFACTURERS');
    let types: any = [];
    manufacturers.forEach((element: any) => {
        let ret: any = {};
        ret.id = element.name;
        ret.text = element.name;
        types.push(ret);
    });

    return types;
};

const offices = () => {
    const manufacturers = NyUtils.load('OFFICE');
    let types: any = [];
    manufacturers.forEach((element: any) => {
        let ret: any = {};
        ret.id = element.name;
        ret.text = element.name;
        types.push(ret);
    });

    return types;
};

const groups = () => {
    const groups = NyUtils.load('DEVICE_GROUPS');
    let types: any = [];
    groups.forEach((element: any) => {
        let ret: any = {};
        ret.id = element.id;
        ret.text = element.name;
        types.push(ret);
    });

    return types;
};

const deviceTypes = () => {
    const aTypes = GetEnum({ enumName: 'DEVICE_TYPE' });
    let types = [];
    for (var key in aTypes) {
        if (aTypes.hasOwnProperty(key)) {
            let ret = { id: undefined, text: '' };
            ret.id = aTypes[key];
            ret.text = key;
            types.push(ret);
        }
    }
    return types;
};



const { Text } = Typography;
function DeviceArchiveColumns(props?: any) {
    return [
        {
            title: geti18nText('device.table.column.name'),
            dataIndex: 'name',
            render: (text: string, record: { [index: string]: any }) => {
                return (
                    <div>
                        <div style={{ display: 'inline' }}>{record.name}</div>
                    </div>
                );
            },
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('device.information.table.model'),
            dataIndex: ['model', 'name'],

            sorter: (a: any, b: any) => {},
            ...getColumnSearchOption(deviceModels()),
        },
        {
            title: geti18nText('device.information.table.model.type'),
            width: '7%',
            dataIndex: ['model', 'type'],
            render: (text: string, record: { [index: string]: any }) => {
                return (
                    <div>
                        {geti18nText(
                            'device.model.type.' +
                                GetEnumNameForValue({
                                    enumName: 'DEVICE_TYPE',
                                    value: record.model?.type,
                                })?.toLowerCase()
                        )}
                    </div>
                );
            },
            ...getColumnSearchOption(deviceTypes()),
            sorter: (a: any, b: any) => {},
        },
        {
            title: geti18nText('device.table.column.office'),
            dataIndex: ['office', 'name'],
            sorter: (a: any, b: any) => {},
            ...getColumnSearchOption(
                offices(),
                undefined,
                undefined,
                props && props.officeName ? props.officeName : undefined
            ),
        },
        {
            title: geti18nText('office.table.column.hnb_unit_code'),
            dataIndex: ['office', 'hnbUnitCode'],
            ...getColumnSearch('string'),
            width: '20%',
            sorter: (a: any, b: any) => {},
        },
        {
            title: geti18nText('service.information.table.column.count.state'),
            dataIndex: 'programCountState',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
            align: 'right',
            render: (text: string, record: { [index: string]: any }) => {
                if (record.programCountState) {
                    return <div>{NyUtils.formatNumber(record.programCountState)}</div>;
                }
            },
        },
        {
            title: geti18nText('device.table.column.ip'),
            dataIndex: 'ip',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('device.table.column.port'),
            dataIndex: 'port',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('device.edit.connection_type'),
            dataIndex: 'connectionType',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },

        {
            title: geti18nText('device.edit.device.mode'),
            dataIndex: 'mode',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('device.edit.hnb.daily_report'),
            dataIndex: 'dailyReport',
            sorter: (a: any, b: any) => {},
            render: (text: string, record: { [index: string]: any }) => {
                if (record.dailyReport == true) {
                    return <div>{geti18nText('device.edit.hnb.report.yes')}</div>;
                }
                return <div>{geti18nText('device.edit.hnb.report.no')}</div>;
            },
            ...getColumnSearch('boolean'),
        },
        {
            title: geti18nText('device.edit.hnb.monthly_report'),
            dataIndex: 'monthlyReport',
            sorter: (a: any, b: any) => {},
            render: (text: string, record: { [index: string]: any }) => {
                if (record.monthlyReport == true) {
                    return <div>{geti18nText('device.edit.hnb.report.yes')}</div>;
                }
                return <div>{geti18nText('device.edit.hnb.report.no')}</div>;
            },
            ...getColumnSearch('boolean'),
        },
        {
            title: geti18nText('device.edit.pbz.id'),
            dataIndex: 'pbzId',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('device.edit.moxa_serial_number'),
            width: '10%',
            dataIndex: 'moxaSerialNumber',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('device.table.column.serial.number'),
            width: '10%',
            dataIndex: 'serialNumber',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('device.table.column.manufacturer'),
            dataIndex: ['manufacturer', 'name'],
            sorter: (a: any, b: any) => {},
            ...getColumnSearchOption(manufacturers()),
        },
        {
            title: geti18nText('device.table.column.inventory.number'),
            dataIndex: 'inventoryNumber',
            width: '8%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('device.table.column.vendor'),
            dataIndex: ['vendor', 'name'],

            sorter: (a: any, b: any) => {},
            ...getColumnSearchOption(deviceModels()),
        },
        {
            title: geti18nText('device.edit.maintenance.contract'),
            dataIndex: 'maintenanceContractDate',
            render: (text: string, record: { [index: string]: any }) => {
                if (record.maintenanceContractDate) {
                    return (
                        <div>
                            {new Date(record.maintenanceContractDate).toLocaleString(
                                NyUtils.getSelectedLocale(),
                                getTimezone()
                            )}
                        </div>
                    );
                }
            },
            sorter: (a: any, b: any) => {},
            ...getColumnSearchOption(deviceModels()),
        },
        {
            title: geti18nText('device.edit.tab.details.service.vendor'),
            dataIndex: ['serviceVendor', 'name'],

            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('device.edit.warranty'),
            width: '10%',
            dataIndex: 'warranty_date',
            render: (text: string, record: { [index: string]: any }) => {
                if (record.warranty_date) {
                    return (
                        <div>
                            {new Date(record.warranty_date).toLocaleString(NyUtils.getSelectedLocale(), getTimezone())}
                        </div>
                    );
                }
            },
            sorter: (a: any, b: any) => {},
            ...getColumnSearchOption(deviceModels()),
        },
        {
            title: geti18nText('device.table.column.group.names'),
            dataIndex: 'groupId',
            render: (text: string, record: { [index: string]: any }) => {
                if (text == undefined) {
                    return <></>;
                } else {
                    var array = text.split('||');

                    return (
                        <>
                            {array != null && array.length > 1 && (
                                <Popover
                                    placement="left"
                                    content={
                                        <div>
                                            {array.map((item: any) => {
                                                return (
                                                    <div>
                                                        <Text>{item}</Text>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    }
                                    overlayStyle={{
                                        whiteSpace: 'pre-line',
                                        maxHeight: '40vh',
                                        overflowY: 'auto',
                                        border: '1px solid #E0E0E0',
                                    }}
                                >
                                    <div>{array[0] + ' ... '}</div>
                                </Popover>
                            )}
                            {array != null && array.length == 1 && <div>{array[0]}</div>}
                        </>
                    );
                }
            },
            ...getColumnSearchOption(groups()),
        },
        {
            title: geti18nText('device.table.column.last.activity'),
            dataIndex: 'lastActivity',
            width: '15%',
            sorter: (a: any, b: any) => {},
            render: (text: string, record: { [index: string]: any }) => {
                if (record.lastActivity) {
                    return (
                        <div>
                            {new Date(record.lastActivity).toLocaleString(NyUtils.getSelectedLocale(), getTimezone())}
                        </div>
                    );
                }
            },
            ...getColumnDateOption(true, undefined, undefined, true),
        },
        {
            title: geti18nText('manufacturer.alarm.table.active'),
            dataIndex: 'active',
            width: '1%',
            render: (text: any, record: { active: any }) => {
                if (record.active) {
                    return <CheckOutlined style={{ color: 'green' }} />;
                } else {
                    return <CloseOutlined style={{ color: 'red' }} />;
                }
            },
            ...getColumnSearchOption(ENUMS.ACTIVE(), 'equals_bool'),
        },

        
        {
            title: geti18nText('device.table.column.ecb.label'),
            dataIndex: 'ecb',
            width: '1%',
            render: (text: any, record: { ecb: any }) => {
                if (record.ecb === true) {
                    return <CheckOutlined style={{ color: 'green' }} />;
                } else {
                    return <CloseOutlined style={{ color: 'red' }} />;
                }
            },
            ...getColumnSearchOption(ENUMS.ACTIVE(), 'equals_bool'),
        },
        {
            title: geti18nText('device.edit.info.device.archive.details'),
            dataIndex: 'details',
            render: (text: string, record: { [index: string]: any }) => {
                return (
                    <Popover
                        content={
                            <React.Fragment>
                                <React.Fragment>
                                    <Comment
                                        author={record.u.username}
                                        avatar={<UserOutlined />}
                                        datetime={new Date(record.validFrom).toLocaleString(
                                            NyUtils.getSelectedLocale(),
                                            getTimezone()
                                        )}
                                        content={record.u.firstName + ' ' + record.u.lastName}
                                    />
                                </React.Fragment>
                            </React.Fragment>
                        }
                        title={geti18nText('device.edit.info.device.archive.edited')}
                    >
                        <EditOutlined />
                    </Popover>
                );
            },
        },
    ];
}

export default DeviceArchiveColumns;
