import {
    getColumnDateOption,
    getColumnSearch,
    geti18nText,
    NyDataEdit,
    NyDataTable,
    NySession,
    NyUtils,
} from '@nybble/nyreact';
import { Form, Modal, Tabs } from 'antd';
import moment from 'moment';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { CONSTANTS_REQ } from '../../utils/Constants';
import { formatCurrency, formatCurrencyById, getTimezone } from '../../utils/Utils';
import { DetailsCountingCSVColCustomization } from './details';

const CountingDetailsGroupedIndex = (props: any) => {
    const [editHeader, setEditHeader] = useState(geti18nText('counting.details.header'));

    useEffect(() => {
        if (props.date) {
            let formatedDate = new Date(props.date).toLocaleDateString(NyUtils.getSelectedLocale(), getTimezone());
            if (props.type === 'month') {
                formatedDate = formatedDate.substring(3);
            }
            setEditHeader(geti18nText('counting.details.header') + ' - ' + formatedDate);
        }
    }, [props.date]);

    function onCancelCountGroupedVisible() {
        props.setVisible(false);
    }

    function csvFileName() {
        const date = new Date();
        const formatDate = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();
        let formatedDate = null;
        if (props.date) {
            formatedDate = new Date(props.date).toLocaleDateString(NyUtils.getSelectedLocale(), getTimezone());
            if (props.type === 'month') {
                formatedDate = formatedDate.substring(3);
            }
        }
        return geti18nText('counting.details.header') + '_' + formatedDate + '_by_' + formatDate;
    }

    return (
        <Modal
            visible={props.visible}
            title={editHeader}
            width={1500}
            footer={null}
            onCancel={onCancelCountGroupedVisible}
            destroyOnClose={true}
        >
            <NyDataTable
                url={CONSTANTS_REQ.COUNTING_DETAILS.LIST_GROUPED}
                showRecordModal={true}
                hideButtons={true}
                setDefaultPageSize={20}
                exportCSV={true}
                colCSVCustomization={DetailsCountingCSVColCustomization()}
                CSVFileName={csvFileName()}
                addedData={{
                    type: props.type,
                    deviceId: props.deviceId,
                    date: props.date,
                    timezone: getTimezone().timeZone,
                }}
                columns={[
                    {
                        title: geti18nText('counting.details.grouping.currency'),
                        dataIndex: 'currency_code',
                        width: '5%',
                        align: 'right',
                    },
                    {
                        title: geti18nText('counting.details.table.value'),
                        dataIndex: 'valueExport',

                        align: 'right',
                        render: (text: string, record: { [index: string]: any }) => {
                            if (record.value) {
                                return formatCurrencyById(record.value, record.currency_id);
                            }
                        },
                    },
                    {
                        title: geti18nText('counting.details.table.sum'),
                        dataIndex: 'sumExport',
                        align: 'right',
                        render: (text: string, record: { [index: string]: any }) => {
                            if (record.sum) {
                                return formatCurrencyById(record.sum, record.currency_id);
                            }
                        },
                    },
                    {
                        title: geti18nText('counting.details.table.fit'),
                        dataIndex: 'fit',
                        align: 'right',
                        render: (text: string, record: { [index: string]: any }) => {
                            if (record.fit) {
                                return NyUtils.formatNumber(record.fit);
                            }
                        },
                    },
                    {
                        title: geti18nText('counting.details.table.atm'),
                        dataIndex: 'atm',
                        align: 'right',
                        render: (text: string, record: { [index: string]: any }) => {
                            if (record.atm) {
                                return NyUtils.formatNumber(record.atm);
                            }
                        },
                    },
                    {
                        title: geti18nText('counting.details.table.tlr'),
                        dataIndex: 'tlr',
                        align: 'right',
                        render: (text: string, record: { [index: string]: any }) => {
                            if (record.tlr) {
                                return NyUtils.formatNumber(record.tlr);
                            }
                        },
                    },
                    {
                        title: geti18nText('counting.details.table.unfit'),
                        dataIndex: 'unfit',
                        align: 'right',
                        render: (text: string, record: { [index: string]: any }) => {
                            if (record.unfit) {
                                return NyUtils.formatNumber(record.unfit);
                            }
                        },
                    },
                    {
                        title: geti18nText('counting.details.table.manual'),
                        dataIndex: 'manual',
                        align: 'right',
                        render: (text: string, record: { [index: string]: any }) => {
                            if (record.manual) {
                                return NyUtils.formatNumber(record.manual);
                            }
                        },
                    },
                    {
                        title: geti18nText('counting.details.table.unsorted'),
                        dataIndex: 'unsorted',
                        align: 'right',
                        render: (text: string, record: { [index: string]: any }) => {
                            if (record.unsorted) {
                                return NyUtils.formatNumber(record.unsorted);
                            }
                        },
                    },
                    {
                        title: geti18nText('counting.details.table.rejected'),
                        dataIndex: 'rejected',
                        align: 'right',
                        render: (text: string, record: { [index: string]: any }) => {
                            if (record.rejected) {
                                return NyUtils.formatNumber(record.rejected);
                            }
                        },
                    },
                    {
                        title: geti18nText('counting.details.table.net'),
                        dataIndex: 'net',
                        sorter: (a: any, b: any) => {},
                        ...getColumnSearch('string'),
                    },
                    {
                        title: geti18nText('counting.details.table.series'),
                        dataIndex: 'series',
                        sorter: (a: any, b: any) => {},
                        ...getColumnSearch('string'),
                    },
                ]}
            />
        </Modal>
    );
};

export default CountingDetailsGroupedIndex;
