import {
    geti18nText,
    NyDataEdit,
    NySession,
    NyMaskContent,
    NyRequestResolver,
    RESPONSE,
    NyUtils,
} from '@nybble/nyreact';
import {
    Col,
    Form,
    Input,
    Row,
    Tabs,
    Image,
    Descriptions,
    Tooltip,
    Button,
    Modal,
    InputNumber,
    notification,
} from 'antd';
import L from 'leaflet';
import React, { useEffect, useRef, useState } from 'react';
import NyImageUpload from '../../../components/file-upload';
import { CONSTANTS_REQ } from '../../../utils/Constants';

const LogoSettings = (props: any) => {
    const [uploadKey, setUploadKey] = useState<any>(Date.now());
    const [file, setFile] = useState<any>(null);
    const [formSettings] = Form.useForm();
    const setDefaultFilterValue = () => {
        return [{ field: 'setting_group', condition: 'equals', value: 'APP_SETTINGS' }];
    };

    useEffect(() => {
        fetch();
    }, []);

    async function setValues(dataForm: any) {
        console.log(dataForm);
        if (dataForm != undefined) {
            const appLogo = dataForm.find((item: any) => item.settingKey === 'APP_LOGO')?.value;
            setFile(appLogo);
        }
    }

    const fetch = () => {
        NyRequestResolver.requestGet(CONSTANTS_REQ.APPLICATION_SETTINGS.LIST, {
            search: encodeURI(JSON.stringify(setDefaultFilterValue())),
        }).then((result: any) => {
            if (result.status === RESPONSE.OK) {
                if (result.data) {
                    setValues(result.data?.content);
                }
            }
        });
    };

    const saveAppSettings = (file: any) => {
        NyRequestResolver.requestPost(CONSTANTS_REQ.APPLICATION_SETTINGS.UPLOAD_IMAGE, undefined, {
            image: file,
        }).then((result: any) => {
            if (result && result.status === RESPONSE.OK) {
                notification.success({
                    message: geti18nText('app.default.save.ok'),
                    description: geti18nText('app.default.save.ok.desc'),
                    duration: 4,
                });
            } else {
                notification.error({
                    message: geti18nText('app.default.save.nok'),
                    description: result.data?.message,
                    duration: 5,
                });
            }
        });
    };

    return (
        <Row>
            <Form form={formSettings} onFinish={() => saveAppSettings(file)}>
                <Col span={20} style={{ marginTop: '15px' }}>
                    <Form.Item label={geti18nText('settings.app.settings.logo')} name="image">
                        <NyImageUpload
                            accept="image/*"
                            key={uploadKey}
                            files={file}
                            setFiles={setFile}
                            multiple={false}
                            value={file}
                        />
                    </Form.Item>
                    {file != undefined && (
                        <Row>
                            <Form.Item>
                                <Button
                                    type="link"
                                    onClick={() => {
                                        setFile(null);
                                        saveAppSettings(null);
                                    }}
                                >
                                    {geti18nText('settings.app.settings.resetBtn')}
                                </Button>
                            </Form.Item>
                            <Form.Item>
                                <Button type="primary" htmlType="submit">
                                    {geti18nText('settings.app.settings.saveBtn')}
                                </Button>
                            </Form.Item>
                        </Row>
                    )}
                </Col>
            </Form>
        </Row>
    );
};

export default LogoSettings;
