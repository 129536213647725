import { useEffect, useState } from 'react';

const TableSettings = () => {
    useEffect(() => {}, []);
    // let settings: { [index: string]: any } | undefined = {};
    const [settings, setSettings] = useState<{ [index: string]: any } | undefined>({});
    return {
        onLoadData: (data: { [index: string]: any } | string, params: { [index: string]: any } | undefined) => {
            // settings = params;
            setSettings(params);
        },
        refresh: () => {
            setSettings(undefined);
        },

        setDefaultSortOrder(): { order: string; orderType: string } {
            return {
                order: settings?.order,
                orderType: settings?.orderType,
            };
        },
        setDefaultPageSize(defaultValue: any): number {
            return settings?.max != undefined ? settings?.max : defaultValue;
        },

        checkForSearchItem(customFilter: any) {
            let res = settings?.search ? JSON.parse(decodeURI(settings?.search)) : customFilter;
            return res;
        },
        setDefaultFilterValue(defaultFilter: any) {
            return settings?.search ? JSON.parse(decodeURI(settings?.search)) : defaultFilter;
        },
        setDefaultCurrentPage(): number {
            return settings?.offset ? settings?.offset + 1 : 1;
        },
    };
};

export default TableSettings;
