import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import {
    getColumnDateOption,
    getColumnSearch,
    getColumnSearchOption,
    geti18nText,
    NyDataTable,
    NySearchField,
    NySpinner,
    NyUtils,
} from '@nybble/nyreact';
import { Button, Col, Form, Popover, Radio, Row, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setModalOpen } from '../../slices/modalOpenSlice';
import { CONSTANTS_REQ } from '../../utils/Constants';
import { getTimezone } from '../../utils/Utils';

export const HnbDataProps = (props: any) => {
    const [addButtonDisabled, setAddButtonDisabled] = useState(false);

    function setSettings() {
        props.addWidget({});
    }

    return (
        <>
            <Row>
                <Col span={24} style={{ textAlign: 'right' }}>
                    <Button
                        type="primary"
                        disabled={addButtonDisabled}
                        style={{ marginRight: '1em' }}
                        onClick={() => setSettings()}
                    >
                        {geti18nText('dashboard.widget.button.add')}
                    </Button>
                </Col>
            </Row>
        </>
    );
};

export const HnbDataIndex = (props: any) => {
    const [loading, setLoading] = useState(false);
    const [key, setKey] = useState(new Date() + 'table');
    const { Title } = Typography;
    const { Text, Link } = Typography;
    const dispatch = useDispatch();

    function remove() {
        props.onRemoveWidget(props.widgetKey);
    }

    useEffect(() => {
        const interval = setInterval(() => {
            setKey(new Date() + 'table');
        }, 1 * 60 * 1000);

        return () => {
            clearInterval(interval);
        };
    }, []);

    function setTitle() {
        return <>{geti18nText('hnb.data.header')}</>;
    }

    function onRowClickHandler(record: any) {
        console.log(record);
        dispatch(
            setModalOpen({
                modal: 'deviceEdit',
                visible: true,
                item: record.device.id,
            })
        );
    }

    const offices = () => {
        const manufacturers = NyUtils.load('OFFICE');
        let types: any = [];
        manufacturers.forEach((element: any) => {
            let ret: any = {};
            ret.id = element.name;
            ret.text = element.name;
            types.push(ret);
        });

        return types;
    };

    const deviceModels = () => {
        const models = NyUtils.load('DEVICE_MODELS');
        let types: any = [];
        models.forEach((element: any) => {
            let ret: any = {};
            ret.id = element.name;
            ret.text = element.name;
            types.push(ret);
        });
        return types;
    };

    function columns() {
        return [
            {
                title: geti18nText('hnbdata.table.device.name'),
                dataIndex: ['device', 'name'],
                sorter: (a: any, b: any) => {},
                ...getColumnSearch('string'),
            },
            {
                title: geti18nText('hnbdata.table.deviceModel.name'),
                dataIndex: ['deviceModel', 'name'],

                align: 'center',
                sorter: (a: any, b: any) => {},
                ...getColumnSearchOption(deviceModels()),
            },
            {
                title: geti18nText('hnbdata.table.office.name'),
                dataIndex: ['office', 'name'],

                align: 'center',
                sorter: (a: any, b: any) => {},
                ...getColumnSearchOption(offices()),
            },
            {
                title: geti18nText('hnbdata.table.device.serial.number'),
                dataIndex: ['device', 'serialNumber'],

                align: 'center',
                sorter: (a: any, b: any) => {},
                ...getColumnSearch('string'),
            },
            {
                title: geti18nText('hnbdata.table.device.inventory.number'),
                dataIndex: ['device', 'inventoryNumber'],

                align: 'center',
                sorter: (a: any, b: any) => {},
                ...getColumnSearch('string'),
            },
            {
                title: geti18nText('hnbdata.table.device.count.state'),
                dataIndex: ['device', 'program_count_state'],

                align: 'right',
                sorter: (a: any, b: any) => {},
                render: (text: string, record: { [index: string]: any }) => {
                    if (record.device.program_count_state) {
                        return NyUtils.formatNumber(record.device.program_count_state);
                    }
                },
            },
            {
                title: geti18nText('hnbdata.table.device.last.activity.date'),
                dataIndex: ['device', 'count_state_change'],
                align: 'center',
                sorter: (a: any, b: any) => {},
                render: (text: string, record: { [index: string]: any }) => {
                    if (record.device.count_state_change) {
                        return (
                            <div>
                                {new Date(record.device.count_state_change).toLocaleString(NyUtils.getSelectedLocale(),getTimezone())}
                            </div>
                        );
                    }
                },
                ...getColumnDateOption(true,undefined,undefined,true),
            },

            {
                title: geti18nText('hnbdata.table.device.maintenance.date'),
                dataIndex: 'maintenance_date',

                align: 'center',
                sorter: (a: any, b: any) => {},
                render: (text: string, record: { [index: string]: any }) => {
                    if (record.maintenance_date) {
                        return (
                            <div>{new Date(record.maintenance_date).toLocaleString(NyUtils.getSelectedLocale(),getTimezone())}</div>
                        );
                    }
                },
                ...getColumnDateOption(true,undefined,undefined,true),
            },
        ];
    }

    if (loading) {
        return <NySpinner></NySpinner>;
    } else
        return (
            <>
                <Row>
                    {' '}
                    <Col span={20}>
                        <Title level={3}>{setTitle()}</Title>
                    </Col>
                    <Col style={{ textAlign: 'right' }} span={4}>
                        <Button
                            shape="circle"
                            size="small"
                            style={{ border: 'none' }}
                            icon={<CloseOutlined />}
                            onClick={() => remove()}
                        />
                    </Col>
                </Row>
                <Row style={{ padding: '10px' }}>
                    <Col span={24}>
                        <NyDataTable
                            url={CONSTANTS_REQ.HNB_DEVICE.LIST}
                            setDefaultPageSize={10}
                            key={key}
                            showRecordModal={false}
                            onRowSelect={onRowClickHandler}
                            hideButtons={true}
                            showRowSelection={false}
                            scroll={{ y: 40 * props.h - 140 }}
                            readonly={false}
                            columns={columns()}
                        />
                    </Col>
                </Row>
            </>
        );
};
