import { ExperimentOutlined, PlusOutlined } from '@ant-design/icons';
import {
    geti18nText, NyRequestResolver, NySearchField, NyUtils, RESPONSE
} from '@nybble/nyreact';
import { Button, Col, Form, Modal, Popover, Row, Space, Switch, Typography } from 'antd';
import _ from "lodash";
import React, { useState } from 'react';
import { default as RGL, WidthProvider } from "react-grid-layout";
import { CONSTANTS_REQ } from '../../utils/Constants';
import { DeviceError, DeviceErrorProps } from './deviceError';
import { DeviceNumber, DeviceNumbersProps } from './DeviceNumber';
import { OfficeMap, OfficeMapProps } from './OfficeMap';
import { StatisticChartDashboard, StatisticChartsProps } from './StatisticChartIndex';
import { HnbDataProps, HnbDataIndex } from './HnbData';
const ReactGridLayout = WidthProvider(RGL);

export default class Dashboard extends React.PureComponent {

    constructor(props) {
        super(props);
        
        this.state = {
            gridKey: Date.now(),
            lock:true,
            widgets:[],
            layout: []

        };
        this.addWidget = this.addWidget.bind(this);
        this.lockWidget = this.lockWidget.bind(this);
        this.removeWidget = this.removeWidget.bind(this);
        this.redraw = this.redraw.bind(this);
        this.onLayoutChange = this.onLayoutChange.bind(this);
        this.onResizeStop = this.onResizeStop.bind(this);
     
    }

    componentDidMount() {
         NyRequestResolver.requestGet(
            CONSTANTS_REQ.USER.WIDGETS,
                undefined
        ).then((res)=>{
            if(res.status == RESPONSE.OK) {
                NyUtils.save('WIDGETS',  JSON.parse (JSON.stringify(res.data)).widgets);
                NyUtils.save('DASHBOARD_LOCK',  JSON.parse (JSON.stringify(res.data)).lock);
                NyUtils.save('LAYOUT',  JSON.parse (JSON.stringify(res.data)).layout);
            }
            this.setState({
                lock:NyUtils.load("DASHBOARD_LOCK") != null ? NyUtils.load("DASHBOARD_LOCK") : true ,
                widgets: NyUtils.load("WIDGETS")? NyUtils.load("WIDGETS"): 
                [{"key":"key1632469060632","component":"DeviceNumber","dataGrid":{"w":12,"h":4,"x":0,"y":0,"i":"key1632469060632","minW":4,"minH":3,"moved":false,"static":true},"settings":{"widgetKey":"key1632469060632"},"onResizeRedraw":true},{"key":"key1632469605391","component":"DeviceError","dataGrid":{"w":12,"h":8,"x":0,"y":30,"i":"key1632469605391","minW":6,"minH":6,"moved":false,"static":true},"settings":{"widgetKey":"key1632469605391"},"onResizeRedraw":true},{"key":"key1632469649875","component":"HnbDataIndex","dataGrid":{"w":12,"h":8,"x":0,"y":22,"i":"key1632469649875","minW":6,"minH":6,"moved":false,"static":true},"settings":{"widgetKey":"key1632469649875"},"onResizeRedraw":true},{"key":"key1632469829454","component":"OfficeMap","dataGrid":{"w":12,"h":10,"x":0,"y":12,"i":"key1632469829454","minW":6,"minH":6,"moved":false,"static":true},"settings":{"widgetKey":"key1632469829454"},"onResizeRedraw":true},{"key":"key1632475894898","component":"StatisticChartDashboard","dataGrid":{"w":4,"h":8,"x":0,"y":4,"i":"key1632475894898","minW":3,"minH":4,"moved":false,"static":true},"settings":{"period":"today","deviceAll":"D_O","color":"#4a90e2","typeChart":"count","widgetKey":"key1632475894898"},"onResizeRedraw":true},{"key":"key1632475948975","component":"StatisticChartDashboard","dataGrid":{"w":4,"h":8,"x":4,"y":4,"i":"key1632475948975","minW":3,"minH":4,"moved":false,"static":true},"settings":{"period":"today","deviceAll":"D_O","color":"#6c719c","typeChart":"sum","widgetKey":"key1632475948975"},"onResizeRedraw":true},{"key":"key1632476207471","component":"StatisticChartDashboard","dataGrid":{"w":4,"h":8,"x":8,"y":4,"i":"key1632476207471","minW":3,"minH":4,"moved":false,"static":true},"settings":{"period":"today","deviceAll":"D_O","color":"#fc3737","typeChart":"error","widgetKey":"key1632476207471"},"onResizeRedraw":true}],
                    layout: NyUtils.load("LAYOUT")? NyUtils.load("LAYOUT"):[
                        {"w":12,"h":4,"x":0,"y":0,"i":"key1632469060632","minW":4,"minH":3,"moved":false,"static":true},{"w":12,"h":8,"x":0,"y":30,"i":"key1632469605391","minW":6,"minH":6,"moved":false,"static":true},{"w":12,"h":8,"x":0,"y":22,"i":"key1632469649875","minW":6,"minH":6,"moved":false,"static":true},{"w":12,"h":10,"x":0,"y":12,"i":"key1632469829454","minW":6,"minH":6,"moved":false,"static":true},{"w":4,"h":8,"x":0,"y":4,"i":"key1632475894898","minW":3,"minH":4,"moved":false,"static":true},{"w":4,"h":8,"x":4,"y":4,"i":"key1632475948975","minW":3,"minH":4,"moved":false,"static":true},{"w":4,"h":8,"x":8,"y":4,"i":"key1632476207471","minW":3,"minH":4,"moved":false,"static":true}
                    ]
            },
            
            );        
            
        });
    }

     
    createElement(obj) {
        let Tagname = WidgetsComponentsMap[obj.component];
        let settings = { ...obj.settings, ...obj.dataGrid };
        settings.key = 'w_' + obj.key;
        settings.onRemoveWidget = this.removeWidget;
        const w = React.createElement(Tagname,settings,'');
        return React.createElement('div', { id: 'container_' + obj.key, key: obj.key, style: obj.style, "data-grid": obj.dataGrid }, w);
    }

    addWidget(selectedWidget, settings) {
        let widgetList = this.state.widgets;
        let layout = this.state.layout;
        const newKey = 'key' + Date.now();
        settings.widgetKey = newKey;
        let yNew=0
        if(widgetList.length > 0){
            let last=widgetList[widgetList.length-1];
            yNew=last.dataGrid.y+last.dataGrid.h;
        }

        widgetList.push({
            key: newKey,
            component: selectedWidget.component,
            dataGrid: { w: selectedWidget.w, h: selectedWidget.h, x: 0, y: yNew, minW: selectedWidget.minW, minH: selectedWidget.minH },
            settings: settings,
            onResizeRedraw: selectedWidget.onResizeRedraw
        });

        layout.push({
             w: selectedWidget.w, h: selectedWidget.h, x: 0, y: yNew, minW: selectedWidget.minW, minH: selectedWidget.minH 
        })

        layout.forEach((l)=>{return l.static=false});

        NyUtils.save("WIDGETS", widgetList);
        NyUtils.save("DASHBOARD_LOCK", false);
        NyUtils.save("LAYOUT", layout);
        this.saveUserWidgets(widgetList.length >0 ?widgetList:[],layout,false);

        this.setState({
            lock:false,
            widgets: widgetList,
            layout:layout
        });

        this.forceUpdate();
    }

    removeWidget(key) {
        let widgetList = this.state.widgets;
        let layoutList = this.state.layout;
        var removeIndex = widgetList.map(function (item) { return item.key; }).indexOf(key);
        var removeLayoutIndex = layoutList.map(function (item) { return item.i; }).indexOf(key);

        widgetList.splice(removeIndex, 1);
        layoutList.splice(removeLayoutIndex, 1);
        widgetList.forEach((w)=>{return w.dataGrid.static=false});
        layoutList.forEach((l)=>{return l.static=false;});
        NyUtils.save("WIDGETS", widgetList);
        NyUtils.save("LAYOUT", layoutList);
        NyUtils.save("DASHBOARD_LOCK", false);

        this.saveUserWidgets(widgetList.length >0 ?widgetList:[],layoutList,this.state.lock);
        this.setState({
            lock:false,
            widgets: widgetList,
            layout:layoutList
        });
        
        this.forceUpdate();
    }

    lockWidget(lock) {
        let newWidgetList = this.state.widgets.map(l => {
            let newL=l;
            newL.dataGrid.static=lock;
            return newL
        });
        var newLayout = this.state.layout.map(l => {
            return {...l, static: lock}
        })
        NyUtils.save("DASHBOARD_LOCK", lock);
        NyUtils.save("WIDGETS", newWidgetList);
        NyUtils.save("LAYOUT", newLayout);
        this.setState({
            lock:lock,
            widgets: newWidgetList,
            layout:newLayout
            ,gridKey: Date.now()
        });
        
        this.saveUserWidgets(newWidgetList.length >0 ?newWidgetList:[],newLayout,lock);
        this.forceUpdate();
    }

    onLayoutChange(currentLayout) {
        let newLayout=[];
        let widgetList = this.state.widgets;
        let lock=this.state.lock;
        if(widgetList && lock != null){
            widgetList.forEach((w) => {
                let position = currentLayout.find(({ i }) => i === w.key);
                let clonePosition = { ...position };
                delete clonePosition.i;
                w.dataGrid = position;
                newLayout.push(position);
            });
            NyUtils.save("WIDGETS", widgetList);
            NyUtils.save("DASHBOARD_LOCK", lock);
            NyUtils.save("LAYOUT", newLayout);
            if(newLayout.length !== this.state.layout.length){
                newLayout.forEach((c)=>{
                    return c.static=false;
                })
            }
            this.saveUserWidgets(widgetList.length > 0 ?widgetList:[],newLayout,lock);
            this.setState({
                lock:lock,
                widgets: widgetList,
                layout:newLayout
            });
        }
        this.forceUpdate();
        
    }

    onResizeStop(layout, oldItem, newItem, placeholder, e, element) {
        const widget = this.state.widgets.find(({ key }) => key === newItem.i);
        if (widget.onResizeRedraw) {
            this.forceUpdate();
        }
    }

    redraw() {
        this.forceUpdate();
    }



     saveUserWidgets(widgets,layout, lock) {
        
        let widgetData= widgets.length >0? {widgets:widgets, lock:lock, layout:layout}:[]
        NyRequestResolver.requestPost(
            CONSTANTS_REQ.USER.WIDGETS,
                undefined, {widgets:JSON.stringify(widgetData)},false
            
        );

    }

    render() {
        return (
            <>
            <Row>
                <AddWidgetRow lock={this.state.lock} onLockWidget={this.lockWidget} addWidget={this.addWidget} />
            </Row>
            <Row>
                <Col span={24}>
                    <ReactGridLayout 
                        breakpoints={{lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0}}
                        key={this.state.gridKey} 
                        margin={[15,15]} 
                        className="layout" 
                        rowHeight={30}  
                        onLayoutChange={this.onLayoutChange}
                        layouts={this.state.layouts} 
                        onResizeStop={this.onResizeStop}>
                        {_.map(this.state.widgets, el => this.createElement(el))}   
                        
                    </ReactGridLayout>
                </Col>
            </Row>
            </>
        )
      }
}



const AddWidgetRow = (props) => {
    const [popupVisible, setPopupVisible] = useState(false);
    const [visible, setVisible] = useState(false);
    const { Text, Link } = Typography;


    function onChangeSwitchLock(checked) {
        props.onLockWidget(checked);
        //setVisible(false);
    }

    function handleVisibleChange(visible) {
        setVisible(visible);
    }

    const content = (
        <div>
            <Row>
                <Space>
                <Switch checked={props.lock} onChange={onChangeSwitchLock} />
                <Text>{geti18nText('dashboard.widget.popover.lock.title')}</Text>
                </Space>
                
            </Row>
            <Row style={{marginTop:'15px'}}>
                <Button onClick={() => {setPopupVisible(true)
                    setVisible(false);
                }} size={"small"} type="primary" shape="round" icon={<PlusOutlined/>} >
                {geti18nText("dashboard.widget.popover.add.title")}
                </Button>
            </Row>
        </div>
      );

    return (
        <Col span={24} style={{ textAlign: 'right' }}>
            <Popover visible={visible}
                    onVisibleChange={handleVisibleChange} placement="bottom" content={content} trigger="click">
                <Button type="primary" shape="round" icon={<ExperimentOutlined/>} />
            </Popover>
            <AddWidgetModal visible={popupVisible} setVisible={setPopupVisible} addWidget={props.addWidget} />
        </Col>
    )
}

export const widgetsCatalog = () => {
    return [
        { id: "deviceNumbers", text: geti18nText("dashboard.widget.device.number.title"), settings:DeviceNumbersProps, onResizeRedraw: true, component: "DeviceNumber", w: 12, h: 4, minW: 6, minH: 4 },
        { id: "StatisticChartDashboard", style:{ borderWidth: "1px", borderStyle: "solid", borderColor: " #e8e8e8" }, text: geti18nText("dashboard.widget.statistic"), onResizeRedraw: true,settings:StatisticChartsProps, component: "StatisticChartDashboard", w: 4, h: 8, minW: 3, minH: 4 },
        { id: "DeviceError", style:{ borderWidth: "1px", borderStyle: "solid", borderColor: " #e8e8e8" }, text: geti18nText("dashboard.widget.error.table"), onResizeRedraw: true,settings:DeviceErrorProps, component: "DeviceError", w: 12, h: 8, minW: 6, minH: 6 },
        { id: "OfficeMap", style:{ borderWidth: "1px", borderStyle: "solid", borderColor: " #e8e8e8" }, text: geti18nText("dashboard.widget.map.office"), onResizeRedraw: true,settings:OfficeMapProps, component: "OfficeMap", w: 12, h: 15, minW: 6, minH: 6 },
        { id: "HnbDataIndex", style:{ borderWidth: "1px", borderStyle: "solid", borderColor: " #e8e8e8" }, text: geti18nText("dashboard.widget.hnb.data"), onResizeRedraw: true,settings:HnbDataProps, component: "HnbDataIndex", w: 12, h: 15, minW: 6, minH: 6 },
        
    ]
}

export const WidgetsComponentsMap = {
    "DeviceNumber": DeviceNumber,
    "StatisticChartDashboard": StatisticChartDashboard,
    "DeviceError": DeviceError,
    "OfficeMap": OfficeMap,
    "HnbDataIndex": HnbDataIndex,
}

export const AddWidgetModal = (props) => {

    const [selectedWidget, setSelectedWidget] = useState(undefined);
    const [form] = Form.useForm();

    function closePopup() {
        setSelectedWidget(undefined);
        form.resetFields(['widget']);
        props.setVisible(false);
    }

    function addWidget(settings) {
        props.addWidget(selectedWidget, settings);
        props.setVisible(false);
        form.resetFields();
        setSelectedWidget(undefined);
    }

    function widgetSelected(value) {
        form.resetFields();
        form.setFieldsValue({widget:value})
        if (value) {
            const widget = widgetsCatalog().find(({ id }) => id === value.id);
            setSelectedWidget(widget);
        }
        else {
            setSelectedWidget(undefined);
        }
    }

    

    return (
        <>
            <Modal
                visible={props.visible}
                title={geti18nText("dashboard.widget.add.title")}
                onCancel={closePopup}
                destroyOnClose={true}
                width={800}
                footer={[]}>

                <Form labelCol={{ span: 5 }}
      wrapperCol={{ span: 16 }}  form={form}>
                    <Form.Item name="widget" label={geti18nText("dashboard.widget.choose")}>
                        
                        <NySearchField style={{width:'60%'}}  searchBy={"name"} onChange={widgetSelected} options={widgetsCatalog()}  />
                        
                    </Form.Item>

                    {selectedWidget &&
                        <>
                            {React.createElement(selectedWidget.settings, { addWidget: addWidget, form: form }, '')}
                        </>
                    }
                </Form>
            </Modal>
        </>
    );
};


