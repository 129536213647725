import { BellOutlined, DeleteOutlined, LinkOutlined } from '@ant-design/icons';
import { geti18nText, NyRequestResolver } from '@nybble/nyreact';
import { Badge, Button, Col, Collapse, Empty, List, Popconfirm, Popover, Row } from 'antd';
import list from 'antd/lib/list';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { useHistory } from 'react-router-dom';
import { RootState } from '../../rootReducer';
import { setActive } from '../../slices/menuSlice';
import { setModalOpen } from '../../slices/modalOpenSlice';
import {
    deleteAllNotification,
    deleteAllNotificationByType,
    deleteNotification,
    readAllNotification,
    readAllNotificationByType,
    readNotification,
} from '../../slices/notificationSlice';
import { CONSTANTS_REQ } from '../../utils/Constants';
import { mailClose, mailOpen } from '../../utils/CustomIcons';
import { GetEnumNameForValue } from '../../utils/Enums';
import DEFAULT_NOTIFICATION_SETTINGS from '../../utils/NotificationSettings';
import { setEnumFormat } from '../../utils/Utils';
import { addNotification } from '../../slices/notificationSlice';
const { Panel } = Collapse;

const NotificationsIndex = (props: any) => {
    const dispatch = useDispatch();
    const [notificationsVisible, setNotificationsVisible] = useState(false);
    const [activePanel, setActivePanel] = useState(undefined);
    const [rowSelected, setRowSelected] = useState(undefined);
    const [notificationSettings, setNotificationSettings] = useState<any>(DEFAULT_NOTIFICATION_SETTINGS);
    const [laodingBtns, setLoadingBtns] = useState([]);

    const [initLoading, setInitLoading] = useState(false);
    const [loading, setLoading] = useState(false);

    const isMobile = useMediaQuery({ query: `(max-width: 760px)` });

    const { count, notifications } = useSelector((state: RootState) => state.notification);
    let history = useHistory();

    function getEnumKeyByNotificationType(notificationType: any) {
        return GetEnumNameForValue({
            enumName: 'NOTIFICATION_ALARM_TYPE',
            value: Number(notificationType),
        });
    }

    function compare(a: any, b: any) {
        if (a.sort < b.sort) {
            return -1;
        }
        if (a.sort > b.sort) {
            return 1;
        }
        return 0;
    }

    const openItemInTab = (path: any, modalName: any, item: any, notif: any, title: any) => {
        setNotificationsVisible(false);
        dispatch(setActive('/device'));
        dispatch(
            setModalOpen({
                modal: modalName,
                visible: true,
                item: item.deviceId,
            })
        );
    };
    const openNotificationItemInTab = (path: any, modalName: any, item: any, notif: any, title: any) => {
        setNotificationsVisible(false);
        console.log('Item ', item);
        dispatch(setActive('/admin/notification'));
        dispatch(
            setModalOpen({
                modal: modalName,
                visible: true,
                item: item.id,
            })
        );
    };

    const openCert = () => {
        setNotificationsVisible(false);
        dispatch(setActive('/cert'));
    };

    const openInstances = () => {
        setNotificationsVisible(false);
        dispatch(setActive('/admin/instances'));
    };

    const onReadAllButtonClick = () => {
        if (activePanel !== undefined) {
            dispatch(readAllNotificationByType(activePanel));
            NyRequestResolver.requestPut(CONSTANTS_REQ.NOTIFICATION.READ_ALL, undefined, {
                notificationType: activePanel,
            });
        } else {
            dispatch(readAllNotification());
            NyRequestResolver.requestPut(CONSTANTS_REQ.NOTIFICATION.READ_ALL, undefined, {});
        }
    };

    const onDeleteAllButtonClick = () => {
        if (activePanel !== undefined) {
            dispatch(deleteAllNotificationByType(activePanel));
            NyRequestResolver.requestPut(CONSTANTS_REQ.NOTIFICATION.DELETE_ALL, undefined, {
                notificationType: activePanel,
            });
        } else {
            dispatch(deleteAllNotification());
            NyRequestResolver.requestPut(CONSTANTS_REQ.NOTIFICATION.DELETE_ALL, undefined, {});
        }
    };

    const notificationRead = (notification: any) => {
        dispatch(readNotification(notification));
        const params = {
            id: notification.id,
            unread: !notification.unread,
        };
        NyRequestResolver.requestPut(CONSTANTS_REQ.NOTIFICATION.EDIT + '/' + notification.id, undefined, params);
    };

    function notificationDelete(notification: any) {
        dispatch(deleteNotification(notification));

        const params = {
            id: notification.id,
        };
        NyRequestResolver.requestPut(CONSTANTS_REQ.NOTIFICATION.DELETE + '/' + notification.id, undefined, params);
    }

    const getLink = (link: any) => {
        if (link.function === 'openLicense') {
            openCert();
        } else if (link.function === 'openInstances') {
            openInstances();
        } else if (link.function === 'openNotificationItemInTab') {
            openNotificationItemInTab(link.path, link.modalName, link.item, link.notification, link.title);
        } else openItemInTab(link.path, link.modalName, link.item, link.notification, link.title);
    };

    function readDeleteButtons(data: any, link: any) {
        return (
            <Col span={6} style={{ textAlign: 'center', paddingBottom: '2%' }}>
                <Button
                    shape="circle"
                    icon={data.unread ? mailClose : mailOpen}
                    onClick={(event) => {
                        event.preventDefault();
                        event.stopPropagation();
                        notificationRead(data);
                    }}
                />

                <Button
                    style={{ marginLeft: '5px' }}
                    shape="circle"
                    icon={<DeleteOutlined />}
                    onClick={(event) => {
                        event.preventDefault();
                        event.stopPropagation();
                        notificationDelete(data);
                        //getLink(link);
                    }}
                />
            </Col>
        );
    }

    const itemRow = (content: any, data: any, link: any) => {
        return [
            <Row
                key={new Date().toJSON()}
                gutter={24}
                className={
                    data.unread
                        ? `${rowSelected === data.id ? 'ny-notification-selected' : 'ny-notification-unread'}`
                        : `${rowSelected === data.id ? 'ny-notification-selected' : 'ny-notification'}`
                }
                onClick={() => {
                    if (link) {
                        getLink(link);
                        setRowSelected(undefined);
                    }
                }}
                style={{
                    cursor: 'pointer',
                    width: '100%',
                }}
            >
                <Col span={18}>{content}</Col>
                {readDeleteButtons(data, link)}
            </Row>,
        ];
    };

    const onLoadMore = (type: any) => {
        setLoading(true);
        getNotificationByType(type);
    };
    const loadMore = (type: any) => {
        return !initLoading && !loading && (laodingBtns[type] == undefined || laodingBtns[type] == true) ? (
            <div
                style={{
                    textAlign: 'center',
                    marginTop: 12,
                    height: 32,
                    lineHeight: '32px',
                }}
            >
                <Button onClick={(e) => onLoadMore(type)}>{geti18nText('app.default.btn.loading.more')}</Button>
            </div>
        ) : null;
    };

    function getNotificationByType(type: any) {
        const notificationGroup = notifications[type];
        NyRequestResolver.requestGet(CONSTANTS_REQ.NOTIFICATION.GET_BY_TYPE, {
            notificationType: type,
            offset: notificationGroup.offset + 1,
        }).then((result: any) => {
            setLoading(false);
            if (result && result.data && result.data.content) {
                result.data.content = notificationGroup.content.concat(result.data.content);
                var copyArray = JSON.parse(JSON.stringify(notifications));
                const unread = copyArray[type].unread;
                copyArray[type] = result.data;
                copyArray[type].unread = unread;
                var loadingBtnArray: any = [...laodingBtns];
                loadingBtnArray[type] = true;
                setLoadingBtns(loadingBtnArray);
                dispatch(addNotification(copyArray));
            } else {
                var loadingBtnArray: any = [...laodingBtns];
                loadingBtnArray[type] = false;
                setLoadingBtns(loadingBtnArray);
            }
        });
    }

    const getNotificationItems = (notificationType: any) => {
        const t = notifications[notificationType];
        const slicedArray = notifications[notificationType].content;
        return (
            <List
                className="demo-loadmore-list"
                loading={false}
                itemLayout="horizontal"
                loadMore={loadMore(notificationType)}
                dataSource={slicedArray}
                renderItem={(item) => (
                    <List.Item>
                        {itemRow(<h4>{getNotificationData(item).content}</h4>, item, getNotificationData(item).link)}
                    </List.Item>
                )}
            />
        );
    };

    function getNotificationGroups() {
        let items = [];
        let notificationType: any;

        for (notificationType of notificationSettings) {
            let enumKey: any = getEnumKeyByNotificationType(notificationType.notificationType);

            if (
                enumKey &&
                notifications &&
                notifications[notificationType.notificationType] &&
                notifications[notificationType.notificationType].content.length > 0
            ) {
                let typeName: any = setEnumFormat('NOTIFICATION_ALARM_TYPE', Number(notificationType.notificationType));
                items.push(
                    <Panel
                        showArrow={false}
                        header={
                            <Row gutter={24}>
                                <Col span={20}> {typeName && typeName.text ? typeName.text : ''}</Col>
                                <Col span={4} style={{ textAlign: 'right' }}>
                                    <Badge
                                        count={count && count[enumKey] ? count[enumKey] : 0}
                                        style={{
                                            fontSize: '13px',
                                            minWidth: '24px',
                                            height: '24px',
                                            lineHeight: '24px',
                                            color: '#252525',
                                            fontWeight: 'bold',
                                            borderRadius: '24px',
                                            backgroundColor: notificationType.color
                                                ? notificationType.color
                                                : '#ff4d4f',
                                        }}
                                    ></Badge>
                                </Col>
                            </Row>
                        }
                        key={notificationType.notificationType}
                    >
                        <div className={'ny-notification-panel'}>
                            {getNotificationItems(notificationType.notificationType)}
                        </div>
                    </Panel>
                );
            }
        }
        return items;
    }

    const popoverContent = () => {
        if (notifications && Object.keys(notifications).length > 0) {
            return [
                <div style={isMobile ? { width: 300 } : { width: 400 }}>
                    <Collapse
                        accordion
                        destroyInactivePanel
                        onChange={(data: any) => {
                            if (data) {
                                setActivePanel(data);
                            } else {
                                setActivePanel(undefined);
                            }
                        }}
                    >
                        {getNotificationGroups()}
                    </Collapse>
                    <div>
                        <Popconfirm
                            title={geti18nText('app.default.button.popconfirm.markAsRead')}
                            onConfirm={onReadAllButtonClick}
                        >
                            <Button className={'ny-notification-button'}>
                                {activePanel !== undefined
                                    ? geti18nText('app.default.button.markAsRead.group')
                                    : geti18nText('app.default.button.markAsRead')}
                            </Button>
                        </Popconfirm>
                        <Popconfirm
                            title={geti18nText('app.default.button.popconfirm.delete.all')}
                            onConfirm={onDeleteAllButtonClick}
                        >
                            <Button className={'ny-notification-button'}>
                                {activePanel !== undefined
                                    ? geti18nText('app.default.button.delete.all.group')
                                    : geti18nText('app.default.button.delete.all')}
                            </Button>
                        </Popconfirm>
                    </div>
                </div>,
            ];
        } else {
            return [
                <div style={isMobile ? { width: 300 } : { width: 400 }}>
                    <Empty description={false} />
                </div>,
            ];
        }
    };

    if (isMobile) {
        return (
            <Popover
                content={popoverContent}
                trigger={['click']}
                placement="topRight"
                visible={notificationsVisible}
                onVisibleChange={(visible: any) => setNotificationsVisible(visible)}
            >
                <Button>
                    <Badge count={count && count['ALL'] ? count['ALL'] : 0} size="small" offset={[0, -5]}>
                        <BellOutlined />
                    </Badge>
                </Button>
            </Popover>
        );
    } else {
        return (
            <Popover
                content={popoverContent}
                trigger={['click']}
                placement="topRight"
                visible={notificationsVisible}
                onVisibleChange={(visible: any) => setNotificationsVisible(visible)}
            >
                <Button>
                    <Badge count={count && count['ALL'] ? count['ALL'] : 0} size="small" offset={[0, -5]}>
                        <BellOutlined />
                    </Badge>
                </Button>
            </Popover>
        );
    }
};

export default NotificationsIndex;

export const getNotificationData = (data: any) => {
    let enumKey = GetEnumNameForValue({
        enumName: 'NOTIFICATION_ALARM_TYPE',
        value: Number(data.notificationType),
    });

    let notif = data;
    let link = undefined;
    let content = '';
    switch (enumKey) {
        case 'SYSTEM_WARNING_LICENSE_EXPIRATION':
            content =
                data.message === '1'
                    ? `${geti18nText('notification.device.system.license.expiration.for.day', [data.message])}`
                    : `${geti18nText('notification.device.system.license.expiration.for.days', [data.message])}`;
            link = {
                function: 'openLicense',
            };

            break;
        case 'SYSTEM_INSTANCE_WARNING':
            content = `${geti18nText('notification.instance.warning', [data.message])}`;
            link = {
                function: 'openInstances',
            };

            break;
        case 'RESTART_CONTAINER_INFO':
            const msgs = data.message?.split(';');
            const containerId = msgs[0] != null ? msgs[0] : '';
            const username = msgs[1] != null ? msgs[1] : data.user?.username;
            content = `${geti18nText('notification.container.restart.info', [containerId, username])}`;
            link = {
                function: 'openInstances',
            };

            break;
        case 'DEVICE_WARNING':
            content = `${geti18nText('notification.device.warning')} ${data.deviceName ? data.deviceName : ''}`;
            link = {
                function: 'openItemInTab',
                path: '/device',
                modalName: 'deviceEdit',
                item: data,
                notification: notif,
                title: 'employeeLeave.table.header',
            };
            break;
        case 'DEVICE_CRITICAL_ERROR':
            content = `${geti18nText('notification.device.critical.error')} ${data.deviceName ? data.deviceName : ''}`;
            link = {
                function: 'openItemInTab',
                path: '/device',
                modalName: 'deviceEdit',
                item: data,
                notification: notif,
                title: 'employeeLeave.table.header',
            };
            break;
        case 'SYSTEM_INSTANCE_WARNING':
            content = `${geti18nText('notification.device.instance.inactive', [data.message])}`;
            link = {
                function: 'openInstances',
                path: '/admin/instances',
                modalName: 'index',
                item: data,
                notification: notif,
                title: 'employeeLeave.table.header',
            };
            break;
        case 'DISCONNECTING_DEVICE_INFO':
            content = geti18nText('notification.container.disconnected.devices');
            link = {
                function: 'openNotificationItemInTab',
                path: '/notification',
                modalName: 'notificationEdit',
                item: data,
                notification: notif,
                title: 'employeeLeave.table.header',
            };
            break;
        case 'STOP_COUNTING_INFO':
            content = geti18nText('notification.container.counting.devices');
            link = {
                function: 'openNotificationItemInTab',
                path: '/notification',
                modalName: 'notificationEdit',
                item: data,
                notification: notif,
                title: 'employeeLeave.table.header',
            };
            break;
    }

    return { content: content, link: link };
};
