import { createSlice } from '@reduxjs/toolkit';

interface IModalOpenState {
    visible: boolean;
    modal: string | undefined;
    item: any;
}

const initialState: IModalOpenState = {
    visible: false,
    modal: undefined,
    item: undefined,
};

const modalOpen = createSlice({
    name: 'helper',
    initialState,
    reducers: {
        modalOpenInit(state) {
            state.visible = false;
            state.modal = undefined;
            state.item = undefined;
        },
        setModalOpen(state, action) {
            state.visible = action.payload.visible;
            state.modal = action.payload.modal;
            state.item = action.payload.item;
        },
        setModalVisible(state, action) {
            state.visible = action.payload;
            state.modal = undefined;
            state.item = undefined;
        },
    },
});

export const { modalOpenInit, setModalOpen, setModalVisible } = modalOpen.actions;

export default modalOpen.reducer;
