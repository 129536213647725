import { geti18nText, NyRequestResolver, NySession, NyUtils, RESPONSE } from '@nybble/nyreact';
import { Alert, Button, Col, Form, Input, message, notification, Row, Typography } from 'antd';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import NyFileUpload from '../../components/file-upload/index_file';
import { setActive } from '../../slices/menuSlice';
import { CONSTANTS_REQ } from '../../utils/Constants';
import { getTimezone } from '../../utils/Utils';

const CertManagment = () => {
    const [form] = Form.useForm();
    const [uploadKey, setUploadKey] = useState<any>(Date.now());
    const [file, setFile] = useState<any>(null);
    const [loading, setLoading] = useState(false);
    const [validLicense, setValidLicense] = useState<any>(false);
    const [validDate, setValidDate] = useState<any>(null);
    const { Title } = Typography;
    const history = useHistory();
    const dispatch = useDispatch();

    useEffect(() => {
        fetch();
    }, []);

    function setValues(dataForm: any) {
        setValidLicense(null);
        if (dataForm.validTo) {
            const d = dataForm.validTo;
            dataForm.validTo = new Date(d[0], d[1] - 1, d[2]).toLocaleDateString(
                NyUtils.getSelectedLocale(),
                getTimezone()
            );
            setValidDate(dataForm.validTo);
        }
        if (dataForm.isValid) {
            setValidLicense(dataForm.isValid);
        }
        form.setFieldsValue(dataForm);
    }

    const fetch = () => {
        NyRequestResolver.requestGet(
            NySession.hasAnyRole(['RULE_CERTIFICATE_UPLOAD'])
                ? CONSTANTS_REQ.LICENSE.LICENSE_INFO
                : CONSTANTS_REQ.LICENSE.LICENSE_INFO_READ
        ).then((result) => {
            if (result.status === RESPONSE.OK) {
                if (result.data != null) {
                    //setIsCreated(true);
                    setValues(result.data);
                }
            }
            setLoading(false);
        });
    };

    const onFinish = (values: any) => {
        const formData = new FormData();
        if (values.instance != null) {
            formData.append('instance', values.instance);
        }

        if (file.length === 1) {
            file.forEach((file: any) => {
                formData.append('file', file, file.name);
            });
        }

        NyRequestResolver.requestPostFile(CONSTANTS_REQ.LICENSE.CERT_UPLOAD, undefined, formData).then(
            (response: any) => {
                setLoading(false);
                setUploadKey(Date.now());
                setFile([]);
                if (response.status == RESPONSE.OK) {
                    if (response.data != undefined) {
                        setValues(response.data);
                        if (response.data.isValid == true) {
                            logOut();
                            notification['success']({
                                message: geti18nText('cert.form.upload.success'),
                                duration: 5,
                            });
                            return;
                        }
                    }
                    notification['warning']({
                        message: geti18nText('cert.form.upload.warning'),
                        description: geti18nText('cert.form.upload.error.msg'),
                        duration: 5,
                    });
                } else {
                    notification['error']({
                        message: geti18nText('cert.form.upload.error.title'),
                        description: geti18nText('cert.form.upload.error.msg'),
                    });
                }
            }
        );
    };
    function logOut() {
        if (NySession.hasAnyRole(['RULE_PREVIEW_DEVICE', 'RULE_PREVIEW_USER'])) return;
        dispatch(setActive('/'));
        NySession.logoutUser();
        history.push('/login');
    }

    function onCopyClick(): any {
        const value = form.getFieldValue('instance');
        let textArea = document.createElement('textarea');
        textArea.value = value;
        textArea.style.position = 'fixed';
        textArea.style.left = '-999999px';
        textArea.style.top = '-999999px';
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
        return new Promise((res, rej) => {
            var successful = document.execCommand('copy');
            successful ? res(value) : rej();
            if (successful) {
                message.info(geti18nText('cert.form.copy.btn.msg'));
            }
            textArea.remove();
        });
    }

    return (
        <>
            <Form form={form} onFinish={onFinish} labelCol={{ span: 7 }}>
                <Title level={5}> {geti18nText('cert.form.title')}</Title>
                {validDate && (
                    <Row
                        gutter={20}
                        style={{ textAlign: 'left', marginTop: '20px', marginBottom: '20px', marginLeft: '10%' }}
                    >
                        {validLicense != undefined && validLicense == true ? (
                            <Alert message={geti18nText('cert.form.cert.valid') + ': ' + validDate} type="success" />
                        ) : (
                            <Alert message={geti18nText('cert.form.cert.not.valid') + ': ' + validDate} type="error" />
                        )}
                    </Row>
                )}

                {NySession.hasAnyRole(['RULE_CERTIFICATE_UPLOAD']) && (
                    <>
                        <Row gutter={18}>
                            <Col span={14}>
                                <Form.Item label={geti18nText('cert.form.instance')} name="instance">
                                    <Input readOnly={true} />
                                </Form.Item>
                            </Col>
                            <Col span={4}>
                                {' '}
                                <Form.Item style={{ marginRight: '20px' }}>
                                    <Button type="primary" onClick={onCopyClick}>
                                        {geti18nText('cert.form.copy.btn')}
                                    </Button>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={18}>
                            <Col span={14}>
                                <Form.Item label={geti18nText('cert.form.upload.brn')} name="file">
                                    <NyFileUpload key={uploadKey} files={file} setFiles={setFile} multiple={false} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={18} style={{ textAlign: 'left', marginTop: '20px', marginLeft: '10%' }}>
                            <Col span={12}>
                                <Form.Item style={{ marginRight: '20px' }}>
                                    <Button type="primary" htmlType="submit">
                                        {geti18nText('cert.form.btn.save')}
                                    </Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </>
                )}
            </Form>
        </>
    );
};

export default CertManagment;
