import { combineReducers } from '@reduxjs/toolkit';
import selectedDeviceGroupReducer from './slices/selectedDeviceGroupSlice';
import selectedDeviceCompanyReducer from './slices/selectedDeviceCompanySlice';
import selectedDeviceSettingsDenomination from './slices/deviceSettingsDenominationSlice';
import notificationReducer from './slices/notificationSlice';
import modalOpenReducer from './slices/modalOpenSlice';
import dashboardReducer from './slices/dashboardSlice';
import menuReducer from './slices/menuSlice';



const rootReducer = combineReducers({
    selectedDeviceGroup: selectedDeviceGroupReducer,
    selectedDeviceCompany: selectedDeviceCompanyReducer,
    selectedDeviceSettingsDenomination: selectedDeviceSettingsDenomination,
    notification: notificationReducer,
    modalOpen: modalOpenReducer,
    dashboard: dashboardReducer,
    menu: menuReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
