import { geti18nText, NyDataEdit, NyDataTable, NySession, NyUtils } from '@nybble/nyreact';
import { Col, Form, Input, Row, Tabs } from 'antd';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { RootState } from '../../../rootReducer';
import { selectedGroupSet } from '../../../slices/selectedDeviceGroupSlice';
import { CONSTANTS_REQ } from '../../../utils/Constants';
import { GetEnumNameForValue } from '../../../utils/Enums';
import DeviceColumns from '../device/columns';
import DeviceCSVColCistomization from '../device/csvColumnsCustom';
import DeviceCSVColumnsExport from '../device/csvDeviceColumnsExport';
import DeleteFromGroup from './DeleteFromGroup';

const DeviceGroupEdit = (props: any) => {
    const [editHeader, setEditHeader] = useState(geti18nText('device.group.modal.title'));
    const [loading, setLoading] = useState(false);
    const [isCreate, setIsCreate] = useState(false);
    const [groupId, setGroupId] = useState(false);
    const { id } = useParams<any>();
    const history = useHistory();
    const [form] = Form.useForm();
    const { TabPane } = Tabs;
    const dispatch = useDispatch();
    const { group, refreshGroup } = useSelector((state: RootState) => state.selectedDeviceGroup);

    const onModalClose = () => {
        setEditHeader(geti18nText('device.group.modal.title'));
        form.resetFields();
    };
    function setValues(dataForm: any) {
        dispatch(selectedGroupSet({ group: dataForm.id, refreshGroup: 0, refreshGroupAdd: 0 }));
        setEditHeader(dataForm.name);
        setGroupId(dataForm.id);
        form.setFieldsValue(dataForm);
    }
    function onSaveAndGetData(data: any) {
        const groups: [any] = NyUtils.load('DEVICE_GROUPS');
        if (data.active === true) {
            let index = groups.findIndex((el) => el.id === data.id);
            if (index != -1) groups.splice(index, 1);
            groups.push({ id: data.id, name: data.name });
        } else {
            let index = groups.findIndex((el) => el.id === data.id);
            if (index != -1) groups.splice(index, 1);
        }
        NyUtils.save('DEVICE_GROUPS', groups ? groups : []);
        props.onSaveAndGetData(data);
    }
    function setDefaultFilterValue() {
        if(NySession.hasAnyRole(['RULE_PREVIEW_DEVICE_WRITTEN_OFF'])) {
            return [ { field: 'groupId', condition: 'equals', value: group }];
        }
        else { 
            return [{ field: 'writtenOff', condition: 'equals_bool', value: 0 }, 
                { field: 'groupId', condition: 'equals', value: group }
        
            ];
        }
    }

    return (
        <NyDataEdit
            layout="horizontal"
            formProps={{ labelCol: { span: 7 }, wrapperCol: { span: 17 } }}
            editHeader={editHeader}
            loading={loading}
            setLoading={setLoading}
            onModalClose={onModalClose}
            url={CONSTANTS_REQ.DEVICE_GROUP.EDIT}
            setValues={setValues}
            width={1500}
            form={form}
            goBack={() => history.goBack()}
            paramsId={id}
            setIsCreate={setIsCreate}
            {...props}
            hideButtons={false}
            onSaveAndGetData={onSaveAndGetData}
        >
            <Tabs defaultActiveKey="1" type="card">
                <TabPane tab={geti18nText('device.edit.tab.info')} key="1">
                    <Row>
                        <Col span={12}>
                            <Form.Item name="id" style={{ display: 'none' }}>
                                <Input readOnly={true} />
                            </Form.Item>
                            <Form.Item
                                rules={[
                                    {
                                        required: true,
                                        message: geti18nText('app.default.required'),
                                    },
                                ]}
                                label={geti18nText('device.group.edit.name')}
                                name="name"
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                </TabPane>
                <TabPane tab={geti18nText('device.group.edit.list.device')} key="2" disabled={isCreate || loading}>
                    {groupId && (
                        <>
                            <NyDataTable
                                headerTitle={geti18nText('device.table.header')}
                                url={CONSTANTS_REQ.DEVICE.LIST}
                                showRecordModal={false}
                                hideButtons={false}
                                hideNewButton={true}
                                readonly={true}
                                fetchWhenChange={refreshGroup}
                                setDefaultPageSize={20}
                                scroll={{ x: 1200 }}
                                columns={DeviceColumns({isAdmin:true})}
                                csvColumns={DeviceCSVColumnsExport()}
                                showRowSelection={true}
                                rowSelectionModal={DeleteFromGroup}
                                exportCSV={true}
                                setDefaultFilterValue={setDefaultFilterValue}
                                colCSVCustomization={DeviceCSVColCistomization()}
                            />
                        </>
                    )}
                </TabPane>
            </Tabs>
        </NyDataEdit>
    );
};

export default DeviceGroupEdit;
