import { geti18nText, NySession, NyUtils } from '@nybble/nyreact';
import moment from 'moment';
import { GetEnum } from './Enums';

export const setEnumFormat = function (enumName: any, value: any) {
    const enums: [] = getEnumArray(enumName);

    if (enums === undefined) {
        return {};
    }

    return enums.find((e: any) => {
        return e.id === value;
    });
};

export const getEnumArray = function (enumName: any, text: any = 'text', id: any = 'id', enumAlias: any = undefined) {
    const aTypes = GetEnum({ enumName: enumName });
    let types: any = [];
    for (var key in aTypes) {
        if (aTypes.hasOwnProperty(key)) {
            let ret: any = {};
            ret[id] = aTypes[key];
            if (enumAlias) {
                ret[text] = geti18nText(`app.enum.${enumAlias}.` + ret[id]);
            } else {
                ret[text] = geti18nText(`app.enum.${enumName}.` + ret[id]);
            }
            types.push(ret);
        }
    }
    return types;
};

export function getTimezone() {
    let zone: any = {};
    zone.timeZone = moment.tz.guess();
    if (NySession.getUser().timezone) {
        zone.timeZone = NySession.getUser().timezone;
    }
    return zone;
}

export function formatCurrency(num: number, currencyCode: string) {
    var precision = 2;
    const currencyes = NyUtils.load('CURRENCY');
    const currencyIndex = currencyes.findIndex((x: any) => x.currencyCode == currencyCode);
    if (currencyIndex > -1) {
        precision =
            currencyes[currencyIndex] != null && currencyes[currencyIndex].precision != null
                ? currencyes[currencyIndex].precision
                : 2;
    }
    return NyUtils.formatNumber(num, precision);
}

export function formatCurrencyById(num: number, currencyId: any) {
    var precision = 2;
    const currencyes = NyUtils.load('CURRENCY');
    const currencyIndex = currencyes.findIndex((x: any) => x.id == parseInt(currencyId));
    if (currencyIndex > -1) {
        precision =
            currencyes[currencyIndex] != null && currencyes[currencyIndex].precision != null
                ? currencyes[currencyIndex].precision
                : 2;
    }
    return NyUtils.formatNumber(num, precision);
}
