import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface ISelectedDeviceGroupSlice {
    group: any;
    refreshGroup: number;
    refreshGroupAdd: number;
}

const initialState: ISelectedDeviceGroupSlice = {
    group: undefined,
    refreshGroup: 0,
    refreshGroupAdd:0
};

const selectedDeviceGroupSlice = createSlice({
    name: 'selectedDeviceGroupSlice',
    initialState,
    reducers: {
        selectedGroupInit(state:any) {
            state.device = {};
        },
        selectedGroupSet(state:any, action: PayloadAction<ISelectedDeviceGroupSlice>) {
            const { group: group } = action.payload;
            state.group = group;
        },
        refreshGroupList(state) {
            state.refreshGroup++;
        },
        refreshGroupAdd(state) {
            state.refreshGroupAdd++;
        },
    },
});

export const { selectedGroupInit, selectedGroupSet, refreshGroupList,refreshGroupAdd } = selectedDeviceGroupSlice.actions;

export default selectedDeviceGroupSlice.reducer;
