import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import {
    ENUMS,
    getColumnDateOption,
    getColumnSearch,
    getColumnSearchOption,
    geti18nText,
    NyDataTable,
    NySession,
    NyUtils,
} from '@nybble/nyreact';
import { Col, Popover, Tabs, Typography } from 'antd';
import React from 'react';
import { AnyIfEmpty } from 'react-redux';
import { CONSTANTS_REQ } from '../../utils/Constants';
import { getTimezone } from '../../utils/Utils';

const DeviceCountReportIndex = (props: any) => {
    const { TabPane } = Tabs;
    const { Text } = Typography;

    function setDefaultFilterValue() {
        console.log(props);
        return [{ field: 'device_id', condition: 'equals', value: props.value.id }];
    }

    return (
        <NyDataTable
            url={CONSTANTS_REQ.REPORT_COUNTING.LIST}
            showRecordModal={true}
            hideButtons={true}
            setDefaultPageSize={10}
            exportCSV={true}
            setDefaultFilterValue={setDefaultFilterValue}
            columns={[
                {
                    title: geti18nText('counting.report.id'),
                    dataIndex: 'id',
                    width: '5%',
                    align: 'center',
                    sorter: (a: any, b: any) => {},
                    ...getColumnSearch('number'),
                },
                {
                    title: geti18nText('counting.report.device.name'),
                    dataIndex: ['device', 'name'],
                    width: '10%',
                    align: 'center',
                    sorter: (a: any, b: any) => {},
                    ...getColumnSearch('string'),
                },
                {
                    title: geti18nText('counting.report.created'),
                    dataIndex: 'created',
                    width: '10%',
                    align: 'center',
                    sorter: (a: any, b: any) => {},
                    render: (text: string, record: { [index: string]: any }) => {
                        if (record.created) {
                            return (
                                <div>
                                    {new Date(record.created).toLocaleString(
                                        NyUtils.getSelectedLocale(),
                                        getTimezone()
                                    )}
                                </div>
                            );
                        }
                    },
                    ...getColumnSearch('string'),
                },
                {
                    title: geti18nText('counting.report.raw.data'),
                    dataIndex: 'rawData',
                    width: '20%',
                    sorter: (a: any, b: any) => {},
                    render: (value: any, record: any) => {
                        const text = hex2a(value);
                        return (
                            <>
                                {text && text.length > 40 && (
                                    <Popover
                                        content={
                                            <Col
                                                span={24}
                                                style={{
                                                    padding: '20px',
                                                    whiteSpace: 'pre-wrap',
                                                    overflowY: 'scroll',
                                                    maxWidth: '55vw',
                                                    height: '40vh',
                                                    background: 'rgba(0,0,0,0.1)',
                                                }}
                                            >
                                                {text}
                                            </Col>
                                        }
                                    >
                                        <Text>{text.substring(0, 40)}...</Text>
                                    </Popover>
                                )}
                                {(text == null || text.length <= 40) && <Text>{text}</Text>}
                            </>
                        );
                    },
                    ...getColumnSearch('string'),
                },
            ]}
        />
    );
};

export default DeviceCountReportIndex;

export function hex2a(hexx: any) {
    if (hexx == null) return '';
    var hex = hexx.toString(); //force conversion
    var str = '';
    hex = hex.replace('\\x', '');
    for (var i = 0; i < hex.length; i += 2) str += String.fromCharCode(parseInt(hex.substr(i, 2), 16));
    return str;
}
