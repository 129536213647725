import { NySearchField } from '@nybble/nyreact';
import { geti18nText, NyInputNumber } from '@nybble/nyreact';
import { InputNumber } from 'antd';
import { Form, Input } from 'antd';
import { useSelector } from 'react-redux';
import { CONSTANTS_REQ } from '../../../utils/Constants';
//import { RootState } from '../../rootReducer';

const EditableCell = ({ editing, dataIndex, title, inputType, record, index, children, formName, ...restProps }: any) => {
    let inputNode: any = undefined;
    switch (inputType) {
        case 'value':
            inputNode = <NyInputNumber ></NyInputNumber>;
            break;
        
        case 'currency':
            inputNode = <Input disabled />;
            break;
        default:
            inputNode = <Input disabled />;
    }

    function rules() {
        let rules : any= [];
        let rule : any = {};
        if(inputType === 'value') {
            rule.required=true;
        }
        if(inputType === 'value') {
            rule.type= 'regexp';
        }
        if(inputType === 'value') {
            rule.pattern= new RegExp(/\d+/g);
        }
        rule.message = geti18nText('device.edit.settingsCalculateApoen.wrong.format');
        rules.push(rule);
        return rules;
    }

    return (
        <td {...restProps}>
            {editing ? (
                <Form.Item
                    name={dataIndex}
                    style={{
                        margin: 0,
                    }}
                    rules={[
                        {
                          required: true,
                          message: ` is required.`,
                        },
                      ]}
                >
                    {inputNode}
                </Form.Item>
            ) : (
                children
            )}
        </td>
    );
};

export default EditableCell;
