import { SearchOutlined } from '@ant-design/icons';
import { geti18nText, NyDataTable } from '@nybble/nyreact';
import { Button, Col, Input, Modal, Row } from 'antd';
import React, { useState } from 'react';
import { CONSTANTS_REQ } from '../../utils/Constants';
import DeviceColumns from '../admin/device/columns';
import DeviceCSVColumnsExport from '../admin/device/csvDeviceColumnsExport';
const DeviceSearchIndex = (props: any) => {
    const [visible, setVisible] = useState<any>();

    function setDefaultFilterValue() {
        if(!props.isAdmin) {
            return [{ field: 'writtenOff', condition: 'equals_bool', value: 0 }];
        }
        else return [];
    }

    function onCancelDeviceModal() {
        setVisible(false);
    }

    function onRowClickHandler(record: any) {
        setVisible(false);
        props.onRowClickHandler(record);
    }

    return (
        <>
        <Row gutter={12}>
                        <Col span={21}>
                            <Input allowClear={true} maxLength={7} readOnly={true} value={props.deviceValue}></Input>
                        </Col>
                        <Col span={3}>
                            <Button
                                onClick={() => {
                                    setVisible(true);
                                }}
                                type="primary"
                                icon={<SearchOutlined />}
                            ></Button>
                        </Col>
                    </Row>
        <Modal
                visible={visible}
                title={geti18nText('statistic.chart.modal.option.device.select')}
                width={1500}
                footer={null}
                onCancel={onCancelDeviceModal}
                destroyOnClose={true}
                okText={geti18nText('menu.statistic.modal.settings.add')}
            >
                <NyDataTable
                    url={CONSTANTS_REQ.DEVICE.LIST}
                    showRecordModal={false}
                    addNewButtonText={geti18nText('device.add.new')}
                    hideButtons={true}
                    onRowSelect={onRowClickHandler}
                    setDefaultPageSize={20}
                    columns={DeviceColumns()}
                    scroll={{ x: 1200 }}
                    exportCSV={false}
                    setDefaultFilterValue={setDefaultFilterValue}
                    csvColumns={DeviceCSVColumnsExport()}
                />
            </Modal>
        </>
    );
};

export default DeviceSearchIndex;
