import { getColumnDateOption, getColumnSearch, geti18nText, NyDataEdit, NyDataTable, NyUtils } from '@nybble/nyreact';
import { Form, Modal, Tabs } from 'antd';
import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { CONSTANTS_REQ } from '../../../utils/Constants';

import DeviceColumns from '../device/columns';
import DeviceCSVColCistomization from '../device/csvColumnsCustom';
import DeviceCSVColumnsExport from '../device/csvDeviceColumnsExport';
import DeviceEdit from '../../device/edit';

interface IListDevicesIndex {
    id: number;
    visible: boolean;
    instance_id:any;
    closeModal?: () => void;
}

const ListDeviceIndex = ({ visible, id,instance_id, closeModal }: IListDevicesIndex) => {
    const [editHeader, setEditHeader] = useState(geti18nText('instance.edit.header'));
    const [loading, setLoading] = useState(false);
    const [isCreate, setIsCreate] = useState(false);

    const history = useHistory();
    const [form] = Form.useForm();

    function setDefaultFilterValue() {
        return [{ field: 'instanceId', condition: 'equals', value: id }];
    }

    return (
        <Modal
            getContainer={false}
            destroyOnClose={true}
            footer={null}
            width={1500}
            onCancel={closeModal}
            title={geti18nText('instance.reinit.modal.title')+': '+instance_id}
            visible={visible}
        >
            <NyDataTable
                headerTitle={geti18nText('device.table.header')}
                modalComponent={DeviceEdit}
                url={CONSTANTS_REQ.DEVICE.LIST}
                showRecordModal={true}
                hideButtons={false}
                hideNewButton={true}
                readonly={false}
                setDefaultPageSize={20}
                scroll={{ x: 1200 }}
                columns={DeviceColumns()}
                csvColumns={DeviceCSVColumnsExport()}
                exportCSV={true}
                setDefaultFilterValue={setDefaultFilterValue}
                colCSVCustomization={DeviceCSVColCistomization()}
            />
        </Modal>
    );
};

export default ListDeviceIndex;
