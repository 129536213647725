import { EditTwoTone, DeleteOutlined, CheckOutlined, CloseOutlined, HistoryOutlined } from '@ant-design/icons';
import {
    ENUMS,
    getColumnDateOption,
    getColumnSearch,
    getColumnSearchOption,
    geti18nText,
    NyDataEdit,
    NyDataTable,
    NySearchField,
    NySession,
    NySpinner,
    NyUtils,
} from '@nybble/nyreact';
import { Button, Col, Descriptions, Form, Input, Modal, Popconfirm, Row, Table, Tabs, Tag } from 'antd';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import NyImageUpload from '../../../components/file-upload';
import { RootState } from '../../../rootReducer';
import { CONSTANTS_REQ } from '../../../utils/Constants';
import { GetEnum, GetEnumNameForValue } from '../../../utils/Enums';
import DeleteDeviceSettingsDenomination from './DeleteDeviceSettingsDenominations';
import DeviceSettingsDenominationsEdit from './editSettingsDenominations';
import EditableCell from './EditableCell';
import AdminModsEdit from './editMods';
import { NyRequestResolver } from '@nybble/nyreact';
import { RESPONSE } from '@nybble/nyreact';
import { Tooltip } from 'antd';
import { Space } from 'antd';
import { notification } from 'antd';
import { getTimezone } from '../../../utils/Utils';

const DeviceModelEdit = (props: any) => {
    const [editHeader, setEditHeader] = useState(geti18nText('device.model.table.header'));
    const [loading, setLoading] = useState(false);
    const { id } = useParams<any>();
    const history = useHistory();
    const [form] = Form.useForm();
    const [uploadKey, setUploadKey] = useState<any>(Date.now());
    const [file, setFile] = useState<any>(null);
    const [idModel, setIdModel] = useState<any>(null);
    const [dataDenominationSettings, setDataDenominationSettings] = useState<any>([]);
    const [openModalCreate, setOpenModalCreate] = useState<any>(false);
    const { refresh } = useSelector((state: RootState) => state.selectedDeviceSettingsDenomination);
    const [editingKey, setEditingKey] = useState<any>('');
    const [itemForm] = Form.useForm();
    const [openModalEditSettingDenomination, setOpenModalEditSettingDenomination] = useState<any>(false);
    const [visibleHistory, setVisibleHistory] = useState<any>(false);
    const [pagination, setPagination] = useState<any>({
        showSizeChanger: true,
        pageSizeOptions: ['10', '20', '50', '100'],
        locale: {
            items_per_page: '',
        },
        total: 0,
        showTotal: (total: number) => {
            return `${geti18nText('app.default.total')} ${total} ${geti18nText('app.default.records')}`;
        },
    });
    const [current, setCurrent] = useState<any>(undefined);
    const [pageSize, setPageSize] = useState<any>(undefined);
    const [refreshDenominationSettings, setRefreshDenominationSettings] = useState<any>(Math.floor(Math.random() * 10));

    const onModalClose = () => {
        setFile(null);
        setEditHeader(geti18nText('device.model.table.header'));
        form.resetFields();
    };
    function setValues(dataForm: any) {
        setEditHeader(dataForm.name);
        setIdModel(dataForm.id);
        if (dataForm.image != null) {
            dataForm.image = 'data:image/jpeg;base64,' + dataForm.image;
        }
        setFile(dataForm.image);
        dataForm.type = {
            id: dataForm.type,
            text: geti18nText(
                'device.model.type.' +
                    GetEnumNameForValue({ enumName: 'DEVICE_TYPE', value: dataForm.type })?.toLowerCase()
            ),
        };
        dataForm.communicationType = {
            id: dataForm.communicationType,
            text: GetEnumNameForValue({ enumName: 'COMMUNICATION_TYPE', value: dataForm.communicationType }),
        };
        if (dataForm.model) {
            dataForm.model = {
                id: dataForm.model,
                text: GetEnumNameForValue({ enumName: 'DEVICE_MODEL_PATTERN_TYPES', value: dataForm.model }),
            };
        }
        form.setFieldsValue(dataForm);
    }

    function onSaveAndGetData(data: any) {
        const models: [any] = NyUtils.load('DEVICE_MODELS');
        if (data.active === true) {
            let index = models.findIndex((el) => el.id === data.id);
            if (index != -1) models.splice(index, 1);
            models.push({ id: data.id, name: data.name });
        } else {
            let index = models.findIndex((el) => el.id === data.id);
            if (index != -1) models.splice(index, 1);
        }
        NyUtils.save('DEVICE_MODELS', models ? models : []);
        props.onSaveAndGetData(data);
    }

    const communicationTypes = () => {
        const aTypes = GetEnum({ enumName: 'COMMUNICATION_TYPE' });
        let types = [];
        for (var key in aTypes) {
            if (aTypes.hasOwnProperty(key)) {
                let ret = { id: undefined, text: '' };
                ret.id = aTypes[key];
                ret.text = key;
                types.push(ret);
            }
        }
        return types;
    };

    const modelPatterns = () => {
        const aTypes = GetEnum({ enumName: 'DEVICE_MODEL_PATTERN_TYPES' });
        let types = [];
        for (var key in aTypes) {
            if (aTypes.hasOwnProperty(key)) {
                let ret = { id: undefined, text: '' };
                ret.id = aTypes[key];
                ret.text = key;
                types.push(ret);
            }
        }
        return types;
    };

    const deviceTypes = () => {
        const aTypes = GetEnum({ enumName: 'DEVICE_TYPE' });
        let types = [];
        for (var key in aTypes) {
            if (aTypes.hasOwnProperty(key)) {
                let ret = { id: undefined, text: '' };
                ret.id = aTypes[key];
                ret.text = geti18nText('device.model.type.' + key.toLowerCase());
                types.push(ret);
            }
        }
        return types;
    };

    function ondeviceTypeChange(val: any) {
        if (val && val.id && val.id === -1) {
            form.resetFields(['type']);
        }
    }

    function onCommunicationTypeChange(val: any) {
        if (val && val.id && val.id === -1) {
            form.resetFields(['type']);
        }
    }

    function normalize(values: any) {
        let normalized = { ...values };
        normalized.communicationType = values.communicationType.id;
        if (normalized.model != undefined) {
            normalized.model = values.model.id;
        }
        normalized.type = values.type.id;
        normalized.file = file;
        if (normalized.image != null) {
            delete normalized.image;
        }

        return normalized;
    }

    const validateKey = (rule: any, value: any, callback: any) => {
        if (!/^[a-zA-Z0-9-_]*$/.test(value)) {
            callback(geti18nText('device.model.table.key.validate'));
            return;
        }
        callback();
    };
    const { TabPane } = Tabs;

    const deviceModels = () => {
        const models = NyUtils.load('DEVICE_MODELS');
        let types: any = [];
        models.forEach((element: any) => {
            let ret: any = {};
            ret.id = element.id;
            ret.text = element.name;
            types.push(ret);
        });
        return types;
    };

    function setIsCreate(val: any) {
        if (val === true) {
            setOpenModalCreate(true);
        } else {
            setOpenModalCreate(false);
        }
    }

    function setDefaultFilterValueMods() {
        return [
            { field: 'deviceModel.id', condition: 'equals', value: idModel },
            { field: 'deviceModel.active', condition: 'equals_bool', value: 1 },
        ];
    }

    /* function fetchDataSettingDenomination(params: { [index: string]: any } = {}) {
        if (current != null && params.offset === undefined) {
            params.offset = current;
        }
        setLoading(true);
        NyRequestResolver.requestGet(CONSTANTS_REQ.DEVICE_MODEL_SETTINGS_DENOMINATIONS.LIST, { ...params, ...{deviceModelId: idModel} }).then((response:any) => {
            if (response && response.status === RESPONSE.OK) {
                const pageable = { ...pagination };
                if (response.data && typeof response.data !== 'string') {
                    
                    pageable.total = response.data['totalSize'];
                    pageable.current = response.data['pageNumber'] + 1;
                    pageable.pageSize = response.data['size'];
                    setDataDenominationSettings(response.data.content);
                    setLoading(false);
                    setPagination(pageable);
                }
            } else {
                setDataDenominationSettings([]);
                setLoading(false);
            }
        });
    } */

    const { TextArea } = Input;

    const isEditing = (record: any) => record.id === editingKey;

    const columns = [
        {
            title: geti18nText('device.edit.settingsCalculateApoen.currency'),
            dataIndex: ['currency', 'currency_code'],
            key: '1',
            width: '40%',
            sorter: (a: any, b: any) => {},
            editable: false,
            // ...getColumnSearch('string'),
            inputType: 'currency',
        },
        {
            title: geti18nText('device.edit.settingsCalculateApoen.number.divide'),
            dataIndex: 'value',
            width: '50%',
            key: '2',
            editable: true,
            sorter: (a: any, b: any) => {},
            //...getColumnSearch('number'),
            inputType: 'value',
        },
        {
            title: geti18nText('device.edit.settingsCalculateApoen.action'),
            key: 'action',
            width: '10%',
            render: (text: any, record: any) => {
                const editable = isEditing(record);
                return editable ? (
                    <ActionEditable text={text} record={record} />
                ) : (
                    <ActionNotEditable text={text} record={record} />
                );
            },
        },
    ];

    const edit = (record: any) => {
        let editForm = undefined;
        editForm = { ...record };
        itemForm.setFieldsValue(editForm);
        setEditingKey(record.id);
    };

    const cancelEdit = (record: any) => {
        setRefreshDenominationSettings(Math.floor(Math.random() * 10));
        setEditingKey('');
    };
    const save = async (record: any) => {
        try {
            setLoading(true);
            const row = await itemForm.validateFields();
            NyRequestResolver.requestPut(CONSTANTS_REQ.DEVICE_MODEL_SETTINGS_DENOMINATIONS.EDIT, undefined, {
                currencyId: record.currency.id,
                deviceModelId: record.deviceModelId,
                value: row.value,
            }).then((result: any) => {
                if (result.status === RESPONSE.OK) {
                    notification.success({
                        message: geti18nText('app.default.save.ok'),
                        description: geti18nText('app.default.save.ok.desc'),
                        duration: 3,
                    });
                    setRefreshDenominationSettings(Math.floor(Math.random() * 10));
                    setLoading(false);
                } else {
                    notification.error({
                        message: geti18nText('app.default.save.nok'),
                        description: '',
                        duration: 3,
                    });
                    setLoading(false);
                }
            });
            setEditingKey('');
        } catch (errInfo) {
            setLoading(false);
            console.log('Validate Failed:', errInfo);
        }
    };

    function deleteSettingDenomination(record: any) {
        setLoading(true);
        NyRequestResolver.requestPost(CONSTANTS_REQ.DEVICE_MODEL_SETTINGS_DENOMINATIONS.DELETE, undefined, {
            settingsDenomination: {
                currencyId: record.currency.id,
                deviceModelId: record.deviceModelId,
                value: record.value,
            },
        }).then((result: any) => {
            if (result.status === RESPONSE.OK) {
                notification.success({
                    message: geti18nText('app.default.save.ok'),
                    description: geti18nText('app.default.save.ok.desc'),
                    duration: 3,
                });
                setRefreshDenominationSettings(Math.floor(Math.random() * 10));
                setLoading(false);
            } else {
                notification.error({
                    message: geti18nText('app.default.save.nok'),
                    description: '',
                    duration: 3,
                });
                setLoading(false);
            }
        });
    }

    const ActionNotEditable = ({ text, record }: any) => {
        return (
            <React.Fragment>
                <Space size="middle" style={{ textAlign: 'right' }}>
                    <Tooltip placement="top" title={geti18nText('app.default.button.edit')}>
                        <Button type="link" onClick={() => edit(record)}>
                            <EditTwoTone />
                        </Button>
                    </Tooltip>
                    <Popconfirm
                        title={geti18nText('device.edit.settingsCalculateApoen.delete.text')}
                        onConfirm={() => {
                            deleteSettingDenomination(record);
                        }}
                    >
                        <Tooltip placement="top" title={geti18nText('app.default.button.delete')}>
                            <Button danger type="link">
                                <DeleteOutlined />
                            </Button>
                        </Tooltip>
                    </Popconfirm>
                </Space>
            </React.Fragment>
        );
    };
    const ActionEditable = ({ text, record }: any) => {
        return (
            <React.Fragment>
                <Space size="middle" style={{ textAlign: 'right' }}>
                    <Tooltip placement="top" title={geti18nText('app.default.button.save')}>
                        <Button type="link" onClick={() => save(record)}>
                            <CheckOutlined />
                        </Button>
                    </Tooltip>
                    <Popconfirm
                        title={geti18nText('device.edit.settingsCalculateApoen.delete.text')}
                        onConfirm={() => {
                            deleteSettingDenomination(record);
                        }}
                    >
                        <Tooltip placement="top" title={geti18nText('app.default.button.delete')}>
                            <Button danger type="link">
                                <DeleteOutlined />
                            </Button>
                        </Tooltip>
                    </Popconfirm>
                    <Tooltip placement="top" title={geti18nText('app.default.button.cancel')}>
                        <Button type="link" onClick={() => cancelEdit(record)}>
                            <CloseOutlined />
                        </Button>
                    </Tooltip>
                </Space>
            </React.Fragment>
        );
    };

    const mergedColumns = columns.map((col: any) => {
        if (!col.editable) {
            return col;
        }

        return {
            ...col,
            onCell: (record: any) => {
                return {
                    record,
                    inputType: col.inputType,
                    dataIndex: col.dataIndex,
                    title: col.title,
                    editing: isEditing(record),
                    modelId: idModel,
                };
            },
        };
    });

    function setVisibleSettingsModal(val: any) {
        setOpenModalEditSettingDenomination(val);
        if (val === false) {
            setRefreshDenominationSettings(Math.floor(Math.random() * 10));
        }
    }

    function handleCancelHistory() {
        setVisibleHistory(false);
    }

    const setDefaultSortOrderDenominationSettings = { order: 'currency.currencyCode', orderType: 'desc' };

    const denominationSettingsHistoryAction = () => {
        const aTypes = GetEnum({ enumName: 'DENOMINATION_SETTINGS_HISTORY_ACTION' });
        let types = [];
        for (var key in aTypes) {
            if (aTypes.hasOwnProperty(key)) {
                let ret: any = {};
                ret.id = aTypes[key];
                ret.text = geti18nText('device.edit.settingsCalculateApoen.action.' + key.toLowerCase());
                types.push(ret);
            }
        }
        return types;
    };

    return (
        <NyDataEdit
            layout="horizontal"
            formProps={{ labelCol: { span: 7 }, wrapperCol: { span: 17 } }}
            editHeader={editHeader}
            loading={loading}
            setLoading={setLoading}
            onModalClose={onModalClose}
            url={CONSTANTS_REQ.DEVICE_MODEL.EDIT_IMAGE}
            setValues={setValues}
            normalize={normalize}
            width={900}
            form={form}
            goBack={() => history.goBack()}
            paramsId={id}
            setIsCreate={setIsCreate}
            {...props}
            onSaveAndGetData={onSaveAndGetData}
            hideButtons={!NySession.hasAnyRole(['RULE_UPDATE_DEVICE_MODEL'])}
        >
            <Tabs defaultActiveKey="1" type="card">
                <TabPane tab={geti18nText('device.edit.tab.info')} key="1">
                    <Row>
                        <Form.Item name="id" style={{ display: 'none' }}>
                            <Input readOnly={true} />
                        </Form.Item>
                        <Col span={18}>
                            <Form.Item
                                rules={[
                                    {
                                        required: true,
                                        message: geti18nText('app.default.required'),
                                    },
                                ]}
                                label={geti18nText('device.model.table.name')}
                                name="name"
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={18}>
                            <Form.Item
                                rules={[
                                    {
                                        required: true,
                                        message: geti18nText('app.default.required'),
                                    },
                                ]}
                                label={geti18nText('device.model.table.type')}
                                name="type"
                            >
                                <NySearchField
                                    onChange={ondeviceTypeChange}
                                    options={deviceTypes()}
                                    map={{ id: 'id', label: 'text' }}
                                    searchBy="text"
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={18}>
                            <Form.Item
                                rules={[
                                    {
                                        required: true,
                                        message: geti18nText('app.default.required'),
                                    },
                                ]}
                                label={geti18nText('device.table.column.communication.type')}
                                name="communicationType"
                            >
                                <NySearchField
                                    onChange={onCommunicationTypeChange}
                                    options={communicationTypes()}
                                    map={{ id: 'id', label: 'text' }}
                                    searchBy="text"
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}></Col>
                    </Row>
                    <Row>
                        <Col span={18}>
                            <Form.Item label={geti18nText('device.model.model')} name="model">
                                <NySearchField
                                    options={modelPatterns()}
                                    map={{ id: 'id', label: 'text' }}
                                    searchBy="text"
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}></Col>
                    </Row>
                    <Row>
                        <Col span={18}>
                            <Form.Item label={geti18nText('device.model.table.external_id')} name="externalId">
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={18} style={{ marginTop: '15px' }}>
                            <Form.Item label={geti18nText('device.model.table.image')} name="image">
                                <NyImageUpload
                                    key={uploadKey}
                                    files={file}
                                    setFiles={setFile}
                                    multiple={false}
                                    value={file}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </TabPane>
                {NySession.getSetting('mod')['exist'] === true && (
                    <TabPane tab={geti18nText('mods.header')} key="2" disabled={openModalCreate || loading}>
                        {!loading ? (
                            <NyDataTable
                                url={CONSTANTS_REQ.MODS.LIST}
                                showRecordModal={true}
                                modalComponent={AdminModsEdit}
                                hideButtons={true}
                                setDefaultFilterValue={setDefaultFilterValueMods}
                                setDefaultPageSize={20}
                                columns={[
                                    {
                                        title: geti18nText('mods.table.column.name'),
                                        dataIndex: 'name',
                                        sorter: (a: any, b: any) => {},
                                        ...getColumnSearch('string'),
                                    },

                                    {
                                        title: geti18nText('mods.table.column.description'),
                                        dataIndex: 'description',
                                        sorter: (a: any, b: any) => {},
                                        ...getColumnSearch('string'),
                                    },
                                ]}
                            />
                        ) : (
                            <NySpinner></NySpinner>
                        )}
                    </TabPane>
                )}
                <TabPane
                    tab={geti18nText('device.edit.settingsCalculateApoen')}
                    key="3"
                    disabled={openModalCreate || loading}
                >
                    {!loading ? (
                        <>
                            <Form form={itemForm}>
                                <NyDataTable
                                    url={CONSTANTS_REQ.DEVICE_MODEL_SETTINGS_DENOMINATIONS.LIST}
                                    showRecordModal={false}
                                    readonly={true}
                                    hideButtons={true}
                                    setDefaultSortOrder={setDefaultSortOrderDenominationSettings}
                                    setDefaultPageSize={20}
                                    addedData={{ deviceModelId: idModel }}
                                    columns={mergedColumns}
                                    fetchWhenChange={refreshDenominationSettings}
                                    antdTableProps={{
                                        components: {
                                            body: {
                                                cell: EditableCell,
                                            },
                                        },
                                    }}
                                />
                            </Form>
                            <Row>
                                <Button
                                    type="primary"
                                    style={{ marginTop: '10px' }}
                                    onClick={() => {
                                        setOpenModalEditSettingDenomination(true);
                                    }}
                                >
                                    {geti18nText('device.edit.settingsCalculateApoen.add')}
                                </Button>

                                <Button
                                    style={{ marginTop: '10px', marginLeft: '10px' }}
                                    onClick={() => {
                                        setVisibleHistory(true);
                                    }}
                                >
                                    <HistoryOutlined /> {geti18nText('device.edit.settingsCalculateApoen.user.change')}
                                </Button>
                            </Row>
                            <DeviceSettingsDenominationsEdit
                                modelId={idModel}
                                setVisible={setVisibleSettingsModal}
                                visible={openModalEditSettingDenomination}
                            ></DeviceSettingsDenominationsEdit>
                            <Modal
                                title={geti18nText('device.edit.settingsCalculateApoen.user.change')}
                                visible={visibleHistory}
                                width={800}
                                onCancel={handleCancelHistory}
                                onOk={handleCancelHistory}
                            >
                                <NyDataTable
                                    url={CONSTANTS_REQ.DEVICE_SETTINGS_DENOMINATIONS_HISTORY.LIST}
                                    showRecordModal={false}
                                    addedData={{ deviceModelId: idModel }}
                                    setDefaultPageSize={20}
                                    showRowSelection={false}
                                    //fetchWhenChange={refreshHistory}
                                    selectOnSave={false}
                                    readonly={true}
                                    hideButtons={true}
                                    setRowClassName={(record: any) => {
                                        return '';
                                    }}
                                    columns={[
                                        {
                                            title: geti18nText('device.edit.settingsCalculateApoen.currency'),
                                            dataIndex: ['currency', 'currency_code'],
                                            width: '10%',
                                            sorter: (a: any, b: any) => {},
                                            ...getColumnSearch('string'),
                                        },
                                        {
                                            title: geti18nText('device.edit.settingsCalculateApoen.number.divide'),
                                            dataIndex: 'value',
                                            width: '20%',
                                            sorter: (a: any, b: any) => {},
                                            ...getColumnSearch('number'),
                                        },
                                        {
                                            title: geti18nText('device.edit.settingsCalculateApoen.creadted'),
                                            dataIndex: 'created',
                                            width: '20%',
                                            sorter: (a: any, b: any) => {},
                                            ...getColumnDateOption(true, undefined, undefined, true),
                                            render: (text: string, record: { [index: string]: any }) => {
                                                if (record.created) {
                                                    return (
                                                        <div>
                                                            {new Date(record.created).toLocaleString(
                                                                NyUtils.getSelectedLocale(),
                                                                getTimezone()
                                                            )}
                                                        </div>
                                                    );
                                                }
                                            },
                                        },
                                        {
                                            title: geti18nText('device.edit.settingsCalculateApoen.user'),
                                            dataIndex: 'users',
                                            width: '20%',
                                            sorter: (a: any, b: any) => {},
                                            //...getColumnSearch('string'),
                                            render: (text: string, record: { [index: string]: any }) => {
                                                if (record.users) {
                                                    return (
                                                        <div>
                                                            {record.users.firstName + ' ' + record.users.lastName}
                                                        </div>
                                                    );
                                                }
                                            },
                                        },
                                        {
                                            title: geti18nText('device.edit.settingsCalculateApoen.action'),
                                            dataIndex: 'type',
                                            width: '20%',
                                            sorter: (a: any, b: any) => {},
                                            ...getColumnSearchOption(denominationSettingsHistoryAction()),
                                            render: (text: string, record: { [index: string]: any }) => {
                                                if (record.type == 1) {
                                                    return (
                                                        <Tag color="green">
                                                            {geti18nText(
                                                                'device.edit.settingsCalculateApoen.action.add'
                                                            )}
                                                        </Tag>
                                                    );
                                                } else if (record.type == 0) {
                                                    return (
                                                        <Tag color="red">
                                                            {geti18nText(
                                                                'device.edit.settingsCalculateApoen.action.delete'
                                                            )}
                                                        </Tag>
                                                    );
                                                } else {
                                                    return (
                                                        <Tag color="blue">
                                                            {geti18nText(
                                                                'device.edit.settingsCalculateApoen.action.edit'
                                                            )}
                                                        </Tag>
                                                    );
                                                }
                                            },
                                        },
                                    ]}
                                />
                            </Modal>
                        </>
                    ) : (
                        <NySpinner></NySpinner>
                    )}
                </TabPane>
            </Tabs>
        </NyDataEdit>
    );
};

export default DeviceModelEdit;
