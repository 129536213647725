import {
    geti18nText,
    NyDataEdit,
    NyRequestResolver,
    NySession,
    NyUtils,
    RESPONSE,
    NyMaskContent,
    NySearchField,
    getColumnDateOption,
    getColumnSearch,
    NyDataTable,
} from '@nybble/nyreact';
import { Col, Descriptions, Divider, Form, Input, Row, Select, Tabs, Typography } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { RootState } from '../../rootReducer';
import { selectedGroupSet } from '../../slices/selectedDeviceCompanySlice';
import { CONSTANTS_REQ, URL_PREFIX } from '../../utils/Constants';
import { GetEnumNameForValue } from '../../utils/Enums';
import DeviceColumns from '../admin/device/columns';
import DeviceCSVColCistomization from '../admin/device/csvColumnsCustom';
import DeviceCSVColumnsExport from '../admin/device/csvDeviceColumnsExport';
import DeleteFromGroup from '../admin/device_groups/DeleteFromGroup';
import DeviceIndex from '../device';
import UserIndex from '../user';
import DeleteFromCompany from './DeleteFromCompany';
const { TabPane } = Tabs;
const { Title } = Typography;
const CompanyEdit = (props: any) => {
    const [editHeader, setEditHeader] = useState(geti18nText('company.table.header'));
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();
    const history = useHistory();
    const { id } = useParams<any>();
    const [isCreate, setIsCreate] = useState(false);
    const [userItem, setUserItem] = useState<any>(null);
    const [companyId, setCompanyId] = useState(null);
    const { company, refreshCompany } = useSelector((state: RootState) => state.selectedDeviceCompany);
    const dispatch = useDispatch();

    const onModalClose = () => {
        form.resetFields();
        setEditHeader(geti18nText('company.table.header'));
    };

    function setValues(dataForm: any) {
        dispatch(selectedGroupSet({ company: dataForm.id, refreshCompany: 0 }));
        setEditHeader(dataForm.name);
        delete dataForm.password;
        setCompanyId(dataForm.id);
        if (Array.isArray(dataForm.userGroupRoles)) {
            dataForm.roles = dataForm.userGroupRoles.map((userRole: { [index: string]: any }) => {
                return { key: userRole.id, label: userRole.authority };
            });
        }
        form.setFieldsValue(dataForm);
        setUserItem(dataForm);
    }

    const validateAuthority = (rule: any, value: any, callback: any) => {
        if (!/^[a-zA-Z0-9-_. ]*$/.test(value)) {
            callback(geti18nText('user.group.edit.name.validate'));
            return;
        }
        callback();
    };

    function normalizeValues(values: any) {
        console.log(values);
        let normalized = { ...values };
        normalized.active = 'active' in values ? values['active'] : true;
        normalized.roles = Array.isArray(form.getFieldValue('roles'))
            ? form.getFieldValue('roles').map((selectedRole: any) => {
                  let ret: { [index: string]: any } = {};
                  if (selectedRole.hasOwnProperty('value')) {
                      ret.id = parseInt(selectedRole.value, 10);
                  } else if (selectedRole.hasOwnProperty('id')) {
                      ret.id = parseInt(selectedRole.id, 10);
                  } else if (selectedRole.hasOwnProperty('key')) {
                      ret.id = parseInt(selectedRole.key, 10);
                  }
                  return ret;
              })
            : null;

        return normalized;
    }

    function setDefaultFilterValue() {

        if(NySession.hasAnyRole(['RULE_PREVIEW_DEVICE_WRITTEN_OFF'])) {
            return [{ field: 'companyId', condition: 'equals', value: company }];
        }


        else   return [{ field: 'companyId', condition: 'equals', value: company }, { field: 'writtenOff', condition: 'equals_bool', value: 0 }];
    }
    return (
        <NyDataEdit
            layout="horizontal"
            formProps={{ labelCol: { span: 7 }, wrapperCol: { span: 17 } }}
            editHeader={editHeader}
            loading={loading}
            setLoading={setLoading}
            onModalClose={onModalClose}
            url={CONSTANTS_REQ.COMPANY.EDIT}
            setValues={setValues}
            width={1200}
            form={form}
            goBack={() => history.goBack()}
            paramsId={id}
            setIsCreate={setIsCreate}
            normalize={normalizeValues}
            hideButtons={!NySession.hasAnyRole(['RULE_UPDATE_COMPANY'])}
            {...props}
        >
            <Tabs defaultActiveKey="1" type="card">
                <TabPane tab={geti18nText('company.edit.tab.general')} key="1">
                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item name="id" style={{ display: 'none' }}>
                                <Input />
                            </Form.Item>
                            <Form.Item
                                label={geti18nText('company.edit.name')}
                                name="name"
                                normalize={(value) => (value || '').toUpperCase()}
                                rules={[
                                    {
                                        required: true,
                                        message: geti18nText('app.default.required'),
                                    },
                                    {
                                        min: 3,
                                        message: geti18nText('user.group.edit.name.length.validate'),
                                    },
                                    {
                                        validator: validateAuthority,
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item name="id" style={{ display: 'none' }}>
                                <Input />
                            </Form.Item>
                            <Form.Item label={geti18nText('company.edit.address')} name="address">
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Divider orientation="left" plain>
                        <Title level={5}>{geti18nText('company.edit.section.role')}</Title>
                    </Divider>
                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item label={geti18nText('company.edit.roles')} name="roles">
                                <NySearchField
                                    url={CONSTANTS_REQ.ROLE.SEARCH}
                                    map={{ id: 'id', label: 'text' }}
                                    searchBy="text"
                                    mode="multiple"
                                    onChange={onchange}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}></Col>
                    </Row>
                </TabPane>
                <TabPane disabled={isCreate} tab={geti18nText('company.edit.tab.users')} key="2">
                    <UserIndex companyId={companyId}></UserIndex>
                </TabPane>
                <TabPane disabled={isCreate} tab={geti18nText('company.edit.tab.devices')} key="3">
                    {companyId && (
                        <>
                            <NyDataTable
                                headerTitle={geti18nText('device.table.header')}
                                url={CONSTANTS_REQ.DEVICE.LIST}
                                showRecordModal={false}
                                hideButtons={false}
                                hideNewButton={true}
                                readonly={true}
                                setDefaultPageSize={20}
                                fetchWhenChange={refreshCompany}
                                columns={DeviceColumns({isAdmin:true})}
                                scroll={{ x: 1200 }}
                                csvColumns={DeviceCSVColumnsExport(true)}
                                showRowSelection={true}
                                setDefaultFilterValue={setDefaultFilterValue}
                                rowSelectionModal={DeleteFromCompany}
                                exportCSV={true}
                                colCSVCustomization={DeviceCSVColCistomization()}
                            />
                        </>
                    )}
                </TabPane>
            </Tabs>
        </NyDataEdit>
    );
};

export default CompanyEdit;
