import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import hrHR from 'antd/lib/locale/hr_HR';
import enEN from 'antd/lib/locale/en_US';
import { ConfigProvider } from 'antd';
import { APPLICATION_KEY, URL_PREFIX } from './utils/Constants';
import { NySettings, NyUtils } from '@nybble/nyreact';
import './assets/scss/index.scss';
import 'antd/dist/antd.compact.css';
import 'leaflet/dist/leaflet.css';
import 'react-leaflet-markercluster/dist/styles.min.css';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import { Provider } from 'react-redux';
import store from './store';

const userAgent = navigator.userAgent.toLowerCase();
const isTablet =
    /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(
        userAgent
    );
if (localStorage['theme'] && localStorage['theme'] === 'dark') {
    if (isTablet != undefined && isTablet == true) {
        import('./assets/scss/theme/dark_16.less');
    } else {
        import('./assets/scss/theme/dark.less');
    }
} else {
    if (isTablet != undefined && isTablet == true) {
        import('./assets/scss/theme/light_16.less');
    } else {
        import('./assets/scss/theme/light.less');
    }
}

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            {/* ConfigProvider - ant design components language */}
            <ConfigProvider locale={NyUtils.getSelectedLocale() == 'hr-HR' ? hrHR : enEN}>
                {/* Initial settings for NyReact framework */}
                <NySettings
                    applicationKey={APPLICATION_KEY}
                    urlPreffix={URL_PREFIX}
                    urlRefresh={URL_PREFIX + 'access_token'}
                />
                <App />
            </ConfigProvider>
        </Provider>
    </React.StrictMode>,
    document.getElementById('root')
);
