import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import {
    ENUMS,
    getColumnDateOption,
    getColumnSearch,
    getColumnSearchOption,
    geti18nText,
    NyDataTable,
    NySession,
    NyUtils,
} from '@nybble/nyreact';
import { Tabs } from 'antd';
import React from 'react';
import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { CONSTANTS_REQ } from '../../utils/Constants';
import { GetEnumNameForValue } from '../../utils/Enums';
import DeviceColumns from '../admin/device/columns';
import DeviceCSVColCistomization from '../admin/device/csvColumnsCustom';
import DeviceCSVColumnsExport from '../admin/device/csvDeviceColumnsExport';
import DeviceEdit from './edit';
const DeviceIndex = (props: any) => {
    const { TabPane } = Tabs;
    let history = useHistory();
    const location = useLocation();

    function setDefaultFilterValue() {
        let value: any = [];
        if (props.companyId != null) {
            const id = parseInt(props.companyId);
            if (id != undefined) value.push({ field: 'company.id', condition: 'equals', value: id });
        }

        if (props.officeId != null) {
            const id = parseInt(props.officeId);
            if (id != undefined) value.push({ field: 'office.id', condition: 'equals', value: id });
        }

        if (props.location?.state && props.location?.state?.officeId != null) {
            const id = parseInt(props.location.state.officeId);
            if (history.location.state) {
                history.replace({ pathname: '/device', state: {} });
            }
            if (id != undefined) value.push({ field: 'office.id', condition: 'equals', value: id });
        }

        if (!props.isAdmin) {
            value.push({ field: 'writtenOff', condition: 'equals_bool', value: 0 });
        }

        if (props.groupIds != null) {
            value.push({
                field: 'groupIds',
                condition: 'in',
                value: props.groupIds.toString(),
            });
        }
        if (props.status != null) {
            value.push({
                field: 'status',
                condition: 'equals',
                value: props.status,
            });
        }

        if (props.useParser != null) {
            value.push({
                field: 'useParser',
                condition: 'equals_bool',
                value: 1,
            });
        }

        return value;
    }

    return (
        <NyDataTable
            headerTitle={geti18nText('device.table.header')}
            url={CONSTANTS_REQ.DEVICE.LIST}
            showRecordModal={true}
            hideButtons={true}
            modalComponent={DeviceEdit}
            setDefaultPageSize={20}
            exportCSV={true}
            scroll={{ x: 1200 }}
            columns={DeviceColumns(
                props.location?.state && props.location?.state?.officeName != null
                    ? { officeName: props.location.state.officeName }
                    : undefined,
                props.status,
                props.groupIds
            )}
            setDefaultFilterValue={setDefaultFilterValue}
            csvColumns={DeviceCSVColumnsExport()}
            colCSVCustomization={DeviceCSVColCistomization()}
        />
    );
};

export default DeviceIndex;
