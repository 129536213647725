import { ApiOutlined, IssuesCloseOutlined } from '@ant-design/icons';
import { geti18nText, getColumnSearch, NyUtils, getColumnSearchOption, getColumnDateOption } from '@nybble/nyreact';
import { Popover, Tooltip } from 'antd';
import { getTimezone } from '../../../utils/Utils';

function HnbSnapshotColumns(props?: any) {
    const offices = () => {
        const manufacturers = NyUtils.load('OFFICE');
        let types: any = [];
        manufacturers.forEach((element: any) => {
            let ret: any = {};
            ret.id = element.name;
            ret.text = element.name;
            types.push(ret);
        });

        return types;
    };
    return [
        {
            dataIndex: 'username',
            render: (text: string, record: { [index: string]: any }) => {
                    return record.username ? (
                    <div>
                        <Popover
                        content={
                        <div>{record.username} {new Date(record.dateChanged).toLocaleString(NyUtils.getSelectedLocale(),getTimezone())}</div> 
                        }
                        
                        >
                <IssuesCloseOutlined style={{ color: 'red' }} />
            </Popover>
                    </div>
                ) : null
            },
        },
        {
            title: geti18nText('hnb.snapshot.table.hnb_device_type'),
            dataIndex: 'hnbDeviceType',
            sorter: (a: any, b: any) => {},
            render: (text: string, record: { [index: string]: any }) => {
                if (record.hnbDeviceType) {
                    return <div> {geti18nText('hnb.device.type.' + record['hnbDeviceType'])}</div>;
                }
            },
            ...getColumnSearch('number'),
        },
        {
            title: geti18nText('hnb.snapshot.table.hnb_device_location'),
            dataIndex: 'hnbDeviceLocation',
            sorter: (a: any, b: any) => {},
            render: (text: string, record: { [index: string]: any }) => {
                if (record.hnbDeviceType) {
                    return <div> {geti18nText('hnb.device.location.' + record['hnbDeviceLocation'])}</div>;
                }
            },
            ...getColumnSearch('number'),
        },
        {
            title: geti18nText('hnb.snapshot.table.software_eur'),
            dataIndex: 'softwareEur',
            align: 'center',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('hnb.snapshot.table.software_hr'),
            dataIndex: 'softwareHr',
            align: 'center',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('hnb.snapshot.table.count_state'),
            dataIndex: 'programCountState',
            align: 'center',
            sorter: (a: any, b: any) => {},
            render: (text: string, record: { [index: string]: any }) => {
                if (record.programCountState) {
                    return <div>{NyUtils.formatNumber(record.programCountState, 0)}</div>;
                }
            },
            ...getColumnSearch('string'),
        },

        {
            title: geti18nText('hnb.snapshot.table.branch_name'),
            dataIndex: 'branchName',
            align: 'center',
            sorter: (a: any, b: any) => {},
            ...getColumnSearchOption(offices()),
        },
        {
            title: geti18nText('hnb.snapshot.table.branch_hnb_code'),
            dataIndex: 'branchHnbCode',
            align: 'center',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('hnb.device.maintenance_date'),
            dataIndex: 'maintenanceDate',
            sorter: (a: any, b: any) => {},
            render: (text: string, record: { [index: string]: any }) => {
                if (record.maintenanceDate) {
                    return (
                        <div>{new Date(record.maintenanceDate).toLocaleDateString(NyUtils.getSelectedLocale(),getTimezone())}</div>
                    );
                }
            },
            ...getColumnDateOption(true,undefined,undefined,true),
        },
        {
            title: geti18nText('hnb.snapshot.table.created'),
            dataIndex: 'created',
            sorter: (a: any, b: any) => {},
            render: (text: string, record: { [index: string]: any }) => {
                if (record.created) {
                    return <div>{new Date(record.created).toLocaleDateString(NyUtils.getSelectedLocale(),getTimezone())}</div>;
                }
            },
            ...getColumnDateOption(true,undefined,undefined,true),
        },
    ];
}

export default HnbSnapshotColumns;
