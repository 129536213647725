import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import {
    ENUMS,
    getColumnDateOption,
    getColumnSearch,
    getColumnSearchOption,
    geti18nText,
    NyDataTable,
    NySession,
    NyUtils,
} from '@nybble/nyreact';
import { Tabs } from 'antd';
import React from 'react';
import { CONSTANTS_REQ } from '../../utils/Constants';
import { getTimezone } from '../../utils/Utils';

const DeviceInfoIndex = (props: any) => {
    const { TabPane } = Tabs;

    function setDefaultFilterValue() {
        console.log(props);
        return [{ field: 'device_id', condition: 'equals', value: props.device_id }];
    }

    const manufacturers = () => {
        const manufacturers = NyUtils.load('MANUFACTURERS');
        let types: any = [];
        manufacturers.forEach((element: any) => {
            let ret: any = {};
            ret.id = element.name;
            ret.text = element.name;
            types.push(ret);
        });

        return types;
    };

    return (
        <NyDataTable
            url={CONSTANTS_REQ.DEVICE_INFORMATION.LIST}
            showRecordModal={true}
            hideButtons={true}
            setDefaultPageSize={10}
            exportCSV={true}
            setDefaultFilterValue={setDefaultFilterValue}
            columns={[
                {
                    title: geti18nText('device.information.table.id'),
                    dataIndex: 'id',
                    width: '10%',
                    sorter: (a: any, b: any) => {},
                    ...getColumnSearch('number'),
                },
                {
                    title: geti18nText('device.information.table.manufacturer'),
                    dataIndex: 'manufacturer',
                    sorter: (a: any, b: any) => {},
                    ...getColumnSearch('string'),
                    // ...getColumnSearchOption(manufacturers()),
                },
                {
                    title: geti18nText('device.information.table.total'),
                    dataIndex: 'total',
                    sorter: (a: any, b: any) => {},
                    ...getColumnSearch('number'),
                },
                {
                    title: geti18nText('device.information.table.created'),
                    dataIndex: 'created',
                    sorter: (a: any, b: any) => {},
                    render: (text: string, record: { [index: string]: any }) => {
                        if (record.created) {
                            return <div>{new Date(record.created).toLocaleString(NyUtils.getSelectedLocale(),getTimezone())}</div>;
                        }
                    },
                    ...getColumnSearch('string'),
                },
                {
                    title: geti18nText('device.information.table.jam'),
                    dataIndex: 'jam',
                    sorter: (a: any, b: any) => {},
                    ...getColumnSearch('number'),
                },
                {
                    title: geti18nText('device.information.table.feed'),
                    dataIndex: 'feed',
                    sorter: (a: any, b: any) => {},
                    ...getColumnSearch('number'),
                },
                {
                    title: geti18nText('device.information.table.raw_data'),
                    dataIndex: 'raw_data',
                    sorter: (a: any, b: any) => {},
                    ...getColumnSearch('string'),
                },
            ]}
        />
    );
};

export default DeviceInfoIndex;
