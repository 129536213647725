import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { ENUMS, getColumnDateOption, getColumnSearchOption, geti18nText, NyDataTable, NyUtils } from '@nybble/nyreact';
import { Form, Row } from 'antd';
import moment from 'moment';
import { useState } from 'react';
import { FormatedDatePicker } from '../../../components/formated-date-picker/formatedDatePicker';
import { CONSTANTS_REQ } from '../../../utils/Constants';
import { GetEnum, GetEnumNameForValue } from '../../../utils/Enums';
import { getTimezone } from '../../../utils/Utils';
import DeviceArchiveColumns from '../../device_archive/columns';
import HnbSnapshotColumns from '../../hnb/snapshot/columns';

const MasterDataIndex = (props: any) => {
    const [reportDate, setReportDate] = useState<any>(null);
    const [fetch, setFetch] = useState<any>(null);

    function setDefaultFilterValue() {
        if (props.deviceId != null) {
            const id = parseInt(props.deviceId);
            if (id != undefined) return [{ field: 'device_id', condition: 'equals', value: id }];
        }

        return [];
    }

    const deviceTypes = () => {
        const aTypes = GetEnum({ enumName: 'DEVICE_TYPE' });
        let types = [];
        for (var key in aTypes) {
            if (aTypes.hasOwnProperty(key)) {
                let ret = { id: undefined, text: '' };
                ret.id = aTypes[key];
                ret.text = key;
                types.push(ret);
            }
        }
        return types;
    };

    const CORRECT = () => {
        let types: any = [];
        types.push(
            { id: 1, text: geti18nText('device.table.column.valid') },
            { id: 0, text: geti18nText('device.table.column.invalid') }
        );
        return types;
    };
    const REPAIR = () => {
        let types: any = [];
        types.push(
            { id: 1, text: geti18nText('device.table.column.repair') },
            { id: 0, text: geti18nText('device.table.column.not.repair') }
        );
        return types;
    };

    function tableColumns(): any {
        const archiveColumnes = DeviceArchiveColumns().filter(
            (x: any) =>
                x.title != null &&
                x.dataIndex != 'details' &&
                x.dataIndex != 'active' &&
                x.dataIndex != 'programCountState'
        );
        const snapshotColumns: any = HnbSnapshotColumns().filter(
            (x: any) =>
                x.title != null && x.dataIndex != 'branchId' && x.dataIndex != 'branchName' && x.dataIndex != 'created'
        );
        const masterColumns: any = {
            title: geti18nText('hnbdata.table.device.last.count_state_change'),
            dataIndex: ['d', 'count_state_change'],
            width: '15%',
            align: 'center',
            sorter: (a: any, b: any) => {},
            render: (text: string, record: { [index: string]: any }) => {
                if (record.count_state_change) {
                    return (
                        <div>
                            {new Date(record.count_state_change).toLocaleString(
                                NyUtils.getSelectedLocale(),
                                getTimezone()
                            )}
                        </div>
                    );
                }
            },
            ...getColumnDateOption(true, undefined, undefined, true),
        };

        const active: any = {
            title: geti18nText('manufacturer.alarm.table.active'),
            dataIndex: 'active',

            render: (text: any, record: { active: any }) => {
                if (record.active) {
                    return <CheckOutlined style={{ color: 'green' }} />;
                } else {
                    return <CloseOutlined style={{ color: 'red' }} />;
                }
            },
            ...getColumnSearchOption(ENUMS.ACTIVE(), 'equals_bool'),
        };
        var columns = archiveColumnes.concat(snapshotColumns);
        columns.splice(15, 0, masterColumns);
        columns.push(active);

        const correct: any = {
            dataIndex: 'correct',
            width: '1%',
            render: (text: any, record: { correct: any }) => {
                if (record.correct) {
                    return <CheckOutlined style={{ color: 'green' }} />;
                } else {
                    return <CloseOutlined style={{ color: 'red' }} />;
                }
            },
            ...getColumnSearchOption(CORRECT(), 'equals_bool'),
        };

        const repair: any = {
            //title: geti18nText('device.table.column.repair'),
            dataIndex: 'repair',
            width: '1%',
            render: (text: any, record: { repair: any }) => {
                if (record.repair === true) {
                    return <CheckOutlined style={{ color: 'green' }} />;
                } else {
                    return <CloseOutlined style={{ color: 'red' }} />;
                }
            },
            ...getColumnSearchOption(REPAIR(), 'equals_bool'),
        };
        columns.push(correct);
        columns.push(repair);
        return columns;
    }

    function csvColumns() {
        let col = tableColumns().map((col: any) => {
            if (col.dataIndex === 'correct') {
                col.title = geti18nText('device.table.column.valid');
            }
            if (col.dataIndex === 'repair') {
                col.title = geti18nText('device.table.column.repair');
            }
            return col;
        });
        col.push({
            title: geti18nText('device.reference.csv.label'),
            dataIndex: 'comment',
        });
        return col;
    }

    function csvFileName() {
        const date = reportDate != null ? new Date(reportDate) : new Date();
        const formatDate = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();
        return 'maticni_podaci_' + formatDate;
    }

    return (
        <>
            <Row>
                <Form.Item label={geti18nText('master_data.filter.date')} name={'timeStampOn'}>
                    <FormatedDatePicker
                        showTime={true}
                        onChange={(value: any) => {
                            if (value != null) {
                                const d = moment(value);
                                setReportDate(d);
                                setFetch(Math.random());
                            } else {
                                setReportDate(null);
                                setFetch(Math.random());
                            }
                        }}
                    />
                </Form.Item>
            </Row>
            <NyDataTable
                //headerTitle={geti18nText('device.edit.info.device.archive.title')}
                url={CONSTANTS_REQ.DEVICE_ARCHIVE.LIST_PER_DAY}
                showRecordModal={false}
                hideButtons={false}
                hideNewButton={true}
                readonly={true}
                fetchWhenChange={fetch}
                setDefaultPageSize={10}
                columns={tableColumns()}
                setDefaultFilterValue={setDefaultFilterValue}
                scroll={{ x: 2800 }}
                exportCSV={true}
                addedData={{ timeStampOn: reportDate }}
                CSVFileName={csvFileName()}
                csvColumns={csvColumns()}
                colCSVCustomization={[
                    {
                        id: (value: any) => {
                            return value;
                        },
                    },
                    {
                        'model.communicationType': (value: any) => {
                            const a = GetEnumNameForValue({ enumName: 'DEVICE_TYPE', value: value });
                            return GetEnumNameForValue({ enumName: 'COMMUNICATION_TYPE', value: value });
                        },
                    },
                    {
                        'model.type': (value: any) => {
                            return geti18nText(
                                'device.model.type.' +
                                    GetEnumNameForValue({
                                        enumName: 'DEVICE_TYPE',
                                        value: value,
                                    })?.toLowerCase()
                            );
                        },
                    },
                    {
                        lastActivity: (value: string) => {
                            return value != null
                                ? new Date(value).toLocaleString(NyUtils.getSelectedLocale(), getTimezone())
                                : '';
                        },
                    },
                    {
                        warranty_date: (value: string) => {
                            return value != null
                                ? new Date(value).toLocaleString(NyUtils.getSelectedLocale(), getTimezone())
                                : null;
                        },
                    },
                    {
                        maintenanceDate: (value: string) => {
                            return value != null
                                ? new Date(value).toLocaleString(NyUtils.getSelectedLocale(), getTimezone())
                                : '';
                        },
                    },

                    {
                        maintenanceContractDate: (value: string) => {
                            return value != null
                                ? new Date(value).toLocaleString(NyUtils.getSelectedLocale(), getTimezone())
                                : '';
                        },
                    },
                    {
                        hnbDeviceType: (value: any) => {
                            return geti18nText('hnb.device.type.' + value);
                        },
                    },
                    {
                        hnbDeviceLocation: (value: any) => {
                            return geti18nText('hnb.device.location.' + value);
                        },
                    },
                    {
                        count_state_change: (value: string) => {
                            return value != null
                                ? new Date(value).toLocaleString(NyUtils.getSelectedLocale(), getTimezone())
                                : '';
                        },
                    },
                    {
                        dailyReport: (value: any) => {
                            return value == true
                                ? geti18nText('device.edit.hnb.report.yes')
                                : geti18nText('device.edit.hnb.report.no');
                        },
                    },
                    {
                        monthlyReport: (value: any) => {
                            return value == true
                                ? geti18nText('device.edit.hnb.report.yes')
                                : geti18nText('device.edit.hnb.report.no');
                        },
                    },
                    {
                        active: (value: boolean) => {
                            if (value === true) {
                                return geti18nText('device.active.label');
                            } else {
                                return geti18nText('device.inactive.label');
                            }
                        },
                    },
                    {
                        correct: (value: boolean) => {
                            if (value === true) {
                                return geti18nText('device.active.label');
                            } else {
                                return geti18nText('device.inactive.label');
                            }
                        },
                    },
                    {
                        repair: (value: boolean) => {
                            if (value === true) {
                                return geti18nText('device.active.label');
                            } else {
                                return geti18nText('device.inactive.label');
                            }
                        },
                    },
                    {
                        comment: (value: string) => {
                            return value;
                        },
                    },
                ]}
            />
        </>
    );
};

export default MasterDataIndex;
