import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface IDashboardState {
    mapBounds: string | undefined;
    
}

const initialState: IDashboardState = {
    mapBounds: undefined,
};



interface IMapBoiunds {
    bounds: string;
}



const dashboard = createSlice({
    name: 'dashboard',
    initialState,
    reducers: {
    
        setMapBounds(state, action: PayloadAction<IMapBoiunds>) {
            state.mapBounds = action.payload.bounds;
        },
    },
});

export const {
    setMapBounds,
} = dashboard.actions;

export default dashboard.reducer;
