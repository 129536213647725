import { geti18nText, NyDataEdit, NyRequestResolver, NySearchField, NyUtils } from '@nybble/nyreact';
import { Col, Form, Input, notification, Row, Tabs } from 'antd';
import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { CONSTANTS_REQ } from '../../../utils/Constants';
import DeviceModelEdit from './edit';

const AdminModsEdit = (props: any) => {
    const [editHeader, setEditHeader] = useState(null);
    const [loading, setLoading] = useState(false);
    const [idMod, setIdMod] = useState(false);
    const { id } = useParams<any>();
    const history = useHistory();
    const [form] = Form.useForm();
    const { TabPane } = Tabs;
    const { TextArea } = Input;

    const onModalClose = () => {
        form.resetFields();
    };

    function setValues(dataForm: any) {
        setEditHeader(dataForm.name);
        setIdMod(dataForm.id);
        form.setFieldsValue(dataForm);
    }

    function deleteMod() {
        NyRequestResolver.requestGet(CONSTANTS_REQ.MODS.DELETE, { id: idMod }).then((result: any) => {
            if (result.status === 204) {
                okNotification(geti18nText('app.default.delete.ok.desc'), '');
                props.setVisible(false);
                props.onSave();
            }
        });
    }

    function okNotification(message: any, description: any) {
        notification['success']({
            message: message,
            duration: 10,
            description: description,
        });
    }

    function error(message: any) {
        notification['error']({
            message: geti18nText('service.information.import.nok'),
            description: message,
            duration: 0,
        });
    }

    function customButtons() {
        return [
            {
                text: geti18nText('app.default.button.delete'),
                style: { backgroundColor: '#ff4d4f', color: 'white', marginRight: '1em' },
                onClick: deleteMod,
                addPopconfirm: true,
                popConfirmMsg: geti18nText('mods.tab.delete.popconfirm.msg'),
                popConfirmOk: '',
                popConfirmCancel: '',
            },
        ];
    }

    function normalize(values: any) {
        let deviceModel = { id: values.deviceModel.id };
        delete values.deviceModel;
        values.deviceModel = deviceModel;
        return values;
    }

    return (
        <NyDataEdit
            layout="horizontal"
            formProps={{ labelCol: { span: 5 }, wrapperCol: { span: 15 } }}
            editHeader={editHeader}
            loading={loading}
            setLoading={setLoading}
            onModalClose={onModalClose}
            url={CONSTANTS_REQ.MODS.EDIT}
            setValues={setValues}
            width={800}
            form={form}
            goBack={() => history.goBack()}
            hideActivationButtons={true}
            customButtons={customButtons()}
            paramsId={id}
            normalize={normalize}
            {...props}
            hideButtons={false}
        >
            <>
                <Row>
                    <Col span={12}>
                        <Form.Item name="id" style={{ display: 'none' }}>
                            <Input readOnly={true} />
                        </Form.Item>
                        <Form.Item
                            style={{ display: 'none' }}
                            rules={[
                                {
                                    required: true,
                                    message: geti18nText('app.default.required'),
                                },
                            ]}
                            label={geti18nText('mods.table.edit.name')}
                            name="name"
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            style={{ display: 'none' }}
                            rules={[
                                {
                                    required: true,
                                    message: geti18nText('app.default.required'),
                                },
                            ]}
                            name="deviceModel"
                            label={geti18nText('mods.table.edit.model')}
                        >
                            <NySearchField
                                url={CONSTANTS_REQ.DEVICE_MODEL.SEARCH}
                                map={{ id: 'id', label: 'name' }}
                                searchBy="name"
                                AddNewModalComponent={DeviceModelEdit}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={12}>
                        <Form.Item name="description" label={geti18nText('mods.table.edit.description')}>
                            <TextArea autoFocus={true} rows={3} />
                        </Form.Item>
                    </Col>
                </Row>
            </>
        </NyDataEdit>
    );
};

export default AdminModsEdit;
