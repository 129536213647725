import { geti18nText, NyDataEdit, NySession } from '@nybble/nyreact';
import { Col, Form, Input, InputNumber, Row, Tabs } from 'antd';
import React, { useState } from 'react';

import { useHistory, useParams } from 'react-router-dom';
import { CONSTANTS_REQ } from '../../utils/Constants';

const HnbDeviceHistoryEdit = (props: any) => {
    const [editHeader, setEditHeader] = useState(geti18nText('hnb.device.table.count_state'));
    const [loading, setLoading] = useState(false);
    const [isCreate, setIsCreate] = useState(false);
    const { id } = useParams<any>();
    const history = useHistory();
    const [form] = Form.useForm();

    const onModalClose = () => {
        setEditHeader(geti18nText('hnb.device.table.count_state'));
        form.resetFields();
    };
    function setValues(dataForm: any) {
        form.setFieldsValue(dataForm);
    }

    return (
        <NyDataEdit
            layout="horizontal"
            editHeader={editHeader}
            loading={loading}
            setLoading={setLoading}
            onModalClose={onModalClose}
            url={CONSTANTS_REQ.HNB_DEVICE_HISTORY.EDIT}
            setValues={setValues}
            width={500}
            form={form}
            goBack={() => history.goBack()}
            paramsId={id}
            setIsCreate={setIsCreate}
            {...props}
            hideActivationButtons={true}
        >
            <Col>
                <Form.Item name="id" style={{ display: 'none' }}>
                    <Input readOnly={true} />
                </Form.Item>
                <Form.Item label={geti18nText('hnb.device.table.count_state')} name="countState">
                    <InputNumber min={1} />
                </Form.Item>
            </Col>
        </NyDataEdit>
    );
};

export default HnbDeviceHistoryEdit;
