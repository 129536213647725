import { geti18nText, NyDataTable } from '@nybble/nyreact';
import { CONSTANTS_REQ } from '../../utils/Constants';
import DeviceCSVColCistomization from '../admin/device/csvColumnsCustom';
import DeviceCSVColumnsExport from '../admin/device/csvDeviceColumnsExport';
import DeviceArchiveColumns from './columns';

const DeviceArchiveIndex = (props: any) => {
    function setDefaultFilterValue() {
        if (props.deviceId != null) {
            const id = parseInt(props.deviceId);
            if (id != undefined) return [{ field: 'device_id', condition: 'equals', value: id }];
        }

        return [];
    }
    function csvFileName() {
        const date = new Date();
        const formatDate = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();
        return 'device_archive_' + formatDate;
    }

    return (
        <NyDataTable
            url={CONSTANTS_REQ.DEVICE_ARCHIVE.LIST}
            showRecordModal={false}
            hideButtons={false}
            hideNewButton={true}
            readonly={true}
            setDefaultPageSize={10}
            scroll={{ x: 2600 }}
            columns={DeviceArchiveColumns()}
            csvColumns={DeviceCSVColumnsExport()}
            colCSVCustomization={DeviceCSVColCistomization()}
            setDefaultFilterValue={setDefaultFilterValue}
            CSVFileName={csvFileName()}
            exportCSV={true}
        />
    );
};

export default DeviceArchiveIndex;
