import React, { useEffect, useState } from 'react';
import { Table, Typography } from 'antd';
import { geti18nText, NySession, NyUtils } from '@nybble/nyreact';
import { getTimezone } from '../../utils/Utils';

const StatisticPrint = React.forwardRef((props: any, ref: any) => {
    const { Title } = Typography;
    const [index, setDataIndex] = useState<any>([]);
    const [data, setData] = useState<any>(null);

    useEffect(() => {
        if (props.data) {
            let tmpIndex: any = [];
            props.data.devices.forEach((d: any) => {
                tmpIndex.push({ title: formChartName(d), key: d.value.key });
            });
            setDataIndex(tmpIndex);
            setData(props.data.data);
        }
    }, [props.data]);


    function formChartName(device:any){
        let name=device.value.name;
        let status =''
        if(device.statusActive != null) {
           status = " - " +geti18nText('device.import.column.status')+ ": ".concat(device.statusActive === true ? geti18nText('app.default.active') : geti18nText('app.default.inactive'));
        }
        if(device.statusCorrect != null   && device.statusActive != null && device.statusActive === false) {
           status= status.concat(' / ').concat(device.statusCorrect === true ? geti18nText('statistic.device.status.correct') : geti18nText('statistic.device.status.notcorrect'));
        }
        name= name+status;
        return  name;
        
    }

    function titleType(type: any) {
        switch (type) {
            case 'count':
                return geti18nText('dashboard.widget.counting.stat.label');

            case 'sum':
                return geti18nText('dashboard.widget.sum.stat.label');

            case 'time':
                return geti18nText('dashboard.widget.duration.stat.label');

            case 'error':
                return geti18nText('dashboard.widget.error.stat.label');
        }
        return '';
    }

    function setTimeNumber(value: any) {
        let formValue = value;
        if (formValue < 60) {
            formValue = formValue + ' s';
        } else if (formValue < 3600) {
            formValue = NyUtils.formatNumber(value / 60, 2) + ' m';
        } else {
            formValue = formValue = NyUtils.formatNumber(value / 3600, 2) + ' h';
        }
        return formValue;
    }

    function columns() {
        let col: any = [];
        col.push({
            title: <div style={{ fontSize: '10px' }}>{geti18nText('statistic.chart.screenshot.converted')}</div>,
            dataIndex: 'converted',
            render: (text: string, record: { [index: string]: any }) => {
                return <Typography.Text style={{ fontSize: '10px' }}>{record.converted}</Typography.Text>;
            },
        });
        index.forEach((i: any) => {
            col.push({
                title: <div style={{ fontSize: '10px' }}>{i.title}</div>,
                dataIndex: i.key,
                render: (text: string, record: { [index: string]: any }) => {
                    return (record[i.key]!= null ? (
                        <Typography.Text style={{ fontSize: '10px' }}>
                            {props.type !== 'time' ? NyUtils.formatNumber(record[i.key]) : setTimeNumber(record[i.key])}
                        </Typography.Text>
                    ) : (
                        ''
                    ));
                },
                align: 'right',
            });
        });

        return col;
    }

    return (
        <div ref={ref}>
            <div>
                <Title level={2}>{NySession.getSetting('ux').title} </Title>
                {geti18nText('statistic.chart.screenshot.date.created')}{' '}
                {new Date().toLocaleString(NyUtils.getSelectedLocale(),getTimezone())}
                <br></br>
            </div>
            <div className="statistic-chart-export-title">
                <Title level={3}>{titleType(props.type)} </Title>

                <br></br>
            </div>
            <br></br>

            <img style={{ width: '100%' }} src={props.png}></img>

            {data && (
                <div style={{ marginTop: '50px', marginLeft: '5%' }}>
                    <Table size="small" columns={columns()} pagination={false} dataSource={data} />
                </div>
            )}
        </div>
    );
});

export default StatisticPrint;
