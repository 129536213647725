import { NyRequestResolver, RESPONSE } from '@nybble/nyreact';
import { CONSTANTS_REQ, APPLICATION_KEY } from './Constants';

export interface IEnum {
    enumName:
        | 'DEVICE_TYPE'
        | 'COMMUNICATION_TYPE'
        | 'USER_RULE_TYPE'
        | 'NOTIFICATION_ALARM_TYPE'
        | 'NOTIFICATION_ALARM_CLASS_TYPE'
        | 'MANUFACTURERS'
        | 'CURRENCY_TYPE'
        | 'NOTIFICATION_TYPE'
        | 'HNB_DEVICE_TYPE'
        | 'HNB_DEVICE_LOCATION'
        | 'DEVICE_MODELS'
        | 'OFFICE'
        | 'CURRENCY'
        | 'DEVICE_MODE'
        | 'DEVICE_CONNECTION_TYPE'
        | 'DEVICE_MODEL_PATTERN_TYPES'
        | 'PARSER_COUNT_STATUS'
        | 'DENOMINATION_SETTINGS_HISTORY_ACTION'
        | 'DEVICE_STATUS';
}

export interface IEnumValue extends IEnum {
    value: any;
}

export const GetEnum = ({ enumName }: IEnum) => {
    const en = loadFromSession(APPLICATION_KEY + 'enums');

    if (en !== undefined && en[enumName] !== undefined) {
        return en[enumName];
    } else {
        return undefined;
    }
};

export const LoadEnums = async function () {
    await NyRequestResolver.requestGet(CONSTANTS_REQ.ENUMS).then((response: any) => {
        if (response.status === RESPONSE.OK) {
            sessionStorage.setItem(APPLICATION_KEY + 'enums', JSON.stringify(response.data));
        }
    });
};

export const GetEnumNameForValue = function ({ enumName, value }: IEnumValue) {
    const enums = GetEnum({ enumName: enumName });
    if (enums === undefined) {
        return undefined;
    }

    for (const [key, enumValue] of Object.entries(enums)) {
        if (enumValue === value) {
            return key;
        }
    }

    return undefined;
};

function loadFromSession(key: string) {
    const values = sessionStorage.getItem(key);
    if (values && values !== 'undefined') {
        return JSON.parse(values);
    }
    return undefined;
}
