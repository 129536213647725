import { geti18nText, NyDataEdit, NyInputNumber, NySearchField } from '@nybble/nyreact';
import { Col, Form, Input, InputNumber, Row, Tabs } from 'antd';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { CONSTANTS_REQ } from '../../../utils/Constants';

const DeviceSettingsDenominationsEdit = (props: any) => {
    const [editHeader, setEditHeader] = useState(geti18nText('device.edit.settingsCalculateApoen'));
    const [loading, setLoading] = useState(false);
    const { id } = useParams<any>();
    const history = useHistory();
    const [form] = Form.useForm();
    const { TabPane } = Tabs;
    const { TextArea } = Input;

    const onModalClose = () => {
        setEditHeader(geti18nText('device.edit.settingsCalculateApoen'));
        form.resetFields();
        props.setVisible(false);
    };
    function setValues(dataForm: any) {
        setEditHeader(geti18nText('reference.add.edit'));
        form.setFieldsValue(dataForm);
    }

    function onBeforeSave() {
        form.setFieldsValue({ deviceModelId: props.modelId });
    }

    function normalize(values: any) {
        let normalized :any = { ...values };
        normalized.currencyId= parseInt(values.currencyId.id);
        return normalized;
    }

    return (
        <NyDataEdit
            layout="horizontal"
            formProps={{ labelCol: { span: 5 }, wrapperCol: { span: 15 } }}
            editHeader={editHeader}
            loading={loading}
            setLoading={setLoading}
            onModalClose={onModalClose}
            isModal={true}
            hideActivationButtons={true}
            url={CONSTANTS_REQ.DEVICE_MODEL_SETTINGS_DENOMINATIONS.EDIT}
            setValues={setValues}
            width={800}
            form={form}
            goBack={() => history.goBack()}
            onBeforeSave={onBeforeSave}
            normalize={normalize}
            paramsId={id}
            {...props}
            hideButtons={false}
        >
            <Row>
                <Col span={24}>
                    <Form.Item name="id" style={{ display: 'none' }}>
                        <Input readOnly={true} />
                    </Form.Item>
                    <Form.Item name="deviceModelId" style={{ display: 'none' }}>
                        <Input readOnly={true} />
                    </Form.Item>
                    <Form.Item label={geti18nText('device.edit.settingsCalculateApoen.currency')} name="currencyId" >
                        <NySearchField
                            style={{width: '100%'}}
                            url={CONSTANTS_REQ.CURRENCY.SEARCH_FOR_SETTINGS}
                            map={{ id: 'id', label: 'name' }}
                            searchBy="currencyCode"
                            addedData={props && props.modelId ? {deviceModelId: props.modelId } : undefined}
                                    
                        />
                    </Form.Item>
                    </Col><Col span={24}>              
                        <Form.Item  label={geti18nText('device.edit.settingsCalculateApoen.number.divide')} name="value" >
                        <NyInputNumber />
                        </Form.Item>
                        
                    </Col>
            </Row>
        </NyDataEdit>
    );
};

export default DeviceSettingsDenominationsEdit;
