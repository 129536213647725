import { DownloadOutlined, FilePdfOutlined, PlusCircleOutlined } from '@ant-design/icons';
import {
    geti18nText,
    NyColorPicker, NyRequestResolver,
    NySearchField,
    NySession,
    NySpinner,
    NyUtils,
    RESPONSE
} from '@nybble/nyreact';
import { Button, Col, DatePicker, Divider, Form, Modal, Popover, Radio, Row, Typography, Checkbox } from 'antd';
import { stat } from 'fs';
import moment from 'moment';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { CSVLink } from 'react-csv';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { useCurrentPng } from 'recharts-to-png';
import { FormatedRangePicker } from '../../components/formated-date-picker/formatedDatePicker';
import { setActive } from '../../slices/menuSlice';
import { CONSTANTS_REQ } from '../../utils/Constants';
import { GetEnum } from '../../utils/Enums';
import { getTimezone } from '../../utils/Utils';
import DeviceSearchIndex from '../device/searchIndex';
import StatisticPrint from './StaisticPrint';

const StatisticChartIndex = (props: any) => {
    const [loading, setLoading] = useState(false);
    const [showRange, setShowRange] = useState(false);
    const [graphData, setGraphData] = useState<any>(null);
    const [visibleSettings, setVisibleSettings] = useState<any>(null);
    const [selectedKey, setSelectedKey] = useState<any>();
    const [form] = Form.useForm();
    const [timestampFrom, setTimestampFrom] = useState<any>(null);
    const [timestampTo, setTimestampTo] = useState<any>(null);
    const [devices, setDevices] = useState<any>([]);
    const [rangeValue, setRangeValue] = useState<any>(null);
    const [typeChart, setTypeChart] = useState<any>('count');
    const [hidenType, setHidenType] = useState<any>(false);
    const [hidenErrorType, setHidenErrorType] = useState<any>(false);
    const [png, setPng] = useState<any>(undefined);
    const [imageLoad, setImageLoad] = useState<any>(false);
    const [csvLoad, setCsvLoad] = useState<any>(false);
    const dispatch = useDispatch();
    const history = useHistory();
    const [exportData, setExportData] = useState([]);
    const csvLinkEl = useRef<any>();


    const { Title } = Typography;

    const componentRef = useRef<any>();

    const [getPng, { ref, isLoading }] = useCurrentPng();

    const HandleDownload = useCallback(async () => {
        setImageLoad(true);
        const png = await getPng();
        if (png) {
            setPng(png);
            if (handlePrint) handlePrint();
            setImageLoad(false);
        }
    }, [getPng]);



    useEffect(() => {
        
        if (props.deviceId) {
            form.setFieldsValue({ period: { id: 'today', text: geti18nText('dashboard.widget.chart.period.today') } });
            fetchData(false);
        } else if (NyUtils.load('STATISTIC')) {
            let saved = NyUtils.load('STATISTIC');
            form.setFieldsValue({ period: {id:saved.period.id, text: period.filter((f:any)=>{
                if(f.id === saved.period.id){
                    return f;
                }
            }).map((v:any)=>{
                return v.text;
            })[0]}  
            });
            if (saved.period.id === 'span') {
                if (saved.timestampFrom && saved.timestampTo) {
                    setTimestampFrom(saved.timestampFrom);
                    setTimestampTo(saved.timestampTo);
                    setRangeValue([moment(saved.timestampFrom), moment(saved.timestampTo)]);
                    setShowRange(true);
                }
            }
            setTypeChart(saved.type);
            if (saved.type === 'error') {
                setHidenType(saved.type === 'error' ? true : false);
            }
            let typeValue = saved.graph.devices.filter((d: any) => {
                return d.type === 'type';
            });

            if (typeValue && typeValue.length > 0) {
                setHidenErrorType(true);
            }
            fetchDataByDevices(saved.timestampFrom, saved.timestampTo, saved.type,saved.graph.devices, saved.deviceActive,saved.deviceCorrect);
        } else {
            form.setFieldsValue({ period: { id: 'today', text: geti18nText('dashboard.widget.chart.period.today') } });
            fetchData(false);
        }
    }, []);

    function getParams(values: any) {
        let params: any = {};
        if (form.getFieldValue('period')) {
            params.period = form.getFieldValue('period').id === -1 ? 'today' : form.getFieldValue('period').id;
        } else {
            params.period = 'today';
        }

        if (values) {
            if (values.device) {
                params.device = values.device.id;
            }

            if (values.branch) {
                params.branch = values.branch.id;
            }

            if (values.deviceGroup) {
                params.deviceGroup = values.deviceGroup.id;
            }
            if (values.model) {
                params.deviceModel = values.model.id;
            }
            if (values.type) {
                params.type = '' + values.type.id;
            }
            if(values.deviceActive != null) {
                params.deviceActive=values.deviceActive === true ? '1' : '0';
            }
            if( values.deviceCorrect != null && values.deviceActive != null && values.deviceActive === false ) {
                params.deviceCorrect=values.deviceCorrect === true ? '1' : '0';
            }
        }
        if (props.deviceId) {
            params.device = props.deviceId;
        }
        if (timestampTo != null && timestampFrom != null) {
            params.timestampFrom = timestampFrom;
            params.timestampTo = timestampTo;
        }
        params.lang = NyUtils.getSelectedLanguage();
        params.timezone=getTimezone().timeZone;
        
        return params;
    }

    function getParamsByDevices(timestampFrom?: any, timestampTo?: any, deviceSession?: any,deviceActive?: any,deviceCorrect?: any) {
        let params: any = {};
        if (form.getFieldValue('period')) {
            params.period = form.getFieldValue('period').id;
        } else {
            params.period = 'today';
        }
        let tmpDevices = deviceSession ? deviceSession : devices;
        if (tmpDevices) {
            tmpDevices = tmpDevices.filter((d: any) => {
                return d.key !== 'D_0';
            });
            let tmpParamDevices: any = [];
            let tmpParamDevicesGroup: any = [];
            let tmpParamBranches: any = [];
            let tmpParamModels: any = [];
            let tmpParamTypes: any = [];

            tmpDevices.forEach((dL: any) => {
                if (dL.type === 'device' && dL.value.id) {
                    tmpParamDevices.push(dL.value.id);
                }
                if (dL.type === 'deviceGroup' && dL.value.id) {
                    let groupStatus:any={};
                    if(dL.statusActive != null) {
                        groupStatus.statusActive=dL.statusActive;
                    }
                    if(dL.statusCorrect != null) {
                        groupStatus.statusCorrect=dL.statusCorrect;
                    }
                    tmpParamDevicesGroup.push({id:dL.value.id, key:dL.value.key, type:'DEVICE_GROUP', ...groupStatus})
                }
                if (dL.type === 'branch' && dL.value.id) {
                    let branchStatus:any={};
                    if(dL.statusActive != null) {
                        branchStatus.statusActive=dL.statusActive;
                        
                    }
                    if(dL.statusCorrect != null) {
                        branchStatus.statusCorrect=dL.statusCorrect;
                        
                    }
                    tmpParamBranches.push({id:dL.value.id, key:dL.value.key, type:'BRANCH', ...branchStatus})
                }
                if (dL.type === 'model' && dL.value.id) {
                    let modelStatus:any={};
                    if(dL.statusActive != null) {
                        modelStatus.statusActive=dL.statusActive;
                        
                    }
                    if(dL.statusCorrect != null) {
                        modelStatus.statusCorrect=dL.statusCorrect;
                        
                    }
                    tmpParamModels.push({id:dL.value.id, key:dL.value.key, type:'MODEL', ...modelStatus})
                    
                }
                if (dL.type === 'type' && dL.value.id != null) {
                    let typeStatus:any={};
                    if(dL.statusActive != null) {
                        typeStatus.statusActive=dL.statusActive;
                        
                    }
                    if(dL.statusCorrect != null) {
                        typeStatus.statusCorrect=dL.statusCorrect;
                        
                    }
                    tmpParamTypes.push({id:dL.value.id,key:dL.value.key, type:'MODEL', ...typeStatus})
                    
                }
            });
            if (tmpParamDevices && tmpParamDevices.length > 0) {
                params.devices = tmpParamDevices;
            }
            if (tmpParamDevicesGroup && tmpParamDevicesGroup.length > 0) {
                params.devicesGroup = encodeURI(JSON.stringify(tmpParamDevicesGroup));
            }
            if (tmpParamBranches && tmpParamBranches.length > 0) {
                params.branches = encodeURI(JSON.stringify( tmpParamBranches));
            }
            if (tmpParamModels && tmpParamModels.length > 0) {
                params.deviceModels = encodeURI(JSON.stringify(tmpParamModels));
            }
            if (tmpParamTypes && tmpParamTypes.length > 0) {
                params.types = encodeURI(JSON.stringify(tmpParamTypes));
            }
        }
        params.lang = NyUtils.getSelectedLanguage();
       

        if (timestampFrom != null && timestampTo != null) {
            params.timestampFrom = timestampFrom;
            params.timestampTo = timestampTo;
        }
       
        params.timezone=getTimezone().timeZone;
        return params;
    }

    function setTypeDevice(val: any) {
        if (val.device) {
            return { type: 'device', value: { ...val.device, key: 'D_' + val.device.id, color: val.color } };
        }
        if (val.deviceGroup) {
            return {
                type: 'deviceGroup',
                value: { ...val.deviceGroup, key: 'DG_' + val.deviceGroup.id+formDeviceKey(val), color: val.color},
                ...formActiveCorrectDevice(val)
            };
        }
        if (val.branch) {
            return { type: 'branch', value: { ...val.branch, key: 'DB_' + val.branch.id+formDeviceKey(val), color: val.color,  },
            ...formActiveCorrectDevice(val)
        };
        }
        if (val.model) {
            return { type: 'model', value: { ...val.model, key: 'DM_' + val.model.id+formDeviceKey(val), color: val.color },...formActiveCorrectDevice(val) };
        }
        if (val.type) {
            setHidenErrorType(true);
            return { type: 'type', value: { ...val.type, key: 'DT_' + val.type.id+formDeviceKey(val), color: val.color },...formActiveCorrectDevice(val) };
        }
    }

    function formActiveCorrectDevice(val:any)
    {
      let active:any={}
      if( val.deviceActive != null) active.statusActive= val.deviceActive ;
      if( val.deviceCorrect != null) active.statusCorrect= val.deviceCorrect ;
      return active;
                       
    }
    function addDevice(val: any) {
        setDevices((oldArray: any) => [...oldArray.filter((d: any) => d.value.key !== 'D_0'), setTypeDevice(val)]);
        setVisibleSettings(false);
        fetchData(false, typeChart, val);
    }

    function formKey(val: any, activeCorrectKey:any): string {
        switch (val.type) {
            case 'device':
                return 'D_' + val.id;
            case 'deviceGroup':
                return activeCorrectKey !== '' ? 'DG_' + val.id + '_'+ activeCorrectKey :'DG_' + val.id;
            case 'branch':
                return activeCorrectKey !== '' ? 'DB_' + val.id + '_'+ activeCorrectKey :'DB_' + val.id;
            case 'model':
                return activeCorrectKey !== '' ? 'DM_' + val.id + '_'+ activeCorrectKey :'DM_' + val.id;
            case 'type':
                return activeCorrectKey !== '' ? 'DT_' + val.id + '_'+ activeCorrectKey :'DT_' + val.id;
        }
        return '';
    }

    function makeUrl(type: any): string {
        switch (type) {
            case 'count':
                return CONSTANTS_REQ.STATISTIC.COUNT;

            case 'sum':
                return CONSTANTS_REQ.STATISTIC.SUM;

            case 'time':
                return CONSTANTS_REQ.STATISTIC.TIME;
            case 'error':
                return CONSTANTS_REQ.STATISTIC.ERROR;
        }
        return '';
    }

    function makeUrlList(type: any): string {
        switch (type) {
            case 'count':
                return CONSTANTS_REQ.STATISTIC.COUNT_BY_LIST;

            case 'sum':
                return CONSTANTS_REQ.STATISTIC.SUM_BY_LIST;

            case 'time':
                return CONSTANTS_REQ.STATISTIC.TIME_LIST;
            case 'error':
                return CONSTANTS_REQ.STATISTIC.ERROR_BY_LIST;
        }
        return '';
    }

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    function fetchData(isPeriodChange: boolean, type?: any, values?: any) {
        setLoading(true);
        NyRequestResolver.requestGet(makeUrl(type ? type : 'count'), getParams(values)).then((result: any) => {
            if (result.status === RESPONSE.OK) {
                
                let chartData: any = [];
                if (graphData && isPeriodChange !== true && selectedKey && values) {
                    let data = graphData.data;
                    let newData: any = [];
                    const key: string = formKey(selectedKey,formStatusKey(values));
                    let rData = result.data;
                    rData.forEach((r: any) => {
                        data.forEach((d: any) => {
                            if (r.converted == d.converted) {
                                let dChartObj = d;
                                dChartObj[key] = r.total;
                                newData.push(dChartObj);
                            }
                        });
                    });
                    let devices = graphData.devices.filter((d: any) => {
                        return d.value.key !== 'D_0';
                    });
                    devices.push({
                        type: selectedKey.type,
                        value: { id: selectedKey.id, key: key, color: values.color, name: selectedKey.name },
                        statusActive: values.deviceActive != null ? values.deviceActive : undefined,
                        statusCorrect: values.deviceCorrect != null ? values.deviceCorrect : undefined
                    });
                    setGraphData({ devices: devices, data: newData });
                    if (!props.deviceId) {
                        let sessionSave : any= {
                            timestampFrom: timestampFrom,
                            timestampTo: timestampTo,
                            type: type,
                            period: form.getFieldValue('period'),
                            graph: { devices: devices, data: newData },
                        };

                        NyUtils.save('STATISTIC', sessionSave );
                    }
                } else if (props.deviceId) {
                    chartData = result.data.map((r: any) => {
                        let obj: any = { converted: r.converted, ts: r.ts, total: r.total };
                        let key = 'D_' + props.deviceId;
                        obj[key] = r.total;
                        return obj;
                    });
                    setDevices([
                        {
                            type: 'device',
                            value: {
                                name: props.deviceName,
                                id: props.deviceId,
                                key: 'D_' + props.deviceId,
                                color: '#4d8d03',
                            },
                        },
                    ]);
                    setGraphData({
                        devices: [
                            {
                                type: 'device',
                                value: {
                                    name: props.deviceName,
                                    id: props.deviceId,
                                    key: 'D_' + props.deviceId,
                                    color: '#4d8d03',
                                },
                            },
                        ],
                        data: chartData,
                    });
                    if (!props.deviceId) {
                        NyUtils.save('STATISTIC', {
                            timestampFrom: timestampFrom,
                            timestampTo: timestampTo,
                            type: 'count',
                            period: form.getFieldValue('period'),
                            graph: {
                                devices: [
                                    {
                                        type: 'device',
                                        value: {
                                            name: props.deviceName,
                                            id: props.deviceId,
                                            key: 'D_' + props.deviceId,
                                            color: '#4d8d03',
                                        },
                                    },
                                ],
                                data: chartData,
                            },
                        });
                    }
                } else {
                    chartData = result.data.map((r: any) => {
                        let obj: any = { converted: r.converted, ts: r.ts, total: r.total };
                        let key = 'D_0';
                        obj[key] = r.total;
                        return obj;
                    });
                    setDevices([
                        {
                            type: 'device',
                            value: {
                                name: geti18nText('statistic.chart.title.all.device'),
                                key: 'D_0',
                                color: '#4d8d03',
                            },
                        },
                    ]);
                    setGraphData({
                        devices: [
                            {
                                type: 'device',
                                value: {
                                    name: geti18nText('statistic.chart.title.all.device'),
                                    key: 'D_0',
                                    color: '#4d8d03',
                                },
                            },
                        ],
                        data: chartData,
                    });
                    if (!props.deviceId) {


                        let sessionSave : any= {
                            timestampFrom: timestampFrom,
                            timestampTo: timestampTo,
                            type: 'count',
                            period: form.getFieldValue('period'),
                            graph: {
                                devices: [
                                    {
                                        type: 'device',
                                        value: {
                                            name: geti18nText('statistic.chart.title.all.device'),
                                            key: 'D_0',
                                            color: '#4d8d03',
                                        },
                                    },
                                ],
                                data: chartData,
                            },
                        };
                        NyUtils.save('STATISTIC', sessionSave);
                    }
                }
                setLoading(false);
            }
            else if(result.status === 403) {
                dispatch(setActive('/'));
                NySession.logoutUser();
                setLoading(false);
                setGraphData(null);
                history.push('/login');
            }
            
            else {
                setLoading(false);
            }
        });
    }


    function formStatusKey(values:any) {
        let key:any='';
        if(values.deviceActive != null) {
            key=values.deviceActive === true ? 'SA' : 'SNA';
        }
        if( values.deviceCorrect != null && values.deviceActive !== true  ) {
            key= values.deviceCorrect === true ? key !== '' ? key+'_SC' : 'SC' : key !== '' ? key+'_SNC' : 'SNC'; 
            
        }
        return key;
    }
    function formDeviceKey(values:any) {
        let key:any='';
        if(values.deviceActive != null) {
            key=values.deviceActive === true ? 'SA' : 'SNA';
        }
        if( values.deviceCorrect != null && values.deviceActive !== true  ) {
            key= values.deviceCorrect === true ? key !== '' ? key+'_SC' : 'SC' : key !== '' ? key+'_SNC' : 'SNC'; 
            
        }
        if(key !== '') return '_'+key
        else return '';
    }
    function checkTypeKey(device: any, key: any, valueInDate: any) {
        switch (device.type) {
            case 'device':
                if (device.value.key === key) {
                    valueInDate[device.value.key] = valueInDate[key];
                }
                return valueInDate;
                

            case 'deviceGroup':
                if (device.value.key === key) {
                    valueInDate[device.value.key] = valueInDate[key];
                }
                return valueInDate;
            case 'branch':
                if (device.value.key === key) {
                    valueInDate[device.value.key] = valueInDate[key];
                }
                return valueInDate;
            case 'model':
                if (device.value.key === key) {
                    valueInDate[device.value.key] = valueInDate[key];
                }
                return valueInDate;
            case 'type':
                if (device.value.key === key) {
                    valueInDate[device.value.key] = valueInDate[key];
                }
                return valueInDate;
        }
    }

    function fetchDataByDevices(timestampFrom?: any, timestampTo?: any, type?: any, devicesSession?: any, deviceActive?: any,deviceCorrect?: any) {
        setLoading(true);
        NyRequestResolver.requestGet(
            makeUrlList(type ? type : 'count'),
            getParamsByDevices(timestampFrom, timestampTo, devicesSession, deviceActive, deviceCorrect)
        ).then((res: any) => {
            if (res.status === RESPONSE.OK) {
            let chartData = [];
            let mapData = res.data;
            let tmpDevices = devicesSession ? devicesSession : devices;

            for (let data in mapData) {
                let valueInDate: any = mapData[data];
                let newData: any = {};
                for (let keyDevice in valueInDate) {
                    if (tmpDevices && tmpDevices.length > 0) {
                        tmpDevices.forEach((d: any) => {
                            newData = checkTypeKey(d, keyDevice, valueInDate);
                        });
                    } else {
                        newData['D_0'] = valueInDate[keyDevice];
                    }
                }
                
                chartData.push({ converted: data, ...newData });
            }
            setDevices(tmpDevices);
            setGraphData({ devices: tmpDevices, data: chartData });
            if (!props.deviceId) {

                let session : any={
                    timestampFrom: timestampFrom,
                    timestampTo: timestampTo,
                    type: type,
                    period: form.getFieldValue('period'),
                    graph: { devices: tmpDevices, data: chartData },
                };
                if(deviceActive != null) {
                    session.deviceActive=deviceActive;
                }
                if(deviceCorrect != null) {
                    session.deviceCorrect=deviceCorrect;
                }
                NyUtils.save('STATISTIC', session);
            }
            setLoading(false);
            }
            
            else if(res.status === 403) {
                dispatch(setActive('/'));
                NySession.logoutUser();
                setLoading(false);
                setGraphData(null);
                history.push('/login');
            }
            else {
                setLoading(false);
            }
        });
    }

    function setTitleLgend(value: any, key:any) {
        if (value === 'D_' + geti18nText('statistic.chart.title.all.device'))
            return geti18nText('statistic.chart.title.all.device');
        else if(key === 'D_0') return geti18nText('statistic.chart.title.all.device');  
        else return value;
    }

    function removeChart(val: any) {

        let cData = graphData;
        let tmpDevices = devices.filter((d: any) => {
            return d.value.key != val;
        });

        let data = cData.data;
        data.forEach((d: any) => {
            delete d[val];
        });
        let keyType = tmpDevices.filter((d: any) => {
            return d.type === 'type';
        });

        if (keyType.length === 0) {
            setHidenErrorType(false);
        }
        setDevices(tmpDevices);
       
        setGraphData({ devices: tmpDevices, data: data });
        if (tmpDevices.length === 0) {
            setHidenErrorType(false);
            fetchData(false);
        } else
            NyUtils.save('STATISTIC', {
                timestampFrom: timestampFrom,
                timestampTo: timestampTo,
                type: typeChart,
                period: form.getFieldValue('period'),
                graph: { devices: tmpDevices, data: data },
            });
    }

    function content(val: any,key:any) {
        return (
            <div>
                <Row>
                    <Col span={16}>
                        <p>{val}</p>
                    </Col>
                    <Col span={8}>
                        <Button type="dashed" size="small" onClick={() => removeChart(key)}>
                            {geti18nText('statistic.chart.remove.device')}
                        </Button>
                    </Col>
                </Row>
            </div>
        );
    }

    const renderColorfulLegendText = (value: any, entry: any) => {
        const { color } = entry;
        if (props.deviceId || value === geti18nText('statistic.chart.title.all.device')) {
            return <span style={{ color }}>{setTitleLgend(value,entry.dataKey)}</span>;
        } else
            return (
                <Popover title={content(value,entry.dataKey)} trigger="hover">
                    <span style={{ color }}>{setTitleLgend(value, entry.dataKey)}</span>
                </Popover>
            );
    };

    const renderTooltip = (value: any, name: any, props: any) => {
        let formValue = value;
        if (typeChart === 'time') {
            if (formValue < 60) {
                formValue = formValue + ' s';
            } else if (formValue < 3600) {
                formValue = NyUtils.formatNumber(value / 60, 2) + ' m';
            } else {
                formValue = formValue = NyUtils.formatNumber(value / 3600, 2) + ' h';
            }
        }
        if (typeChart !== 'time') {
            formValue = NyUtils.formatNumber(formValue);
        }
        return [formValue, name];
    };

    const options = [
        { label: geti18nText('statistic.chart.option.count'), value: 'count' },
        { label: geti18nText('statistic.chart.option.sum'), value: 'sum' },
        { label: geti18nText('statistic.chart.option.time'), value: 'time' },
        { label: geti18nText('statistic.chart.option.error'), value: 'error', disabled: hidenErrorType },
    ];

    const period = [
        { id: 'today', text: geti18nText('dashboard.widget.chart.period.today') },
        { id: '24h', text: geti18nText('dashboard.widget.chart.period.24h') },
        { id: '3', text: '3 ' + geti18nText('dashboard.widget.chart.period.days') },
        { id: '7', text: '7 ' + geti18nText('dashboard.widget.chart.period.days') },
        { id: '14', text: '14 ' + geti18nText('dashboard.widget.chart.period.days') },
        { id: '30', text: '30 ' + geti18nText('dashboard.widget.chart.period.days') },
        { id: 'span', text: geti18nText('statistic.chart.period.range') },
    ];

    function periodOnChange(val: any) {
        if (val.id === 'span') {
            setShowRange(true);
        } else if (val.id === -1) {
            form.setFieldsValue({ period: { id: 'today', text: geti18nText('dashboard.widget.chart.period.today') } });
            setShowRange(false);
            setRangeValue(null);
            setSelectedKey(null);
            fetchDataByDevices(null, null, typeChart);
        } else {
            setShowRange(false);
            setRangeValue(null);
            setSelectedKey(null);
            fetchDataByDevices(null, null, typeChart);
        }
    }

    function onRangeChange(dates: any) {
        if (dates != null) {
            setTimestampFrom(dates[0] ? moment(dates[0]).valueOf() : null);
            setTimestampTo(dates[1] ? moment(dates[1]).valueOf() : null);
            fetchDataByDevices(moment(dates[0]).valueOf(), moment(dates[1]).valueOf(), typeChart);
            setRangeValue(dates);
        } else {
            setTimestampFrom(null);
            setTimestampTo(null);
            setRangeValue(dates);
            form.setFieldsValue({ period: { id: 'today', text: geti18nText('dashboard.widget.chart.period.today') } });
            setShowRange(false);
            fetchDataByDevices(null, null, typeChart);
        }
    }

    const { RangePicker } = DatePicker;

    function onTypeChartChange(val: any) {
        setTypeChart(val.target.value);
        setHidenType(val.target.value === 'error' ? true : false);
        fetchDataByDevices(timestampFrom, timestampTo, val.target.value);
    }

    function tickFormater(value: any) {
        if (typeChart === 'time') {
            if (value < 60) {
                value = value + ' s';
            } else if (value < 3600) {
                value = NyUtils.formatNumber(value / 60) + ' m';
            } else {
                value = NyUtils.formatNumber(value / 3600) + ' h';
            }
            return value;
        } else return value > 1 ? NyUtils.formatNumber(value) : value;
    }


    function header() {
        let headers : any= [];
        headers.push({ label: geti18nText('statistic.csv.date.time'), key: 'converted' });
        if(graphData){
        graphData.devices.forEach((d:any)=>{
            headers.push({ label: csvColumnName(d), key: d.value.key });
        })
        }   
        return headers;
    

    }

    function formChartName(device:any){
        let name=device.value.name;
        let status =''
        if(device.statusActive != null) {
           status = " - " +geti18nText('device.import.column.status')+ ": ".concat(device.statusActive === true ? geti18nText('app.default.active') : geti18nText('app.default.inactive'));
        }
        if(device.statusCorrect != null   && device.statusActive != null && device.statusActive === false) {
           status= status.concat(' / ').concat(device.statusCorrect === true ? geti18nText('statistic.device.status.correct') : geti18nText('statistic.device.status.notcorrect'));
        }
        name= name+status;
        return  name;
        
    }

    function csvColumnName(text:any) :any {
        switch (text.type) {
            case 'device':
                return text.value.name;
                break;
            
            case 'deviceGroup':
                return geti18nText('statistic.chart.modal.option.device.group') + ':'+formChartName(text);
                break;
            case 'branch':
                return geti18nText('statistic.chart.modal.option.location') + ':' +formChartName(text);
                break;
            case 'model':
                return geti18nText('statistic.chart.modal.option.device.group') + ':' +formChartName(text);
                break;
            case 'type':
                return geti18nText('statistic.chart.modal.option.device.model') + ':'+formChartName(text);
                break;
        }
        
    }

    if (loading) {
        return <NySpinner></NySpinner>;
    } else
        return (
            <>
                <div style={{ display: 'none' }}>
                    <StatisticPrint data={graphData} png={png} type={typeChart} ref={componentRef} />
                </div>
                {!props.deviceId && (
                    <Divider orientation="left" plain>
                        <Title level={5}> {geti18nText('statistic.title')}</Title>
                    </Divider>
                )}

                <Form style={{ marginTop: '15px' }} form={form}>
                    <Row>
                        <Col offset={1} span={8}>
                            <Radio.Group value={typeChart} options={options} onChange={onTypeChartChange} />
                        </Col>
                        <Col span={4}>
                            <Form.Item name="period" label={geti18nText('dashboard.widget.chart.period')}>
                                <NySearchField
                                    onChange={periodOnChange}
                                    searchBy={'name'}
                                    style={{ width: '80%' }}
                                    options={period}
                                />
                            </Form.Item>
                        </Col>
                        <Col hidden={!showRange} span={7}>
                            <FormatedRangePicker                                
                                value={rangeValue}
                                showTime={true}
                                onChange={onRangeChange}
                            ></FormatedRangePicker>
                        </Col>
                        <Col span={2}>
                            <Button
                                loading={imageLoad}
                                onClick={() => HandleDownload()}
                                type="primary"
                                icon={<FilePdfOutlined />}
                            >
                                {geti18nText('app.default.button.save')}
                            </Button>
                        </Col>
                        <Col span={2}>
                            <CSVLink
                                data={exportData}
                                asyncOnClick={true}
                                onClick={(event, done) => {
                                            setExportData(graphData.data);
                                            done();
                                        }}
                                target="_blank"
                                separator={';'}
                                headers={header()}
                                filename={
                                            geti18nText('statistic.csv.pretitle') +new Date().toLocaleDateString(NyUtils.getSelectedLocale(),getTimezone()) +
                                            geti18nText('statistic.chart.option.'+typeChart).toLowerCase().replace(/ /g,"_")+
                                            '.csv'
                                } 
                                >
                                        
                                <Button
                                    loading={csvLoad}
                                    type="primary"
                                    icon={<DownloadOutlined />}
                                >
                                {geti18nText('statistic.export.csv')}
                                </Button>
                            </CSVLink>
                        </Col>
                        
                    </Row>
                </Form>

                <Row hidden={props.deviceId}>
                    <Col offset={1} style={{ marginTop: '15px' }}>
                        <Button
                            disabled={
                                form.getFieldValue('period') &&
                                form.getFieldValue('period').id === 'span' &&
                                rangeValue === null
                            }
                            onClick={() => setVisibleSettings(true)}
                            type="primary"
                            icon={<PlusCircleOutlined />}
                        >
                            {geti18nText('statistic.add.chart')}
                        </Button>
                        <AddDeviceModal
                            hidenType={hidenType}
                            addDevice={addDevice}
                            setSelectedKey={setSelectedKey}
                            visible={visibleSettings}
                            setVisible={setVisibleSettings}
                        />
                    </Col>
                </Row>
                <Row style={{ marginTop: '15px' }}>
                    <Col span={24} style={{ height: '100%' }}>
                        <ResponsiveContainer width="100%" height={400}>
                            {loading ? (
                                <NySpinner />
                            ) : graphData ? (
                                <LineChart
                                    ref={ref}
                                    margin={{ top: 1, left: 15, right: 0, bottom: 0 }}
                                    data={graphData.data}
                                >
                                    <CartesianGrid vertical={false} stroke="#DDD" />
                                    <XAxis dataKey="converted" />
                                    <YAxis tickFormatter={tickFormater} />
                                    <Tooltip formatter={renderTooltip} />
                                    <Legend formatter={renderColorfulLegendText} />
                                    {graphData.devices.map((device: any) => {
                                        return (
                                            <Line
                                                type="monotone"
                                                strokeLinecap="round"
                                                strokeWidth={3}
                                                name={formChartName(device)}
                                                dataKey={device.value.key}
                                                stroke={device.value.color}
                                                dot={false}
                                                legendType="square"
                                            />
                                        );
                                    })}
                                </LineChart>
                            ) : (
                                <></>
                            )}
                        </ResponsiveContainer>
                    </Col>
                </Row>
            </>
        );
};

export const AddDeviceModal = (props: any) => {
    const [form] = Form.useForm();
    const [radioValue, setRadioValue] = useState<any>('device');
    const [deviceActive, setDeviceActive] = useState<any>(undefined);
    const [deviceCorrect, setDeviceCorrect] = useState<any>(undefined);

    const [device, setDevice] = useState<any>(undefined);

    function closePopup() {
        form.resetFields();
        props.setVisible(false);
    }
    function addDevice() {
        form.validateFields()
            .then((values: any) => {
                let normalized = values;
                if (device) {
                    normalized.device = device;
                   
                }
                if(deviceCorrect != null ) {
                    normalized.deviceCorrect=deviceCorrect;
                }
                if(deviceActive != null ) {
                    normalized.deviceActive=deviceActive
                }
                props.addDevice(normalized);
            })
            .catch(() => {
                console.log('error');
            });
    }
    return (
        <>
            <Modal
                visible={props.visible}
                title={geti18nText('menu.statistic.modal.settings.title')}
                onCancel={closePopup}
                onOk={addDevice}
                width={750}
                destroyOnClose={true}
                okText={geti18nText('menu.statistic.modal.settings.add')}
            >
                <StatisticOptions
                    deviceRequired={false}
                    {...props}
                    form={form}
                    setDevice={setDevice}
                    setDeviceCorrect={setDeviceCorrect}
                    setDeviceActive={setDeviceActive}
                    device={device}
                ></StatisticOptions>
            </Modal>
        </>
    );
};

export const StatisticOptions = (props: any) => {
    const [radioValue, setRadioValue] = useState<any>('device');
    const [statusValue, setStatusValue] = useState<any>();
    const [device, setDevice] = useState<any>(undefined);
    const [deviceValue, setDeviceValue] = useState<any>(undefined);
    const [deviceModal, setDeviceModal] = useState<any>(false);
    const [color, setColor] = useState<any>(undefined);
    const [activeChecked, setActiveChecked] = useState<any>(true);
    const [correctChecked, setCorrectChecked] = useState<any>(true);
    
   

    useEffect(() => {
        props.form.setFieldsValue({ color: '#000000' });
        if (props.setRadioValue) props.setRadioValue('device');
        
    }, []);

    useEffect(() => {
        if (props.hidenType === true) {
            setRadioValue('device');
        }
    }, [props.hidenType]);

    const options = () => {
        let opt = [
            { label: geti18nText('statistic.chart.modal.option.device'), value: 'device' },
            { label: geti18nText('statistic.chart.modal.option.device.group'), value: 'deviceGroup' },
            { label: geti18nText('statistic.chart.modal.option.location'), value: 'location' },
            { label: geti18nText('statistic.chart.modal.option.device.model'), value: 'model' },
        ];
        if (!props.hidenType) {
            opt.push({ label: geti18nText('statistic.chart.modal.option.type'), value: 'type' });
        }

        return opt;
    };

    function onChangeDevice(val: any, type: any) {
        if (val.id !== -1) {
            if (props.setSelectedKey) props.setSelectedKey({ type: type, ...val });
        } else {
            if (props.setSelectedKey) props.setSelectedKey(null);
        }
    }

    function onChangeRadio(val: any) {
        if (props.setSelectedKey) props.setSelectedKey(null);
        if (props.setDevice) props.setDevice(undefined);
        props.form.resetFields(['device', 'deviceGroup', 'branch', 'type','deviceActive','deviceCorrect']);
        setDeviceValue(undefined);
        setRadioValue(val.target.value);
        setStatusValue(false);
        setActiveChecked(true);
        setCorrectChecked(true);
        props.setDeviceActive(undefined);
        props.setDeviceCorrect(undefined);
        if (props.setRadioValue) {
            props.setRadioValue(val.target.value);
        }
    }

    function setDefaultFilterValue() {
        return [{ field: 'active', condition: 'equals_bool', value: 1 }];
    }

    function onRowClickHandler(record: any) {
        setDeviceModal(false);
        if (props.setSelectedKey) props.setSelectedKey({ type: 'device', ...record });
        if (props.setDevice) props.setDevice({ id: record.id, name: record.name });
        props.form.setFieldsValue({ device: record.name });
        setDevice({ id: record.id, name: record.name });
        setDeviceValue(record.name);
    }

    const currencyType = () => {
        const aTypes = GetEnum({ enumName: 'CURRENCY_TYPE' });
        let types = [];
        for (var key in aTypes) {
            let ret = { id: undefined, text: '' };
            ret.id = aTypes[key];
            ret.text = geti18nText('currency.type.' + key.toLowerCase());
            types.push(ret);
        }

        return types;
    };

    function onChangeColor(color: any) {
        props.form.setFieldsValue({ color: color });
        setColor(color);
    }
    
    function onChangeInactive(status: any) {
           setActiveChecked(!activeChecked);
           props.setDeviceActive(!activeChecked);
    }
    
    
    function onChangeCorrect(status: any) {
        setCorrectChecked(!correctChecked);
        props.setDeviceCorrect(!correctChecked);
    }
    
    function onChangeStatus(status: any) {
        if(status.target.checked === true) {
            props.setDeviceActive(true);
            props.setDeviceCorrect(true);
        } else {
            props.setDeviceActive(undefined);
            props.setDeviceCorrect(undefined);
        }
        
        setStatusValue(status.target.checked);
    }


    return (
        <Row>
            <Form labelCol={{ span: 5 }} wrapperCol={{ span: 16 }} form={props.form} style={{ width: '100%' }}>
                <Form.Item name="dataFor" label={geti18nText('dashboard.widget.chart.data.for')}>
                    <Row>
                        <Col offset={1} span={24}>
                            <Radio.Group onChange={onChangeRadio} value={radioValue} options={options()} />
                        </Col>
                    </Row>
                </Form.Item>
                <Form.Item
                    hidden={radioValue !== 'device'}
                    rules={[
                        {
                            required: radioValue == 'device' && props.deviceRequired,
                            message: geti18nText('app.default.required'),
                        },
                    ]}
                    name="device"
                    label={geti18nText('statistic.chart.modal.option.device.select')}
                >
                    <DeviceSearchIndex
                        visible={deviceModal}
                        deviceValue={deviceValue}
                        onRowClickHandler={onRowClickHandler}
                    />
                </Form.Item>
               
                <Form.Item
                    hidden={radioValue !== 'deviceGroup'}
                    rules={[
                        {
                            required: radioValue == 'deviceGroup',
                            message: geti18nText('app.default.required'),
                        },
                    ]}
                    name="deviceGroup"
                    label={geti18nText('statistic.chart.modal.option.device.group.select')}
                >
                    <NySearchField
                        searchBy="name"
                        style={{ width: '50%' }}
                        onChange={(val: any) => onChangeDevice(val, 'deviceGroup')}
                        url={CONSTANTS_REQ.DEVICE_GROUP.SEARCH}
                        map={{ id: 'id', label: 'name' }}
                    />
                </Form.Item>

                <Form.Item
                    hidden={radioValue !== 'location'}
                    rules={[
                        {
                            required: radioValue === 'location',
                            message: geti18nText('app.default.required'),
                        },
                    ]}
                    name="branch"
                    label={geti18nText('statistic.chart.modal.option.location.select')}
                >
                    <NySearchField
                        searchBy="name"
                        style={{ width: '50%' }}
                        onChange={(val: any) => onChangeDevice(val, 'branch')}
                        url={CONSTANTS_REQ.LOCATION.SEARCH}
                        map={{ id: 'id', label: 'name' }}
                    />
                </Form.Item>

                <Form.Item
                    hidden={radioValue !== 'model'}
                    rules={[
                        {
                            required: radioValue === 'model',
                            message: geti18nText('app.default.required'),
                        },
                    ]}
                    name="model"
                    label={geti18nText('statistic.chart.modal.option.model.select')}
                >
                    <NySearchField
                        searchBy="name"
                        style={{ width: '50%' }}
                        onChange={(val: any) => onChangeDevice(val, 'model')}
                        url={CONSTANTS_REQ.DEVICE_MODEL.SEARCH}
                        map={{ id: 'id', label: 'name' }}
                    />
                </Form.Item>

                <Form.Item
                    hidden={radioValue !== 'type'}
                    rules={[
                        {
                            required: radioValue === 'type',
                            message: geti18nText('app.default.required'),
                        },
                    ]}
                    name="type"
                    label={geti18nText('statistic.chart.modal.option.type.select')}
                >
                    <NySearchField
                        searchBy="name"
                        style={{ width: '50%' }}
                        options={currencyType()}
                        onChange={(val: any) => onChangeDevice(val, 'type')}
                        map={{ id: 'id', label: 'name' }}
                    />
                </Form.Item>
                 
                <Form.Item
                    hidden={radioValue == 'device'}
                    initialValue={'1'}
                   
                    label={geti18nText( 'statistic.chart.modal.option.device.status')}
                >

                    <Checkbox checked={statusValue}  onChange={onChangeStatus}>{}</Checkbox>
                 </Form.Item>
                 
                 { statusValue && statusValue === true &&
                    <Form.Item
                        hidden={radioValue == 'device'}
                        style={{width:'100%'}}
                        name="status"
                        
                    >
                            <Row style={{marginLeft:150, width:'100%'}}>
                                <Col >
                                    <Checkbox style={{ marginRight: 5 }} checked={activeChecked} onChange={onChangeInactive}   value={'ACTIVE'}>
                                        {geti18nText('app.default.active')}
                                </Checkbox>
                                </Col>
                                <Col >
                                    <Checkbox style={{ marginRight: 5 }} checked={!activeChecked} onChange={onChangeInactive}  value={'INACTIVE'}>
                                        {geti18nText('app.default.inactive')}
                                </Checkbox>
                                </Col>
                                {!activeChecked &&
                                <>
                                <Col >
                                    <Checkbox style={{ marginRight: 5 }} checked={correctChecked} onChange={onChangeCorrect}   value={'CORRECT'}>
                                        {geti18nText('statistic.device.status.correct')}
                                </Checkbox>
                                </Col>
                                <Col >
                                    <Checkbox style={{ marginRight: 5 }} checked={!correctChecked} onChange={onChangeCorrect}   value={'INCORRECT'}>
                                        {geti18nText('statistic.device.status.notcorrect')}
                                </Checkbox>
                                </Col>
                                </>
                                }          
                            </Row>
                        
                    </Form.Item>
                }
                <Form.Item
                    rules={[
                        {
                            required: true,
                            message: geti18nText('app.default.required'),
                        },
                    ]}
                    name="color"
                    label={geti18nText('statistic.modal.color.select.label')}
                >
                    <div style={{ width: '40%' }}>
                        <NyColorPicker
                            value={color}
                            onChange={(e: any) => {
                                onChangeColor(e);
                            }}
                            defaultColor={'#000000'}
                        />
                    </div>
                </Form.Item>
            </Form>
        </Row>
    );
};

export default StatisticChartIndex;
