import { createSlice } from '@reduxjs/toolkit';

interface IMenuSlice {
    collapsed: boolean;
    active:any
}

const initialState: IMenuSlice = {
    collapsed: false,
    active: undefined,
};

const menuSlice = createSlice({
    name: 'menuSlice',
    initialState,
    reducers: {
        menuInit(state) {
            state.collapsed = false;
        },
        setMenuCollapsed(state, action) {
            state.collapsed = action.payload;
        },
        setActive(state, action) {
            state.active = action.payload;
        }
    },
});

export const { menuInit, setMenuCollapsed, setActive } = menuSlice.actions;

export default menuSlice.reducer;
