import { DeleteOutlined, EditOutlined, HistoryOutlined, UserOutlined } from '@ant-design/icons';
import {
    getColumnDateOption,
    getColumnSearch,
    geti18nText,
    NyDataEdit,
    NyDataTable,
    NyRequestResolver,
    NySearchField,
    NySession,
    NyUtils,
    RESPONSE,
} from '@nybble/nyreact';
import {
    AutoComplete,
    Col,
    Collapse,
    Divider,
    Form,
    Input,
    InputNumber,
    notification,
    Popconfirm,
    Row,
    Switch,
    Tabs,
    Checkbox,
    Tag,
    Button,
    Modal,
} from 'antd';
import moment from 'moment';
import momentTimezone from 'moment-timezone';
import React, { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { FormatedDatePicker } from '../../../components/formated-date-picker/formatedDatePicker';
import { RootState } from '../../../rootReducer';
import { CONSTANTS_REQ } from '../../../utils/Constants';
import { GetEnum } from '../../../utils/Enums';
import { getTimezone } from '../../../utils/Utils';
import CompanyEdit from '../../company/edit';
import DeviceModelEdit from '../device-model/edit';
import AddToGroup from '../device_groups/AddToGroup';
import DeviceGroupEdit from '../device_groups/edit';
import ManufacturerEdit from '../manufacturer/edit';
import AdminLocationEdit from '../office/edit';
import ServiceVendorEdit from '../service-vendor/edit';
import VendorEdit from '../vendor/edit';
import DeleteDeviceSettingsDenomination from '../device-model/DeleteDeviceSettingsDenominations';
import AdminReferenceEdit from './editReferece';
import DeviceSettingsDenominationsEdit from '../device-model/editSettingsDenominations';
import HnbDeviceEdit from './hnb_edit';

const AdminDeviceEdit = (props: any) => {
    const { Panel } = Collapse;
    const [editHeader, setEditHeader] = useState(geti18nText('device.add.new'));
    const [loading, setLoading] = useState(false);
    const [saveAsCopy, setSaveAsCopy] = useState(false);
    const { id } = useParams<any>();
    const history = useHistory();
    const [form] = Form.useForm();
    const { TabPane } = Tabs;
    const [openModalCreate, setOpenModalCreate] = useState<any>(false);
    const [deviceId, setDeviceId] = useState<any>(false);
    const { TextArea } = Input;
    const [inventoryNumber, setInventoryNumber] = useState<any>(null);
    const [deviceName, setDeviceName] = useState<any>(null);
    const [isDeviceClient, setIsDeviceClient] = useState<any>(false);
    const [useParser, setUseParser] = useState<any>(true);
    const [refreshHnb, setRefreshHnb] = useState<any>(null);
    const [refreshHistory, setRefreshHistory] = useState<any>(null);
    const deviceTabele = useRef<any>(null);
    const [deviceReference, refreshDeviceReference] = useState<number>(0);
    const [isWrittenOffDevice, setIsWrittenOffDevice] = useState<any>(false);
    const [isActiveDevice, setIsActiveDevice] = useState<any>(true);
    const [isCorrectDevice, setIsCorrectDevice] = useState<any>(null);
    const [isRepairDevice, setIsRepairDevice] = useState<any>(false);
    const [isECB, setIsECB] = useState<any>(false);
    const { refresh } = useSelector((state: RootState) => state.selectedDeviceSettingsDenomination);
    const [visibleHistory, setVisibleHistory] = useState(false);

    const onModalClose = () => {
        setEditHeader(geti18nText('device.add.new'));
        setOpenModalCreate(false);
        setUseParser(true);
        setRefreshHnb(null);
        setIsWrittenOffDevice(null);
        setIsCorrectDevice(null);
        setIsActiveDevice(null);
        setIsRepairDevice(null);
        setIsECB(false);
        form.resetFields();
    };

    function setValues(dataForm: any) {
        if (dataForm['useParser'] != null) {
            setUseParser(dataForm['useParser']);
        }

        if (dataForm['name']) {
            setDeviceName(dataForm['name']);
        }
        if (dataForm.mode) {
            dataForm.mode = {
                id: dataForm.mode,
                text: dataForm.mode,
            };
        }

        if (dataForm.connectionType) {
            dataForm.connectionType = {
                id: dataForm.connectionType,
                text: geti18nText('app.enum.DEVICE_CONNECTION_TYPE.' + dataForm.connectionType),
            };
        }

        if (dataForm['office']) {
            delete dataForm['office'].searchProjections;
        }
        if (dataForm['manufacturer']) {
            delete dataForm['manufacturer'].searchProjections;
        }
        if (dataForm['model']) {
            delete dataForm['model'].searchProjections;
            dataForm['model'].isClient ? setIsDeviceClient(true) : setIsDeviceClient(false);
            delete dataForm['model'].isClient;
        }
        if (dataForm['deviceGroups']) {
            dataForm['deviceGroups'] = dataForm['deviceGroups'].map((dg: any) => {
                return { key: dg.id, label: dg.name };
            });
        }
        if (dataForm['deviceCompanies']) {
            dataForm['companies'] = dataForm['deviceCompanies'].map((dg: any) => {
                return { key: dg.id, label: dg.name };
            });
        }
        if (dataForm['inventoryNumber']) {
            setInventoryNumber(dataForm['inventoryNumber']);
        }
        if (dataForm.hnbDevice) {
            if (dataForm.hnbDevice.hnbDeviceLocation != undefined) {
                dataForm.hnbDevice.hnbDeviceLocation = {
                    id: dataForm.hnbDevice.hnbDeviceLocation,
                    text: geti18nText('hnb.device.location.' + dataForm.hnbDevice.hnbDeviceLocation),
                };
            }
            if (dataForm.hnbDevice.hnbDeviceType) {
                dataForm.hnbDevice.hnbDeviceType = {
                    id: dataForm.hnbDevice.hnbDeviceType,
                    text: geti18nText('hnb.device.type.' + dataForm.hnbDevice.hnbDeviceType),
                };
            }

            if (dataForm.hnbDevice.maintenanceDate) {
                const d = dataForm.hnbDevice.maintenanceDate;

                dataForm.hnbDevice.maintenanceDate = moment(d);
            }
        }

        setIsActiveDevice(dataForm.active ? dataForm.active : false);
        setIsWrittenOffDevice(dataForm.writtenOff ? dataForm.writtenOff : false);
        setIsCorrectDevice(dataForm.correct ? dataForm.correct : false);
        setIsRepairDevice(dataForm.repair != null ? dataForm.repair : false);

        if (dataForm.warrantyDate) {
            const d = dataForm.warrantyDate;
            dataForm.warrantyDate = moment(d);
        }
        if (dataForm.maintenanceContractDate) {
            const d = dataForm.maintenanceContractDate;
            dataForm.maintenanceContractDate = moment(d);
        }

        if (dataForm.countStateChange) {
            const d = dataForm.countStateChange;

            dataForm.countStateChange = moment(d);
        }
        if (dataForm['vendor']) {
            delete dataForm['vendor'].searchProjections;
        }

        if (dataForm['serviceVendor']) {
            delete dataForm['serviceVendor'].searchProjections;
        }

        if (dataForm.timezone != undefined) {
            dataForm.timezone = { id: dataForm.timezone, text: dataForm.timezone };
        }

        setIsECB(dataForm.ecb);
        setDeviceId(dataForm['id']);
        setEditHeader(dataForm.name);
        form.setFieldsValue(dataForm);
    }

    const deviceMode = () => {
        const aTypes = GetEnum({ enumName: 'DEVICE_MODE' });
        let types = [];
        for (var key in aTypes) {
            if (aTypes.hasOwnProperty(key)) {
                let ret = { id: '', text: '' };
                ret.id = key;
                ret.text = key;
                types.push(ret);
            }
        }
        return types;
    };

    const deviceConnectonType = () => {
        const aTypes = GetEnum({ enumName: 'DEVICE_CONNECTION_TYPE' });
        let types = [];
        for (var key in aTypes) {
            if (aTypes.hasOwnProperty(key)) {
                let ret = { id: '', text: '' };
                ret.id = key;
                ret.text = geti18nText('app.enum.DEVICE_CONNECTION_TYPE.' + key);
                types.push(ret);
            }
        }
        return types;
    };

    function setIsCreate(val: any) {
        if (val === true) {
            setOpenModalCreate(true);
        } else {
            setOpenModalCreate(false);
        }
    }

    function saveAsCopyValues() {
        let normalized = form.getFieldsValue();
        if (normalized.deviceGroups) {
            if (Array.isArray(normalized.deviceGroups)) {
                normalized.groupList = normalized.deviceGroups.map((group: any) => {
                    return Number(group.key);
                });
            } else {
                normalized.groupList = [Number(normalized.deviceGroups.key)];
            }
        }
        delete normalized.deviceGroups;
        if (normalized.companies) {
            if (Array.isArray(normalized.companies)) {
                normalized.deviceCompanies = normalized.companies.map((company: any) => {
                    return { id: Number(company.key) };
                });
            } else {
                normalized.deviceCompanies = [{ id: Number(normalized.companies.key) }];
            }
        }
        if (normalized.hnbDevice) {
            delete normalized.hnbDevice.id;
            normalized.hnbDevice.hnbDeviceLocation = normalized.hnbDevice?.hnbDeviceLocation?.id;
            normalized.hnbDevice.hnbDeviceType = normalized.hnbDevice?.hnbDeviceType?.id;
            if (normalized.hnbDevice.maintenanceDate)
                normalized.hnbDevice.maintenanceDate = moment(normalized.hnbDevice.maintenanceDate);
        } else {
            normalized.hnbDevice = {};
        }

        delete normalized.companies;
        return normalized;
    }

    function customButtons() {
        return openModalCreate === true
            ? []
            : [
                  {
                      text: geti18nText('device.admin.edit.tab.info.copy.btn'),
                      onClick: () => {
                          const name = form.getFieldValue('name');
                          try {
                              setLoading(true);
                              form.setFieldsValue({
                                  id: null,
                              });
                              form.validateFields()
                                  .then((values: any) => {
                                      form.setFieldsValue({ name: deviceName == name ? name + ' COPY' : name });
                                      NyRequestResolver.requestPost(
                                          CONSTANTS_REQ.DEVICE.EDIT,
                                          undefined,
                                          saveAsCopyValues()
                                      ).then((result: any) => {
                                          setLoading(false);

                                          if (result.status === RESPONSE.CREATED) {
                                              notification.success({
                                                  message: geti18nText('app.default.save.ok'),
                                                  description: geti18nText('app.default.save.ok.desc'),
                                                  duration: 3,
                                              });
                                              props.setVisible(false);
                                              props.onSave();
                                          } else {
                                              notification.error({
                                                  message: geti18nText('app.default.save.nok'),
                                                  description: result.data?.message,
                                                  duration: 0,
                                              });
                                          }
                                      });
                                  })
                                  .catch(() => {
                                      setLoading(false);
                                  });
                          } catch (err) {
                              console.log('ERROR');
                              setLoading(false);
                              notification.error({
                                  message: geti18nText('app.default.save.nok'),
                                  description: geti18nText('device.error.ip.port'),
                                  duration: 0,
                              });
                          }
                      },
                  },
              ];
    }

    function normalize(values: any) {
        let normalized = { ...values };
        if (normalized.model) {
            normalized.model.id = values.model.id;
            if (normalized.model.id == -1) {
                delete normalized.model;
            }
        }

        if (values.mode) {
            normalized.mode = values.mode.id;
        }
        if (values.connectionType) {
            normalized.connectionType = values.connectionType.id;
        }
        if (normalized.office && normalized.office.id && normalized.office.id === -1) {
            delete normalized.office;
        }

        if (normalized.manufacturer && normalized.manufacturer.id && normalized.manufacturer.id === -1) {
            delete normalized.manufacturer;
        }
        if (normalized.vendor && normalized.vendor.id && normalized.vendor.id === -1) {
            delete normalized.vendor;
        }
        if (normalized.serviceVendor && normalized.serviceVendor.id && normalized.serviceVendor.id === -1) {
            delete normalized.serviceVendor;
        }

        if (normalized.deviceGroups) {
            if (Array.isArray(normalized.deviceGroups)) {
                normalized.groupList = normalized.deviceGroups.map((group: any) => {
                    return Number(group);
                });
            } else {
                normalized.groupList = [Number(normalized.deviceGroups)];
            }
        }

        if (values.hnbDevice) {
            normalized.hnbDevice.hnbDeviceLocation = normalized.hnbDevice?.hnbDeviceLocation?.id;
            normalized.hnbDevice.hnbDeviceType = normalized.hnbDevice?.hnbDeviceType?.id;
        } else {
            normalized.hnbDevice = {};
        }

        delete normalized.deviceGroups;
        if (normalized.companies) {
            if (Array.isArray(normalized.companies)) {
                normalized.deviceCompanies = normalized.companies.map((company: any) => {
                    return { id: Number(company) };
                });
            } else {
                normalized.deviceCompanies = [{ id: Number(normalized.companies) }];
            }
        }
        delete normalized.companies;

        if (refreshHnb != null) {
            normalized.refreshHnb = true;
        }

        if (normalized.timezone) {
            normalized.timezone = normalized.timezone.id;
        }

        if (normalized.office) {
            delete normalized.office.created;
        }

        if (normalized.manufacturer) {
            delete normalized.manufacturer.created;
        }

        if (normalized.vendor) {
            delete normalized.vendor.created;
        }
        if (normalized.serviceVendor) {
            delete normalized.serviceVendor.created;
        }
        if (normalized.active !== false) {
            normalized.active = true;
        }

        if (isWrittenOffDevice !== true) {
            normalized.writtenOff = false;
        } else {
            normalized.writtenOff = true;
            normalized.useParser = false;
        }

        if (isRepairDevice) {
            normalized.repair = isRepairDevice;
        }

        if (isCorrectDevice !== false) {
            normalized.correct = true;
            normalized.repair = false;
        } else {
            normalized.correct = false;
        }

        if (isActiveDevice !== false) {
            normalized.active = true;
            normalized.repair = false;
            normalized.correct = true;
        } else {
            normalized.active = false;
        }

        if (isECB != null) {
            normalized.ecb = isECB;
        }

        if (
            normalized.inventoryNumber != null &&
            (normalized.inventoryNumber == '' || normalized.inventoryNumber.replaceAll(' ', '') == '')
        ) {
            delete normalized.inventoryNumber;
        }
        return normalized;
    }

    function validateInvetoryNumber(rule: any, value: any, callback: any) {
        const code = form.getFieldValue('inventoryNumber');
        if (code == null) {
            return callback();
        }
        if (code == '') {
            return callback();
        }
        if (code.replaceAll(' ', '') == '') {
            return callback();
        }
        if (code == inventoryNumber && form.getFieldValue('id') != null) {
            return callback();
        }

        NyRequestResolver.requestGet(CONSTANTS_REQ.DEVICE.VALIDATE_INVENTORY_NUMBER + '/' + code).then(
            (result: any) => {
                if (result.status === RESPONSE.OK) {
                    if (result.data != null) {
                        const responseData: number = result.data;
                        if (responseData == 0) {
                            callback();
                            return;
                        }
                    }
                }
                callback(geti18nText('device.error.table.inventoryNumber'));
            }
        );
    }

    function onSwichChange(checked: any) {
        setUseParser(checked);
    }

    const timeZones = () => {
        return momentTimezone.tz.names().map((tz: any) => {
            return { id: tz, text: tz };
        });
    };
    function deviceReferenceDelete(reference: any) {
        if (reference.id == null) return;

        NyRequestResolver.requestDelete(CONSTANTS_REQ.REFERENCES.DELETE + '/' + reference.id).then((result) => {
            if (result.status === 200) {
                notification.success({
                    message: geti18nText('app.default.delete.ok'),
                    description: geti18nText('app.default.delete.ok.desc'),
                    duration: 3,
                });
                refreshDeviceReference(new Date().getTime());
            } else {
                notification.error({
                    message: geti18nText('app.default.delete.nok'),
                    duration: 3,
                });
            }
        });
    }

    const onCheckWrittenOffDevice = (e: any) => {
        setIsWrittenOffDevice(e.target.checked);
        if (isActiveDevice == null) {
            setIsActiveDevice(true);
        }
    };

    const onCheckIsActiveDevice = (e: any) => {
        setIsActiveDevice(e.target.checked);
        if (isCorrectDevice == null) {
            setIsCorrectDevice(true);
        }
    };

    const onCheckIsCorrectDevice = (e: any) => {
        setIsCorrectDevice(e.target.checked);
    };

    const onCheckIsRepairDevice = (e: any) => {
        setIsRepairDevice(e.target.checked);
    };

    const onCheckIsECB = (e: any) => {
        setIsECB(e.target.checked);
    };

    const handleCancelHistory = () => {
        setVisibleHistory(false);
    };

    return (
        <NyDataEdit
            layout="horizontal"
            formProps={{ labelCol: { span: 5 }, wrapperCol: { span: 15 } }}
            editHeader={editHeader}
            loading={loading}
            setLoading={setLoading}
            onModalClose={onModalClose}
            url={CONSTANTS_REQ.DEVICE.EDIT}
            setValues={setValues}
            width={1500}
            form={form}
            goBack={() => history.goBack()}
            paramsId={id}
            setIsCreate={setIsCreate}
            customButtons={customButtons()}
            normalize={normalize}
            {...props}
            hideButtons={!NySession.hasAnyRole(['RULE_UPDATE_DEVICE', 'RULE_UPDATE_LIMITED_DEVICE'])}
            triggerSave={saveAsCopy}
            hideActivationButtons
        >
            <Tabs
                defaultActiveKey="1"
                type="card"
                onChange={(key: any) => {
                    if (key == 2) setRefreshHnb(new Date());
                }}
            >
                <TabPane tab={geti18nText('device.edit.tab.info')} key="1">
                    <Row gutter={24}>
                        <Col span={13}>
                            <Form.Item
                                label={geti18nText('device.table.column.use_parser')}
                                name="useParser"
                                valuePropName="checked"
                            >
                                <Switch style={{ marginLeft: '35px' }} defaultChecked onChange={onSwichChange}></Switch>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Divider orientation="left">{geti18nText('device.table.column.header.device_status')}</Divider>
                    <Row>
                        {NySession.hasAnyRole(['RULE_PREVIEW_DEVICE_WRITTEN_OFF_EDIT']) ? (
                            <>
                                <Col span={2}>
                                    <label>{geti18nText('device.table.column.written_off.lbl') + ':'} </label>
                                </Col>
                                <Col span={4}>
                                    <Checkbox
                                        onChange={onCheckWrittenOffDevice}
                                        checked={isWrittenOffDevice}
                                        disabled={!NySession.hasAnyRole(['RULE_PREVIEW_DEVICE_WRITTEN_OFF'])}
                                    >
                                        {geti18nText('device.table.column.written_off')}
                                    </Checkbox>
                                </Col>
                            </>
                        ) : NySession.hasAnyRole(['RULE_PREVIEW_DEVICE_WRITTEN_OFF']) ? (
                            <>
                                <Col span={2}>
                                    <label>{geti18nText('device.table.column.written_off.lbl') + ':'} </label>
                                </Col>
                                <Col span={5}>
                                    {isWrittenOffDevice ? (
                                        <Tag color="error">{geti18nText('device.table.column.written_off')}</Tag>
                                    ) : null}
                                </Col>
                            </>
                        ) : null}
                    </Row>
                    {isWrittenOffDevice == false && (
                        <Row gutter={17} style={{ marginTop: '10px' }}>
                            <>
                                <Col span={2}></Col>
                                <Col span={5}>
                                    <Checkbox
                                        onChange={onCheckIsActiveDevice}
                                        checked={isActiveDevice}
                                        disabled={
                                            !NySession.hasAnyRole(['RULE_UPDATE_DEVICE', 'RULE_UPDATE_LIMITED_DEVICE'])
                                        }
                                    >
                                        {geti18nText('device.table.column._active')}
                                    </Checkbox>
                                </Col>
                                {isActiveDevice == false && (
                                    <>
                                        <Col span={5}>
                                            <Checkbox
                                                onChange={onCheckIsCorrectDevice}
                                                checked={isCorrectDevice}
                                                disabled={
                                                    !NySession.hasAnyRole([
                                                        'RULE_UPDATE_DEVICE',
                                                        'RULE_UPDATE_LIMITED_DEVICE',
                                                    ])
                                                }
                                            >
                                                {geti18nText('device.table.column.valid')}
                                            </Checkbox>
                                        </Col>
                                        {isCorrectDevice == false && (
                                            <Col span={5}>
                                                <Checkbox
                                                    onChange={onCheckIsRepairDevice}
                                                    checked={isRepairDevice}
                                                    disabled={
                                                        !NySession.hasAnyRole([
                                                            'RULE_UPDATE_DEVICE',
                                                            'RULE_UPDATE_LIMITED_DEVICE',
                                                        ])
                                                    }
                                                >
                                                    {geti18nText('device.table.column.repair')}
                                                </Checkbox>
                                            </Col>
                                        )}
                                    </>
                                )}
                            </>
                        </Row>
                    )}

                    <Row style={{ marginTop: '10px' }}>
                        <Col span={7}>
                            <label style={{ marginRight: '5px' }}>
                                {geti18nText('device.table.column.ecb.label') + ':'}{' '}
                            </label>
                            <Checkbox
                                onChange={onCheckIsECB}
                                checked={isECB}
                                disabled={!NySession.hasAnyRole(['RULE_UPDATE_DEVICE'])}
                            ></Checkbox>
                        </Col>
                    </Row>

                    <Divider orientation="left">{geti18nText('device.table.column.header.general')}</Divider>
                    <Row>
                        <Col span={12}>
                            <Form.Item name="id" style={{ display: 'none' }}>
                                <Input readOnly={true} />
                            </Form.Item>
                            <Form.Item name="active" style={{ display: 'none' }}>
                                <Input readOnly={true} />
                            </Form.Item>
                            <Form.Item
                                rules={[
                                    {
                                        required: true,
                                        message: geti18nText('app.default.required'),
                                    },
                                ]}
                                label={geti18nText('device.table.column.name')}
                                name="name"
                            >
                                <Input
                                    disabled={
                                        NySession.hasAnyRole(['RULE_UPDATE_LIMITED_DEVICE']) &&
                                        !NySession.hasAnyRole(['RULE_UPDATE_DEVICE'])
                                    }
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                rules={[
                                    {
                                        required: useParser,
                                        message: geti18nText('app.default.required'),
                                    },
                                ]}
                                label={geti18nText('device.table.column.model')}
                                name="model"
                            >
                                <NySearchField
                                    disabled={
                                        NySession.hasAnyRole(['RULE_UPDATE_LIMITED_DEVICE']) &&
                                        !NySession.hasAnyRole(['RULE_UPDATE_DEVICE'])
                                    }
                                    url={CONSTANTS_REQ.DEVICE_MODEL.SEARCH}
                                    map={{ id: 'id', label: 'name' }}
                                    searchBy="name"
                                    onChange={(e: any) => {
                                        if (e.id != -1) {
                                            NyRequestResolver.requestGet(
                                                CONSTANTS_REQ.DEVICE_MODEL.EDIT + '/' + e.id
                                            ).then((result: any) => {
                                                if (result.status === RESPONSE.OK) {
                                                    if (result.data != null) {
                                                        result.data.isClient
                                                            ? setIsDeviceClient(true)
                                                            : setIsDeviceClient(false);
                                                    }
                                                }
                                            });
                                        }
                                    }}
                                    AddNewModalComponent={DeviceModelEdit}
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row>
                        <Col span={12}>
                            <Form.Item label={geti18nText('device.table.column.serial.number')} name="serialNumber">
                                <Input
                                    disabled={
                                        NySession.hasAnyRole(['RULE_UPDATE_LIMITED_DEVICE']) &&
                                        !NySession.hasAnyRole(['RULE_UPDATE_DEVICE'])
                                    }
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label={geti18nText('device.table.column.manufacturer')} name="manufacturer">
                                <NySearchField
                                    disabled={
                                        NySession.hasAnyRole(['RULE_UPDATE_LIMITED_DEVICE']) &&
                                        !NySession.hasAnyRole(['RULE_UPDATE_DEVICE'])
                                    }
                                    url={CONSTANTS_REQ.MANUFACTURER.SEARCH}
                                    map={{ id: 'id', label: 'name' }}
                                    searchBy="name"
                                    AddNewModalComponent={ManufacturerEdit}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12}>
                            <Form.Item
                                label={geti18nText('device.table.column.inventory.number')}
                                name="inventoryNumber"
                                dependencies={['serialNumber']}
                                rules={[{ validator: validateInvetoryNumber }]}
                            >
                                <Input
                                    disabled={
                                        NySession.hasAnyRole(['RULE_UPDATE_LIMITED_DEVICE']) &&
                                        !NySession.hasAnyRole(['RULE_UPDATE_DEVICE'])
                                    }
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label={geti18nText('device.edit.moxa_serial_number')} name="moxaSerialNumber">
                                <Input
                                    disabled={
                                        NySession.hasAnyRole(['RULE_UPDATE_LIMITED_DEVICE']) &&
                                        !NySession.hasAnyRole(['RULE_UPDATE_DEVICE'])
                                    }
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12}>
                            <Form.Item label={geti18nText('device.edit.pbz.id')} name="pbzId">
                                <Input
                                    disabled={
                                        NySession.hasAnyRole(['RULE_UPDATE_LIMITED_DEVICE']) &&
                                        !NySession.hasAnyRole(['RULE_UPDATE_DEVICE'])
                                    }
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row>
                        <Col span={12}>
                            <Form.Item
                                rules={[
                                    {
                                        required: useParser,
                                        message: geti18nText('app.default.required'),
                                    },
                                ]}
                                label={geti18nText('device.table.column.ip')}
                                name="ip"
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                dependencies={['ip']}
                                rules={[
                                    {
                                        required: isDeviceClient && useParser,
                                        message: geti18nText('app.default.required'),
                                    },
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            if (!useParser && !getFieldValue('ip')) {
                                                return Promise.resolve();
                                            }

                                            if (!getFieldValue('ip') && useParser) {
                                                return Promise.reject(
                                                    new Error(geti18nText('device.error.ip.port.null'))
                                                );
                                            }
                                            if (value) {
                                                return new Promise((res, rec) => {
                                                    try {
                                                        NyRequestResolver.requestGet(
                                                            CONSTANTS_REQ.DEVICE.CHECK_PORT_IP,
                                                            {
                                                                ip: form.getFieldValue('ip'),
                                                                port: form.getFieldValue('port'),
                                                                id:
                                                                    openModalCreate === true
                                                                        ? null
                                                                        : getFieldValue('id'),
                                                            }
                                                        ).then((result: any) => {
                                                            if (result.status === RESPONSE.OK) {
                                                                if (result.data === true) {
                                                                    res(true);
                                                                } else {
                                                                    rec(new Error(geti18nText('device.error.ip.port')));
                                                                }
                                                            } else {
                                                                rec(new Error(geti18nText('device.error.ip.port')));
                                                            }
                                                        });
                                                    } catch (err) {
                                                        console.log('ERROR');
                                                        return rec('Error');
                                                    }
                                                });
                                            } else {
                                                return Promise.resolve();
                                            }
                                        },
                                    }),
                                ]}
                                label={geti18nText('device.table.column.port')}
                                name="port"
                            >
                                <InputNumber
                                    disabled={
                                        NySession.hasAnyRole(['RULE_UPDATE_LIMITED_DEVICE']) &&
                                        !NySession.hasAnyRole(['RULE_UPDATE_DEVICE'])
                                    }
                                    style={{ width: '100%' }}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12}>
                            <Form.Item label={geti18nText('device.edit.device.mode')} name="mode">
                                <NySearchField
                                    options={deviceMode()}
                                    map={{ id: 'id', label: 'text' }}
                                    searchBy="text"
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label={geti18nText('device.edit.connection_type')} name="connectionType">
                                <NySearchField
                                    options={deviceConnectonType()}
                                    map={{ id: 'id', label: 'text' }}
                                    searchBy="text"
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    {NySession.getSetting('timezone')['use'] === true && (
                        <Row>
                            <Col span={12}>
                                <Form.Item label={geti18nText('device.edit.timezone')} name={'timezone'}>
                                    <NySearchField
                                        options={timeZones()}
                                        map={{ id: 'id', label: 'text' }}
                                        searchBy="text"
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                    )}

                    <Divider orientation="left">{geti18nText('device.edit.divider.title.warranty')}</Divider>
                    <Row>
                        <Col span={12}>
                            <Form.Item label={geti18nText('device.table.column.vendor')} name="vendor">
                                <NySearchField
                                    disabled={
                                        NySession.hasAnyRole(['RULE_UPDATE_LIMITED_DEVICE']) &&
                                        !NySession.hasAnyRole(['RULE_UPDATE_DEVICE'])
                                    }
                                    url={CONSTANTS_REQ.VENDOR.SEARCH}
                                    map={{ id: 'id', label: 'name' }}
                                    searchBy="name"
                                    AddNewModalComponent={VendorEdit}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label={geti18nText('device.table.column.service.vendor')} name="serviceVendor">
                                <NySearchField
                                    url={CONSTANTS_REQ.SERVICE_VENDOR.SEARCH}
                                    map={{ id: 'id', label: 'name' }}
                                    searchBy="name"
                                    AddNewModalComponent={ServiceVendorEdit}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item
                                label={geti18nText('device.edit.maintenance.contract')}
                                name={'maintenanceContractDate'}
                            >
                                <FormatedDatePicker />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label={geti18nText('device.edit.warranty')} name={'warrantyDate'}>
                                <FormatedDatePicker />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Divider orientation="left">{geti18nText('device.edit.divider.title.group')}</Divider>
                    <Row>
                        {!NySession.getUser().isBranch && (
                            <Col span={12}>
                                <Form.Item label={geti18nText('device.table.column.office')} name="office">
                                    <NySearchField
                                        url={CONSTANTS_REQ.LOCATION.SEARCH}
                                        map={{ id: 'id', label: 'name' }}
                                        searchBy="name"
                                        AddNewModalComponent={AdminLocationEdit}
                                    />
                                </Form.Item>
                            </Col>
                        )}
                    </Row>
                    <Row>
                        <Col span={12}>
                            <Form.Item label={geti18nText('device.group.title')} name="deviceGroups">
                                <NySearchField
                                    url={CONSTANTS_REQ.DEVICE_GROUP.SEARCH}
                                    map={{ id: 'id', label: 'name' }}
                                    searchBy="name"
                                    mode="multiple"
                                    AddNewModalComponent={DeviceGroupEdit}
                                />
                            </Form.Item>
                        </Col>
                        {!NySession.getUser().isCompany && (
                            <Col span={12}>
                                <Form.Item label={geti18nText('device.edit.company')} name="companies">
                                    <NySearchField
                                        url={CONSTANTS_REQ.COMPANY.SEARCH}
                                        map={{ id: 'id', label: 'name' }}
                                        searchBy="name"
                                        mode="multiple"
                                        AddNewModalComponent={CompanyEdit}
                                    />
                                </Form.Item>
                            </Col>
                        )}
                    </Row>
                </TabPane>
                <TabPane tab={geti18nText('hnb.device.table.header')} key="2" disabled={openModalCreate || loading}>
                    <HnbDeviceEdit deviceId={deviceId} useParser={useParser} refresh={refreshHnb}></HnbDeviceEdit>
                </TabPane>
                <TabPane tab={geti18nText('device.edit.tab.reference')} key="3" disabled={openModalCreate || loading}>
                    <NyDataTable
                        headerTitle={geti18nText('device.locations.header')}
                        url={CONSTANTS_REQ.REFERENCES.LIST_BY_DEVICE}
                        showRecordModal={true}
                        addNewButtonText={geti18nText('reference.add.new')}
                        modalComponent={AdminReferenceEdit}
                        readonly={!NySession.hasAnyRole(['UPDATE_COMMENTS'])}
                        addedData={{ deviceId: deviceId }}
                        setDefaultPageSize={20}
                        fetchWhenChange={deviceReference}
                        hideButtons={!NySession.hasAnyRole(['UPDATE_COMMENTS'])}
                        columns={[
                            {
                                dataIndex: 'id',
                                width: '10%',

                                render: (text: string, record: { [index: string]: any }) => {
                                    if (record.text) {
                                        return (
                                            <>
                                                <span style={{ whiteSpace: 'pre-line' }}>
                                                    {record.text.split(/\r\n|\r|\n/).length <= 5
                                                        ? record.text
                                                        : record.text
                                                              .split(/\r\n|\r|\n/)
                                                              .slice(0, 5)
                                                              .join('\n') + '...'}
                                                </span>
                                                <div>
                                                    <UserOutlined /> {record.username}{' '}
                                                    {new Date(record.created).toLocaleString(
                                                        NyUtils.getSelectedLocale(),
                                                        getTimezone()
                                                    )}
                                                    {NySession.hasAnyRole(['UPDATE_COMMENTS']) && (
                                                        <>
                                                            <Popconfirm
                                                                placement="rightTop"
                                                                title={geti18nText('app.default.button.delete')}
                                                                okText={geti18nText('app.default.button.yes')}
                                                                cancelText={geti18nText('app.default.button.no')}
                                                                onConfirm={(e) => deviceReferenceDelete(record)}
                                                            >
                                                                <DeleteOutlined
                                                                    style={{
                                                                        fontSize: '20px',
                                                                        color: 'red',
                                                                        marginRight: '10px',
                                                                        marginBottom: '10px',
                                                                        float: 'right',
                                                                    }}
                                                                />
                                                            </Popconfirm>
                                                        </>
                                                    )}
                                                </div>
                                            </>
                                        );
                                    }
                                },
                            },
                        ]}
                    />
                </TabPane>
            </Tabs>
        </NyDataEdit>
    );
};

export default AdminDeviceEdit;
