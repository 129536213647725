import { CheckOutlined, CloseOutlined, UploadOutlined } from '@ant-design/icons';
import {
    ENUMS,
    getColumnDateOption,
    getColumnSearch,
    getColumnSearchOption,
    geti18nText,
    NyDataTable,
    NyRequestResolver,
    NySession,
    NySpinner,
    NyUtils,
    RESPONSE,
} from '@nybble/nyreact';
import { Button, Col, Modal, notification, Row, Statistic, Table, Tabs, Tag, Upload } from 'antd';
import React, { useState } from 'react';
import { CONSTANTS_REQ } from '../../../utils/Constants';
import { GetEnumNameForValue } from '../../../utils/Enums';
import TableSettings from '../../table_settings';
import DeviceColumns from './columns';
import DeviceCSVColCistomization from './csvColumnsCustom';
import DeviceCSVColumnsExport from './csvDeviceColumnsExport';
import AdminDeviceEdit from './edit';
const AdminDeviceIndex = () => {
    const { TabPane } = Tabs;
    const [visible, setVisible] = useState(false);
    const [importing, setImporting] = useState(false);
    const [showData, setShowData] = useState(false);
    const [data, setData] = useState<any>(undefined);
    const [imported, setImported] = useState(0);
    const table = TableSettings();

    function setDefaultFilterValue() {
        if (!NySession.hasAnyRole(['RULE_PREVIEW_DEVICE_WRITTEN_OFF'])) {
            return [{ field: 'writtenOff', condition: 'equals_bool', value: 0 }];
        }
        return [];
    }

    const addedButtons = (onPopupSave: () => void) => {
        return (
            <Button style={{ marginLeft: '10px' }} type="primary" onClick={() => setVisible(true)}>
                {geti18nText('device.import.title')}
            </Button>
        );
    };

    function onCancelDeviceModal() {
        setVisible(false);
        setImporting(false);
        setShowData(false);
        let im = imported;
        im++;
        setImported(im);
    }

    function okNotification(message: any) {
        notification['success']({
            message: message,
            duration: 10,
        });
    }

    function error(message: any) {
        notification['error']({
            message: geti18nText('reports.download.nok'),
            description: message,
            duration: 0,
        });
    }

    function importData(file: any) {
        setImporting(true);
        var formData = new FormData();
        formData.append('file', file.file);
        NyRequestResolver.requestPostFile(CONSTANTS_REQ.DEVICE.IMPORT_DEVICE, undefined, formData).then(
            (result: any) => {
                if (result && result.status === RESPONSE.OK) {
                    file.onSuccess('');
                    okNotification(geti18nText('device.import.success'));
                    setData(result.data);
                    setShowData(true);
                    setImporting(false);
                } else {
                    console.log(JSON.stringify(result.data));
                    error(geti18nText('currency.denomination.import.data.NOK'));
                    setImporting(false);
                }
            }
        );
    }

    const columns = [
        {
            title: geti18nText('device.import.column.inventory'),
            dataIndex: 'inventoryNumber',
        },
        {
            title: geti18nText('device.import.column.name'),
            dataIndex: 'name',
        },
        {
            title: geti18nText('device.import.column.status'),
            dataIndex: 'status',
            render: (text: string, record: { [index: string]: any }) => {
                if (record.status) {
                    if (record.status === 'SUCCESS') {
                        return (
                            <div>
                                <Tag color="success">{record.status}</Tag>
                            </div>
                        );
                    }
                    if (record.status === 'ERROR') {
                        return (
                            <div>
                                <Tag color="error">{record.status}</Tag>
                            </div>
                        );
                    }
                    if (record.status === 'EXIST') {
                        return (
                            <div>
                                <Tag color="warning">{record.status}</Tag>
                            </div>
                        );
                    }
                }
            },
        },
        {
            title: geti18nText('device.import.column.error'),
            dataIndex: 'error',
        },
    ];

    return (
        <>
            <NyDataTable
                headerTitle={geti18nText('device.table.header')}
                url={CONSTANTS_REQ.DEVICE.LIST}
                showRecordModal={true}
                addNewButtonText={geti18nText('device.add.new')}
                hideButtons={
                    !NySession.hasAnyRole(['RULE_ADD_DEVICE']) ||
                    (NySession.hasAnyRole(['RULE_UPDATE_LIMITED_DEVICE']) &&
                        !NySession.hasAnyRole(['RULE_UPDATE_DEVICE']))
                }
                modalComponent={AdminDeviceEdit}
                columns={DeviceColumns({isAdmin:true})}
                scroll={{ x: 1200 }}
                fetchWhenChange={imported}
                csvColumns={DeviceCSVColumnsExport(true)}
                exportCSV={true}
                addedButtons={NySession.hasAnyRole(['RULE_ADD_DEVICE']) ? addedButtons : undefined}
                colCSVCustomization={DeviceCSVColCistomization()}
                setDefaultPageSize={table.setDefaultPageSize(20)}
                onDataLoaded={table.onLoadData}
                setDefaultFilterValue={() => table.setDefaultFilterValue(setDefaultFilterValue())}
                setDefaultCurrentPage={table.setDefaultCurrentPage()}
                setDefaultSortOrder={table.setDefaultSortOrder()}
                setRowClassName={(record: any) => {
                    if (record.writtenOff === true) {
                        return 'table-row-deactivated';
                    }

                    return '';
                }}
            />
            <Modal
                visible={visible}
                title={geti18nText('device.import.title')}
                width={900}
                footer={null}
                maskClosable={false}
                onCancel={onCancelDeviceModal}
                destroyOnClose={true}
                okText={geti18nText('menu.statistic.modal.settings.add')}
            >
                {!importing && (
                    <div style={{ textAlign: 'center' }}>
                        <Upload customRequest={importData} showUploadList={false}>
                            <Button type="primary" icon={<UploadOutlined />} style={{ marginLeft: '10px' }}>
                                {geti18nText('device.import.title.btn')}
                            </Button>
                        </Upload>
                    </div>
                )}
                {importing && <NySpinner></NySpinner>}
                {showData && (
                    <>
                        <Row style={{ marginTop: 16 }} gutter={12}>
                            <Col span={8}>
                                <Statistic
                                    valueStyle={{ color: 'green' }}
                                    title={geti18nText('device.import.inserted')}
                                    value={data.INSERTED}
                                />
                            </Col>
                            <Col span={8}>
                                <Statistic
                                    valueStyle={{ color: 'red' }}
                                    title={geti18nText('device.import.error')}
                                    value={data.ERROR}
                                />
                            </Col>
                            <Col span={8}>
                                <Statistic
                                    valueStyle={{ color: 'orange' }}
                                    title={geti18nText('device.import.exist')}
                                    value={data.EXIST}
                                />
                            </Col>
                        </Row>
                        <Table style={{ marginTop: '15px' }} size="small" columns={columns} dataSource={data.DATA} />
                    </>
                )}
            </Modal>
        </>
    );
};

export default AdminDeviceIndex;
