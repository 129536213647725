import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import {
    ENUMS,
    getColumnDateOption,
    getColumnSearch,
    getColumnSearchOption,
    geti18nText,
    NyDataEdit,
    NyDataTable,
    NySession,
    NyUtils,
} from '@nybble/nyreact';
import { Col, Descriptions, Form, Input, Row, Tabs } from 'antd';
import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { CONSTANTS_REQ } from '../../../utils/Constants';

const ManufacturerEdit = (props: any) => {
    const [editHeader, setEditHeader] = useState(geti18nText('manufacturer.table.header'));
    const [loading, setLoading] = useState(false);
    const [isCreate, setIsCreate] = useState(false);
    const { id } = useParams<any>();
    const history = useHistory();
    const [form] = Form.useForm();

    const onModalClose = () => {
        setEditHeader(geti18nText('manufacturer.table.header'));
        form.resetFields();
    };
    function setValues(dataForm: any) {
        setEditHeader(dataForm.name);
        form.setFieldsValue(dataForm);
    }

    function onSaveAndGetData(data: any) {
        const manufacturers: [any] = NyUtils.load('MANUFACTURERS');
        if (data.active === true) {
            let index = manufacturers.findIndex((el) => el.id === data.id);
            if (index != -1) manufacturers.splice(index, 1);
            manufacturers.push({ id: data.id, name: data.name });
        } else {
            let index = manufacturers.findIndex((el) => el.id === data.id);
            if (index != -1) manufacturers.splice(index, 1);
        }
        NyUtils.save('MANUFACTURERS', manufacturers ? manufacturers : []);
        props.onSaveAndGetData(data);
    }

    return (
        <NyDataEdit
            layout="horizontal"
            formProps={{ labelCol: { span: 7 }, wrapperCol: { span: 17 } }}
            editHeader={editHeader}
            loading={loading}
            setLoading={setLoading}
            onModalClose={onModalClose}
            url={CONSTANTS_REQ.MANUFACTURER.EDIT}
            setValues={setValues}
            width={800}
            form={form}
            goBack={() => history.goBack()}
            paramsId={id}
            setIsCreate={setIsCreate}
            {...props}
            onSaveAndGetData={onSaveAndGetData}
            hideButtons={!NySession.hasAnyRole(['RULE_UPDATE_D_MANUFACTURE'])}
        >
            <Row gutter={24}>
                <Form.Item name="id" style={{ display: 'none' }}>
                    <Input readOnly={true} />
                </Form.Item>
                <Col span={18}>
                    <Form.Item
                        rules={[
                            {
                                required: true,
                                message: geti18nText('app.default.required'),
                            },
                        ]}
                        label={geti18nText('manufacturer.alarm.table.name')}
                        name="name"
                    >
                        <Input />
                    </Form.Item>
                </Col>
            </Row>
        </NyDataEdit>
    );
};

export default ManufacturerEdit;
