import { NyUtils } from '@nybble/nyreact';
import { DatePicker } from 'antd';

export const FormatedDatePicker = (props: any) => {
    const FORMAT_DATE_TIME_HR = 'DD.MM.YYYY HH:mm:ss';
    const FORMAT_DATE_TIME_EN = 'MM/DD/YYYY, HH:mm:ss';
    const FORMAT_DATE_EN = 'MM/DD/YYYY';
    const FORMAT_DATE_HR = 'DD.MM.YYYY';
    const { RangePicker } = DatePicker;

    function setFormat(showTime: boolean) {
        if (showTime) {
            if (NyUtils.getSelectedLocale() == 'hr-HR') return FORMAT_DATE_TIME_HR;
            else return FORMAT_DATE_TIME_EN;
        } else {
            if (NyUtils.getSelectedLocale() == 'hr-HR') return FORMAT_DATE_HR;
            else return FORMAT_DATE_EN;
        }
    }

    return (
        <>
            {!props.type && (
                <DatePicker
                    disabled={props.disabled}
                    showTime={props.showTime}
                    onChange={props.onChange}
                    value={props.value ? props.value : undefined}
                    defaultValue={props.defaultValue ? props.defaultValue : undefined}
                    format={setFormat(props.showTime != null ? props.showTime : false)}
                />
            )}
            {props.type === 'range' && (
                <RangePicker
                    disabled={props.disabled}
                    value={props.value}
                    showTime={props.showTime}
                    format={setFormat(props.showTime != null ? props.showTime : false)}
                    onChange={props.onChange}
                />
            )}
        </>
    );
};

export const FormatedRangePicker = (props: any) => {
    const FORMAT_DATE_TIME_HR = 'DD.MM.YYYY HH:mm:ss';
    const FORMAT_DATE_TIME_EN = 'MM/DD/YYYY, HH:mm:ss';
    const FORMAT_DATE_EN = 'MM/DD/YYYY';
    const FORMAT_DATE_HR = 'DD.MM.YYYY';
    const { RangePicker } = DatePicker;

    function setFormat(showTime: boolean) {
        if (showTime) {
            if (NyUtils.getSelectedLocale() == 'hr-HR') return FORMAT_DATE_TIME_HR;
            else return FORMAT_DATE_TIME_EN;
        } else {
            if (NyUtils.getSelectedLocale() == 'hr-HR') return FORMAT_DATE_HR;
            else return FORMAT_DATE_EN;
        }
    }

    return (
        <RangePicker
            value={props.value}
            showTime={props.showTime}
            format={setFormat(props.showTime != null ? props.showTime : false)}
            onChange={props.onChange}
        />
    );
};
