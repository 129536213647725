import {
    geti18nText,
    NyProgressNotificationTimer,
    NyRequestResolver,
    NySession,
    NySettings,
    NyUtils,
    RESPONSE,
} from '@nybble/nyreact';
import { Button, Checkbox, Col, Divider, Input, notification, Row, Spin } from 'antd';
import Title from 'antd/lib/typography/Title';
import QRCode from 'qrcode.react';
import React, { useEffect, useRef, useState } from 'react';
import { CONSTANTS_REQ, URL_PREFIX } from '../../utils/Constants';

const Settings2FAIndex = () => {
    const [checked, setChecked] = useState<any>(false);
    const [qrHidden, setQrHidden] = useState<any>(true);
    const [disableSave, setDisableSave] = useState<any>(false);
    const [token, setToken] = useState<any>(undefined);
    const [refId, setRefId] = useState<any>(undefined);
    const [showTimerNotification, setShowTimerNotification] = useState(false);
    const [settings2faSaved, setSettings2faSaved] = useState(false);
    const intervalRef = useRef<any>(null);
    const [start, setStart] = useState(false);
    const eventSourceRef = useRef<any>(null);
    const [loading, setLoading] = useState(false);

    function onChange(e: any) {
        if (e.target.checked === true) {
            if (token === undefined) generateToken();
            setDisableSave(settings2faSaved);
        } else {
            setDisableSave(false);
        }

        setQrHidden(!e.target.checked);
        setChecked(e.target.checked);
    }
    useEffect(() => {
        init();
    }, []);

    function init() {
        setLoading(true);
        NyRequestResolver.requestGet(CONSTANTS_REQ.USER.FIND_BY_USERNAME + NySession.getUser().username).then(
            (result) => {
                if (result.status === RESPONSE.OK) {
                    if (result.data != null) {
                        const responseData: { [index: string]: any } | Object | undefined | any = result.data;
                        console.log(responseData);
                        if (responseData.active2fa === true) {
                            setQrHidden(false);
                            setToken(responseData.token);
                            setChecked(true);
                            setDisableSave(true);
                            setSettings2faSaved(true);
                            setRefId(responseData.refId);
                        } else {
                            setRefId(responseData.refId);
                        }
                    }
                } else {
                    const responseData: { [index: string]: any } | Object | undefined | any = result.data;
                    setRefId(responseData.refId);
                }
                setLoading(false);
            }
        );
    }

    function generateToken() {
        setToken(genHexString(60));
    }

    function genHexString(len: any) {
        let output = '';
        for (let i = 0; i < len; ++i) {
            output += Math.floor(Math.random() * 16).toString(16);
        }
        return output;
    }

    function getQRValue() {
        let qr = { app_name: NySession.getSetting('ux')['token-title'], token: token, ref_id: refId };
        return JSON.stringify(qr);
    }

    function register() {
        if (checked === false) {
            NyRequestResolver.requestPost(CONSTANTS_REQ.USER.DISABLE_2FA, undefined, {
                username: NySession.getUser().username,
            }).then((result) => {
                if (result.status === 200) {
                    setSettings2faSaved(false);
                    setToken(undefined);
                    notification.success({
                        message: geti18nText('app.default.save.ok'),
                        description: geti18nText('app.default.save.ok.desc'),
                        duration: 3,
                    });
                } else {
                    notification.error({
                        message: geti18nText('app.default.save.nok'),
                        duration: 3,
                    });
                }
            });
        } else {
            setShowTimerNotification(true);
            setStart(true);
            eventSourceRef.current = new EventSource(
                URL_PREFIX + 'auth/register?token=' + token + '&username=' + NySession.getUser().username
            );
            eventSourceRef.current.onmessage = (e: any) => {
                let notResult = JSON.parse(e.data);
                setShowTimerNotification(false);
                eventSourceRef.current.close();
                clearInterval(intervalRef.current);
                setStart(false);
                if (notResult.confirmed === true) {
                    setDisableSave(checked === true ? true : false);
                    setSettings2faSaved(true);
                    notification.success({
                        message: geti18nText('app.default.save.ok'),
                        description: geti18nText('app.default.save.ok.desc'),
                        duration: 3,
                    });
                } else {
                    if (notResult.status && notResult.status === 'NO_EXIST') {
                        notification.error({
                            message: geti18nText('app.default.save.nok'),
                            description: geti18nText('app.save.nok.desc'),
                            duration: 3,
                        });
                    } else
                        notification.error({
                            message: geti18nText('app.default.save.nok'),
                            description: geti18nText('settings.2fa.cancel'),
                            duration: 3,
                        });
                }
            };

            eventSourceRef.current.onerror = () => {
                clearInterval(intervalRef.current);
                setShowTimerNotification(false);
                eventSourceRef.current.close();
            };
        }
    }

    function onTimerFinish() {
        setShowTimerNotification(false);
        clearInterval(intervalRef.current);
        eventSourceRef.current.close();
        setStart(false);
    }

    function onStart(e: any) {
        intervalRef.current = e;
    }

    return (
        <>
            <Divider style={{ fontSize: 18 }} orientation="left">
                {geti18nText('settings.2fa.title')}
            </Divider>
            {!loading && (
                <>
                    <Col hidden={showTimerNotification}>
                        <Row>
                            <Checkbox checked={checked} onChange={onChange} style={{ fontSize: 14 }}>
                                {geti18nText('settings.2fa.title.checkbox')}
                            </Checkbox>
                        </Row>
                        {!qrHidden && (
                            <>
                                <Row style={{ marginTop: '20px' }}>
                                    <Title level={4}>{geti18nText('settings.2fa.scan')}</Title>
                                </Row>
                                <div style={{ background: 'white', width: '256px' }}>
                                    <QRCode style={{ padding: '15px' }} size={256} value={getQRValue()} />
                                </div>
                                <Row style={{ marginTop: '10px', width: '400px' }}>
                                    <Input value={token} readOnly={true} />
                                </Row>
                            </>
                        )}

                        <Row style={{ marginTop: '20px' }}>
                            <Button disabled={disableSave} onClick={() => register()} type="primary">
                                {geti18nText('app.default.button.save')}
                            </Button>
                        </Row>
                    </Col>
                    <Col hidden={!showTimerNotification}>
                        <NyProgressNotificationTimer
                            start={start}
                            onStart={onStart}
                            onFinish={onTimerFinish}
                        ></NyProgressNotificationTimer>
                    </Col>
                </>
            )}
            {loading && (
                <div className="spin-container">
                    <Spin size="large" />
                </div>
            )}
        </>
    );
};

export default Settings2FAIndex;
