import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import {
    ENUMS,
    getColumnDateOption,
    getColumnSearch,
    getColumnSearchOption,
    geti18nText,
    NyDataTable,
    NyRequestResolver,
    NySearchField,
    NySpinner,
    NyUtils,
    RESPONSE,
} from '@nybble/nyreact';
import { Button, Col, Descriptions, Form, Popover, Radio, Row, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
    Area,
    AreaChart,
    CartesianGrid,
    Legend,
    Line,
    LineChart,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from 'recharts';
import { setActive } from '../../slices/menuSlice';
import { setModalOpen } from '../../slices/modalOpenSlice';
import { CONSTANTS_REQ } from '../../utils/Constants';
import DeviceErrorColumns from '../device_error/columns';
import DeviceIndex from '../device/index';
import DeviceSearchIndex from '../device/searchIndex';
import { getTimezone } from '../../utils/Utils';

export const DeviceErrorProps = (props: any) => {
    const [addButtonDisabled, setAddButtonDisabled] = useState(false);
    const [deviceValue, setDeviceValue] = useState<any>(undefined);
    const [radioTypeValue, setRadioTypeValue] = useState('device');

    function setSettings() {
        props.form
            .validateFields()
            .then((val: any) => {
                if (val) {
                    let settings: any = { period: val.period };
                    if (val.deviceError) {
                        settings.device = val.deviceError;
                    }
                    if (val.deviceGroup) {
                        settings.deviceGroup = val.deviceGroup;
                    }
                    props.addWidget(settings);
                }
            })
            .catch(() => {
                console.log('error');
            });
    }

    const options = [
        { label: geti18nText('dashboard.widget.counting.number.device'), value: 'device' },
        { label: geti18nText('dashboard.widget.counting.number.device.groups'), value: 'deviceGroup' },
    ];

    function onChangeRadioType(value: any) {
        props.form.resetFields(['deviceError', 'deviceGroup']);
        setRadioTypeValue(value.target.value);
    }

    function onChangePeriod(val: any) {
        if (val.id === -1) {
            props.form.resetFields(['period']);
        }
    }

    function onDeviceGroup(val: any) {
        if (val.id === -1) {
            props.form.resetFields(['deviceGroup']);
        }
    }

    function onDevice(val: any) {
        if (val.id === -1) {
            props.form.resetFields(['deviceError']);
        }
    }

    function onRowClickHandler(value: any) {
        props.form.setFieldsValue({ deviceError: value });
        setDeviceValue(value.name);
    }

    const period = [
        { id: 'today', text: geti18nText('dashboard.widget.chart.period.today') },
        { id: '24h', text: geti18nText('dashboard.widget.chart.period.24h') },
        { id: '3', text: '3 ' + geti18nText('dashboard.widget.chart.period.days') },
        { id: '7', text: '7 ' + geti18nText('dashboard.widget.chart.period.days') },
        { id: '14', text: '14 ' + geti18nText('dashboard.widget.chart.period.days') },
        { id: '30', text: '30 ' + geti18nText('dashboard.widget.chart.period.days') },
    ];

    return (
        <>
            <Row style={{ marginBottom: 10 }}>
                <Col span={24}>
                    <Radio.Group options={options} onChange={onChangeRadioType} value={radioTypeValue} />
                </Col>
            </Row>
            <Row hidden={radioTypeValue == 'deviceGroup'}>
                <Col span={24}>
                    <Form.Item name="deviceError" label={geti18nText('dashboard.widget.counting.number.device')}>
                        <DeviceSearchIndex isAdmin={false} deviceValue={deviceValue} onRowClickHandler={onRowClickHandler} />
                    </Form.Item>
                </Col>
            </Row>
            <Row hidden={radioTypeValue == 'device'}>
                <Col span={24}>
                    <Form.Item name="deviceGroup" label={geti18nText('dashboard.widget.counting.number.device.groups')}>
                        <NySearchField
                            onChange={onDeviceGroup}
                            url={CONSTANTS_REQ.DEVICE_GROUP.SEARCH}
                            map={{ id: 'id', label: 'name' }}
                            searchBy="name"
                            mode="multiple"
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col span={24} style={{ textAlign: 'right' }}>
                    <Button
                        type="primary"
                        disabled={addButtonDisabled}
                        style={{ marginRight: '1em' }}
                        onClick={() => setSettings()}
                    >
                        {geti18nText('dashboard.widget.button.add')}
                    </Button>
                </Col>
            </Row>
        </>
    );
};

export const DeviceError = (props: any) => {
    const [loading, setLoading] = useState(false);
    const [key, setKey] = useState(new Date() + 'table');
    const { Title } = Typography;
    const { Text, Link } = Typography;
    const dispatch = useDispatch();

    function remove() {
        props.onRemoveWidget(props.widgetKey);
    }

    function setDefaultFilterValue() {
        let filter: any = [];
        if (props.device != null) {
            const id = parseInt(props.device);
            if (id != undefined) filter.push({ field: 'device.id', condition: 'equals', value: props.device.id });
        }
        if (props.deviceGroup != null) {
            filter.push({
                field: 'groupId',
                condition: 'equals',
                value: props.deviceGroup
                    .map((dg: any) => {
                        return dg.id;
                    })
                    .toString(),
            });
        }

        return filter;
    }

    useEffect(() => {
        const interval = setInterval(() => {
            setKey(new Date() + 'table');
        }, 1 * 60 * 1000);

        return () => {
            clearInterval(interval);
        };
    }, []);

    function setTitle() {
        if (props.deviceGroup) {
            return <>{geti18nText('dashboard.widget.error.table.title.device.group')}</>;
        } else return <>{geti18nText('dashboard.widget.error.table.title')}</>;
    }

    function setGroup() {
        if (props.deviceGroup) {
            let groups = props.deviceGroup
                .map((g: any) => {
                    return g.name;
                })
                .join(',');
            if (groups.length)
                return (
                    <>
                        {groups.length > 50 && (
                            <Popover content={<Text type="secondary"> {groups} </Text>} trigger="hover">
                                {groups.substring(0, 50) + ' ...'}
                            </Popover>
                        )}
                        {groups.length <= 50 && groups}
                    </>
                );
        } else return <></>;
    }

    function onRowClickHandler(record: any) {
        console.log(record);
        dispatch(
            setModalOpen({
                modal: 'deviceEdit',
                visible: true,
                item: record.deviceId,
            })
        );
    }

    function drawDetails(record: any) {
        return (
            <>
                <Row style={{ width: '300px' }}>
                    <Col span={16}>{geti18nText('device.error.table.count.mode')}</Col>
                    <Col span={6}>
                        {record.countMode ? (
                            <CheckOutlined style={{ color: 'green' }} />
                        ) : (
                            <CloseOutlined style={{ color: 'red' }} />
                        )}
                    </Col>
                </Row>

                <Row style={{ width: '300px' }}>
                    <Col span={16}>{geti18nText('device.error.table.hopper.exist')}</Col>
                    <Col span={6}>
                        {record.hopperExist ? (
                            <CheckOutlined style={{ color: 'green' }} />
                        ) : (
                            <CloseOutlined style={{ color: 'red' }} />
                        )}
                    </Col>
                </Row>

                <Row style={{ width: '300px' }}>
                    <Col span={16}>{geti18nText('device.error.table.hopper.fail.feed')}</Col>
                    <Col span={6}>
                        {record.hopperFailFeed ? (
                            <CheckOutlined style={{ color: 'green' }} />
                        ) : (
                            <CloseOutlined style={{ color: 'red' }} />
                        )}
                    </Col>
                </Row>

                <Row style={{ width: '300px' }}>
                    <Col span={16}>{geti18nText('device.error.table.reject.exist')}</Col>
                    <Col span={6}>
                        {record.rejectExists ? (
                            <CheckOutlined style={{ color: 'green' }} />
                        ) : (
                            <CloseOutlined style={{ color: 'red' }} />
                        )}
                    </Col>
                </Row>

                <Row style={{ width: '300px' }}>
                    <Col span={16}>{geti18nText('device.error.table.hopper.exist')}</Col>
                    <Col span={6}>
                        {record.rejectFull ? (
                            <CheckOutlined style={{ color: 'green' }} />
                        ) : (
                            <CloseOutlined style={{ color: 'red' }} />
                        )}
                    </Col>
                </Row>

                <Row style={{ width: '300px' }}>
                    <Col span={16}>{geti18nText('device.error.table.reject.near.full')}</Col>
                    <Col span={6}>
                        {record.rejectNearFull ? (
                            <CheckOutlined style={{ color: 'green' }} />
                        ) : (
                            <CloseOutlined style={{ color: 'red' }} />
                        )}
                    </Col>
                </Row>

                <Row style={{ width: '300px' }}>
                    <Col span={16}>{geti18nText('device.error.table.reject.waiting')}</Col>
                    <Col span={6}>
                        {record.rejectWaiting ? (
                            <CheckOutlined style={{ color: 'green' }} />
                        ) : (
                            <CloseOutlined style={{ color: 'red' }} />
                        )}
                    </Col>
                </Row>
            </>
        );
    }

    const offices = () => {
        const manufacturers = NyUtils.load('OFFICE');
        let types: any = [];
        manufacturers.forEach((element: any) => {
            let ret: any = {};
            ret.id = element.name;
            ret.text = element.name;
            types.push(ret);
        });

        return types;
    };

    const deviceModels = () => {
        const models = NyUtils.load('DEVICE_MODELS');
        let types: any = [];
        models.forEach((element: any) => {
            let ret: any = {};
            ret.id = element.name;
            ret.text = element.name;
            types.push(ret);
        });
        return types;
    };

    function columns() {
        return [
            {
                title: geti18nText('device.error.table.id'),
                dataIndex: 'id',
                width: '3%',
                align: 'center',
                sorter: (a: any, b: any) => {},
                ...getColumnSearch('number'),
            },
            {
                title: geti18nText('device.error.table.error.code'),
                dataIndex: 'errorCode',
                width: '5%',
                align: 'center',
                sorter: (a: any, b: any) => {},
                ...getColumnSearch('string'),
            },
            {
                title: geti18nText('device.error.table.error'),
                width: '5%',
                dataIndex: 'error',
                align: 'center',
                sorter: (a: any, b: any) => {},
                ...getColumnSearch('string'),
            },
            {
                title: geti18nText('device.error.table.device.model'),
                dataIndex: ['deviceModel', 'name'],
                align: 'center',
                sorter: (a: any, b: any) => {},
                ...getColumnSearchOption(deviceModels()),
            },
            {
                title: geti18nText('device.error.table.office'),
                dataIndex: ['office', 'name'],
                align: 'center',
                sorter: (a: any, b: any) => {},
                ...getColumnSearchOption(offices()),
            },
            {
                title: geti18nText('device.error.table.serial'),
                dataIndex: 'deviceSerial',
                align: 'center',
                sorter: (a: any, b: any) => {},
                ...getColumnSearch('string'),
            },
            {
                title: geti18nText('device.error.table.inventory'),
                dataIndex: 'deviceInventory',
                align: 'center',
                sorter: (a: any, b: any) => {},
                ...getColumnSearch('string'),
            },
            {
                title: geti18nText('device.error.table.details'),
                dataIndex: 'countMode',
                width: '4%',
                align: 'center',
                render: (text: any, record: { [index: string]: any }) => {
                    if (
                        record.countMode ||
                        record.hopperExist ||
                        record.hopperFailFeed ||
                        record.rejectExists ||
                        record.rejectFull ||
                        record.rejectNearFull ||
                        record.rejectWaiting
                    ) {
                        return (
                            <Popover content={drawDetails(record)}>
                                <CheckOutlined style={{ color: 'green' }} />
                            </Popover>
                        );
                    } else {
                        return (
                            <Popover style={{ width: '200px' }} content={drawDetails(record)}>
                                <CloseOutlined style={{ color: 'red' }} />
                            </Popover>
                        );
                    }
                },
            },

            {
                title: geti18nText('device.error.table.error.time'),
                dataIndex: 'errorTime',
                width: '10%',
                align: 'center',
                sorter: (a: any, b: any) => {},
                render: (text: string, record: { [index: string]: any }) => {
                    if (record.errorTime) {
                        return (
                            <div>
                                {new Date(record.errorTime).toLocaleString(NyUtils.getSelectedLocale(), getTimezone())}
                            </div>
                        );
                    }
                },
                ...getColumnDateOption(true, undefined, undefined, true),
            },
        ];
    }

    if (loading) {
        return <NySpinner></NySpinner>;
    } else
        return (
            <>
                <Row>
                    {' '}
                    <Col span={20}>
                        <Title level={3}>{setTitle()}</Title>
                        {props.deviceGroup && setGroup()}
                    </Col>
                    <Col style={{ textAlign: 'right' }} span={4}>
                        <Button
                            shape="circle"
                            size="small"
                            style={{ border: 'none' }}
                            icon={<CloseOutlined />}
                            onClick={() => remove()}
                        />
                    </Col>
                </Row>
                <Row style={{ padding: '10px' }}>
                    <Col span={24}>
                        <NyDataTable
                            url={CONSTANTS_REQ.DEVICE_ERROR.LIST}
                            setDefaultPageSize={10}
                            exportCSV={true}
                            key={key}
                            showRecordModal={false}
                            onRowSelect={onRowClickHandler}
                            hideButtons={true}
                            showRowSelection={false}
                            scroll={{ y: 40 * props.h - 140 }}
                            setDefaultFilterValue={setDefaultFilterValue}
                            readonly={false}
                            columns={columns()}
                            colCSVCustomization={DeviceErrorCSVColCustomization()}
                            csvColumns={ErrorDeviceCSVColumnsExport()}
                            CSVFileName={'devices_error'}
                        />
                    </Col>
                </Row>
            </>
        );
};

export const DeviceErrorCSVColCustomization: any = () => {
    return [
        {
            errorTime: (value: string) => {
                return new Date(value).toLocaleString(NyUtils.getSelectedLocale(), getTimezone());
            },
        },
        {
            countMode: (value: boolean) => {
                return value ? 'TRUE' : 'FALSE';
            },
        },
        {
            countMode: (value: boolean) => {
                return value ? 'TRUE' : 'FALSE';
            },
        },
        {
            hopperExist: (value: boolean) => {
                return value ? 'TRUE' : 'FALSE';
            },
        },

        {
            hopperFailFeed: (value: boolean) => {
                return value ? 'TRUE' : 'FALSE';
            },
        },
        {
            rejectExists: (value: boolean) => {
                return value ? 'TRUE' : 'FALSE';
            },
        },
        {
            rejectFull: (value: boolean) => {
                return value ? 'TRUE' : 'FALSE';
            },
        },
        {
            rejectNearFull: (value: boolean) => {
                return value ? 'TRUE' : 'FALSE';
            },
        },
        {
            rejectWaiting: (value: boolean) => {
                return value ? 'TRUE' : 'FALSE';
            },
        },
    ];
};
export const ErrorDeviceCSVColumnsExport: any = () => {
    return [
        {
            title: geti18nText('device.error.table.id'),
            dataIndex: 'id',
        },
        {
            title: geti18nText('device.error.table.error.code'),
            dataIndex: 'errorCode',
        },
        {
            title: geti18nText('device.error.table.error'),
            dataIndex: 'error',
        },
        {
            title: geti18nText('device.error.table.device.model'),
            dataIndex: ['deviceModel', 'name'],
        },
        {
            title: geti18nText('device.error.table.office'),
            dataIndex: ['office', 'name'],
        },
        {
            title: geti18nText('device.error.table.serial'),
            dataIndex: 'deviceSerial',
        },
        {
            title: geti18nText('device.error.table.inventory'),
            dataIndex: 'deviceInventory',
        },
        {
            title: geti18nText('device.error.table.details'),
            dataIndex: 'countMode',
        },
        {
            title: geti18nText('device.error.table.count.mode'),
            dataIndex: 'countMode',
        },
        {
            title: geti18nText('device.error.table.hopper.exist'),
            dataIndex: 'hopperExist',
        },
        {
            title: geti18nText('device.error.table.hopper.fail.feed'),
            dataIndex: 'hopperFailFeed',
        },
        {
            title: geti18nText('device.error.table.reject.exist'),
            dataIndex: 'rejectExists',
        },
        {
            title: geti18nText('device.error.table.reject.full'),
            dataIndex: 'rejectFull',
        },
        {
            title: geti18nText('device.error.table.reject.near.full'),
            dataIndex: 'rejectNearFull',
        },
        {
            title: geti18nText('device.error.table.reject.waiting'),
            dataIndex: 'rejectWaiting',
        },
        {
            title: geti18nText('device.error.table.error.time'),
            dataIndex: 'errorTime',
        },
    ];
};
