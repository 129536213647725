import { geti18nText, NySession, NyUtils } from '@nybble/nyreact';
import { GetEnumNameForValue } from '../../../utils/Enums';
import { getTimezone } from '../../../utils/Utils';

const DeviceCSVColCistomization: any = () => {
    return [
        {
            communicationType: (value: string) => {
                return GetEnumNameForValue({ enumName: 'COMMUNICATION_TYPE', value: value })?.toString();
            },
        },
        {
            lastActivity: (value: string) => {
                return value ? new Date(value).toLocaleString(NyUtils.getSelectedLocale(), getTimezone()) : '';
            },
        },
        {
            groupId: (value: string) => {
                if (value == undefined) return '';
                return value.replaceAll('||', ', ');
            },
        },
        {
            'model.type': (value: any) => {
                return geti18nText(
                    'device.model.type.' +
                        GetEnumNameForValue({
                            enumName: 'DEVICE_TYPE',
                            value: value,
                        })?.toLowerCase()
                );
            },
        },
        {
            warranty_date: (value: string) => {
                return value != null ? new Date(value).toLocaleString(NyUtils.getSelectedLocale(), getTimezone()) : '';
            },
        },
        {
            hnbDeviceType: (value: any) => {
                return geti18nText('hnb.device.type.' + value);
            },
        },
        {
            dailyReport: (value: any) => {
                return value == true
                    ? geti18nText('device.edit.hnb.report.yes')
                    : geti18nText('device.edit.hnb.report.no');
            },
        },
        {
            monthlyReport: (value: any) => {
                return value == true
                    ? geti18nText('device.edit.hnb.report.yes')
                    : geti18nText('device.edit.hnb.report.no');
            },
        },
        {
            maintenanceContractDate: (value: string) => {
                return value != null ? new Date(value).toLocaleString(NyUtils.getSelectedLocale(), getTimezone()) : '';
            },
        },
        {
            active: (value: boolean) => {
                if (value === true) {
                    return geti18nText('device.active.label');
                } else {
                    return geti18nText('device.inactive.label');
                }
            },
        },
        {
            ecb: (value: boolean) => {
                if(value === true) {
                    return geti18nText('device.active.label')
                }
                else {
                    return geti18nText('device.inactive.label')
                }
            },
        },
        
        {
        writtenOff: (value: boolean) => {
            if(value === true) {
                return geti18nText('device.active.label')
            }
            else {
                return geti18nText('device.inactive.label')
            }
        },
        },
        {
        correct: (value: boolean) => {
            if(value === true) {
                return geti18nText('device.active.label')
            }
            else {
                return geti18nText('device.inactive.label')
            }
        }},
        {
        repair: (value: boolean) => {
            if(value === true) {
                return geti18nText('device.active.label')
            }
            else {
                return geti18nText('device.inactive.label')
            }
        },
        
    },
    {
        "comment" : (value:string)=>{
            return value;
        }
    },
    ];
};
export default DeviceCSVColCistomization;
