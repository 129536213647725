import { CheckOutlined, CloseOutlined, DownloadOutlined, UploadOutlined } from '@ant-design/icons';
import {
    ENUMS,
    getColumnSearch,
    getColumnSearchOption,
    geti18nText,
    NyDataTable,
    NyRequestResolver,
    NySession,
    NyUtils,
    RESPONSE,
} from '@nybble/nyreact';
import { Button, notification, Tabs, Upload } from 'antd';
import React, { useState } from 'react';
import { CONSTANTS_REQ } from '../../../utils/Constants';
import TableSettings from '../../table_settings';
import AdminCurrencyEdit from './edit';
const AdminCurrencyIndex = () => {
    const { TabPane } = Tabs;
    const [loading, setLoading] = useState(false);
    const [loadingImport, setLoadingImport] = useState(false);
    const [imported, setImported] = useState(0);
    const table = TableSettings();
    function setDefaultFilterValue() {
        return [{ field: 'active', condition: 'equals_bool', value: 1 }];
    }

    function exportData() {
        setLoading(true);
        NyRequestResolver.requestGet(CONSTANTS_REQ.CURRENCY.EXPORT, undefined, false, true).then((result) => {
            setLoading(false);
            if (result && result.status === RESPONSE.OK) {
                downloadFile(result);
                setLoading(false);
                okNotification(geti18nText('currency.denomination.export.OK'));
            } else {
                console.log(JSON.stringify(result.data));
                error(JSON.stringify(result.data));
            }
        });
    }

    function downloadFile(result: any) {
        NyUtils.downloadFile(result);
    }

    function okNotification(message: any) {
        notification['success']({
            message: message,
            duration: 10,
        });
    }

    function error(message: any) {
        notification['error']({
            message: geti18nText('reports.download.nok'),
            description: message,
            duration: 0,
        });
    }

    function importData(file: any) {
        setLoadingImport(true);
        var formData = new FormData();
        formData.append('file', file.file);

        NyRequestResolver.requestPostFile(CONSTANTS_REQ.CURRENCY.IMPORT, undefined, formData).then((result: any) => {
            if (result && result.status === RESPONSE.OK) {
                let im = imported;
                im++;
                setImported(im);
                file.onSuccess('');
                okNotification(geti18nText('currency.denomination.import.data.OK'));
                setLoadingImport(false);
            } else {
                console.log(JSON.stringify(result.data));
                error(geti18nText('currency.denomination.import.data.NOK'));
                setLoadingImport(false);
            }
        });
    }

    return (
        <>
            <Button type="primary" loading={loading} onClick={exportData} icon={<DownloadOutlined />}>
                {' '}
                {geti18nText('currency.download')}
            </Button>
            <Upload customRequest={importData} showUploadList={false}>
                <Button type="primary" loading={loadingImport} icon={<UploadOutlined />} style={{ marginLeft: '10px' }}>
                    {geti18nText('currency.upload')}
                </Button>
            </Upload>

            <NyDataTable
                headerTitle={geti18nText('currency.table.header')}
                url={CONSTANTS_REQ.CURRENCY.LIST}
                showRecordModal={true}
                addNewButtonText={geti18nText('currency.add.new')}
                modalComponent={AdminCurrencyEdit}
                setDefaultPageSize={table.setDefaultPageSize(20)}
                onDataLoaded={table.onLoadData}
                setDefaultFilterValue={() => table.setDefaultFilterValue(setDefaultFilterValue())}
                setDefaultCurrentPage={table.setDefaultCurrentPage()}
                setDefaultSortOrder={table.setDefaultSortOrder()}
                fetchWhenChange={imported}
                hideButtons={!NySession.hasAnyRole(['RULE_CRUD_BRANCH'])}
                exportCSV={true}
                columns={[
                    {
                        title: geti18nText('currency.table.column.id'),
                        dataIndex: 'id',
                        width: '10%',
                        sorter: (a: any, b: any) => {},
                        ...getColumnSearch('number'),
                    },
                    {
                        title: geti18nText('currency.table.column.currency.code'),
                        dataIndex: 'currencyCode',
                        sorter: (a: any, b: any) => {},
                        ...getColumnSearch('string'),
                    },
                    {
                        title: geti18nText('currency.table.column.iso.code'),
                        dataIndex: 'isoCode',
                        sorter: (a: any, b: any) => {},
                        ...getColumnSearch('string'),
                    },

                    {
                        title: geti18nText('office.table.column.active'),
                        dataIndex: 'active',
                        width: '10%',
                        render: (text: any, record: { active: any }) => {
                            if (record.active) {
                                return <CheckOutlined style={{ color: 'green' }} />;
                            } else {
                                return <CloseOutlined style={{ color: 'red' }} />;
                            }
                        },
                        ...getColumnSearchOption(ENUMS.ACTIVE(), 'equals_bool', 1),
                    },
                ]}
            />
        </>
    );
};

export default AdminCurrencyIndex;
