import { geti18nText, NyDataEdit } from '@nybble/nyreact';
import { Col, Form, Input, Row, Tabs } from 'antd';
import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { CONSTANTS_REQ } from '../../../utils/Constants';

const AdminReferenceEdit = (props: any) => {
    const [editHeader, setEditHeader] = useState(geti18nText('reference.add.new'));
    const [loading, setLoading] = useState(false);
    const { id } = useParams<any>();
    const history = useHistory();
    const [form] = Form.useForm();
    const { TabPane } = Tabs;
    const { TextArea } = Input;

    const onModalClose = () => {
        setEditHeader(geti18nText('reference.add.new'));
        form.resetFields();
    };
    function setValues(dataForm: any) {
        setEditHeader(geti18nText('reference.add.edit'));
        form.setFieldsValue(dataForm);
    }

    function onBeforeSave() {
        form.setFieldsValue({ deviceId: props.addedData.deviceId });
    }

    return (
        <NyDataEdit
            layout="horizontal"
            formProps={{ labelCol: { span: 5 }, wrapperCol: { span: 15 } }}
            editHeader={editHeader}
            loading={loading}
            setLoading={setLoading}
            onModalClose={onModalClose}
            hideActivationButtons={true}
            url={CONSTANTS_REQ.REFERENCES.EDIT}
            setValues={setValues}
            width={800}
            form={form}
            goBack={() => history.goBack()}
            onBeforeSave={onBeforeSave}
            paramsId={id}
            {...props}
            hideButtons={false}
        >
            <Row>
                <Col span={24}>
                    <Form.Item name="id" style={{ display: 'none' }}>
                        <Input readOnly={true} />
                    </Form.Item>
                    <Form.Item name="deviceId" style={{ display: 'none' }}>
                        <Input readOnly={true} />
                    </Form.Item>
                    <Form.Item
                        rules={[
                            {
                                required: true,
                                message: geti18nText('app.default.required'),
                            },
                        ]}
                        label={geti18nText('references.table.column.text')}
                        name="text"
                    >
                        <TextArea autoFocus={true} rows={3} />
                    </Form.Item>
                </Col>
            </Row>
        </NyDataEdit>
    );
};

export default AdminReferenceEdit;
