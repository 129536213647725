import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import {
    ENUMS,
    getColumnDateOption,
    getColumnSearch,
    getColumnSearchOption,
    geti18nText,
    NyDataTable,
    NySession,
    NyUtils
} from '@nybble/nyreact';
import { Tabs } from 'antd';
import React from 'react';
import { CONSTANTS_REQ } from '../../../utils/Constants';
import TableSettings from '../../table_settings';
import { getTimezone } from '../../../utils/Utils';
import VendorEdit from './edit';
const AdminVendorIndex = () => {
    const { TabPane } = Tabs;
    const table = TableSettings();
    function setDefaultFilterValue() {
        return [{ field: 'active', condition: 'equals_bool', value: 1 }];
    }

    return (
        <NyDataTable
            headerTitle={geti18nText('vendor.table.header')}
            url={CONSTANTS_REQ.VENDOR.LIST}
            showRecordModal={true}
            addNewButtonText={geti18nText('vendor.add.new')}
            hideButtons={!NySession.hasAnyRole(['RULE_ADD_SERVICE_VENDOR'])}
            modalComponent={VendorEdit}
            exportCSV={true}
            setDefaultPageSize={table.setDefaultPageSize(20)}
            onDataLoaded={table.onLoadData}
            setDefaultFilterValue={() => table.setDefaultFilterValue(setDefaultFilterValue())}
            setDefaultCurrentPage={table.setDefaultCurrentPage()}
            setDefaultSortOrder={table.setDefaultSortOrder()}
            columns={[
                {
                    title: geti18nText('vendor.table.id'),
                    dataIndex: 'id',
                    width: '10%',
                    sorter: (a: any, b: any) => {},
                    ...getColumnSearch('number'),
                },
                {
                    title: geti18nText('vendor.table.name'),
                    dataIndex: 'name',
                    sorter: (a: any, b: any) => {},
                    ...getColumnSearch('string'),
                },
                {
                    title: geti18nText('vendor.table.created'),
                    dataIndex: 'created',
                    width: '20%',
                    sorter: (a: any, b: any) => {},
                    ...getColumnDateOption(true,undefined,undefined,true),
                    render: (text: string, record: { [index: string]: any }) => {
                        if (record.created) {
                            return (
                                <div>
                                    {new Date(record.created).toLocaleString(NyUtils.getSelectedLocale(), getTimezone())}
                                </div>
                            );
                        }
                    },
                },

                {
                    title: geti18nText('vendor.table.active'),
                    dataIndex: 'active',
                    width: '10%',
                    render: (text: any, record: { active: any }) => {
                        if (record.active) {
                            return <CheckOutlined style={{ color: 'green' }} />;
                        } else {
                            return <CloseOutlined style={{ color: 'red' }} />;
                        }
                    },
                    ...getColumnSearchOption(ENUMS.ACTIVE(), 'equals_bool', 1),
                },
            ]}
        />
    );
};

export default AdminVendorIndex;
