import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import {
    ENUMS,
    getColumnDateOption,
    getColumnSearch,
    getColumnSearchOption,
    geti18nText,
    NyDataTable,
    NySession,
} from '@nybble/nyreact';
import { Tabs } from 'antd';
import React, { useState } from 'react';
import { CONSTANTS_REQ } from '../../utils/Constants';
import RoleEdit from './edit';
const UserRoleIndex = () => {
    const { TabPane } = Tabs;

    function setDefaultFilterValue() {
        return [{ field: 'active', condition: 'equals_bool', value: 1 }];
    }
    return (
        <NyDataTable
            headerTitle={geti18nText('roles.table.header')}
            url={CONSTANTS_REQ.ROLE.LIST}
            showRecordModal={true}
            addNewButtonText={geti18nText('roles.table.add')}
            modalComponent={RoleEdit}
            setDefaultPageSize={20}
            setDefaultFilterValue={setDefaultFilterValue}
            hideButtons={!NySession.hasAnyRole(['RULE_ADD_USER_ROLE'])}
            exportCSV={true}
            columns={[
                {
                    title: geti18nText('roles.table.id'),
                    dataIndex: 'id',
                    width: '10%',
                    sorter: (a: any, b: any) => {},
                    ...getColumnSearch('number'),
                },
                {
                    title: geti18nText('roles.table.roles'),
                    dataIndex: 'authority',
                    sorter: (a: any, b: any) => {},
                    ...getColumnSearch('string'),
                },
                {
                    title: geti18nText('roles.table.active'),
                    dataIndex: 'active',
                    width: '10%',
                    render: (text: any, record: { active: any }) => {
                        if (record.active) {
                            return <CheckOutlined style={{ color: 'green' }} />;
                        } else {
                            return <CloseOutlined style={{ color: 'red' }} />;
                        }
                    },
                    ...getColumnSearchOption(ENUMS.ACTIVE(), 'equals_bool', 1),
                },
            ]}
        />
    );
};

export default UserRoleIndex;
