import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import {
    ENUMS,
    getColumnDateOption,
    getColumnSearch,
    getColumnSearchOption,
    geti18nText,
    NyDataTable,
    NySession,
} from '@nybble/nyreact';
import { Popover, Tabs, Typography } from 'antd';
import React from 'react';
import { CONSTANTS_REQ } from '../../../utils/Constants';
import { GetEnum, GetEnumNameForValue } from '../../../utils/Enums';
import TableSettings from '../../table_settings';
import AdminNotificationAlarmEdit from './edit';
import SentNotificationIndex from './sent_notification';

const { Text } = Typography;

const AdminNotificationAlarmIndex = () => {
    const { TabPane } = Tabs;
    const table = TableSettings();

    function setDefaultFilterValue() {
        return [{ field: 'active', condition: 'equals_bool', value: 1 }];
    }
    const notificationAlarmTypes = () => {
        const aTypes = GetEnum({ enumName: 'NOTIFICATION_ALARM_TYPE' });
        let types = [];
        for (var key in aTypes) {
            if (aTypes.hasOwnProperty(key)) {
                let ret = { id: undefined, text: '' };
                if (key !== 'SYSTEM_WARNING_LICENSE_EXPIRATION') {
                    ret.id = aTypes[key];
                    ret.text = geti18nText('app.enum.NOTIFICATION_ALARM_TYPE.' + aTypes[key]);
                    types.push(ret);
                }
            }
        }
        return types;
    };

    return (
        <Tabs defaultActiveKey="1" type="card">
            <TabPane tab={geti18nText('tab.notification.alarm')} key="1">
                <NyDataTable
                    headerTitle={geti18nText('notification.alarm.table.header')}
                    url={CONSTANTS_REQ.NOTIFICATION_ALARM.LIST}
                    showRecordModal={true}
                    addNewButtonText={geti18nText('notification.alarm.add.new')}
                    hideButtons={!NySession.hasAnyRole(['RULE_CRUD_NOTIFICATION'])}
                    modalComponent={AdminNotificationAlarmEdit}
                    exportCSV={true}
                    setDefaultPageSize={table.setDefaultPageSize(20)}
                    onDataLoaded={table.onLoadData}
                    setDefaultFilterValue={() => table.setDefaultFilterValue(setDefaultFilterValue())}
                    setDefaultCurrentPage={table.setDefaultCurrentPage()}
                    setDefaultSortOrder={table.setDefaultSortOrder()}
                    colCSVCustomization={[
                        {
                            notificationType: (value: any) => {
                                return GetEnumNameForValue({ enumName: 'NOTIFICATION_ALARM_TYPE', value: value });
                            },
                        },
                        {
                            notificationClass: (value: any) => {
                                return GetEnumNameForValue({ enumName: 'NOTIFICATION_ALARM_CLASS_TYPE', value: value });
                            },
                        },
                    ]}
                    columns={[
                        {
                            title: geti18nText('notification.alarm.table.id'),
                            dataIndex: 'id',
                            width: '10%',
                            sorter: (a: any, b: any) => {},
                            ...getColumnSearch('number'),
                        },
                        {
                            title: geti18nText('notification.alarm.table.recipient'),
                            dataIndex: 'recipients',
                            render: (text: string, record: { [index: string]: any }) => {
                                if (text == undefined) {
                                    return <></>;
                                } else {
                                    var array = text.split('||');

                                    return (
                                        <>
                                            {array != null && array.length > 1 && (
                                                <Popover
                                                    placement="left"
                                                    content={
                                                        <div>
                                                            {array.map((item: any) => {
                                                                return (
                                                                    <div>
                                                                        <Text>{item}</Text>
                                                                    </div>
                                                                );
                                                            })}
                                                        </div>
                                                    }
                                                    overlayStyle={{
                                                        whiteSpace: 'pre-line',
                                                        maxHeight: '40vh',
                                                        overflowY: 'auto',
                                                        border: '1px solid #E0E0E0',
                                                    }}
                                                >
                                                    <div>{array[0] + ' ... '}</div>
                                                </Popover>
                                            )}
                                            {array != null && array.length == 1 && <div>{array[0]}</div>}
                                        </>
                                    );
                                }
                            },
                        },
                        {
                            title: geti18nText('notification.alarm.table.note.alarm.type'),
                            dataIndex: 'notificationType',
                            sorter: (a: any, b: any) => {},

                            render: (text: string, record: { [index: string]: any }) => {
                                return (
                                    <div>
                                        {geti18nText('app.enum.NOTIFICATION_ALARM_TYPE.' + record.notificationType)}
                                    </div>
                                );
                            },
                            ...getColumnSearchOption(notificationAlarmTypes()),
                        },

                        {
                            title: geti18nText('notification.alarm.table.note.alarm.device'),
                            dataIndex: ['device', 'name'],
                            sorter: (a: any, b: any) => {},
                            ...getColumnSearch('string'),
                        },
                        {
                            title: geti18nText('notification.alarm.table.note.alarm.office'),
                            dataIndex: ['office', 'name'],
                            sorter: (a: any, b: any) => {},
                            ...getColumnSearch('string'),
                        },
                        {
                            title: geti18nText('notification.alarm.table.note.alarm.model'),
                            dataIndex: ['deviceModel', 'name'],
                            sorter: (a: any, b: any) => {},
                            ...getColumnSearch('string'),
                        },
                        {
                            title: geti18nText('notification.alarm.table.note.alarm.group'),
                            dataIndex: ['deviceGroup', 'name'],
                            sorter: (a: any, b: any) => {},
                            ...getColumnSearch('string'),
                        },
                        {
                            title: geti18nText('notification.alarm.table.note.alarm.language'),
                            dataIndex: 'language',
                            sorter: (a: any, b: any) => {},
                            ...getColumnSearch('string'),
                        },
                        {
                            title: geti18nText('notification.alarm.table.note.alarm.active'),
                            dataIndex: 'active',
                            width: '10%',
                            render: (text: any, record: { active: any }) => {
                                if (record.active) {
                                    return <CheckOutlined style={{ color: 'green' }} />;
                                } else {
                                    return <CloseOutlined style={{ color: 'red' }} />;
                                }
                            },
                            ...getColumnSearchOption(ENUMS.ACTIVE(), 'equals_bool', 1),
                        },
                    ]}
                />
            </TabPane>
            <TabPane tab={geti18nText('tab.notification.alarm.sent')} key="2">
                <SentNotificationIndex></SentNotificationIndex>
            </TabPane>
        </Tabs>
    );
};

export default AdminNotificationAlarmIndex;
