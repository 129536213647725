import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import {
    ENUMS,
    getColumnDateOption,
    getColumnSearch,
    getColumnSearchOption,
    geti18nText,
    NyDataTable,
    NySession,
    NyUtils,
} from '@nybble/nyreact';
import { Tabs } from 'antd';
import React from 'react';
import { CONSTANTS_REQ } from '../../../utils/Constants';
import { GetEnum, GetEnumNameForValue } from '../../../utils/Enums';
import { getTimezone } from '../../../utils/Utils';
import AdminSentNotificationView from './sent_notification_view';
const SentNotificationIndex = () => {
    const { TabPane } = Tabs;

    function setDefaultFilterValue() {
        return [];
    }

    const notificationAlarmTypes = () => {
        const aTypes = GetEnum({ enumName: 'NOTIFICATION_ALARM_TYPE' });
        let types = [];
        for (var key in aTypes) {
            if (aTypes.hasOwnProperty(key)) {
                let ret = { id: undefined, text: '' };
                if (key !== 'SYSTEM_WARNING_LICENSE_EXPIRATION') {
                    ret.id = aTypes[key];
                    ret.text = geti18nText('app.enum.NOTIFICATION_ALARM_TYPE.' + aTypes[key]);
                    types.push(ret);
                }
            }
        }
        return types;
    };
    return (
        <NyDataTable
            headerTitle={''}
            url={CONSTANTS_REQ.NOTIFICATION.LIST}
            showRecordModal={true}
            addNewButtonText={geti18nText('office.add.new')}
            setDefaultPageSize={20}
            modalComponent={AdminSentNotificationView}
            hideButtons={true}
            exportCSV={true}
            // setDefaultFilterValue={setDefaultFilterValue}
            columns={[
                {
                    title: geti18nText('notification.alarm.sent.id'),
                    dataIndex: 'id',
                    width: '5%',
                    align: 'center',
                    sorter: (a: any, b: any) => {},
                    ...getColumnSearch('number'),
                },
                {
                    title: geti18nText('notification.alarm.sent.note.alarm.type'),
                    dataIndex: 'notificationType',
                    width: '10%',
                    align: 'center',
                    sorter: (a: any, b: any) => {},

                    render: (text: string, record: { [index: string]: any }) => {
                        return <div>{geti18nText('app.enum.NOTIFICATION_ALARM_TYPE.' + record.notificationType)}</div>;
                    },
                    ...getColumnSearchOption(notificationAlarmTypes()),
                },
                {
                    title: geti18nText('notification.alarm.sent.device.name'),
                    dataIndex: ['d', 'name'],
                    width: '10%',
                    align: 'center',
                    render: (text: string, record: { [index: string]: any }) => {
                        if (record.notificationType != 6 && record.notificationType != 7) {
                            return <div>{record.d?.name}</div>;
                        }
                        return <div></div>;
                    },
                    sorter: (a: any, b: any) => {},
                    ...getColumnDateOption(true, undefined, undefined, true),
                },
                {
                    title: geti18nText('notification.alarm.sent.created'),
                    dataIndex: 'created',
                    width: '10%',
                    align: 'center',
                    sorter: (a: any, b: any) => {},
                    render: (text: string, record: { [index: string]: any }) => {
                        if (record.created) {
                            return (
                                <div>
                                    {new Date(record.created).toLocaleString(
                                        NyUtils.getSelectedLocale(),
                                        getTimezone()
                                    )}
                                </div>
                            );
                        }
                    },
                    // ...getColumnDateOption(true),
                },
                {
                    title: geti18nText('notification.alarm.sent.readed'),
                    dataIndex: 'unread',
                    width: '5%',
                    align: 'center',
                    render: (text: any, record: { unread: any }) => {
                        if (!record.unread) {
                            return <CheckOutlined style={{ color: 'green' }} />;
                        } else {
                            return <CloseOutlined style={{ color: 'red' }} />;
                        }
                    },
                    ...getColumnSearchOption(ENUMS.ACTIVE(), 'equals_bool', 1),
                },
                {
                    title: geti18nText('notification.alarm.sent.recipient'),
                    align: 'center',
                    dataIndex: "concat(u.first_name,' ', u.last_name)",
                    width: '10%',
                    render: (text: string, record: { [index: string]: any }) => {
                        if (record.user) {
                            return <div>{record.user.firstname + ' ' + record.user.lastname}</div>;
                        }
                    },
                    sorter: (a: any, b: any) => {},
                    ...getColumnSearch('string'),
                },
            ]}
        />
    );
};

export default SentNotificationIndex;
