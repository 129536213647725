import { ArrowDownOutlined, ArrowUpOutlined, CloseOutlined, DashboardOutlined, FileOutlined } from '@ant-design/icons';
import { geti18nText, NyRequestResolver, NySearchField, NySpinner, NyUtils, RESPONSE } from '@nybble/nyreact';
import { Button, Card, Col, Form, Modal, Popover, Row, Statistic, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { CONSTANTS_REQ } from '../../utils/Constants';
import { setModalOpen } from '../../slices/modalOpenSlice';
import DeviceIndex from '../device';

export const DeviceNumber = (props: any) => {
    const [deviceNumber, setDeviceNumber] = useState<any>(undefined);
    const [loading, setLoading] = useState(false);
    const [activeNumber, setActiveNumber] = useState<any>(undefined);
    const [ftpNumber, setFtpNumber] = useState<any>(undefined);
    const [notActiveNumber, setNotActiveNumber] = useState<any>(undefined);
    const [modalVisible, setModalVisible] = useState(false);
    const [groupIds, setGroupIds] = useState<any>(null);
    const { Text, Link, Title } = Typography;
    const [status, setstatus] = useState(null);

    useEffect(() => {
        fetchData();
        let groups = props.groups?.map((g: any) => {
            return g.id;
        });
        setGroupIds(groups);
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            fetchData();
        }, 5 * 60 * 1000);

        return () => {
            clearInterval(interval);
        };
    }, []);

    function remove() {
        props.onRemoveWidget(props.widgetKey);
    }

    function fetchData() {
        setLoading(true);
        let deviceGroups = props.groups
            ? props.groups.map((g: any) => {
                  return g.id;
              })
            : [];

        NyRequestResolver.requestGet(
            CONSTANTS_REQ.DASHBOARD.DEVICE_NUMBER,
            deviceGroups.length > 0
                ? {
                      deviceGroups: deviceGroups,
                  }
                : undefined
        ).then((result: any) => {
            if (result.status === RESPONSE.OK) {
                setDeviceNumber(result.data.deviceNumber);
                setActiveNumber(result.data.active);
                setNotActiveNumber(result.data.notActive);
                setFtpNumber(result.data.ftp);
                setLoading(false);
            } else {
                setLoading(false);
            }
        });
    }

    function titleDevice() {
        return <div className="statistic-title">{geti18nText('dashboard.devices.number.title')}</div>;
    }

    function titleActiveDevice() {
        return <div className="statistic-title">{geti18nText('dashboard.active.neactive.title.active')}</div>;
    }

    function titleNotActive() {
        return <div className="statistic-title">{geti18nText('dashboard.active.neactive.title.neactive')}</div>;
    }

    function titleFtpDevice() {
        return <div className="statistic-title">{geti18nText('dashboard.active.ftp.title')}</div>;
    }

    function titleGroups() {
        let groups = props.groups
            .map((g: any) => {
                return g.name;
            })
            .join(', ');
        return (
            <Popover content={<Text type="secondary"> {groups} </Text>} trigger="hover">
                <Text type="secondary"> {groups} </Text>
            </Popover>
        );
    }

    if (loading) {
        return <NySpinner></NySpinner>;
    }

    function onCancel() {
        setModalVisible(false);
    }

    function openDeviceModal(status: any) {
        setstatus(status);
        setModalVisible(true);
    }

    return (
        <>
            {props.groups && props.groups.length > 0 && (
                <Row>
                    <Title level={4}>{titleGroups()}</Title>
                </Row>
            )}
            <Row gutter={16} style={{ height: 'calc(100% - 10px)' }}>
                <Col span={6}>
                    <Card
                        style={{ height: 'calc(100% - 10px)', cursor: 'pointer' }}
                        onClick={() => openDeviceModal(null)}
                    >
                        <Statistic
                            title={titleDevice()}
                            value={deviceNumber ? NyUtils.formatNumber(deviceNumber) : undefined}
                            valueStyle={{ color: '#3f8600', fontSize: 30 }}
                            prefix={<DashboardOutlined />}
                        />
                    </Card>
                </Col>
                <Col span={6}>
                    <Card style={{ height: 'calc(100% - 10px)', cursor: 'pointer' }} onClick={() => openDeviceModal(0)}>
                        <Statistic
                            title={titleActiveDevice()}
                            value={activeNumber ? NyUtils.formatNumber(activeNumber) : undefined}
                            valueStyle={{ color: '#3f8600', fontSize: 30 }}
                            prefix={<ArrowUpOutlined />}
                        />
                    </Card>
                </Col>
                <Col span={6}>
                    <Card style={{ height: 'calc(100% - 10px)', cursor: 'pointer' }} onClick={() => openDeviceModal(1)}>
                        <Statistic
                            title={titleNotActive()}
                            value={notActiveNumber ? NyUtils.formatNumber(notActiveNumber) : undefined}
                            valueStyle={{ color: '#cf1322', fontSize: 30 }}
                            prefix={<ArrowDownOutlined />}
                        />
                    </Card>
                </Col>
                <Col span={6}>
                    <Card style={{ height: 'calc(100% - 10px)', cursor: 'pointer' }} onClick={() => openDeviceModal(2)}>
                        <Row>
                            <Col style={{ marginBottom: 1 }} span={20}>
                                <Statistic
                                    title={titleFtpDevice()}
                                    value={ftpNumber ? NyUtils.formatNumber(ftpNumber) : undefined}
                                    valueStyle={{ color: 'orange', fontSize: 30 }}
                                    prefix={<FileOutlined />}
                                />
                            </Col>
                            <Col style={{ textAlign: 'right' }} span={4}>
                                <Button
                                    shape="circle"
                                    size="small"
                                    style={{ border: 'none' }}
                                    icon={<CloseOutlined />}
                                    onClick={() => remove()}
                                />
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
            <Modal visible={modalVisible} onCancel={onCancel} destroyOnClose={true} width={'100vw'}>
                <DeviceIndex isAdmin={true} groupIds={groupIds} status={status} useParser={true}></DeviceIndex>
            </Modal>
        </>
    );
};

export const DeviceNumbersProps = (props: any) => {
    const [addButtonDisabled, setAddButtonDisabled] = useState(false);

    function setSettings() {
        let settings = { groups: props.form.getFieldValue('groups') };
        props.addWidget(settings);
    }

    return (
        <>
            <Row>
                <Col span={24}>
                    <Form.Item name="groups" label={geti18nText('dashboard.widget.counting.number.device.groups')}>
                        <NySearchField
                            url={CONSTANTS_REQ.DEVICE_GROUP.SEARCH}
                            map={{ id: 'id', label: 'name' }}
                            searchBy="name"
                            mode="multiple"
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col span={24} style={{ textAlign: 'right' }}>
                    <Button
                        type="primary"
                        disabled={addButtonDisabled}
                        style={{ marginRight: '1em' }}
                        onClick={() => setSettings()}
                    >
                        {geti18nText('dashboard.widget.button.add')}
                    </Button>
                </Col>
            </Row>
        </>
    );
};
function dispatch(arg0: any) {
    throw new Error('Function not implemented.');
}
