import { CommentOutlined, PieChartOutlined } from '@ant-design/icons';
import {
    getColumnSearch,
    getColumnSearchOption,
    geti18nText,
    NyDataTable,
    NyRequestResolver,
    NySession,
    NyUtils,
    RESPONSE,
    getColumnDateOption,
} from '@nybble/nyreact';
import { Button, Col, Form, Popover, Radio, Row, Switch, Tabs, Tooltip, Typography } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { CONSTANTS_REQ } from '../../utils/Constants';
import { GetEnumNameForValue } from '../../utils/Enums';
import { formatCurrency, getTimezone } from '../../utils/Utils';
import { hex2a } from '../device-count-report-preview';
import TableSettings from '../table_settings';
import CountingDetailsIndex from './details';
import CountingDetailsGroupedIndex from './detailsGrouped';

const CountingIndex = (props: any) => {
    const { TabPane } = Tabs;
    const [form] = Form.useForm();
    const [typeGroup, setTypeGroup] = useState<any>('details');
    const [detailsVisible, setDetailsVisible] = useState<any>(false);
    const [detailsId, setDetailsId] = useState<any>(undefined);
    const [detailsOperator, setDetailsOperator] = useState<any>(undefined);
    const [detailsGroupId, setDetailsGroupId] = useState<any>(undefined);
    const [deviceId, setDeviceId] = useState<any>(undefined);
    const [refreshDetailsCount, setRefreshDetailsCount] = useState<any>(new Date().getUTCMilliseconds());
    const [detailDayMonthVisible, setDetailsDayMonthVisible] = useState<any>(false);
    const { Title } = Typography;
    const { Text } = Typography;
    const [refresh, setRefresh] = useState<any>(null);
    const [filterDate, setFilterDate] = useState<any>(getFilterDate);
    const table = TableSettings();
    const [tableKey, setTableKey] = useState<any>();
    const [filterLast3Days, setFilterLast3Days] = useState<boolean>(true);

    function getFilterDate() {
        let date = new Date();
        date.setDate(date.getDate() - 4);
        return date;
    }

    useEffect(() => {
        setFilterDate(getFilterDate());
        if (props.countigRefresh != null) {
            setRefresh(props.countigRefresh);
            //setTableKey(new Date().getTime());
        }
    }, [props.countigRefresh, filterLast3Days]);

    useEffect(() => {
        if (props.countigRefresh != null) {
            table.refresh();
            setTableKey(new Date().getTime());
        }
    }, [filterLast3Days]);

    function setDefaultFilterValue() {
        console.log('default filter ', filterLast3Days);
        if (filterLast3Days == true) {
            return [
                { field: 'created', condition: 'timestamp_from', value: moment(filterDate) },
                { field: 'device_id', condition: 'equals', value: props.value.id },
            ];
        }
        return [{ field: 'device_id', condition: 'equals', value: props.value.id }];
    }

    const options = [
        { label: geti18nText('counting.details.grouping'), value: 'details' },
        { label: geti18nText('counting.details.grouping.by.day'), value: 'day' },
        { label: geti18nText('counting.details.grouping.by.month'), value: 'month' },
    ];

    function onTypeChartChange(val: any) {
        if (val.target.value == 'day' || val.target.value == 'month') {
            setRefreshDetailsCount(new Date().getUTCMilliseconds());
        }
        setTypeGroup(val.target.value);
    }

    const currencyes = () => {
        const currency = NyUtils.load('CURRENCY');
        let types: any = [];
        currency.forEach((element: any) => {
            let ret: any = {};
            ret.id = element.currencyCode;
            ret.text = element.currencyCode;
            types.push(ret);
        });
        return types;
    };

    const DescriptionMod = (props: any) => {
        const [descriptionContent, setDescriptionContent] = useState<any>(undefined);
        function modDescription(countType: any, deviceModelId: any) {
            NyRequestResolver.requestGet(CONSTANTS_REQ.MODS.FIND_BY_NAME, {
                countType: countType,
                deviceModelId: deviceModelId,
            }).then((response: any) => {
                if (response.status === RESPONSE.OK) {
                    setDescriptionContent(response.data);
                } else {
                    setDescriptionContent(null);
                }
            });
        }
        return (
            <React.Fragment>
                <Popover
                    content={
                        <React.Fragment>
                            {descriptionContent && (
                                <React.Fragment>
                                    <div>{descriptionContent.description}</div>
                                </React.Fragment>
                            )}
                        </React.Fragment>
                    }
                    title={geti18nText('mods.table.edit.description')}
                >
                    <CommentOutlined
                        style={{ fontSize: '14px', color: '#3498DB' }}
                        onMouseOver={() => modDescription(props.countType, props.deviceModelId)}
                    />
                </Popover>
            </React.Fragment>
        );
    };

    function onRowDetails(record: any) {
        setDetailsId(record.id);
        setDetailsOperator(record.operator);
        setDetailsVisible(true);
    }

    function onRowGroupDetails(record: any) {
        setDetailsGroupId(record.id);
        setDeviceId(props.value.id);
        setDetailsDayMonthVisible(true);
    }

    function added() {
        return [
            { field: 'type', condition: 'equals', value: typeGroup },
            { field: 'deviceId', condition: 'equals', value: props.value.id },
            { field: 'language', condition: 'equals', value: NyUtils.getSelectedLanguage() },
        ];
    }

    function csvFileName() {
        const date = new Date();
        const formatDate = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();
        return geti18nText('counting.details.header') + '_' + formatDate;
    }

    function csvFileNameByType() {
        const date = new Date();
        const formatDate = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();
        return geti18nText('counting.details.header') + '_by_' + typeGroup + '_' + formatDate;
    }

    const csvColumns: any = () => {
        return [
            {
                title: geti18nText('count.data.table.id'),
                dataIndex: 'id',
            },
            {
                title: geti18nText('count.data.table.currency'),
                dataIndex: ['currency', 'currency_code'],
            },
            {
                title: geti18nText('count.data.table.counting.sum'),
                dataIndex: 'sumExport',
            },
            {
                title: geti18nText('count.data.table.counting.type'),
                dataIndex: 'countingType',
            },
            {
                title: geti18nText('count.data.table.start.time'),
                dataIndex: 'startTime',
            },
            {
                title: geti18nText('count.data.table.end.time'),
                dataIndex: 'endTime',
            },
            {
                title: geti18nText('count.data.table.operator'),
                dataIndex: 'operator',
            },
            {
                title: geti18nText('counting.report.raw.data'),
                dataIndex: 'rawData',
            },
            {
                title: geti18nText('count.data.table.created'),
                dataIndex: 'created',
            },
            {
                title: geti18nText('count.data.table.count_status'),
                dataIndex: 'countStatus',
            },
        ];
    };

    const DetailsCountingByTypeCSVColCustomization: any = () => {
        return [
            {
                groupedData: (value: any) => {
                    let formated = '';
                    value.forEach((v: any) => {
                        let currency_code = null;
                        if (v.currency_code) {
                            currency_code = v.currency_code;
                        }
                        formated =
                            formated +
                            ' ' +
                            formatCurrency(v.sum, currency_code) +
                            ' ' +
                            (currency_code ? currency_code : '') +
                            ';';
                    });

                    return formated;
                },
            },
        ];
    };

    function onSwichChange(checked: any) {
        setFilterLast3Days(checked);
    }

    const columns = () => {
        return [
            {
                title: geti18nText('count.data.table.id'),
                dataIndex: 'id',
                width: '5%',
                render: (text: string, record: { [index: string]: any }) => {
                    return record.countStatus && record.countStatus == 1 ? (
                        <Tooltip title={geti18nText('count.data.partial.counting')}>
                            <PieChartOutlined style={{ color: 'red', marginRight: '5px', fontSize: '15px' }} />

                            {record.id}
                        </Tooltip>
                    ) : (
                        <div style={{ textAlign: 'left', marginLeft: '17px' }}>{record.id}</div>
                    );
                },
                ...getColumnSearch('number'),
            },
            {
                title: geti18nText('count.data.table.currency'),
                dataIndex: ['currency', 'currency_code'],
                width: '5%',

                ...getColumnSearchOption(currencyes()),
            },
            {
                title: geti18nText('count.data.table.counting.sum'),
                dataIndex: 'sumExport',
                align: 'right',

                render: (text: string, record: { [index: string]: any }) => {
                    if (record.sum) {
                        return formatCurrency(record.sum, record.currency?.currency_code);
                    }
                },
            },
            {
                title: geti18nText('count.data.table.counting.type'),
                dataIndex: 'countingType',

                ...getColumnSearch('string'),
                render: (text: string, record: { [index: string]: any }) => {
                    if (record.countingType) {
                        return (
                            <div>
                                <div style={{ display: 'inline' }}>{record.countingType}</div>
                                {NySession.getSetting('mod')['exist'] === true && props.value?.model != undefined && (
                                    <div style={{ display: 'inline', marginLeft: '5px' }}>
                                        {
                                            <DescriptionMod
                                                deviceModelId={props.value.model.id}
                                                countType={record.countingType}
                                            ></DescriptionMod>
                                        }
                                    </div>
                                )}
                            </div>
                        );
                    }
                },
            },

            {
                title: geti18nText('count.data.table.start.time'),
                dataIndex: 'startTime',

                sorter: (a: any, b: any) => {},
                render: (text: string, record: { [index: string]: any }) => {
                    if (record.startTime) {
                        return (
                            <div>
                                {new Date(record.startTime).toLocaleString(NyUtils.getSelectedLocale(), getTimezone())}
                            </div>
                        );
                    }
                },
                ...getColumnDateOption(true, undefined, undefined, true),
            },
            {
                title: geti18nText('count.data.table.end.time'),
                dataIndex: 'endTime',

                render: (text: string, record: { [index: string]: any }) => {
                    if (record.endTime) {
                        return (
                            <div>
                                {new Date(record.endTime).toLocaleString(NyUtils.getSelectedLocale(), getTimezone())}
                            </div>
                        );
                    }
                },
                ...getColumnDateOption(true, undefined, undefined, true),
            },
            {
                title: geti18nText('count.data.table.operator'),
                dataIndex: 'operator',
                width: '10%',
                ...getColumnSearch('string'),
            },
            {
                title: geti18nText('counting.report.raw.data'),
                dataIndex: 'rawData',
                width: '15%',
                sorter: (a: any, b: any) => {},
                render: (value: any, record: any) => {
                    const text = b64ToUtf8(value);
                    return (
                        <>
                            {text && text.length > 40 && (
                                <Popover
                                    content={
                                        <Col
                                            span={24}
                                            style={{
                                                padding: '20px',
                                                whiteSpace: 'pre-wrap',
                                                overflowY: 'scroll',
                                                maxWidth: '55vw',
                                                height: '40vh',
                                                background: 'rgba(0,0,0,0.1)',
                                            }}
                                        >
                                            {text}
                                        </Col>
                                    }
                                >
                                    <Text>{text.substring(0, 40)}...</Text>
                                </Popover>
                            )}
                            {(text == null || text.length <= 40) && <Text>{text}</Text>}
                        </>
                    );
                },
                ...getColumnSearch('string'),
            },
            {
                title: geti18nText('count.data.table.created'),
                dataIndex: 'created',
                render: (text: string, record: { [index: string]: any }) => {
                    if (record.created) {
                        return (
                            <div>
                                {new Date(record.created).toLocaleString(NyUtils.getSelectedLocale(), getTimezone())}
                            </div>
                        );
                    }
                },
                ...getColumnDateOption(true, undefined, undefined, true),
            },
        ];
    };

    return (
        <>
            <Form style={{ marginTop: '15px' }} form={form}>
                <Row>
                    <Col span={20}>
                        <Form.Item name="type" label={<b>{geti18nText('counting.details.grouping.title')}</b>}>
                            <Radio.Group defaultValue={'details'} options={options} onChange={onTypeChartChange} />
                        </Form.Item>
                    </Col>
                    <Col span={4} style={{ textAlign: 'right' }}>
                        <Button
                            style={{ marginBottom: '10px', marginRight: '10px' }}
                            onClick={() => setRefresh(new Date())}
                        >
                            {geti18nText('app.default.refresh')}
                        </Button>
                    </Col>
                </Row>
                <Col span={12}>
                    <Form.Item
                        label={geti18nText('count.data.table.filter.last3days')}
                        name="filterLast3Days"
                        valuePropName="checked"
                    >
                        <Switch
                            style={{ marginLeft: '5px' }}
                            defaultChecked={filterLast3Days}
                            onChange={onSwichChange}
                        ></Switch>
                    </Form.Item>
                </Col>
            </Form>

            {typeGroup && typeGroup === 'details' && (
                <>
                    <NyDataTable
                        url={CONSTANTS_REQ.COUNTING.LIST}
                        colCSVCustomization={DetailsCountingCSVColCustomization()}
                        hideButtons={true}
                        exportCSV={true}
                        CSVFileName={csvFileName()}
                        onRowSelect={onRowDetails}
                        onDataLoaded={table.onLoadData}
                        setDefaultFilterValue={() => table.setDefaultFilterValue(setDefaultFilterValue())}
                        setDefaultPageSize={table.setDefaultPageSize(20)}
                        setDefaultCurrentPage={table.setDefaultCurrentPage()}
                        setDefaultSortOrder={table.setDefaultSortOrder()}
                        fetchWhenChange={refresh}
                        csvColumns={csvColumns()}
                        key={tableKey}
                        columns={columns()}
                    />
                    <CountingDetailsIndex
                        id={detailsId}
                        visible={detailsVisible}
                        operator={detailsOperator}
                        setVisible={setDetailsVisible}
                    ></CountingDetailsIndex>
                </>
            )}
            {typeGroup && typeGroup !== 'details' && (
                <>
                    {' '}
                    <NyDataTable
                        url={CONSTANTS_REQ.COUNTING.LIST_GROUPING}
                        hideButtons={true}
                        setDefaultPageSize={10}
                        onRowSelect={onRowGroupDetails}
                        exportCSV={true}
                        CSVFileName={csvFileNameByType()}
                        addedData={{ timezone: getTimezone().timeZone }}
                        fetchWhenChange={refreshDetailsCount}
                        setDefaultFilterValue={added}
                        colCSVCustomization={DetailsCountingByTypeCSVColCustomization()}
                        columns={[
                            {
                                title: geti18nText('counting.details.grouping.date'),
                                dataIndex: 'converted',
                                align: 'center',
                                width: '10%',
                            },
                            {
                                title: geti18nText('counting.details.grouping.sum'),
                                dataIndex: 'groupedData',
                                width: '15%',
                                align: 'right',
                                render: (text: string, record: { [index: string]: any }) => {
                                    if (record.groupedData) {
                                        return (
                                            <>
                                                {record.groupedData.map((gd: any) => {
                                                    return (
                                                        <div>
                                                            {formatCurrency(gd.sum, gd.currency_code)}{' '}
                                                            {gd.currency_code}
                                                        </div>
                                                    );
                                                })}
                                            </>
                                        );
                                    }
                                },
                            },
                            {
                                align: 'center',
                            },
                        ]}
                    />
                    <CountingDetailsGroupedIndex
                        type={typeGroup}
                        deviceId={deviceId}
                        date={detailsGroupId}
                        visible={detailDayMonthVisible}
                        setVisible={setDetailsDayMonthVisible}
                    ></CountingDetailsGroupedIndex>
                </>
            )}
        </>
    );
};

export const DetailsCountingCSVColCustomization: any = () => {
    return [
        {
            startTime: (value: string) => {
                return new Date(value).toLocaleString(NyUtils.getSelectedLocale(), getTimezone());
            },
        },
        {
            endTime: (value: string) => {
                return new Date(value).toLocaleString(NyUtils.getSelectedLocale(), getTimezone());
            },
        },
        {
            sumExport: (value: any) => {
                return formatCurrency(value.sum, value.currency_code);
            },
        },

        {
            countStatus: (value: any) => {
                if (value != null) {
                    return geti18nText(
                        'count.data.counting.status.enum.' +
                            GetEnumNameForValue({ enumName: 'PARSER_COUNT_STATUS', value: value })
                    );
                }
                return geti18nText(
                    'count.data.counting.status.enum.' +
                        GetEnumNameForValue({ enumName: 'PARSER_COUNT_STATUS', value: 0 })
                );
            },
        },
    ];
};

export function b64ToUtf8(str: string) {
    if (str == undefined) return '';
    return decodeURIComponent(escape(window.atob(str)));
}
export default CountingIndex;
