import { geti18nText, NyRequestResolver, RESPONSE } from '@nybble/nyreact';
import { Button, Modal, notification } from 'antd';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../rootReducer';
import { refreshCompanyList } from '../../slices/selectedDeviceCompanySlice';
import { CONSTANTS_REQ } from '../../utils/Constants';




const AddToCompany = (hasSelected: any, selectedRowKeys: any,selectedRows:any, onPopupSave: any, clearSelectedRowKeys: any) => {
    const { company, refreshCompany } = useSelector((state: RootState) => state.selectedDeviceCompany);
    const [visible, setVisible] = useState(false);
    const dispatch = useDispatch();

    
    const showModal = () => {
        setVisible(true);
    };

    const handleCancel = () => {
        setVisible(false);
    };

    const handleOk = () => {
         NyRequestResolver.requestPost(CONSTANTS_REQ.DEVICE_COMPANY.ADD_DEVICES_TO_COMPANY, undefined, {
            companyId: Number(company),
            devices: selectedRowKeys,
        }).then((result: any) => {
            if (result.status === RESPONSE.OK) {
                setVisible(false);
                notification.success({
                    message: geti18nText('app.default.save.ok'),
                    description: geti18nText('app.default.save.ok.desc'),
                    duration: 3,
                });
                clearSelectedRowKeys();
                dispatch(refreshCompanyList());       
            }
        }); 
    };

    return (
        <React.Fragment>
            <Button type="primary"  disabled={!hasSelected} onClick={showModal}>
                {geti18nText('device.company.add.to.company.button')}
            </Button>

            <Modal
                title={geti18nText('device.company.add.to.company.modal.title')}
                visible={visible}
                onCancel={handleCancel}
                okText={geti18nText('device.company.add.to.company.modal.okText')}
                onOk={handleOk}
            >
                {geti18nText('device.company.add.from.company.modal.text')}
            </Modal>
        </React.Fragment>
    );
};

export default AddToCompany;


