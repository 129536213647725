import {
    CheckCircleOutlined,
    CloseCircleOutlined,
    LinkOutlined,
    PlayCircleOutlined,
    PoweroffOutlined,
    RedoOutlined,
    SyncOutlined,
    WarningOutlined,
} from '@ant-design/icons';
import { geti18nText, NyRequestResolver, NySession, NySpinner, NyUtils, RESPONSE } from '@nybble/nyreact';
import {
    Button,
    Card,
    Col,
    Descriptions,
    InputNumber,
    notification,
    Popconfirm,
    Row,
    Slider,
    Statistic,
    Tabs,
    Tag,
    Tooltip,
    Typography,
} from 'antd';
import Meta from 'antd/lib/card/Meta';
import React, { useEffect, useState } from 'react';
import { CONSTANTS_REQ } from '../../../utils/Constants';
import { getTimezone } from '../../../utils/Utils';
import ListDeviceIndex from './listDevices';
const AdminInstancesIndex = () => {
    const { TabPane } = Tabs;
    const [visibleConfirmReinit, setConfirmReinit] = useState(false);
    const [visibleConfirmChangeInstance, setVisibleConfirmChangeInstance] = useState(false);
    const [visibleConfirmReinitSingleInstance, setVisibleConfirmReinitSingleInstance] = useState<{
        [index: string]: any;
    }>({});
    const [visibleDevices, setVisibleDevices] = useState(false);
    const [selectedInstance, setSelectedInstance] = useState<any>(null);
    const [selectedWorkerInstanceId, setSelectedWorkerInstanceId] = useState<any>(null);
    const [showValueInstance, setShowValueInstance] = useState(false);
    const [instances, setInstances] = useState<any>([]);
    const [instanceValue, setInstanceValue] = useState<any>(1);
    const [expirationWarning, setExpirationWarning] = useState<any>(180);
    const [expirationError, setExpirationError] = useState<any>(300);
    const [expirationCreatedError, setExpirationCreatedError] = useState<any>(100);
    const [loading, setLoading] = useState(false);
    const [inActiveClientInstances,setInActiveClientInstances]=useState(false);
    const timeLimit = NySession.getSetting('btc-worker-instance')['last-activity-period']*60*1000; //5min
   
    const { Paragraph } = Typography;


    useEffect(() => {
        fetchData();
             let timerId = setInterval(() => {
                setVisibleDevices(false);
                fetchData();
            }, 30000);
            return ()=>{
                clearInterval(timerId);
            };
    }, []);

    function fetchData() {
        setLoading(true);
        NyRequestResolver.requestGet(CONSTANTS_REQ.INSTANCES.LIST).then((result: any) => {
            if(result.status === RESPONSE.OK && result.data.content ){
              setInstances(result.data.content);
              checkInactiveInstances(result.data.content);
             setInstanceValue(result.data.content.length);
             setLoading(false);
            }
            else {
                setLoading(false);
            }
        });
    }

    function reinitAll() {
        setConfirmReinit(true);
    }

    function showNewValueInstance() {
        setShowValueInstance(!showValueInstance);
    }

    const handleOkConfirmReinit = () => {
        NyRequestResolver.requestGet(CONSTANTS_REQ.INSTANCES.REINIT).then((result: any) => {
            setConfirmReinit(false);
        });
    };
    const handleCancelConfirmReinit = () => {
        setConfirmReinit(false);
    };

    const handleCancelConfirmChangeInstance = () => {
        setVisibleConfirmChangeInstance(false);
    };

    const handleCancelConfirmReinitSingleInstance = () => {
        //setVisibleConfirmReinitSingleInstance(false);
    };

    function onChange(value: any) {
        setInstanceValue(value);
    }

    function reinitInstance(instanceId: any) {
        //setVisibleConfirmReinitSingleInstance(false);
        NyRequestResolver.requestGet(CONSTANTS_REQ.INSTANCES.INIT_INSTANCE, {
            instanceId: instanceId,
        }).then((result: any) => {
            console.log('OK');
        });
    }

    function changeInstanceNumber() {
        setVisibleConfirmChangeInstance(false);
        setShowValueInstance(false);
        NyRequestResolver.requestGet(CONSTANTS_REQ.INSTANCES.CHANGE_INSTANCE_NUMBER, {
            instanceNumber: instanceValue,
        }).then((result: any) => {
            console.log('OK');
        });
    }

    function cancelShowNewValueInstance() {
        setShowValueInstance(false);
    }

    function setIntanceTagColor(i: any) {
        if (i.type === 'CLIENT') {
            return <Tag color="#108ee9">{'ID: ' + i.instance_id}</Tag>;
        } else {
            return <Tag color="#2bb42b">{'ID: ' + i.instance_id}</Tag>;
        }
    }

    function setCreatedColor(value: any) {
        if (
            value.initialized === null &&
            (new Date().getTime() - new Date(value.created).getTime()) / 1000 > expirationCreatedError
        ) {
            return (
                <>
                    <WarningOutlined className="Blink" style={{ color: 'red' }} />
                    {new Date(value.created).toLocaleString(NyUtils.getSelectedLocale(), getTimezone())}
                </>
            );
        } else {
            return new Date(value.created).toLocaleString(NyUtils.getSelectedLocale(), getTimezone());
        }
    }

    function setLastActivityColor(value: any) {
        if ((new Date().getTime() - new Date(value).getTime()) / 1000 > expirationError) {
            return (
                <>
                    <WarningOutlined className="Blink" style={{ color: 'red' }} />
                    {new Date(value).toLocaleString(NyUtils.getSelectedLocale(), getTimezone())}
                </>
            );
        } else if ((new Date().getTime() - new Date(value).getTime()) / 1000 > expirationWarning) {
            return (
                <>
                    <WarningOutlined className="Blink" style={{ color: 'orange' }} />
                    {new Date(value).toLocaleString(NyUtils.getSelectedLocale(), getTimezone())}
                </>
            );
        } else {
            return new Date(value).toLocaleString(NyUtils.getSelectedLocale(), getTimezone());
        }
    }

    function closeModal() {
        setVisibleDevices(false);
    }

    function sendNotificationForInactiveInstances(instanceId:any){
        NyRequestResolver.requestPost(CONSTANTS_REQ.INSTANCES.SEND_NOTIFICATION + instanceId).then((result: any) => {
            console.log(result.status);
        })
    }

    function checkInactiveInstances(data:any){
        data.filter((i:any)=> Date.now() - i.last_activity > timeLimit && i.type==='CLIENT' && sendNotificationForInactiveInstances(i.instance_id));    
        if(data.some((i:any)=>  Date.now() - i.last_activity > timeLimit  && i.type==='CLIENT')){
            setInActiveClientInstances(true);
        } else {
            setInActiveClientInstances(false);
        }
    }



    function restartContainer(uid: any) {
        NyRequestResolver.requestPost(CONSTANTS_REQ.INSTANCES.RESTART_CONTAINER, undefined, { containerId: uid }).then(
            (result: any) => {
                if (result.status === RESPONSE.OK) {
                    notification.success({
                        message: geti18nText('app.default.save.ok'),
                        description: geti18nText('instances.restart.save.ok.desc'),
                        duration: 3,
                    });
                } else {
                    notification.error({
                        message: geti18nText('app.default.save.nok'),
                        description: result.data?.message,
                        duration: 0,
                    });
                }
            }
        );
    }

    function addInstances() {
        return instances.map((i: any) => {
            return (
                <Col span={8}>
                    <Card>
                        <Meta
                            title={
                                <Row>
                                    <Col span={16}>{setIntanceTagColor(i)}</Col>
                                    {!i.container_id && (
                                        <Col span={2} offset={1}>
                                            {
                                                <WarningOutlined
                                                    className="Blink"
                                                    style={{ fontSize: '30px', color: 'red' }}
                                                />
                                            }
                                        </Col>
                                    )}
                                    {NySession.hasAnyRole(['RULE_INSTANCE_REINITIALIZATION']) && (
                                        <Col span={2} offset={1}>
                                            {i.container_id && (
                                                <Popconfirm
                                                    title={geti18nText('instances.container.restart.msg')}
                                                    onConfirm={(e) => restartContainer(i.container_id)}
                                                >
                                                    <Tooltip title={geti18nText('instances.container.restart.btn')}>
                                                        <Button
                                                            icon={
                                                                <RedoOutlined
                                                                    style={{ fontSize: '20px', color: 'red' }}
                                                                />
                                                            }
                                                            danger
                                                        ></Button>
                                                    </Tooltip>
                                                </Popconfirm>
                                            )}
                                        </Col>
                                    )}
                                </Row>
                            }
                        />
                        <Row style={{ marginTop: 12 }}>
                            <Col span={18}>
                                <Descriptions column={1}>
                                    {i.container_id !== undefined && (
                                        <Descriptions.Item label={geti18nText('instances.table.device.container.id')}>
                                            <Tag color="#106ae9">{i.container_id}</Tag>
                                        </Descriptions.Item>
                                    )}
                                    {i.instance_worker_id && (
                                        <Descriptions.Item label={geti18nText('instances.table.instance_worker_id')}>
                                            {<Tag color="#fcba03">{i.instance_worker_id}</Tag>}
                                        </Descriptions.Item>
                                    )}
                                    {i.type === 'SERVER' ? (
                                        <Descriptions.Item label={geti18nText('instances.table.device.type')}>
                                            {geti18nText('instances.table.device.type.server')}
                                        </Descriptions.Item>
                                    ) : (
                                        <Descriptions.Item label={geti18nText('instances.table.device.type')}>
                                            {geti18nText('instances.table.device.type.client')}
                                        </Descriptions.Item>
                                    )}
                                    <Descriptions.Item label={geti18nText('instances.table.created')}>
                                        {setCreatedColor(i)}
                                    </Descriptions.Item>
                                    {i.instantiated && (
                                        <Descriptions.Item label={geti18nText('instances.table.instantiated')}>
                                            {new Date(i.instantiated).toLocaleString(
                                                NyUtils.getSelectedLocale(),
                                                getTimezone()
                                            )}
                                        </Descriptions.Item>
                                    )}

                                    {i.initialized && (
                                        <Descriptions.Item label={geti18nText('instances.table.initialized')}>
                                            {new Date(i.initialized).toLocaleString(
                                                NyUtils.getSelectedLocale(),
                                                getTimezone()
                                            )}
                                        </Descriptions.Item>
                                    )}
                                    {i.type === 'SERVER' && (
                                        <Descriptions.Item label={geti18nText('instances.table.device.port.number')}>
                                            {i.port}
                                        </Descriptions.Item>
                                    )}

                                    {i.initializedDevices !== undefined && (
                                        <Descriptions.Item label={geti18nText('instances.table.device.number')}>
                                            <Tag color="#108ee9">{i.initializedDevices}</Tag>
                                            <Paragraph
                                                style={{ color: 'gray', margin: 0, marginTop: '2px' }}
                                                copyable={{
                                                    icon: [<LinkOutlined />, <LinkOutlined />],
                                                    tooltips: false,
                                                    onCopy: () => {
                                                        setSelectedInstance(i.id);
                                                        setSelectedWorkerInstanceId(i.instance_id);
                                                        setVisibleDevices(true);
                                                    },
                                                }}
                                            ></Paragraph>
                                        </Descriptions.Item>
                                    )}
                                    {i.activeDevices !== undefined && (
                                        <Descriptions.Item label={geti18nText('instances.table.active.device.number')}>
                                            <Tag color="#2bb42b">{i.activeDevices}</Tag>
                                        </Descriptions.Item>
                                    )}
                                    {i.inactiveDevices !== undefined && (
                                        <Descriptions.Item
                                            label={geti18nText('instances.table.inactive.device.number')}
                                        >
                                            <Tag color="#ff0000">{i.inactiveDevices}</Tag>
                                        </Descriptions.Item>
                                    )}
                                    {i.connectingDevices !== undefined && (
                                        <Descriptions.Item
                                            label={geti18nText('instances.table.connecting.device.number')}
                                        >
                                            <Tag color="#FF5F00">{i.connectingDevices}</Tag>
                                        </Descriptions.Item>
                                    )}
                                    
                                      { i.last_activity !== undefined && (<Descriptions.Item label={
                                            geti18nText('instances.table.device.last.activity')
                                            }>

                                            <Tag color={`${Date.now() - i.last_activity >
                                             /* NySession.getSetting('btc-worker-instance')['last-activity-period'] */
                                             timeLimit ? 'red':'green' }`} >
                                          {new Date(i.last_activity).toLocaleString(NyUtils.getSelectedLocale(),getTimezone())}
                                          </Tag>
                                        </Descriptions.Item>
                                    )}
                                        
                                </Descriptions>
                            </Col>
                        </Row>
                    </Card>
                </Col>
            );
        });
    }
    if (loading) {
        return <NySpinner></NySpinner>;
    } else {
        return (
            <>
            <Tag style={{padding:'0.3rem'}} color="blue" >{geti18nText('instances.settings.max')}: {NySession.getSetting('btc-worker-instance')['max-instances'] + 1} </Tag>
             {NySession.hasAnyRole(['RULE_INSTANCE_REINITIALIZATION']) && (
                    <Popconfirm
                        title={geti18nText('instance.reinit.all.confirm')}
                        visible={visibleConfirmReinit}
                        onConfirm={handleOkConfirmReinit}
                        placement="topRight"
                        onCancel={handleCancelConfirmReinit}
                       
                    >
                        <Button
                         hidden={!inActiveClientInstances}
                            style={{ backgroundColor: 'orangered' }}
                            icon={<PoweroffOutlined />}
                            onClick={() => reinitAll()}
                        >
                            {geti18nText('instance.reinit.all')}
                        </Button>
                    </Popconfirm>
         )}
               
                <Row style={{ marginTop: 12 }} gutter={[16, 16]}>
                    {instances && addInstances()}
                </Row>

                <ListDeviceIndex
                    visible={visibleDevices}
                    id={selectedInstance}
                    instance_id={selectedWorkerInstanceId}
                    closeModal={closeModal}
                ></ListDeviceIndex>
            </>
        );
    }
};

export default AdminInstancesIndex;
