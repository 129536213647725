import { FileSearchOutlined, HistoryOutlined } from '@ant-design/icons';
import { geti18nText, NyDataEdit, NySession, NyUtils } from '@nybble/nyreact';
import { Button, Col, Descriptions, Form, Modal, Row, Switch, Tabs } from 'antd';
import form from 'antd/lib/form';
import React, { useState } from 'react';
import { CONSTANTS_REQ } from '../../utils/Constants';
import DeviceInfoIndex from '../device_information';
import HnbDeviceHistoryIndex from './history';
import HnbSnapshotHistoryIndex from './snapshot';
import HnbSnapshotHistoryEdit from './snapshot/edit';
import moment from 'moment';
import { getTimezone } from '../../utils/Utils';
const HnbDevicePreview = (props: any) => {
    const { TabPane } = Tabs;
    const [form] = Form.useForm();
    const [item, setItem] = useState<any>(null);
    const [hnbHistoryModalVisible, setHnbHistoryModalVisible] = useState(false);
    const [hnbHistoryArchiveModalVisible, setHnbHistoryAcrhiveModalVisible] = useState(false);
    function setValues(dataForm: any) {
        setItem(dataForm);
    }

    return (
        <NyDataEdit
            layout="horizontal"
            formProps={{ labelCol: { span: 7 }, wrapperCol: { span: 17 } }}
            url={CONSTANTS_REQ.HNB_DEVICE.EDIT_BY_DEVICE}
            setValues={setValues}
            width={1500}
            form={form}
            {...props}
            paramsId={parseInt(props.deviceId)}
            hideButtons={true}
            hideCancelButton={true}
            hideActivationButtons={true}
            hideSubmitButton={true}
            addedData={{ deviceId: props.deviceId }}
        >
            <Row>
                {item != null && (
                    <Col span={12}>
                        <Descriptions
                            column={1}
                            title={geti18nText('hnb.device.table.header')}
                            extra={
                                <>
                                    <Button
                                        style={{ marginBottom: '10px', marginRight: '10px' }}
                                        onClick={() => setHnbHistoryModalVisible(true)}
                                    >
                                        {geti18nText('device.menu.hnb.user.change')}
                                        <HistoryOutlined />
                                    </Button>
                                    {NySession.hasAnyRole(['RULE_UPDATE_MANUAL_COUNT']) && (
                                        <Button
                                            style={{ marginRight: '10px' }}
                                            onClick={() => setHnbHistoryAcrhiveModalVisible(true)}
                                        >
                                            {geti18nText('device.menu.hnb.snapshot')}
                                            <FileSearchOutlined />
                                        </Button>
                                    )}
                                </>
                            }
                        >
                            <Row gutter={24}>
                                <Col span={12}>
                                    <Form.Item
                                        label={geti18nText('device.edit.hnb.daily_report')}
                                        valuePropName="checked"
                                        style={{ marginLeft: '-5%' }}
                                    >
                                        <Switch
                                            defaultChecked={item.device ? item['device']['dailyReport'] : false}
                                            disabled={true}
                                        ></Switch>
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        label={geti18nText('device.edit.hnb.monthly_report')}
                                        valuePropName="checked"
                                        style={{ marginLeft: '-50%' }}
                                    >
                                        <Switch
                                            defaultChecked={item.device ? item['device']['monthlyReport'] : false}
                                            disabled={true}
                                        ></Switch>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Descriptions.Item label={geti18nText('hnb.device.table.hnb_device_code')}>
                                {item['hnbDeviceCode']}
                            </Descriptions.Item>
                            <Descriptions.Item label={geti18nText('hnb.device.table.hnb_device_type')}>
                                {geti18nText('hnb.device.type.' + item['hnbDeviceType'])}
                            </Descriptions.Item>
                            <Descriptions.Item label={geti18nText('hnb.device.table.hnb_device_location')}>
                                {geti18nText('hnb.device.location.' + item['hnbDeviceLocation'])}
                            </Descriptions.Item>
                            <Descriptions.Item label={geti18nText('hnb.device.table.software_eur')}>
                                {item['softwareEur']}
                            </Descriptions.Item>
                            <Descriptions.Item label={geti18nText('hnb.device.table.software_hr')}>
                                {item['softwareHr']}
                            </Descriptions.Item>
                            <Descriptions.Item label={geti18nText('hnb.device.maintenance_date')}>
                                {item['maintenanceDate'] != null
                                    ? new Date(item['maintenanceDate']).toLocaleString(NyUtils.getSelectedLocale(),getTimezone())
                                    : ''}
                            </Descriptions.Item>
                            {item != null && item.device != null && (
                                <>
                                    <Descriptions.Item label={geti18nText('hnb.device.table.program_count_state')}>
                                        {item.device.programCountState}
                                    </Descriptions.Item>

                                    {item.device.useParser == false && (
                                        <Descriptions.Item label={geti18nText('device.edit.hnb.count_state_change')}>
                                            {item.device.countStateChange
                                                ? new Date(item.device.countStateChange).toLocaleString(
                                                      NyUtils.getSelectedLocale(),
                                                      getTimezone()
                                                  )
                                                : ''}
                                        </Descriptions.Item>
                                    )}
                                </>
                            )}
                        </Descriptions>
                    </Col>
                )}
            </Row>
            <Modal
                title={geti18nText('device.menu.hnb.user.change')}
                visible={hnbHistoryModalVisible}
                onCancel={() => {
                    setHnbHistoryModalVisible(false);
                }}
                onOk={() => {
                    setHnbHistoryModalVisible(false);
                }}
                destroyOnClose={true}
                okText={geti18nText('customer.edit.ModalOkText')}
                width={1300}
            >
                {item && <HnbDeviceHistoryIndex hnb_device={item['id']}></HnbDeviceHistoryIndex>}
            </Modal>
            <Modal
                title={geti18nText('device.menu.hnb.snapshot')}
                visible={hnbHistoryArchiveModalVisible}
                onCancel={() => {
                    setHnbHistoryAcrhiveModalVisible(false);
                }}
                onOk={() => {
                    setHnbHistoryAcrhiveModalVisible(false);
                }}
                destroyOnClose={true}
                okText={geti18nText('customer.edit.ModalOkText')}
                width={1300}
            >
                {item && <HnbSnapshotHistoryIndex device_id={parseInt(props.deviceId)}></HnbSnapshotHistoryIndex>}
            </Modal>
        </NyDataEdit>
    );
};

export default HnbDevicePreview;
