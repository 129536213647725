import { geti18nText, NyDataTable, NyRequestResolver, NySession, RESPONSE } from '@nybble/nyreact';
import { Button, Modal, notification } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../rootReducer';
import { refreshCompanyList } from '../../slices/selectedDeviceCompanySlice';
import { CONSTANTS_REQ } from '../../utils/Constants';
import DeviceColumns from '../admin/device/columns';
import DeviceCSVColumnsExport from '../admin/device/csvDeviceColumnsExport';
import AddToCompany from './AddToCompany';

const DeleteFromCompany = (
    hasSelected: any,
    selectedRowKeys: any,
    selectedRows: any,
    onPopupSave: any,
    clearSelectedRowKeys: any
) => {
    const [visible, setVisible] = useState(false);
    const [visibleAdd, setVisibleAdd] = useState(false);
    const { company, refreshCompany } = useSelector((state: RootState) => state.selectedDeviceCompany);
    const dispatch = useDispatch();

    const showModal = () => {
        setVisible(true);
    };

    const showModalAdd = () => {
        setVisibleAdd(true);
    };

    const handleCancel = () => {
        setVisible(false);
    };

    const handleCancelAdd = () => {
        setVisibleAdd(false);
    };

    useEffect(() => {
        setVisibleAdd(false);
        clearSelectedRowKeys();
    }, [refreshCompany]);

    const handleOk = () => {
        NyRequestResolver.requestPost(CONSTANTS_REQ.DEVICE_COMPANY.DELETE_DEVICES_FROM_COMPANY, undefined, {
            companyId: Number(company),
            devices: selectedRowKeys,
        }).then((result: any) => {
            if (result.status === RESPONSE.OK) {
                setVisible(false);
                notification.success({
                    message: geti18nText('app.default.save.ok'),
                    description: geti18nText('app.default.save.ok.desc'),
                    duration: 3,
                });
                clearSelectedRowKeys();
                dispatch(refreshCompanyList());
            }
        });
    };

    function setDefaultFilterValue() {

        if(NySession.hasAnyRole(['RULE_PREVIEW_DEVICE_WRITTEN_OFF'])) {
            return [{ field: 'companyId', condition: 'not_equals', value: company }];
        }

        else   return [{ field: 'companyId', condition: 'not_equals', value: company }, { field: 'writtenOff', condition: 'equals_bool', value: 0 }];
    }

    return (
        <React.Fragment>
            <Button type="primary" danger disabled={!hasSelected} onClick={showModal}>
                {geti18nText('device.company.delete.from.company.button')}
            </Button>

            <Button type="primary" style={{ marginLeft: '20px' }} onClick={showModalAdd}>
                {geti18nText('device.company.add.to.company.button')}
            </Button>

            <Modal
                title={geti18nText('device.company.delete.from.company.modal.title')}
                visible={visible}
                onCancel={handleCancel}
                okButtonProps={{ danger: true }}
                okText={geti18nText('device.company.delete.from.company.modal.okText')}
                onOk={handleOk}
            >
                {geti18nText('device.company.delete.from.company.modal.text')}
            </Modal>
            <Modal
                title={geti18nText('device.company.delete.from.company.modal.title')}
                visible={visibleAdd}
                onCancel={handleCancelAdd}
                width={1500}
                footer={[
                    <Button key="back" onClick={handleCancelAdd}>
                        {geti18nText('app.default.button.cancel')}
                    </Button>,
                ]}
            >
                <NyDataTable
                    headerTitle={geti18nText('device.table.header')}
                    url={CONSTANTS_REQ.DEVICE.LIST}
                    showRecordModal={false}
                    hideButtons={false}
                    hideNewButton={true}
                    readonly={true}
                    fetchWhenChange={refreshCompany}
                    setDefaultFilterValue={setDefaultFilterValue}
                    setDefaultPageSize={10}
                    scroll={{ x: 1200 }}
                    columns={DeviceColumns({isAdmin:true})}
                    csvColumns={DeviceCSVColumnsExport(true)}
                    showRowSelection={true}
                    rowSelectionModal={AddToCompany}
                />
            </Modal>
        </React.Fragment>
    );
};

export default DeleteFromCompany;
