import { CloseOutlined } from '@ant-design/icons';
import { geti18nText, NyRequestResolver, NySearchField, NySession, NySpinner, NyUtils, RESPONSE } from '@nybble/nyreact';
import { Button, Col, Form, Radio, Row, Typography } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { FormatedRangePicker } from '../../components/formated-date-picker/formatedDatePicker';
import { setActive } from '../../slices/menuSlice';
import { CONSTANTS_REQ } from '../../utils/Constants';
import { getTimezone } from '../../utils/Utils';
import { StatisticOptions } from '../statistic/StatisticIndex';

 

export const StatisticChartDashboard = (props: any) => {
    const [loading, setLoading] = useState(false);
    const { Text, Link } = Typography;
    const [graphData, setGraphData] = useState<any>(null);
    const [devices, setDevices] = useState<any>([]);
    const [selectedKey, setSelectedKey] = useState<any>();
    const [type, setType] = useState<any>(undefined);
    const [valuesForRefresh, setValuesForRefresh] = useState<any>();
    const dispatch = useDispatch();
    const history = useHistory();


    
    const { Title } = Typography;

    useEffect(()=>{       
        fetchData(false,props.typeChart,props);
    },[]);


    useEffect(() => {
        const interval = setInterval(() => {
            fetchData(false,props.typeChart,props);
        }, 5 * 60 * 1000);

        return () => {
            clearInterval(interval);
        };
    }, []);

    function getParams(values:any) {
        let params: any={};

        if(values) {
            console.log(values);
            if(values.device) {
                params.device=values.device.id;
                
            }

            if(values.period) {
                params.period=values.period;
            }

            if(values.branch) {
                params.branch=values.branch.id;
            }
            
            if(values.deviceGroup) {
                params.deviceGroup=values.deviceGroup.id
            }
            if(values.model) {
                params.model=values.model.id;
            }
            if(values.type) {
                params.model=values.type.id;
            }
            
            if(values.deviceActive != null) {
                params.deviceActive=values.deviceActive === true ? '1' : '0';
            }
            if( values.deviceCorrect != null  && values.deviceActive != null && values.deviceActive === false ) {
                params.deviceCorrect=values.deviceCorrect === true ? '1' : '0';
            }
            if(values.timestampFrom && values.timestampTo) {
                params.timestampFrom=values.timestampFrom;
                params.timestampTo=values.timestampTo;
            }
            
            
        }
        if(props.deviceId) {
            params.device=props.deviceId;
        }
        
        params.lang=NyUtils.getSelectedLanguage();
        params.timezone=getTimezone().timeZone;    
        return params;
    }


    function makeUrl(type:any):string {
        switch(type){
        case "count":
            return CONSTANTS_REQ.STATISTIC.COUNT;
            
        case "sum":
            return CONSTANTS_REQ.STATISTIC.SUM;
            
        case "time":
            return CONSTANTS_REQ.STATISTIC.TIME;
            
        case "error":
            return CONSTANTS_REQ.STATISTIC.ERROR;    
        }
        return "";
    }

    function formKey(val:any):string {
        switch(val.type) {
            case "device":
                return "D_"+val.id;    
            case "deviceGroup":
                return "DG_"+val.id;
            case "branch":
                return "DB_"+val.id;
            case "model":
                return "DM_"+val.id;    
            case "type":
                return "DT_"+val.id;    
                      
        }
        return "";
    }

    function fetchData(isPeriodChange:boolean,type?:any,values?:any) {
        setLoading(true);
        NyRequestResolver.requestGet(
            makeUrl(type ? type : 'count'),
                getParams(values)
            
        ).then((result: any) => {
            let chartData:any=[];
            if (result.status === RESPONSE.OK) {
                if(values.deviceAll ) {
                    chartData=result.data.map((r:any)=>{
                        let obj:any={converted:r.converted,ts:r.ts,total:r.total}
                        let key="D_0";
                        obj[key]=r.total;
                        return obj;
                    });
                    setDevices([{type:'device', value:{name: geti18nText('statistic.chart.title.all.device'), key:"D_0",color:values.color}}]);
                    setGraphData({devices:[{type:'device', value:{name: geti18nText('statistic.chart.title.all.device'), key:"D_0",color:values.color}}],data:chartData});
                }
                else {
                    let key=formKey(values.selectedKey);
                    chartData=result.data.map((r:any)=>{
                    let obj:any={converted:r.converted,ts:r.ts,total:r.total}
                    obj[key]=r.total;
                    return obj;
                });
                setDevices([{type:type, value:{name: values.selectedKey.name, key:key,color:values.color}}]);
                setGraphData({devices:[{type:type, value:{name: values.selectedKey.name, key:key,color:values.color}}],data:chartData});
                }
                setLoading(false);
            }
            else if(result.status === 403) {
                dispatch(setActive('/'));
                NySession.logoutUser();
                setLoading(false);
                setGraphData(null);
                history.push('/login');
            }
            else {
                setLoading(false);
            }
        });
    }
    

    function remove() {
        props.onRemoveWidget(props.widgetKey);
    }

    function setTitleOnType(type:any,name:any) {
        switch(type){
            case "count":
                return  geti18nText('dashboard.widget.counting.stat.label') +" "+name;
                
            case "sum":
                 return  geti18nText('dashboard.widget.sum.stat.label') +" "+name;

                
            case "time":
                return geti18nText('dashboard.widget.duration.stat.label')+" "+name;
                
            case "error":
                return    geti18nText('dashboard.widget.error.stat.label')+" "+name;
            }
            return "";
    }

    function setTitleLgend() {
        if(props.device) {
           return setTitleOnType(props.typeChart,geti18nText('dashboard.widget.counting.stat.label.device') +" "+ props.device.name);
        }
        
        else if(props.deviceGroup){
            return setTitleOnType(props.typeChart,geti18nText('dashboard.widget.counting.stat.label.device.group') +" "+ props.deviceGroup.name + formStatusTitle());

        }
        else if(props.branch){
            return setTitleOnType(props.typeChart,geti18nText('dashboard.widget.counting.stat.label.office') +" "+ props.branch.name + formStatusTitle());

        }
        else if(props.model){
            return setTitleOnType(props.typeChart,geti18nText('dashboard.widget.counting.stat.label.model') +" "+ props.model.name + formStatusTitle());

        }
        else if(props.type){
            return setTitleOnType(props.typeChart,geti18nText('dashboard.widget.counting.stat.label.type')+" " + props.type.name + formStatusTitle());

        }
        else if(props.deviceAll) {
            return setTitleOnType(props.typeChart," - "+geti18nText('dashboard.widget.counting.stat.label.deviceAll'));
         }
        else return geti18nText('dashboard.widget.counting.stat.label')
    }

    function formStatusTitle() {
        let status =''
        if(props.deviceActive != null) {
           status = " - " +geti18nText('device.import.column.status')+ ": ".concat(props.deviceActive === true ? geti18nText('app.default.active') : geti18nText('app.default.inactive'));
        }
        if(props.deviceCorrect != null   && props.deviceActive != null && props.deviceActive === false) {
           status= status.concat(' / ').concat(props.deviceCorrect === true ? geti18nText('statistic.device.status.correct') : geti18nText('statistic.device.status.notcorrect'));
        }
        return status;
    }

    const renderColorfulLegendText = (value: any, entry: any) => {
        const { color } = entry;
        return <span  style={{ color , fontSize:15}}>
            {setTitleLgend()
            }
            </span>;
    };


    function tickFormater(value:any) {
        if(props.type === 'time') {
            
            if(value < 60) {
                value = value + ' s';
            }
            else if(value <3600) {
                value= NyUtils.formatNumber(value/60,2) + " m";
            }
            else {
                
                value= NyUtils.formatNumber(value/3600,2) + " h";
            }
            return value;
        }
        else return value>1 ? NyUtils.formatNumber(value):value;

    }

    const renderTooltip = (value: any,name:any, props: any) => {
        let formValue=value;
        if(props.typeChart === 'time') {
            
            if(formValue < 60) {
                formValue = formValue + ' s';
            }
            else if(formValue <3600) {
                formValue= NyUtils.formatNumber(value/60,2) + " m";
            }
            else {
                
                formValue= formValue= NyUtils.formatNumber(value/3600,2) + " h";
            }
        }
        if(props.typeChart !== 'time') {
            formValue=NyUtils.formatNumber(formValue);
        }
        return [formValue, name]
    };

   
    if (loading) {
        return <NySpinner></NySpinner>;
    }

    else return (
        <>
        <Row>   <Col span={20}>
                        <Title style={{textAlign:'center'}} level={3}></Title>
                    </Col>
                    <Col style={{textAlign:'right'}} span={4}>
                        <Button
                            shape='circle'
                            size="small"
                            style={{ border: 'none' }}
                            icon={<CloseOutlined />}
                            onClick={() => remove()}
                                    />
                    </Col>
            </Row>
            <Row style={{ height: "calc(100% - 45px)" }}>
                <Col span={24} style={{ height: "100%" }}>
                    <ResponsiveContainer width="100%" height="95%">
                        {loading ? (
                            <NySpinner />
                        ) : (graphData ?
                            (<LineChart
                                margin={{ top: 5, right: 10, left: 10, bottom: 5 }}
                                data={graphData.data}
                            >
                                <CartesianGrid vertical={false} stroke="#DDD" />
                                    <XAxis dataKey="converted" />
                                    <YAxis tickFormatter={tickFormater} />
                                    <Tooltip formatter={renderTooltip} />
                                    <Legend formatter={renderColorfulLegendText} />
                                    {
                                    graphData.devices.map((device:any)=>{
                                            return  <Line 
                                            type="monotone"  
                                            strokeLinecap="round" 
                                            strokeWidth={3}
                                            name={device.value.name}
                                            dataKey={device.value.key}
                                            stroke={device.value.color}
                                            dot={false}
                                            legendType="square"
                                        />
                                        })  
                                        }
                            </LineChart>):<></>
                        )}
                    </ResponsiveContainer>
                </Col>
            </Row>
        </>
    );
};

export const StatisticChartsProps = (props:any) => {
    const [form] =Form.useForm();
    const [addButtonDisabled, setAddButtonDisabled] = useState(false);
    const [showRange, setShowRange] = useState(false);
    const [rangeValue, setRangeValue] = useState<any>(null);
    const [timestampFrom, setTimestampFrom] = useState<any>(null);
    const [timestampTo, setTimestampTo] = useState<any>(null);
    const [radioValue, setRadioValue] = useState<any>('device');
    const [periodValue, setPeriodValue] = useState<any>({id:"today",text:geti18nText("dashboard.widget.chart.period.today")});
    const [typeChart, setTypeChart] = useState<any>('count');
    const [device, setDevice] = useState<any>(undefined);
    const [selectedKey, setSelectedKey] = useState<any>(undefined);
    const [hidenType, setHidenType] = useState<any>(false);
    const [deviceActive, setDeviceActive] = useState<any>(undefined);
    const [deviceCorrect, setDeviceCorrect] = useState<any>(undefined);

    useEffect(()=>{
        form.setFieldsValue({period:{id:"today",text:geti18nText("dashboard.widget.chart.period.today")}});
    },[])

    function setSettings() {
        form.validateFields().then((val:any)=>{
             if(val){
                let settings:any = { period: val.period };
                if(val.device) {
                    settings.device=device;
                }
                if(val.deviceGroup) {
                    settings.deviceGroup=val.deviceGroup;
                }
                if(val.branch) {
                    settings.branch=val.branch;
                }
                if(val.model) {
                    settings.model=val.model;
                }
                if(val.type) {
                    settings.type=val.type;
                }
                if(selectedKey) {
                    settings.selectedKey=selectedKey;
                }
                if(timestampFrom && timestampTo) {
                    settings.timestampFrom=timestampFrom;
                    settings.timestampTo=timestampTo;
                }
                if(!val.device && radioValue && radioValue === 'device') {
                    settings.deviceAll="D_O";
                }
                if(deviceCorrect != null ) {
                    settings.deviceCorrect=deviceCorrect;
                }
                if(deviceActive != null ) {
                    settings.deviceActive=deviceActive
                }
                settings.color=val.color;
                settings.period=periodValue.id;
                settings.typeChart=typeChart;
                props.addWidget(settings);
            }
        }).catch(() => {
           console.log("error")
        }); 
        
    }

    const period = [
        { id: "today", text: geti18nText("dashboard.widget.chart.period.today") },
        { id: "24h", text: geti18nText("dashboard.widget.chart.period.24h") },
        { id: "3", text: "3 " + geti18nText("dashboard.widget.chart.period.days") },
        { id: "7", text: "7 " + geti18nText("dashboard.widget.chart.period.days") },
        { id: "14", text: "14 " + geti18nText("dashboard.widget.chart.period.days") },
        { id: "30", text: "30 " + geti18nText("dashboard.widget.chart.period.days") },
        { id: "span", text: geti18nText("statistic.chart.period.range") },
    ];

    function periodOnChange(val:any){
        if(val.id === 'span') {
            form.setFieldsValue({period:val});
            setPeriodValue(val);
            setShowRange(true);
        }
        else if(val.id === -1) {
            form.setFieldsValue({period:{id:"today",text:geti18nText("dashboard.widget.chart.period.today")}});
            setPeriodValue({id:"today",text:geti18nText("dashboard.widget.chart.period.today")});
            setShowRange(false);
            setRangeValue(null);
          
        }
        else {
            form.setFieldsValue({period:val});
            setPeriodValue(val);
            setShowRange(false);
            setRangeValue(null);
           
        }
        
    }

    function onRangeChange(dates: any) {
        if (dates != null) {
            setTimestampFrom(dates[0] ? moment(dates[0]).valueOf() : null);
            setTimestampTo(dates[1] ? moment(dates[1]).valueOf() : null);
            setRangeValue(dates);
            
        } else {
            setPeriodValue({id:"today",text:geti18nText("dashboard.widget.chart.period.today")});
            setTimestampFrom(null);
            setTimestampTo(null);
            setRangeValue(dates);
            setShowRange(false);
        }
    }

    const optionsType = [
        { label: geti18nText('statistic.chart.option.count'), value: 'count' },
        { label: geti18nText('statistic.chart.option.sum'), value: 'sum' },
        { label: geti18nText('statistic.chart.option.time'), value: 'time' },
        { label: geti18nText('statistic.chart.option.error'), value: 'error' },
    ];

    function onTypeChartChange(val:any) {
        setTypeChart(val.target.value);
        setHidenType(val.target.value === 'error' ? true : false);
    }


    return (
    <>
    <Form labelCol={{ span: 5 }}
      wrapperCol={{ span: 16 }} form={form}>
    <Form.Item  name="typeChrat" label={geti18nText("dashboard.widget.chart.data.type")}>
        <Row>
            <Col offset={1} span={24}>
                <Radio.Group onChange={ onTypeChartChange} value={typeChart} options={optionsType}  />
            </Col>
        </Row>
    </Form.Item>
    <Form.Item rules={[
                        {required: true,message: geti18nText('app.default.required'),
                            },
                    ]} name="period" label={geti18nText("dashboard.widget.chart.period")}>
    <Row >
        <Col  span={10}>
            <NySearchField value={periodValue} onChange={periodOnChange} searchBy={"name"} style={{width:'80%'}}  options={period}  />
        </Col>
        <Col hidden={!showRange} span={14}>
            <FormatedRangePicker  value={rangeValue} showTime={true} onChange={onRangeChange} ></FormatedRangePicker>
        </Col>
    </Row>
    </Form.Item>
    
    <Row>
        
        <Col span={24}>
            <StatisticOptions setDeviceCorrect={setDeviceCorrect}
                    setDeviceActive={setDeviceActive} radioValue={setRadioValue} hidenType={hidenType} setSelectedKey= {setSelectedKey}  setDevice={setDevice} device={device}  form={form} ></StatisticOptions>
        </Col>
    </Row>
    <Row >
        <Col span={24} style={{ textAlign: 'right' }}>
            <Button type="primary" disabled={addButtonDisabled}  onClick={() => setSettings()}>{geti18nText("dashboard.widget.button.add")}</Button>
        </Col>
    </Row>
    </Form>
    </>);
};

