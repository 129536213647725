import {
    BankOutlined,
    BulbFilled,
    BulbOutlined,
    CloudServerOutlined,
    InfoOutlined,
    LockOutlined,
    LogoutOutlined,
    SettingOutlined,
    UsergroupAddOutlined,
    UserOutlined,
    ApartmentOutlined,
    TranslationOutlined,
    RobotOutlined,
    NotificationOutlined,
    DollarCircleOutlined,
    PartitionOutlined,
    DesktopOutlined,
    DashboardOutlined,
    BarChartOutlined,
    AppstoreAddOutlined,
    ToolOutlined,
    FileExclamationOutlined,
    UsbOutlined,
    FileDoneOutlined,
} from '@ant-design/icons';
import {
    geti18nText,
    NyLanguageSelector,
    NyLayoutHeader,
    NyLayoutMenu,
    NyRequestResolver,
    NySession,
    NyUtils,
    RESPONSE,
} from '@nybble/nyreact';
import { Button, Layout, Menu, notification, Result } from 'antd';
import SubMenu from 'antd/lib/menu/SubMenu';
import React, { useRef, useState } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Switch, useHistory, useLocation } from 'react-router-dom';
import { RootState } from '../../rootReducer';
import { addNotification } from '../../slices/notificationSlice';
import { CONSTANTS_REQ } from '../../utils/Constants';
import DeviceEdit from '../../views/device/edit';
import NotificationsIndex from '../notification';
import './index.scss';
import { mainRoutes } from './routes';
import SSE from './see';
import { setModalVisible } from '../../slices/modalOpenSlice';
import { setActive } from '../../slices/menuSlice';
import LayoutMenu from '../layout-menu';
import { getTimezone } from '../../utils/Utils';
import moment from 'moment';
import AdminSentNotificationView from '../../views/admin/notification-alarm/sent_notification_view';

const { Content } = Layout;
const LayoutMain = () => {
    const history = useHistory();
    const location = useLocation();
    const [theme, setTheme] = useState(localStorage['theme'] || 'light');
    const eventSourceRef = useRef<any>(null);
    const dispatch = useDispatch();
    const { visible, item, modal } = useSelector((state: RootState) => state.modalOpen);
    const [appLogo, setAppLogo] = useState();

    const Modals: any = {
        deviceEdit: DeviceEdit,
        notificationEdit: AdminSentNotificationView,
    };

    useEffect(() => {
        if (!('Notification' in window)) {
            console.log('This browser does not support desktop notification');
        } else {
            Notification.requestPermission();
        }

        if (NySession.isUserAuthenticated()) {
            checkIfDBValid();
            getAppLogo();
            eventSourceRef.current = new SSE(CONSTANTS_REQ.NOTIFICATION.GET_ALL, {
                headers: {
                    Authorization: 'Bearer ' + NySession.getUserToken(),
                },
            });

            eventSourceRef.current.onmessage = (e: any) => {
                dispatch(addNotification(JSON.parse(e.data)));
            };

            eventSourceRef.current.onerror = (e: any) => {
                console.log('sse error');
                if (eventSourceRef.current != null) {
                    eventSourceRef.current.close();
                }
            };

            eventSourceRef.current.stream();
            if (NySession.getUser()) {
                moment.tz.setDefault(getTimezone().timeZone);
            }
        }
        // if (NySession?.getUser()?.validAppCertificate != null && NySession?.getUser()?.validAppCertificate === false) {
        //     history.push('/admin/cert');
        // }
    }, []);

    function checkIfDBValid() {
        NyRequestResolver.requestGet(CONSTANTS_REQ.INIT.VALID_DB).then((result: any) => {
            if (result && result.data == false) {
                notification.error({
                    message: geti18nText('app.db.error.title'),
                    description: geti18nText('app.db.error.description'),
                    duration: 15,
                });
            }
        });
    }

    function getAppLogo() {
        NyRequestResolver.requestGet(CONSTANTS_REQ.APPLICATION_SETTINGS.APP_LOGO).then((result: any) => {
            if (result && result.data && result.data.value && result.data.value != '') {
                setAppLogo(result.data.value);
            }
        });
    }

    function generateRoutes(routes: any) {
        let retValue = [];
        let route;
        for (route of routes) {
            if (NySession.hasAnyRole(route.role)) {
                retValue.push(
                    <Route key={route.path} path={route.path} exact={route.exact} component={route.component} />
                );
            }
        }
        return retValue;
    }
    const menu: any = [
        {
            key: 'menu.dashboard',
            icon: <DashboardOutlined />,
            i18n: 'menu.dashboard',
            role: ['RULE_PREVIEW_USER', 'RULE_PREVIEW_DEVICE'],
            path: '/',
        },
        {
            key: 'menu.device',
            icon: <BankOutlined />,
            path: '/device',
            i18n: 'menu.device',
            role: ['RULE_PREVIEW_DEVICE'],
        },
        {
            key: 'menu.statistic.and.reports',
            icon: <BarChartOutlined />,
            i18n: 'menu.statistic.and.reports',
            role: ['RULE_PREVIEW_STATISTIC', 'RULE_PREVIEW_DEVICE'],
            submenu: [
                {
                    key: 'menu.statistic',
                    icon: <BarChartOutlined />,
                    path: '/reports/statistic',
                    i18n: 'menu.statistic',
                    role: ['RULE_PREVIEW_STATISTIC'],
                },
                {
                    key: 'menu.master_data',
                    icon: <FileExclamationOutlined />,
                    path: '/reports/master_data',
                    i18n: 'menu.master_data',
                    role: ['RULE_PREVIEW_DEVICE'],
                },
            ],
        },

        {
            key: 'menu.administration',
            icon: <CloudServerOutlined />,
            i18n: 'menu.administration',
            role: ['RULE_PREVIEW_USER', 'RULE_PREVIEW_DEVICE'],
            submenu: [
                {
                    key: 'menu.admin.device.menu',
                    icon: <BankOutlined />,
                    i18n: 'menu.device',
                    role: ['RULE_PREVIEW_DEVICE'],
                    submenu: [
                        {
                            key: 'menu.admin.device',
                            path: '/admin/device',
                            icon: <BankOutlined />,
                            i18n: 'menu.device',
                            role: ['RULE_PREVIEW_DEVICE'],
                        },
                        {
                            key: 'menu.admin.service.information',
                            path: '/admin/serviceInformation',
                            icon: <UsbOutlined />,
                            i18n: 'menu.service.information',
                            role: ['RULE_EXPORT_IMPORT_SERVICE_INFO'],
                        },
                        {
                            key: 'menu.admin.deviceModel',
                            path: '/admin/deviceModel',
                            icon: <PartitionOutlined />,
                            i18n: 'menu.deviceModel',
                            role: ['RULE_DEVICE_MODEL_PREVIEW'],
                        },
                        {
                            key: 'menu.admin.manufacturer',
                            path: '/admin/manufacturer',
                            icon: <RobotOutlined />,
                            i18n: 'menu.maufacturer',
                            role: ['RULE_PREVIEW_D_MANUFACTURE'],
                        },
                        {
                            key: 'menu.admin.vendor',
                            path: '/admin/vendor',
                            icon: <AppstoreAddOutlined />,
                            i18n: 'menu.vendor',
                            role: ['RULE_PREVIEW_VENDOR'],
                        },
                        {
                            key: 'menu.admin.servicevendor',
                            path: '/admin/service-vendor',
                            icon: <ToolOutlined />,
                            i18n: 'menu.service.vendor',
                            role: ['RULE_PREVIEW_SERVICE_VENDOR'],
                        },
                        {
                            key: 'menu.admin.device.group',
                            icon: <ApartmentOutlined />,
                            i18n: 'menu.device_groups',
                            path: '/admin/deviceGroups',
                            role: ['RULE_PREVIEW_DEVICE_GROUP'],
                        },
                        {
                            key: 'menu.admin.office',
                            path: '/admin/office',
                            icon: <InfoOutlined />,
                            i18n: 'menu.office',
                            role: ['RULE_BRANCH_PREVIEW'],
                        },
                    ],
                },

                {
                    key: 'menu.admin.user.menu',
                    icon: <UserOutlined />,
                    i18n: 'menu.user',
                    role: ['RULE_PREVIEW_USER'],
                    submenu: [
                        {
                            key: 'menu.admin.user',
                            icon: <UserOutlined />,
                            i18n: 'menu.user',
                            path: '/admin/user',
                            role: ['RULE_PREVIEW_USER'],
                        },
                        {
                            key: 'menu.admin.user_role',
                            icon: <LockOutlined />,
                            i18n: 'menu.user_role',
                            path: '/admin/role',
                            role: ['RULE_PREVIEW_USER_ROLE'],
                        },
                        {
                            key: 'menu.admin.user_group',
                            icon: <UsergroupAddOutlined />,
                            i18n: 'menu.user_group',
                            path: '/admin/user_group',
                            role: ['RULE_PREVIEW_USER_GROUP'],
                        },
                        {
                            key: 'menu.admin.company',
                            icon: <UsergroupAddOutlined />,
                            i18n: 'menu.company',
                            path: '/admin/company',
                            role: ['RULE_COMPANY_PREVIEW'],
                        },
                    ],
                },
                {
                    key: 'menu.settings',
                    path: '/admin/settings',
                    icon: <SettingOutlined />,
                    i18n: 'menu.settings',
                    role: ['RULE_SETTINGS_CRUD', 'RULE_LOG_PREVIEW'],
                },
                {
                    key: 'menu.cert',
                    path: '/admin/cert',
                    icon: <FileDoneOutlined />,
                    i18n: 'menu.cert',
                    role: ['RULE_CERTIFICATE_UPLOAD'],
                },
                {
                    key: 'menu.admin.notification.alarm',
                    icon: <NotificationOutlined />,
                    i18n: 'menu.notification.alarm',
                    path: '/admin/notification',
                    role: ['RULE_PREVIEW_NOTIFICATION'],
                },
                {
                    key: 'menu.admin.currency',
                    icon: <DollarCircleOutlined />,
                    i18n: 'menu.admin.currency',
                    path: '/admin/currency',
                    role: ['RULE_CURRENCY_PREVIEW', 'RULE_CURRENCY_CRUD'],
                },
                {
                    key: 'menu.admin.instances',
                    icon: <DesktopOutlined />,
                    i18n: 'menu.admin.instances',
                    path: '/admin/instances',
                    role: ['RULE_INSTANCE_PREVIEW'],
                },
            ],
        },
    ];

    function headerSelect(value: any) {
        if (value.key === 'logout') {
            dispatch(setActive('/'));
            NySession.logoutUser();
            if (NyUtils.load('WIDGETS')) {
                localStorage.removeItem('WIDGETS');
            }
            if (NyUtils.load('DASHBOARD_LOCK') != null) {
                localStorage.removeItem('DASHBOARD_LOCK');
            }
            if (NyUtils.load('LAYOUT') != null) {
                localStorage.removeItem('LAYOUT');
            }
            sessionStorage.removeItem('IoTSettings');
            eventSourceRef.current.close();
            eventSourceRef.current = null;
            history.push('/login');
        }
        if (value.key === 'settings2FA') {
            openSettings2FA();
        }
    }

    function openSettings2FA() {
        history.push('/2FA');
    }

    const changeTheme = () => {
        const nextTheme = theme === 'light' ? 'dark' : 'light';
        localStorage['theme'] = nextTheme;
        window.location.reload();
    };

    const headerContent = () => {
        return (
            <div style={{ display: 'inline-block', width: '100%' }}>
                <div style={{ marginLeft: '300px', float: 'left' }}>
                    <h2>{NySession.getSetting('ux').title}</h2>
                </div>
                <div style={{ float: 'right' }}>
                    <Menu
                        mode="horizontal"
                        style={{ marginRight: '5px' }}
                        selectedKeys={undefined}
                        className="header-menu"
                        onSelect={headerSelect}
                    >
                        <Menu.Item key="version">
                            {geti18nText('login.build')} <p className="version">{NySession.getAppValue('VERSION')}</p>
                        </Menu.Item>
                        <SubMenu
                            className="ant-menu-item"
                            title={
                                <span className="submenu-title-wrapper">
                                    <UserOutlined />
                                    {NySession.getUser().fullName}
                                </span>
                            }
                        >
                            <Menu.Item key="logout">
                                <LogoutOutlined />
                                {geti18nText('navbar.user.logout')}
                            </Menu.Item>
                            {NySession.getSetting('ux')['2fa-menu'] == true && (
                                <Menu.Item key="settings2FA">
                                    <SettingOutlined />
                                    {geti18nText('settings.2fa.submenu.title')}
                                </Menu.Item>
                            )}
                        </SubMenu>
                        {NySession.getAppValue('I18N') != null && (
                            <Menu.Item key="lang">
                                <NyLanguageSelector
                                    languages={NySession.getAppValue('I18N').language.available}
                                    selectedLanguage={NyUtils.getSelectedLanguage()}
                                    onSelect={(lang: object) => {
                                        NyUtils.saveDefaults(lang);
                                    }}
                                    reloadPage={true}
                                />
                            </Menu.Item>
                        )}
                        <Menu.Item key="theme" style={{ marginRight: '40px' }}>
                            <Button onClick={changeTheme}>
                                {theme === 'light' ? <BulbFilled /> : <BulbOutlined />}
                            </Button>
                            <NotificationsIndex></NotificationsIndex>
                        </Menu.Item>
                    </Menu>
                </div>
            </div>
        );
    };

    const MenuLogo = (appLogo: any) => {
        const LoginLogo = () => (
            <img
                src={appLogo != undefined ? appLogo : process.env.PUBLIC_URL + NySession.getSetting('ux')['logo-image']}
                alt={NySession.getSetting('ux')['logo-image-alt']}
                style={{ width: '140px', marginLeft: '50px', marginTop: '10px', padding: '0px' }}
            />
        );

        return (
            <React.Fragment>
                <div className="menu-search" style={{ marginTop: '7px', marginLeft: '5px' }}>
                    <LoginLogo />
                </div>
            </React.Fragment>
        );
    };

    const showModal = () => {
        return (
            item != undefined &&
            modal != undefined &&
            Modals[modal] &&
            React.createElement(
                Modals[modal],
                {
                    isModal: true,
                    visible: visible,
                    setVisible: (visible: any) => dispatch(setModalVisible(visible)),
                    value: item ? { id: item } : undefined,
                    onSave: () => window.location.reload(),
                    onSaveAndGetID: () => window.location.reload(),
                    onSaveAndGetData: () => window.location.reload(),
                    addedData: undefined,
                },
                null
            )
        );
    };

    if (!NySession.isUserAuthenticated()) {
        if (NyUtils.load('STATISTIC')) {
            localStorage.removeItem('STATISTIC');
        }
        return <Redirect to="/login" />;
    } else {
        return (
            <Layout>
                <NyLayoutHeader headerClassName="ny-header" headerContent={headerContent()} />
                <Layout>
                    <LayoutMenu
                        menuItems={menu}
                        menuHistory={history}
                        menuLocation={location}
                        menuTheme={'dark'}
                        siderClassName="ny-sider"
                        menuClassName="ny-menu"
                        menuLogo={MenuLogo(appLogo)}
                    />
                    <Layout className="main-layout">
                        <Content className="main-content thin-scrollbar">
                            <Switch>
                                {generateRoutes(mainRoutes)}
                                <Route
                                    path="*"
                                    render={(props) => (
                                        <Result
                                            status="404"
                                            title="404"
                                            subTitle={geti18nText('app.default.404')}
                                            extra={
                                                <Button type="primary">{geti18nText('app.default.404.back')}</Button>
                                            }
                                        />
                                    )}
                                />
                            </Switch>
                            {showModal()}
                        </Content>
                    </Layout>
                </Layout>
            </Layout>
        );
    }
};
export default LayoutMain;
