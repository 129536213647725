import { geti18nText, NyRequestResolver, NySession } from '@nybble/nyreact';
import React, { useEffect, useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import SSE from '../../components/layout-main/see';
import { CONSTANTS_REQ } from '../../utils/Constants';
import { formatCurrency } from '../../utils/Utils';
const LiveCounting = (props: any) => {
    const [liveDataCount, setLiveDataCount] = useState<any>(0);
    // const [liveDataRejected, setLiveDataRejected] = useState<any>(0);
    const [liveDataSum, setLiveDataSum] = useState<any>(0);
    const [liveDataCurrency, setLiveDataSumCurrency] = useState<any>(undefined);
    const eventSourceRef = useRef<any>(null);
    const deviceLivePreviewId = useRef<any>(null);
    const closeMolda = useRef<any>(false);

    useEffect(() => {
        if (props.deviceId != null) {
            startListeningForDeviceLiveCounting();
        }
        return () => {
            closeMolda.current = true;
            eventSourceRef.current.close();
            stopLiveCounting(deviceLivePreviewId?.current);
        };
    }, [props.deviceId]);

    function startListeningForDeviceLiveCounting() {
        if (closeMolda.current == true) return;
        console.log('Open conection... ');
        if (NySession.isUserAuthenticated()) {
            let previewId = uuidv4();
            deviceLivePreviewId.current = previewId;
            eventSourceRef.current = new SSE(
                CONSTANTS_REQ.DEVICE.DEVICE_LIVE_COUNTING + '?deviceId=' + props.deviceId + '&previewId=' + previewId,
                {
                    headers: {
                        Authorization: 'Bearer ' + NySession.getUserToken(),
                    },
                }
            );

            eventSourceRef.current.onmessage = (e: any) => {
                if (e && e.data) {
                    let data = JSON.parse(e.data);
                    setLiveDataSum(data.sum);
                    setLiveDataCount(data.quantity);
                    setLiveDataSumCurrency(data.currency);
                    // setLiveDataRejected(data.quantityRejected);
                }
            };

            eventSourceRef.current.onerror = (e: any) => {
                console.log('sse error');
                console.log(e);
                eventSourceRef.current.close();
                startListeningForDeviceLiveCounting();
            };

            eventSourceRef.current.stream();
        }
    }

    function stopLiveCounting(deviceLivePreviewId: any) {
        NyRequestResolver.requestGet(CONSTANTS_REQ.DEVICE.DEVICE_STOP_COUNTING, {
            deviceId: props.deviceId,
            previewId: deviceLivePreviewId,
        }).then((res: any) => {
            // console.log('Stop live count ', deviceLivePreviewId);
        });
    }
    return (
        <div style={{ textAlign: 'right', height: 'calc(85vh - 50px)', justifyContent: 'center' }}>
            <div
                style={{
                    textAlign: 'right',
                    flexGrow: 1,
                    display: 'flex',
                    justifyContent: 'end',
                    backgroundColor: '#055cf2',
                    alignItems: 'center',
                    marginLeft: '20%',
                    height: 'calc( 50% - 10px )',
                    borderRadius: '2px',
                    border: '3px solid #f0f0f0',
                }}
            >
                <div style={{ marginLeft: 'auto' }}>
                    <div style={{ fontSize: '100px', color: 'white' }}>
                        {liveDataCount} {geti18nText('device.live.counting.view.pcs')}{' '}
                    </div>
                </div>
            </div>

            <div
                style={{
                    textAlign: 'right',
                    flexGrow: 1,
                    display: 'flex',
                    backgroundColor: '#e8e8e8',
                    marginLeft: '20%',
                    alignItems: 'center',
                    height: 'calc( 50% - 10px )',
                    borderRadius: '2px',
                    border: '3px solid #f0f0f0',
                }}
            >
                <div>
                    <div style={{ fontSize: '100px', color: '#055cf2' }}>
                        {geti18nText('device.live.counting.view.total')}{' '}
                    </div>
                </div>
                <div
                    style={{
                        width: '100%',
                        justifyContent: 'end',
                        display: 'flex',
                    }}
                >
                    <div
                        style={{
                            fontSize: '100px',
                            color: '#055cf2',
                            display: 'flex',
                            alignItems: 'center',
                            marginLeft: 'auto',
                        }}
                    >
                        {formatCurrency(liveDataSum, liveDataCurrency)}{' '}
                        <div style={{ marginLeft: '20px', fontSize: '70px' }}>{liveDataCurrency}</div>{' '}
                    </div>
                </div>
            </div>
            {/* {liveDataRejected > 0 && (
                <div
                    style={{
                        textAlign: 'left',
                        flexGrow: 0,
                        display: 'flex',
                        justifyContent: 'start',
                        backgroundColor: 'white',
                        alignItems: 'center',
                        marginLeft: '20%',
                        height: 'calc( 10% - 10px )',
                    }}
                >
                    <div>
                        <div
                            style={{
                                fontSize: '30px',
                                color: 'gray',
                                paddingLeft: '10px',
                                minWidth: '200px',
                                paddingRight: '10px',
                                border: '1px solid #055cf2',
                            }}
                        >
                            {'RJ '}
                            {liveDataRejected} {geti18nText('device.live.counting.view.pcs')}{' '}
                        </div>
                    </div>
                </div>
            )} */}
        </div>
    );
};

export default LiveCounting;
