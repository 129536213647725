import { geti18nText, NySession } from '@nybble/nyreact';

const DeviceCSVColumnsExport: any = (isAdmin?:boolean) => {
    let csvColumns= [
        {
            title: geti18nText('device.table.column.id'),
            dataIndex: 'id',
        },
        {
            title: geti18nText('device.table.column.name'),
            dataIndex: 'name',
        },
        {
            title: geti18nText('device.information.table.model'),
            dataIndex: ['model', 'name'],
        },
        {
            title: geti18nText('device.information.table.model.type'),
            dataIndex: ['model', 'type'],
        },
        {
            title: geti18nText('device.table.column.office'),
            dataIndex: ['office', 'name'],
        },
        {
            title: geti18nText('office.table.column.hnb_unit_code'),
            dataIndex: ['office', 'hnbUnitCode'],
        },
        {
            title: geti18nText('device.table.column.ip'),
            dataIndex: 'ip',
        },
        {
            title: geti18nText('device.table.column.port'),
            dataIndex: 'port',
        },

        {
            title: geti18nText('device.edit.connection_type'),
            dataIndex: 'connectionType',
        },
        {
            title: geti18nText('device.edit.device.mode'),
            dataIndex: 'mode',
        },
        {
            title: geti18nText('device.edit.hnb.daily_report'),
            dataIndex: 'dailyReport',
        },
        {
            title: geti18nText('device.edit.hnb.monthly_report'),
            dataIndex: 'monthlyReport',
        },
        {
            title: geti18nText('device.edit.pbz.id'),
            dataIndex: 'pbzId',
        },
        {
            title: geti18nText('device.edit.moxa_serial_number'),
            dataIndex: 'moxaSerialNumber',
        },

        {
            title: geti18nText('device.table.column.serial.number'),
            dataIndex: 'serialNumber',
        },
        {
            title: geti18nText('device.table.column.manufacturer'),
            dataIndex: ['manufacturer', 'name'],
        },
        {
            title: geti18nText('device.table.column.inventory.number'),
            dataIndex: 'inventoryNumber',
        },
        {
            title: geti18nText('device.edit.maintenance.contract'),
            dataIndex: 'maintenanceContractDate',
        },

        {
            title: geti18nText('device.table.column.vendor'),
            dataIndex: ['vendor', 'name'],
        },
        {
            title: geti18nText('device.edit.tab.details.service.vendor'),
            dataIndex: ['service_vendor', 'name'],
        },
        {
            title: geti18nText('device.edit.warranty'),
            dataIndex: 'warranty_date',
        },
        {
            title: geti18nText('device.table.column.group.names'),
            dataIndex: 'groupId',
        },{
            title: geti18nText('device.table.column.ecb'),
            dataIndex: 'ecb',
        },
        {
            title: geti18nText('device.table.column.last.activity'),
            dataIndex: 'lastActivity',
        },
        {
            title: geti18nText('manufacturer.alarm.table.active'),
            dataIndex: 'active',
        },

        {title: geti18nText('device.table.column.valid'),
        dataIndex: 'correct'},
        {title: geti18nText('device.table.column.repair'),
        dataIndex: 'repair'},
        {
        title: geti18nText('device.reference.csv.label'),
        dataIndex: 'comment',
        },
    ];

    if(NySession.hasAnyRole(['RULE_PREVIEW_DEVICE_WRITTEN_OFF']) &&  isAdmin === true) {

        csvColumns.splice(csvColumns.length-1, 0, {title: geti18nText('device.table.column.written.off'),
        dataIndex: 'writtenOff'});
    }

    return csvColumns;
};
export default DeviceCSVColumnsExport;
