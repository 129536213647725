import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Modal, notification } from 'antd';
import {
    getColumnDateOption,
    getColumnSearch,
    geti18nText,
    NyDataTable,
    NyRequestResolver,
    NySession,
    NyUtils,
    RESPONSE,
} from '@nybble/nyreact';
import { CONSTANTS_REQ } from '../../../utils/Constants';
import { RootState } from '../../../rootReducer';
import AddToGroup from './AddToGroup';
import { GetEnumNameForValue } from '../../../utils/Enums';
import { useEffect } from 'react';
import { refreshGroupList } from '../../../slices/selectedDeviceGroupSlice';
import DeviceColumns from '../device/columns';
import DeviceCSVColCistomization from '../device/csvColumnsCustom';
import DeviceCSVColumnsExport from '../device/csvDeviceColumnsExport';

const DeleteFromGroup = (
    hasSelected: any,
    selectedRowKeys: any,
    selectedRows: any,
    onPopupSave: any,
    clearSelectedRowKeys: any
) => {
    const [visible, setVisible] = useState(false);
    const [visibleAdd, setVisibleAdd] = useState(false);
    const { group, refreshGroup } = useSelector((state: RootState) => state.selectedDeviceGroup);
    const dispatch = useDispatch();

    const showModal = () => {
        setVisible(true);
    };

    const showModalAdd = () => {
        setVisibleAdd(true);
    };

    const handleCancel = () => {
        setVisible(false);
    };

    const handleCancelAdd = () => {
        setVisibleAdd(false);
    };

    useEffect(() => {
        setVisibleAdd(false);
        clearSelectedRowKeys();
    }, [refreshGroup]);

    const handleOk = () => {
        NyRequestResolver.requestPost(CONSTANTS_REQ.DEVICE_GROUP.DELETE_DEVICES_FROM_GROUP, undefined, {
            groupId: Number(group),
            devices: selectedRowKeys,
        }).then((result: any) => {
            if (result.status === RESPONSE.OK) {
                setVisible(false);
                notification.success({
                    message: geti18nText('app.default.save.ok'),
                    description: geti18nText('app.default.save.ok.desc'),
                    duration: 3,
                });
                clearSelectedRowKeys();
                dispatch(refreshGroupList());
            }
        });
    };

    function setDefaultFilterValue() {
        
        if(NySession.hasAnyRole(['RULE_PREVIEW_DEVICE_WRITTEN_OFF'])) {
            return [ { field: 'groupId', condition: 'not_equals', value: group }];
        }
        else { 
            return [{ field: 'writtenOff', condition: 'equals_bool', value: 0 }, 
                { field: 'groupId', condition: 'equals', value: group }
        
            ];
        }
    }

    return (
        <React.Fragment>
            <Button type="primary" danger disabled={!hasSelected} onClick={showModal}>
                {geti18nText('device.group.delete.from.group.button')}
            </Button>

            <Button type="primary" style={{ marginLeft: '20px' }} onClick={showModalAdd}>
                {geti18nText('device.group.add.to.group.button')}
            </Button>

            <Modal
                title={geti18nText('device.group.delete.from.group.modal.title')}
                visible={visible}
                onCancel={handleCancel}
                okButtonProps={{ danger: true }}
                okText={geti18nText('device.group.delete.from.group.modal.okText')}
                onOk={handleOk}
            >
                {geti18nText('device.group.delete.from.group.modal.text')}
            </Modal>
            <Modal
                title={geti18nText('device.group.add.to.group.modal.title')}
                visible={visibleAdd}
                onCancel={handleCancelAdd}
                width={1500}
                footer={[
                    <Button key="back" onClick={handleCancelAdd}>
                        {geti18nText('app.default.button.cancel')}
                    </Button>,
                ]}
            >
                <NyDataTable
                    headerTitle={geti18nText('device.table.header')}
                    url={CONSTANTS_REQ.DEVICE.LIST}
                    showRecordModal={false}
                    hideButtons={false}
                    hideNewButton={true}
                    readonly={true}
                    fetchWhenChange={refreshGroup}
                    setDefaultFilterValue={setDefaultFilterValue}
                    setDefaultPageSize={10}
                    scroll={{ x: 1200 }}
                    columns={DeviceColumns({isAdmin:true})}
                    csvColumns={DeviceCSVColumnsExport()}
                    showRowSelection={true}
                    rowSelectionModal={AddToGroup}
                    exportCSV={true}
                    colCSVCustomization={DeviceCSVColCistomization()}
                />
            </Modal>
        </React.Fragment>
    );
};

export default DeleteFromGroup;
