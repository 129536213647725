import { createSlice } from '@reduxjs/toolkit';
import { getNotificationData } from '../components/notification';
import { GetEnumNameForValue } from '../utils/Enums';
import { setEnumFormat } from '../utils/Utils';

interface INotificationState {
    notifications: any;
    count: any;
}

const initialState: INotificationState = {
    notifications: [],
    count: {
        ALL: 0,
        ATTENDANCE_LOGOUT_NOTIFICATION: 0,
        ATTENDANCE_LOGOUT_NOTIFICATION_SUPERVISOR: 0,
        EMPLOYEE_CONTRACT_EXPIRATION: 0,
        EMPLOYEE_LEAVE_APPROVAL: 0,
        EMPLOYEE_LEAVE_REQUEST: 0,
        EMPLOYEE_TAX_DEDUCTION: 0,
    },
};

const notification = createSlice({
    name: 'notification',
    initialState,
    reducers: {
        notificationInit(state) {
            state.notifications = [];
            state.count.ALL = 0;
            state.count.ATTENDANCE_LOGOUT_NOTIFICATION = 0;
            state.count.ATTENDANCE_LOGOUT_NOTIFICATION_SUPERVISOR = 0;
            state.count.EMPLOYEE_CONTRACT_EXPIRATION = 0;
            state.count.EMPLOYEE_LEAVE_APPROVAL = 0;
            state.count.EMPLOYEE_LEAVE_REQUEST = 0;
            state.count.EMPLOYEE_TAX_DEDUCTION = 0;
        },
        addNotification(state, action) {
            let type = action.payload && action.payload[0] ? action.payload[0] : 'ALL';
            delete action.payload[0];
            if (type === 'ALL') {
                state.notifications = action.payload;
                state.count['ALL'] = 0;
                for (const [key, value] of Object.entries(action.payload)) {
                    let enumKey: any = GetEnumNameForValue({
                        enumName: 'NOTIFICATION_ALARM_TYPE',
                        value: parseInt(key),
                    });

                    state.count[enumKey] = action.payload[key]?.unread;
                    state.count['ALL'] = state.count['ALL'] + state.count[enumKey];
                }
            } else {
                for (const [key, value] of Object.entries(action.payload)) {
                    let enumKey: any = GetEnumNameForValue({
                        enumName: 'NOTIFICATION_ALARM_TYPE',
                        value: parseInt(key),
                    });

                    if (state.notifications.hasOwnProperty(key)) {
                        let newNotification: any = value;
                        state.notifications[key].content = [
                            ...newNotification.content,
                            ...state.notifications[key].content,
                        ];
                        state.count[enumKey] = state.count[enumKey] + action.payload[key].content?.length;
                        state.notifications[key].unread = state.count[enumKey];
                    } else {
                        let newNotification: any = value;
                        state.notifications[key] = newNotification;
                        state.count[enumKey] = action.payload[key].content?.length;
                        state.notifications[key].unread = action.payload[key].content?.length;
                    }

                    state.count['ALL'] = state.count['ALL'] + action.payload[key].content?.length;

                    // send chrome notification
                    if (Notification.permission === 'granted') {
                        let typeName: any = setEnumFormat('NOTIFICATION_ALARM_TYPE', Number(key));
                        let newNotifications: any = value;
                        newNotifications.content.forEach((element: any) => {
                            let notificationData: any = getNotificationData(element);
                            let notification = new Notification(typeName && typeName.text ? typeName.text : '', {
                                body: notificationData.content,
                            });
                            if (notificationData.link) {
                                let link = notificationData.link;
                                notification.onclick = function () {
                                    if (link.function === 'addTabPane') {
                                        // AddedFunctions.addTabPane(link.path, link.key, link.id, link.notification);
                                    } else if (link.function === 'openItemInTab') {
                                        // openItemInTab(
                                        //     link.path,
                                        //     link.modalName,
                                        //     link.item,
                                        //     link.notification,
                                        //     link.title
                                        // );
                                    }
                                    window.focus();
                                };
                            }
                        });
                    }
                }
            }
        },
        readNotification(state, action) {
            let notification = action.payload;
            let tmpNotifications = { ...state.notifications };
            let enumKey: any = GetEnumNameForValue({
                enumName: 'NOTIFICATION_ALARM_TYPE',
                value: parseInt(notification.notificationType),
            });
            let count = 0;
            tmpNotifications[notification.notificationType].content = tmpNotifications[
                notification.notificationType
            ].content.map((obj: any) => {
                if (obj.id === notification.id) {
                    count = obj.unread ? -1 : 1;
                    return { ...obj, unread: !obj.unread };
                }
                return obj;
            });
            state.count[enumKey] = state.count[enumKey] + count;
            state.count['ALL'] = state.count['ALL'] + count;
            tmpNotifications[notification.notificationType].unread =
                tmpNotifications[notification.notificationType].unread + count;
            state.notifications = tmpNotifications;
        },
        readAllNotificationByType(state, action) {
            let notificationType = action.payload;
            let tmpNotifications = { ...state.notifications };
            let enumKey: any = GetEnumNameForValue({
                enumName: 'NOTIFICATION_ALARM_TYPE',
                value: parseInt(notificationType),
            });
            let count = 0;
            tmpNotifications[notificationType].content = tmpNotifications[notificationType].content.map((obj: any) => {
                if (obj.unread) {
                    count++;
                }
                return { ...obj, unread: false };
            });
            state.count['ALL'] = state.count['ALL'] - state.count[enumKey];
            state.count[enumKey] = 0;
            tmpNotifications[notificationType].unread = 0;

            state.notifications = tmpNotifications;
        },
        readAllNotification(state) {
            let tmpNotifications = { ...state.notifications };
            for (const [key, value] of Object.entries(tmpNotifications)) {
                tmpNotifications[key].content = tmpNotifications[key].content.map((obj: any) => {
                    return { ...obj, unread: false };
                });
                tmpNotifications[key].unread = 0;
            }
            state.notifications = tmpNotifications;
            let count = state.count;
            Object.keys(count).forEach(function (key: any) {
                count[key] = 0;
            });
            state.count = count;
        },
        deleteNotification(state, action) {
            let notification = action.payload;
            let enumKey: any = GetEnumNameForValue({
                enumName: 'NOTIFICATION_ALARM_TYPE',
                value: parseInt(notification.notificationType),
            });
            let tmpNotifications = { ...state.notifications };
            console.log(tmpNotifications);
            let count = 0;
            tmpNotifications[notification.notificationType].content = tmpNotifications[
                notification.notificationType
            ].content.filter((row: any) => {
                if (row.id === notification.id && row.unread) {
                    count++;
                }

                return row.id !== notification.id;
            });
            state.count[enumKey] = state.count[enumKey] - count;
            state.count['ALL'] = state.count['ALL'] - count;
            tmpNotifications[notification.notificationType].unread =
                tmpNotifications[notification.notificationType].unread - count;

            console.log(tmpNotifications);
            if (tmpNotifications[notification.notificationType].content.length > 0) {
                state.notifications = tmpNotifications;
            } else {
                state.notifications = {};
            }
        },
        deleteAllNotificationByType(state, action) {
            let notificationType = action.payload;
            let enumKey: any = GetEnumNameForValue({
                enumName: 'NOTIFICATION_ALARM_TYPE',
                value: parseInt(notificationType),
            });
            let tmpNotifications = { ...state.notifications };
            delete tmpNotifications[notificationType];
            state.count['ALL'] = state.count['ALL'] - state.count[enumKey];
            state.count[enumKey] = 0;
            //tmpNotifications[notificationType].unread = 0;
            state.notifications = tmpNotifications;
        },
        deleteAllNotification(state) {
            let tmpNotifications = { ...state.notifications };
            for (const [key, value] of Object.entries(tmpNotifications)) {
                tmpNotifications[key].unread = 0;
            }
            state.notifications = [];
            let count = state.count;
            Object.keys(count).forEach(function (key: any) {
                count[key] = 0;
            });
            state.count = count;
        },
    },
});

export const {
    notificationInit,
    addNotification,
    readNotification,
    readAllNotificationByType,
    readAllNotification,
    deleteNotification,
    deleteAllNotificationByType,
    deleteAllNotification,
} = notification.actions;

export default notification.reducer;
