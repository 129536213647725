import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import {
    ENUMS,
    getColumnDateOption,
    getColumnSearch,
    getColumnSearchOption,
    geti18nText,
    NyDataTable,
    NySession,
    NyUtils,
} from '@nybble/nyreact';
import { Tabs } from 'antd';
import React, { useState } from 'react';
import { CONSTANTS_REQ } from '../../utils/Constants';
import DeviceErrorColumns, { DetailsErrorCSVColCustomization } from './columns';
const DeviceErrorIndex = (props: any) => {
    const { TabPane } = Tabs;

    function setDefaultFilterValue() {
        if (props.deviceId != null) {
            const id = parseInt(props.deviceId);
            if (id != undefined) return [{ field: 'device.id', condition: 'equals', value: id }];
        }

        return [];
    }

    return (
        <NyDataTable
            headerTitle={geti18nText('device.error.table.header')}
            url={CONSTANTS_REQ.DEVICE_ERROR.LIST}
            setDefaultPageSize={10}
            exportCSV={true}
            showRecordModal={false}
            hideButtons={true}
            showRowSelection={false}
            setDefaultFilterValue={setDefaultFilterValue}
            readonly={true}
            columns={DeviceErrorColumns()}
            colCSVCustomization={DetailsErrorCSVColCustomization()}
        />
    );
};

export default DeviceErrorIndex;
