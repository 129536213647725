import { geti18nText, NyDataEdit, NyDataTable, NySearchField, NySession, NyUtils } from '@nybble/nyreact';
import { Col, Form, Input, InputNumber, Row, Tabs } from 'antd';
import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { CONSTANTS_REQ } from '../../../utils/Constants';
import { GetEnum, GetEnumNameForValue } from '../../../utils/Enums';

const AdminDenominationCurrencyEdit = (props: any) => {
    const [editHeader, setEditHeader] = useState(geti18nText('currency.denomination.add.new'));
    const [loading, setLoading] = useState(false);
    const { id } = useParams<any>();
    const history = useHistory();
    const [form] = Form.useForm();
    const { TabPane } = Tabs;

    const onModalClose = () => {
        setEditHeader(geti18nText('currency.denomination.add.new'));
        form.resetFields();
    };
    function setValues(dataForm: any) {
        const typeIndex = currencyType().findIndex((x) => x.id == dataForm.type);
        if (typeIndex > -1) {
            dataForm.type = currencyType()[typeIndex];
        }
        setEditHeader(
            geti18nText(
                'currency.type.' +
                    GetEnumNameForValue({ enumName: 'CURRENCY_TYPE', value: dataForm.type })?.toLowerCase()
            ) +
                ' - ' +
                dataForm.value
        );
        form.setFieldsValue(dataForm);
    }

    const currencyType = () => {
        const aTypes = GetEnum({ enumName: 'CURRENCY_TYPE' });
        let types = [];
        for (var key in aTypes) {
            let ret = { id: undefined, text: '' };
            ret.id = aTypes[key];
            ret.text = geti18nText('currency.type.' + key.toLowerCase());
            types.push(ret);
        }

        return types;
    };

    function normalize(data: any) {
        data.type = data.type.id;
        return data;
    }

    return (
        <NyDataEdit
            layout="horizontal"
            editHeader={editHeader}
            loading={loading}
            setLoading={setLoading}
            onModalClose={onModalClose}
            url={CONSTANTS_REQ.CURRENCY_DENOMINATIONS.EDIT}
            setValues={setValues}
            width={600}
            form={form}
            goBack={() => history.goBack()}
            paramsId={id}
            {...props}
            hideButtons={!NySession.hasAnyRole(['RULE_CRUD_BRANCH'])}
            normalize={normalize}
        >
            <Tabs defaultActiveKey="1" type="card">
                <TabPane tab={geti18nText('currency.edit.tab.info')} key="1">
                    <Row>
                        <Row gutter={24}>
                            <Form.Item name="id" style={{ display: 'none' }}>
                                <Input readOnly={true} />
                            </Form.Item>
                            <Col span={12}>
                                <Form.Item
                                    rules={[
                                        {
                                            required: true,
                                            message: geti18nText('app.default.required'),
                                        },
                                    ]}
                                    label={geti18nText('currency.denomination.table.column.value')}
                                    name="value"
                                >
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    rules={[
                                        {
                                            required: true,
                                            message: geti18nText('app.default.required'),
                                        },
                                    ]}
                                    label={geti18nText('currency.denomination.table.column.type')}
                                    name="type"
                                >
                                    <NySearchField
                                        style={{ width: '100%' }}
                                        options={currencyType()}
                                        map={{ id: 'id', label: 'text' }}
                                        searchBy="text"
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                    label={geti18nText('currency.denomination.table.column.gtin.code')}
                                    name="gtinCode"
                                >
                                    <Input />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Row>
                </TabPane>
            </Tabs>
        </NyDataEdit>
    );
};

export default AdminDenominationCurrencyEdit;
