import { geti18nText, NyDataEdit, NyUtils } from '@nybble/nyreact';
import { Col, Form, Row, Tabs, Descriptions } from 'antd';
import { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { CONSTANTS_REQ } from '../../../utils/Constants';
import { getNotificationData } from '../../../components/notification';
import { useDispatch } from 'react-redux';
import { setActive } from '../../../slices/menuSlice';
import { setModalOpen } from '../../../slices/modalOpenSlice';
import { getTimezone } from '../../../utils/Utils';

const AdminSentNotificationView = (props: any) => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const { id } = useParams<any>();
    const history = useHistory();
    const [form] = Form.useForm();
    const [data, setData] = useState<any>(undefined);
    const [notificationData, setNotificationData] = useState<any>(undefined);

    function setValues(dataForm: any) {
        setNotificationData(getNotificationData(dataForm));
        setData(dataForm);
    }

    const openItemInTab = (path: any, modalName: any, item: any, notif: any, title: any) => {
        dispatch(setActive('/device'));
        dispatch(
            setModalOpen({
                modal: modalName,
                visible: true,
                item: item.refId,
            })
        );
    };

    const openCert = () => {
        dispatch(setActive('/cert'));
    };

    const openInstances = () => {
        dispatch(setActive('/admin/instances'));
    };

    const getLink = (link: any) => {
        if (link.function === 'openLicense') {
            openCert();
        } else if (link.function === 'openInstances') {
            openInstances();
        } else openItemInTab(link.path, link.modalName, link.item, link.notification, link.title);
    };

    return (
        <NyDataEdit
            editHeader={geti18nText('tab.notification.alarm.sent.details')}
            loading={loading}
            setLoading={setLoading}
            url={CONSTANTS_REQ.NOTIFICATION.EDIT}
            setValues={setValues}
            width={1500}
            form={form}
            hideButtons={true}
            goBack={() => history.goBack()}
            paramsId={id}
            {...props}
        >
            {data && (
                <Row>
                    <Col span={24}>
                        <Descriptions>
                            <Descriptions.Item label="Primatelj">{data.user.fullName}</Descriptions.Item>
                            <Descriptions.Item label="Kreirano">
                                {new Date(data.created).toLocaleString(NyUtils.getSelectedLocale(), getTimezone())}
                            </Descriptions.Item>
                            <Descriptions.Item label="Tip obavijesti/alarm">
                                {notificationData.content}
                                <span
                                    onClick={() => {
                                        if (notificationData.link) {
                                            getLink(notificationData.link);
                                        }
                                    }}
                                    style={{
                                        cursor: 'pointer',
                                    }}
                                >
                                    &nbsp;{data.refId}
                                </span>
                            </Descriptions.Item>
                        </Descriptions>
                    </Col>
                </Row>
            )}
            {data && data.message && (
                <Row style={{ marginTop: '50px' }}>
                    <Col span={2}></Col>
                    <Col span={20}>
                        <div style={{ width: '100%' }} dangerouslySetInnerHTML={{ __html: data.message }} />
                    </Col>
                    <Col span={2}></Col>
                </Row>
            )}
        </NyDataEdit>
    );
};

export default AdminSentNotificationView;
