import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import {
    geti18nText,
    NyDataEdit,
    NySession,
    NySearchField,
    NyDataTable,
    ENUMS,
    getColumnDateOption,
    getColumnSearch,
    getColumnSearchOption,
} from '@nybble/nyreact';
import { Col, Descriptions, Form, Input, Row, Select, Tabs } from 'antd';
import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { CONSTANTS_REQ, URL_PREFIX } from '../../utils/Constants';
const { TabPane } = Tabs;

const UserGroupEdit = (props: any) => {
    const [editHeader, setEditHeader] = useState(geti18nText('user.group.table.header'));
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();
    const history = useHistory();
    const { id } = useParams<any>();
    const [isCreate, setIsCreate] = useState(false);
    const [userItem, setUserItem] = useState<any>(null);
    const [groupId, setGroupId] = useState(false);

    const onModalClose = () => {
        form.resetFields();
        setEditHeader(geti18nText('user.group.table.header'));
    };

    function setValues(dataForm: any) {
        console.log(dataForm);
        delete dataForm.password;
        if (Array.isArray(dataForm.userGroupRoles)) {
            dataForm.roles = dataForm.userGroupRoles.map((userRole: { [index: string]: any }) => {
                return { key: userRole.id, label: userRole.authority };
            });
        }
        form.setFieldsValue(dataForm);
        setUserItem(dataForm);
        setGroupId(dataForm.id);
    }

    const validateAuthority = (rule: any, value: any, callback: any) => {
        if (!/^[a-zA-Z0-9-_]*$/.test(value)) {
            callback(geti18nText('user.group.edit.name.validate'));
            return;
        }
        callback();
    };

    function normalizeValues(values: any) {
        console.log(values);
        let normalized = { ...values };
        normalized.active = 'active' in values ? values['active'] : true;
        normalized.roles = Array.isArray(form.getFieldValue('roles'))
            ? form.getFieldValue('roles').map((selectedRole: any) => {
                  let ret: { [index: string]: any } = {};
                  if (selectedRole.hasOwnProperty('value')) {
                      ret.id = parseInt(selectedRole.value, 10);
                  } else if (selectedRole.hasOwnProperty('id')) {
                      ret.id = parseInt(selectedRole.id, 10);
                  } else if (selectedRole.hasOwnProperty('key')) {
                      ret.id = parseInt(selectedRole.key, 10);
                  }
                  return ret;
              })
            : null;

        return normalized;
    }

    return (
        <NyDataEdit
            layout="horizontal"
            formProps={{ labelCol: { span: 7 }, wrapperCol: { span: 17 } }}
            editHeader={editHeader}
            loading={loading}
            setLoading={setLoading}
            onModalClose={onModalClose}
            url={CONSTANTS_REQ.USER_GROUP.EDIT}
            setValues={setValues}
            width={600}
            form={form}
            goBack={() => history.goBack()}
            paramsId={id}
            setIsCreate={setIsCreate}
            normalize={normalizeValues}
            hideButtons={!NySession.hasAnyRole(['RULE_UPDATE_USER_GROUP'])}
            {...props}
        >
            <Tabs defaultActiveKey="1" type="card">
                <TabPane tab={geti18nText('user.group.edit.tab.general')} key="1">
                    <Row gutter={24}>
                        <Col span={22}>
                            <Form.Item name="id" style={{ display: 'none' }}>
                                <Input />
                            </Form.Item>
                            <Form.Item
                                label={geti18nText('user.group.edit.name')}
                                name="name"
                                normalize={(value) => (value || '').toUpperCase()}
                                rules={[
                                    {
                                        required: true,
                                        message: geti18nText('app.default.required'),
                                    },
                                    {
                                        min: 3,
                                        message: geti18nText('user.group.edit.name.length.validate'),
                                    },
                                    {
                                        validator: validateAuthority,
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={22}>
                            <Form.Item
                                label={geti18nText('user.group.edit.roles')}
                                name="roles"
                                rules={[
                                    {
                                        required: true,
                                        message: geti18nText('app.default.required'),
                                    },
                                ]}
                            >
                                <NySearchField
                                    url={CONSTANTS_REQ.ROLE.SEARCH}
                                    map={{ id: 'id', label: 'text' }}
                                    searchBy="text"
                                    mode="multiple"
                                    onChange={onchange}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}></Col>
                    </Row>
                </TabPane>
                <TabPane disabled={isCreate} tab={geti18nText('user.group.edit.tab.users')} key="2">
                    {groupId && (
                        <>
                            <NyDataTable
                                headerTitle={geti18nText('users.table.header')}
                                url={CONSTANTS_REQ.USER.LIST_BY_GROUP}
                                showRecordModal={false}
                                hideButtons={!NySession.hasAnyRole(['RULE_UPDATE_USER'])}
                                hideNewButton={true}
                                readonly={true}
                                addedData={{ groupId: groupId }}
                                setDefaultPageSize={20}
                                exportCSV={true}
                                columns={[
                                    {
                                        title: geti18nText('users.table.column.id'),
                                        dataIndex: 'id',
                                        width: '10%',
                                        sorter: (a: any, b: any) => {},
                                        ...getColumnSearch('number'),
                                    },
                                    {
                                        title: geti18nText('users.table.column.username'),
                                        dataIndex: 'username',
                                        sorter: (a: any, b: any) => {},
                                        ...getColumnSearch('string'),
                                    },
                                    {
                                        title: geti18nText('users.table.column.last_name'),
                                        dataIndex: 'firstName',
                                        sorter: (a: any, b: any) => {},
                                        ...getColumnSearch('string'),
                                    },
                                    {
                                        title: geti18nText('users.table.column.first_name'),
                                        dataIndex: 'lastName',
                                        sorter: (a: any, b: any) => {},
                                        ...getColumnDateOption(true,undefined,undefined,true),
                                    },
                                    {
                                        title: geti18nText('users.table.column.active'),
                                        dataIndex: 'active',
                                        width: '10%',
                                        render: (text: any, record: { active: any }) => {
                                            if (record.active) {
                                                return <CheckOutlined style={{ color: 'green' }} />;
                                            } else {
                                                return <CloseOutlined style={{ color: 'red' }} />;
                                            }
                                        },
                                        ...getColumnSearchOption(ENUMS.ACTIVE(), 'equals_bool', 1),
                                    },
                                ]}
                            />
                        </>
                    )}
                </TabPane>
            </Tabs>
        </NyDataEdit>
    );
};

export default UserGroupEdit;
