import { CloseOutlined } from "@ant-design/icons";
import { geti18nText, NyRequestResolver, NySpinner, RESPONSE } from "@nybble/nyreact";
import { Button, Col, Row, Typography } from "antd";
import L from 'leaflet';
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import 'leaflet/dist/leaflet.css';
import { useEffect, useRef, useState } from "react";
import { Map, Marker, Popup, TileLayer } from 'react-leaflet';
import MarkerClusterGroup from "react-leaflet-markercluster";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { RootState } from "../../rootReducer";
import { setMapBounds } from '../../slices/dashboardSlice';
import { setActive } from "../../slices/menuSlice";
import { CONSTANTS_REQ } from "../../utils/Constants";




let DefaultIcon = L.icon({
  iconSize: [25, 41],
  iconAnchor: [10, 41],
  popupAnchor: [2, -40],
  iconUrl: icon,
  shadowUrl: iconShadow
});

L.Marker.prototype.options.icon = DefaultIcon;



export const OfficeMapProps = (props:any) => {
    
    function setSettings() {
       
        props.addWidget({});
                 
    }

    return (
    <>

    <Row>
        <Col span={24} style={{ textAlign: 'right' }}>
            <Button type="primary"  style={{ marginRight: "1em" }} onClick={() => setSettings()}>{geti18nText("dashboard.widget.button.add")}</Button>
        </Col>
    </Row>
    </>);
};

export const OfficeMap = (props: any) => {
    const [loading, setLoading] = useState(false);
    const [filterItems, setFilterItems] = useState<any>([]);
    const { Title } = Typography;
    const { Text, Link } = Typography;
    const mapRef = useRef<Map>(null);
    const [popUp, setPopUp] = useState<any>(null);
    const [details, setDetails] = useState<any>(null);
    const [detailsFetched, setDetailsFetched] = useState<any>(false);
    let history = useHistory();
    const {  mapBounds } = useSelector((state: RootState) => state.dashboard);
    const dispatch = useDispatch();
    


    useEffect(()=>{
        fetchData();
    },[])

    useEffect(() => {
        if (mapRef.current && mapBounds != undefined) {
            let west, south, east, north;
            [west, south, east, north] = mapBounds.split(',').map(parseFloat);
            var bounds = new L.LatLngBounds(new L.LatLng(south, west), new L.LatLng(north, east));
            mapRef.current.leafletElement.fitBounds(bounds);
            mapRef.current.leafletElement.setMaxBounds(bounds.pad(0.2));
        }
    }, [mapBounds]);

    function fetchData() {
        NyRequestResolver.requestGet(
            CONSTANTS_REQ.LOCATION.LIST_ALL,
            undefined
            
        ).then((result: any) => {
            if (result.status === RESPONSE.OK) {
                setFilterItems(result.data.filter((d:any)=>{
                    return d.latitude != null && d.longitude != null
                }));
                var bounds: L.LatLngBounds | undefined = undefined;

                if (Array.isArray(result.data)) {
                    for (let pinData of result.data) {
                        if(pinData.latitude != null && pinData.longitude){
                        if (bounds === undefined)
                            bounds = L.latLngBounds([pinData.latitude, pinData.longitude], [pinData.latitude, pinData.longitude]);
                        else bounds.extend([pinData.latitude, pinData.longitude]);
                        }
                    }
                }
                if (mapRef.current && bounds != undefined) {
                    
                    dispatch(setMapBounds({ bounds: bounds.toBBoxString() }));
                    
                    
                }
                
                setLoading(false);
            }
            else {
                setLoading(false);
            }
        });
    }

    function remove() {
        props.onRemoveWidget(props.widgetKey);
    }

    function addPopup(e: any, item: any) {
        setPopUp({
            key: new Date().getTime,
            position: e.latlng,
            item: item,
        });
        NyRequestResolver.requestGet(
            CONSTANTS_REQ.DASHBOARD.DEVICES_INFO,{officeId:item.id}  
        ).then((result: any) => {
            if (result.status === RESPONSE.OK) {
                setDetailsFetched(true);
                setDetails(result.data);
                setLoading(false);
                
            }
            else {
                setDetails(null);
                setDetailsFetched(true);
                setLoading(false);
            }
        });

    }

    function openDevices(id:any, name:any) {
        setPopUp(null);
        setDetails(null);
        setDetailsFetched(false);
        history.push({
            pathname: '/device',
            state: { officeId: id, officeName:name }
          });
          dispatch(setActive('/device'));  
    }

    function detailsPopup(popUpValue:any) {
        if(!detailsFetched) return <NySpinner></NySpinner>
        else return (<>
        <b>{popUpValue.item.name}</b>
            <br />
            <br />
            <b>{geti18nText('dashboard.widget.map.popup.address')}: </b>
            {popUpValue.item.address}
            <br />
            <b>{geti18nText('dashboard.widget.map.popup.total.devices')}: </b>
            {details &&
            <> 
            {details.total}
            <br />
            <b>{geti18nText('dashboard.widget.map.popup.active.devices')}: </b>
            {details.active}
            <br />
            <b>{geti18nText('dashboard.widget.map.popup.inactive.devices')}: </b>
            {details.inactive}
            </>
            }
           
           <br />
           <br />
           <Button onClick={() => openDevices(popUpValue.item.id,popUpValue.item.name)}>
                {geti18nText('dashboard.widget.map.popup.details')}
            </Button> </>)
    }

    if (loading) {
        return <NySpinner></NySpinner>;
    }

    else return (
        <>
            <Row>   <Col span={20}>
                        <Title level={3}>{geti18nText('dashboard.widget.map.title') }</Title>
                    </Col>
                    <Col style={{textAlign:'right'}} span={4}>
                        <Button
                            shape='circle'
                            size="small"
                            style={{ border: 'none' }}
                            icon={<CloseOutlined />}
                            onClick={() => remove()}
                                    />
                    </Col>
            </Row>
            
            <div>
                    <Map
                        center={[44.3057, 16.3366]}
                        zoom={5}
                        //bounds={bounds}
                        scrollWheelZoom={true}
                        maxZoom={18}
                        className= {'markercluster-map'}
                        style={{ height: ((40 * props.h) - 40), width: '100%' }}
                        ref={mapRef}
                    >
                        <TileLayer
                            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        />
                        
                        {filterItems.map((item:any) => (
                            
                            <Marker
                                key={item.id}
                                //icon={homeIcon}
                                position={[item.latitude,item.longitude]}
                                onClick={(e: any) => addPopup(e, item)}
                                
                        ></Marker>))}
                        {popUp && (
                            <Popup key={popUp.key}  position={popUp.position} onClose={() => {setPopUp(null);
                                                                                                                setDetails(null);    }}>
                                {detailsPopup(popUp)}
                                
                            </Popup>)}
                        
                    </Map>
            </div>   
        </>
    );
};