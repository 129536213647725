import './assets/scss/index.scss';
import { NyLogin, NyRequestResolver, NySession, NyUtils, RESPONSE } from '@nybble/nyreact';
import { Spin } from 'antd';
import { title } from 'process';
import React, { useEffect, useState } from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import LayoutMain from './components/layout-main';
import { CONSTANTS_REQ, URL_PREFIX } from './utils/Constants';
import { GetEnum, LoadEnums } from './utils/Enums';
import moment from 'moment';
import { getTimezone } from './utils/Utils';

const App = () => {
    const [loading, setLoading] = useState(false);
    const [title, setTitle] = useState('');

    useEffect(() => {
        initApp();
    }, []);

    const initApp = async () => {
        if (!NyUtils.load('hr.nybble.react.defaults.BTCMonitor')) {
            await initLang().then((v: any) => {
                NyUtils.save('hr.nybble.react.defaults.BTCMonitor', { lang: v.lang });
            });
        }
        setLoading(true);
        await NySession.init();
        moment.tz.setDefault(getTimezone().timeZone);
        NyUtils.save(
            'DEVICE_GROUPS',
            NySession.getAppValue('DEVICE_GROUPS') ? NySession.getAppValue('DEVICE_GROUPS') : []
        );
        setTitle(NySession.getSetting('ux').title);
        await LoadEnums();
        NyUtils.save(
            'MANUFACTURERS',
            GetEnum({ enumName: 'MANUFACTURERS' }) ? GetEnum({ enumName: 'MANUFACTURERS' }) : []
        );
        NyUtils.save(
            'DEVICE_MODELS',
            GetEnum({ enumName: 'DEVICE_MODELS' }) ? GetEnum({ enumName: 'DEVICE_MODELS' }) : []
        );
        NyUtils.save('OFFICE', GetEnum({ enumName: 'OFFICE' }) ? GetEnum({ enumName: 'OFFICE' }) : []);
        NyUtils.save('CURRENCY', GetEnum({ enumName: 'CURRENCY' }) ? GetEnum({ enumName: 'CURRENCY' }) : []);
        setLoading(false);
    };

    const initLang = async () => {
        const rsp: any = await NyRequestResolver.requestGet(CONSTANTS_REQ.INIT.LANG_INIT);
        if (rsp && rsp.status === RESPONSE.OK && rsp.data instanceof Object) {
            return rsp.data;
        }
    };

    const LoginComponent = () => (
        <NyLogin
            login2FA={true}
            url2FA={URL_PREFIX + 'auth/login-2fa/'}
            title={title}
            redirectTo="/"
            url={URL_PREFIX + 'login'}
        />
    );

    if (loading) {
        return (
            <div className="spin-container">
                <Spin size="large" />
            </div>
        );
    } else {
        return (
            <HashRouter>
                <Switch>
                    <Route exact path="/login" component={() => <LoginComponent />} />
                    <Route path="/" component={LayoutMain} />
                </Switch>
            </HashRouter>
        );
    }
};

export default App;
